@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
main,
.mfp-content {
  overflow: hidden;
}
main a,
.mfp-content a {
  color: #005ba4;
  text-decoration: underline;
}
.linkColor {
  color: #005ba4;
}

.sectionLead {
  line-height: 2;
}

[data-background=navy] {
  color: #fff;
  background: #34405b;
}
[data-background=navy] .inner {
  color: #fff;
}

[data-background=purple] {
  background: #ececf4;
}

[data-background=pink] {
  background: #fcf2f6;
}

[data-background=gray] {
  background: #e2e2e2;
}
[data-background=white] {
  background: #fff;
}
.iconDashPink {
  display: inline-block;
  background: url(../images/common/icon_dash_pink.svg) center no-repeat;
}

.iconDashNavy {
  display: inline-block;
  background: url(../images/common/icon_dash_navy.svg) no-repeat;
  background-size: contain;
}

.pageHeader {
  background: #34405b;
}
.pageHeader .breadcrumb {
  color: #fff;
}
.pageHeader .breadcrumb br {
  display: none !important;
}

.tag.bd {
  background: #4c95d0;
}
.tag.oa {
  background: #5958ac;
}
.tag.gr {
  background: #96c55c;
}

main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
main.page2 .pageHeader {
  color: #fff;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    justify-content: flex-start !important;
    height: 200px;
  }
}
main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle,
main.page2 .pageHeader .pageTitle {
  text-align: center;
  font-weight: 400;
  position: relative;
}
main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle:before,
main.page2 .pageHeader .pageTitle:before {
  height: 2px;
  background: #c90056;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.page_company_index:not(.page2) .pageHeader .pageHeaderInner,
.page_product_index:not(.page2) .pageHeader .pageHeaderInner,
.page_document_index:not(.page2) .pageHeader .pageHeaderInner,
.page_support_index:not(.page2) .pageHeader .pageHeaderInner,
.page_data_index:not(.page2) .pageHeader .pageHeaderInner,
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
.page_works_index:not(.page2) .pageHeader .pageHeaderInner,
.page_news_index:not(.page2) .pageHeader .pageHeaderInner,
.page_column_index:not(.page2) .pageHeader .pageHeaderInner,
.page_about_index:not(.page2) .pageHeader .pageHeaderInner {
  color: #fff;
  background: #34405b;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    justify-content: flex-start !important;
    height: 300px;
  }
}
.page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
.page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle {
  font-weight: 400;
  text-align: center;
  position: relative;
}
.page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
.page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before {
  height: 2px;
  background: #c90056;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}
.page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
.page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote {
  text-align: center;
}
.page_company_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_product_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_document_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_support_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_data_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_news_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_column_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
.page_about_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
  padding-left: 45px;
}

[class^=page_voice] .pageHeader,
[class^=page_company] .pageHeader {
  background: url(../images/company/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_product] .pageHeader {
  background: url(../images/product/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_document] .pageHeader {
  background: url(../images/document/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_support] .pageHeader {
  background: url(../images/support/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_data] .pageHeader {
  background: url(../images/data/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_contact] .pageHeader {
  background: url(../images/contact/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_worries] .pageHeader {
  background: url(../images/worries/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_works] .pageHeader {
  background: url(../images/works_greening/page_works_second.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_news]:not(.page_news_index) .pageHeader {
  background: url(../images/news/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_column] .pageHeader {
  background: url(../images/column/header_bg.jpg) no-repeat center;
  background-size: cover;
}

[class^=page_about] .pageHeader {
  background: url(../images/about/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_company_index:not(.page2) .pageHeader {
  background: none;
}
.page_company_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/company/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_product_index:not(.page2) .pageHeader {
  background: none;
}
.page_product_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/product/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_document_index:not(.page2) .pageHeader {
  background: none;
}
.page_document_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/document/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_support_index:not(.page2) .pageHeader {
  background: none;
}
.page_support_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/support/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_data_index:not(.page2) .pageHeader {
  background: none;
}
.page_data_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/data/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_contact_index:not(.page2) .pageHeader {
  background: none;
}
.page_contact_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/contact/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_worries_index:not(.page2) .pageHeader {
  background: none;
}
.page_worries_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/worries/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_works_index:not(.page2) .pageHeader {
  position: relative;
}
.page_works_index:not(.page2) .pageHeader:before {
  content: "";
  position: absolute;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner {
  position: relative;
  background: none !important;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader {
  color: #000000;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderWorksHeaderCase {
  letter-spacing: 0.01em;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
  color: #000000;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb a {
  color: #000000;
}
.page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb > li + li:before {
  border-width: 0 1px 1px 0;
  border-color: #000000;
}
.oa.page_works_index:not(.page2) .pageHeader {
  background: url(../images/works_oa/header_bg.jpg) no-repeat center bottom;
  background-size: cover;
}
.busduct.page_works_index:not(.page2) .pageHeader {
  background: url(../images/works_busduct/header_bg.jpg) no-repeat center;
  background-size: cover;
}
.greening.page_works_index:not(.page2) .pageHeader {
  background: url(../images/works_greening/header_bg.jpg) no-repeat center;
  background-size: cover;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap {
  color: #000;
  position: relative;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .pageHeaderWorksMapTitle {
  line-height: 1.2;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li {
  display: block;
  position: absolute;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList .btnWrap {
  text-align: right;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList a {
  display: block;
}
.greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList a i {
  margin-left: 0;
}

.worksList > li {
  position: relative;
}
.worksList > li > a {
  display: block;
  background: #e2e2e2;
  text-decoration: none;
}
.worksList > li > a:before, .worksList > li > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s opacity;
}
.worksList > li > a:before {
  opacity: 1;
  background: url(../images/common/icon_dash_black.svg) no-repeat;
}
.worksList > li > a:after {
  opacity: 0;
  background: url(../images/common/icon_dash_pink.svg) no-repeat;
}
.worksList > li > a .worksListTitle {
  color: #c90056;
  font-weight: bold;
}
.worksList > li > a .worksListTag {
  display: flex;
  flex-wrap: wrap;
}
.worksList > li > a .worksListTag > li {
  display: block;
  color: #fff;
}
.worksList > li > a .worksListTag > li.caseGreeningRooftop {
  background: #439f52;
}
.worksList > li > a .worksListTag > li.caseGreeningInfra {
  background: #8dab15;
}
.worksList > li > a .worksListTag > li.caseGreeningWall {
  background: #48a88e;
}
.worksList > li > a .worksListTag > li.caseSubProduct {
  background: #34405b;
}
.worksList > li > a .worksListTag > li.caseSubStatus {
  background: #34405b;
}

.page_news_index:not(.page2) .pageHeader {
  background: none;
}
.page_news_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/news/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_column_index:not(.page2) .pageHeader {
  background: none;
}
.page_column_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/column/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.page_about_index:not(.page2) .pageHeader {
  background: none;
}
.page_about_index:not(.page2) .pageHeader .pageHeaderInner {
  background: url(../images/about/header_bg.jpg) no-repeat center;
  background-size: cover;
}

.leadBlock .leadCatch {
  color: #34405b;
  text-align: center;
}
.leadBlock .leadNote {
  text-align: center;
}

.leadBlockSmall .leadCatch {
  color: #34405b;
  text-align: center;
}
.leadBlockSmall .leadNote {
  text-align: center;
}
.sectionHeaderBlock {
  position: relative;
}
.sectionHeaderBlock:before {
  content: "";
  display: block;
  background: #f2f2f2;
  position: absolute;
  top: 0;
  left: 0;
}
.sectionHeaderBlock .sectionHeader,
.sectionHeaderBlock .sectionHeaderThumb,
.sectionHeaderBlock .sectionHeaderLink {
  position: relative;
}
.sectionHeaderBlock .sectionHeaderLink {
  line-height: 1;
}
h3.title {
  font-weight: 400;
  position: relative;
}
h3.title:before {
  content: "";
  display: block;
  height: 100%;
  background: #c90056;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}
h4.title {
  color: #34405b;
  font-weight: bold;
}

h5.title {
  font-weight: 600;
}

.postContent h2 {
  font-weight: 400;
  border-bottom: 1px solid #b8b8b8;
}

.titleLine {
  line-height: 1.5455;
  font-weight: bold;
  position: relative;
}
.titleLine:before {
  content: "";
  display: block;
  height: 2px;
  background: #c90056;
  position: absolute;
  left: 0;
}

.titleCenter {
  line-height: 1.58;
  font-weight: 400;
  text-align: center;
  color: #242e44;
}

.titleBold {
  line-height: 1.58;
  color: #34405b;
  font-weight: bold;
}

h2.title,
.titleBlock {
  line-height: 1.4;
  font-weight: 400;
  border-bottom: 3px solid #f1f1f1;
  position: relative;
}
h2.title:before,
.titleBlock:before {
  display: block;
  background: #c90056;
  position: absolute;
  left: 0;
  bottom: -3px;
}
h2.title.iconIn,
.titleBlock.iconIn {
  display: flex;
  justify-content: space-between;
}
h2.title .iconArea,
.titleBlock .iconArea {
  display: flex;
}
h2.title .iconArea [class^=icon],
.titleBlock .iconArea [class^=icon] {
  margin-left: 10px;
}

.iconBoxing {
  background: url(../images/company/icon_boxing.png) no-repeat;
  background-size: contain;
}

.iconBoxer {
  background: url(../images/company/icon_boxer.png) no-repeat;
  background-size: contain;
}

.iconHockey {
  background: url(../images/company/icon_hockey.png) no-repeat;
  background-size: contain;
}

.iconSupportCsr {
  background: url(../images/company/icon_support.png) no-repeat;
  background-size: contain;
}

.iconFencing {
  background: url(../images/company/icon_fencing.png) no-repeat;
  background-size: contain;
}

.iconFencing_sister {
  background: url(../images/company/icon_fencing_sister.png) no-repeat;
  background-size: contain;
}

.iconSponsor {
  background: url(../images/company/icon_sponsor.png) no-repeat;
  background-size: contain;
}

.iconSoccerFutsal {
  background: url(../images/company/icon_soccerfutsal.png) no-repeat;
  background-size: contain;
}

.iconRegatta {
  background: url(../images/company/icon_regatta.png) no-repeat;
  background-size: contain;
}

.iconBaseball {
  background: url(../images/company/icon_baseball.png) no-repeat;
  background-size: contain;
}

.iconClub {
  background: url(../images/company/icon_club.png) no-repeat;
  background-size: contain;
}

.iconRunning {
  background: url(../images/company/icon_running.png) no-repeat;
  background-size: contain;
}

.contentsList > li > *,
.contentsList3 > li > * {
  display: block;
  text-decoration: none;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}
.contentsList > li .contentsListText .contentsListNote,
.contentsList3 > li .contentsListText .contentsListNote {
  color: #000;
  position: relative;
}

.boxList,
.boxList2 {
  width: 100%;
  list-style-type: none;
}
.boxList > li .boxListTitle,
.boxList2 > li .boxListTitle {
  color: #242e44;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
  position: relative;
}
.boxList > li .boxListTitle span,
.boxList2 > li .boxListTitle span {
  display: block;
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
.boxList > li .boxListTitle:before,
.boxList2 > li .boxListTitle:before {
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #c90056;
}

.borderList,
.borderList3,
.borderList4 {
  text-align: center;
}
.borderListGap > li {
  border: 1px solid #b8b8b8;
  text-align: center;
  background: #fff;
}

.anchorList {
  display: flex;
  flex-wrap: wrap;
}
.anchorList > li {
  text-align: center;
}
.anchorList > li > a {
  display: block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: #34405b;
}
.btnRowList > li + li {
  border-top: 1px solid #fff;
}
.btnRowList .btnS,
.btnRowList .btnM,
.btnRowList .btnL {
  min-width: inherit;
}

ul.anchorListSmall {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
ul.anchorListSmall > li > a {
  display: flex;
  height: 100%;
  align-items: center;
  color: #34405b;
  position: relative;
  text-decoration: none;
  background: #f2f2f2;
}
ul.anchorListSmall > li > a:after {
  content: "";
  display: inline-block;
  transform: rotate(90deg);
  flex: none;
  background: url(../images/common/icon_dash_navy.svg) no-repeat center;
  background-size: contain;
  margin: auto;
  overflow: hidden;
}

ol.anchorListSmall {
  counter-reset: item;
}
ol.anchorListSmall > li {
  position: relative;
}
ol.anchorListSmall > li:before {
  counter-increment: item;
  content: counter(item) ".";
  position: absolute;
  left: 0;
}
ol.anchorListSmall > li > a {
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.productLinkList > li > div,
.productLinkList > li > a,
.productLinkListFull > li > div,
.productLinkListFull > li > a {
  color: #000;
  text-decoration: none;
  box-shadow: 0 0px 16px rgba(42, 42, 42, 0.1);
}
.productLinkList > li > div .productLinkListText .productLinkListHeader,
.productLinkList > li > a .productLinkListText .productLinkListHeader,
.productLinkListFull > li > div .productLinkListText .productLinkListHeader,
.productLinkListFull > li > a .productLinkListText .productLinkListHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
.productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitle,
.productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
.productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitle {
  position: relative;
}
.productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
.productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub,
.productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
.productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub {
  color: #888888;
}
.productLinkList > li > div .productLinkListText .productLinkListNote,
.productLinkList > li > a .productLinkListText .productLinkListNote,
.productLinkListFull > li > div .productLinkListText .productLinkListNote,
.productLinkListFull > li > a .productLinkListText .productLinkListNote {
  color: #000;
}
.productLinkList > li .productLinkListThumb,
.productLinkListFull > li .productLinkListThumb {
  position: relative;
}
.productLinkList > li .productLinkListThumb > ul,
.productLinkListFull > li .productLinkListThumb > ul {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
}
.productLinkList > li .productLinkListThumb > ul > li,
.productLinkListFull > li .productLinkListThumb > ul > li {
  margin-left: 7px;
  margin-bottom: 7px;
}
.productLinkList > li .productLinkListText .productLinkListHeader,
.productLinkListFull > li .productLinkListText .productLinkListHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitle,
.productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitle {
  font-weight: bold;
  position: relative;
}
.productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitleSub,
.productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitleSub {
  color: #888888;
}
.productLinkList > li .productLinkListText .productLinkListNote,
.productLinkListFull > li .productLinkListText .productLinkListNote {
  color: #000;
}

.productLinkListFull {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lineList > li {
  position: relative;
}
.lineList > li .lineListContent {
  line-height: 1.286;
}
.lineList > li .lineListContent figure {
  display: block;
}
.lineList > li .btnWrap {
  margin-top: 0;
}
.postContent ul:not([class]),
ul.commonList {
  text-align: left;
}
.postContent ul:not([class]) > li,
ul.commonList > li {
  padding-left: 1em;
  position: relative;
}
.postContent ul:not([class]) > li:before,
ul.commonList > li:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #333d51;
  position: absolute;
}
.postContent ul:not([class]) > li a,
ul.commonList > li a {
  display: inline-block;
}

ol.commonList {
  counter-reset: number 0;
}
ol.commonList > li {
  padding-left: 1.5em;
  position: relative;
}
ol.commonList > li:before {
  counter-increment: number 1;
  content: "0" counter(number) ".";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: bold;
  color: #c90056;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
ol.commonList > li:nth-child(n+10):before {
  content: counter(number) ".";
}

.iframeWrap {
  width: 100%;
  margin-top: 1.5em;
  position: relative;
}
.iframeWrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

* + .ytWrap > div {
  margin-top: 24px;
}

.ytWrap {
  width: 100%;
  margin: 0 auto 0;
  position: relative;
}
.ytWrap > div {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
}
.ytWrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ytTitle {
  text-align: left;
  line-height: 1.4;
}
#sectionAdd .inner .imgLine3 {
  margin-bottom: 0;
}

.moreList > li {
  background: #fff;
}
.moreList > li a {
  display: block;
  color: #000;
  text-decoration: none;
}
.moreList > li a .moreListItemText .moreListItemMore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: bold;
  color: #c90056;
  position: absolute;
}
.moreList > li a .moreListItemText .moreListItemMore:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 1px;
  margin-right: 5px;
  background: #c90056;
}

.qaList {
  border-bottom: 1px solid #b8b8b8;
}
.qaList > dt {
  position: relative;
  cursor: pointer;
}
.qaList > dt:not(:first-child) {
  border-top: 1px solid #b8b8b8;
}
.qaList > dt:before {
  content: "Q";
  display: block;
  color: #fff;
  text-align: center;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  background: #c90056;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.qaList > dt .qaQuestion {
  position: relative;
}
.qaList > dt .qaQuestion:before, .qaList > dt .qaQuestion:after {
  content: "";
  display: block;
  height: 2px;
  background: #c90056;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s transform;
}
.qaList > dt .qaQuestion:after {
  transform: rotate(90deg);
}
.qaList > dt.open .qaQuestion:after {
  transform: rotate(0);
}
.qaList > dd {
  display: none;
  position: relative;
}
.qaList > dd:before {
  content: "A";
  display: block;
  text-align: center;
  color: #fff;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  background: #34405b;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 0.2s 0.2s opacity;
}
.qaList > dd .qaAnswer {
  background: #f6f6f6;
}
.qaList > dd.open:before {
  opacity: 1;
}

.checkList > li {
  position: relative;
}
.checkList > li:before {
  content: "";
  display: block;
  background: url(../images/common/icon_check.svg) no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0.35em;
}

.scrollTable {
  overflow: auto;
}
.postContent table:not(.hanging),
.commonTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  margin-top: 1.5em;
  text-align: center;
  word-break: break-all;
  border-bottom: 1px solid #b8b8b8;
}
.postContent table:not(.hanging).small > tbody > tr > th,
.postContent table:not(.hanging).small > thead > tr > th,
.postContent table:not(.hanging).small > tr > th,
.postContent table:not(.hanging).small > tbody > tr > td,
.postContent table:not(.hanging).small > thead > tr > td,
.postContent table:not(.hanging).small > tr > td,
.commonTable.small > tbody > tr > th,
.commonTable.small > thead > tr > th,
.commonTable.small > tr > th,
.commonTable.small > tbody > tr > td,
.commonTable.small > thead > tr > td,
.commonTable.small > tr > td {
  text-align: center;
}
.postContent table:not(.hanging).tdC > tbody > tr > td,
.postContent table:not(.hanging).tdC > thead > tr > td,
.commonTable.tdC > tbody > tr > td,
.commonTable.tdC > thead > tr > td {
  text-align: center;
}
.postContent table:not(.hanging):not(.skeletonTable),
.commonTable:not(.skeletonTable) {
  border-top: 1px solid #b8b8b8;
}
.postContent table:not(.hanging).skeletonTable,
.commonTable.skeletonTable {
  table-layout: fixed;
}
.postContent table:not(.hanging).skeletonTable > tbody > tr > th:nth-of-type(1),
.postContent table:not(.hanging).skeletonTable > thead > tr > th:nth-of-type(1),
.postContent table:not(.hanging).skeletonTable > tr > th:nth-of-type(1),
.commonTable.skeletonTable > tbody > tr > th:nth-of-type(1),
.commonTable.skeletonTable > thead > tr > th:nth-of-type(1),
.commonTable.skeletonTable > tr > th:nth-of-type(1) {
  width: 130px !important;
}
.postContent table:not(.hanging).skeletonTable > tbody > tr:not(.skeleton),
.postContent table:not(.hanging).skeletonTable > thead > tr:not(.skeleton),
.postContent table:not(.hanging).skeletonTable > tr:not(.skeleton),
.commonTable.skeletonTable > tbody > tr:not(.skeleton),
.commonTable.skeletonTable > thead > tr:not(.skeleton),
.commonTable.skeletonTable > tr:not(.skeleton) {
  border-top: 1px solid #b8b8b8;
}
.postContent table:not(.hanging).skeletonTable > tbody > tr > td,
.postContent table:not(.hanging).skeletonTable > thead > tr > td,
.postContent table:not(.hanging).skeletonTable > tr > td,
.commonTable.skeletonTable > tbody > tr > td,
.commonTable.skeletonTable > thead > tr > td,
.commonTable.skeletonTable > tr > td {
  vertical-align: top;
}
.postContent table:not(.hanging) > tbody > tr.skeleton th,
.postContent table:not(.hanging) > tbody > tr.skeleton td,
.postContent table:not(.hanging) > thead > tr.skeleton th,
.postContent table:not(.hanging) > thead > tr.skeleton td,
.postContent table:not(.hanging) > tr.skeleton th,
.postContent table:not(.hanging) > tr.skeleton td,
.commonTable > tbody > tr.skeleton th,
.commonTable > tbody > tr.skeleton td,
.commonTable > thead > tr.skeleton th,
.commonTable > thead > tr.skeleton td,
.commonTable > tr.skeleton th,
.commonTable > tr.skeleton td {
  background: none;
  border: none;
  text-align: center;
  padding-top: 0;
  padding-bottom: 25px;
}
.postContent table:not(.hanging) > tbody > tr > *:first-child,
.postContent table:not(.hanging) > thead > tr > *:first-child,
.postContent table:not(.hanging) > tr > *:first-child,
.commonTable > tbody > tr > *:first-child,
.commonTable > thead > tr > *:first-child,
.commonTable > tr > *:first-child {
  border-left: 1px solid #b8b8b8;
}
.postContent table:not(.hanging) > tbody > tr > *,
.postContent table:not(.hanging) > thead > tr > *,
.postContent table:not(.hanging) > tr > *,
.commonTable > tbody > tr > *,
.commonTable > thead > tr > *,
.commonTable > tr > * {
  border-right: 1px solid #b8b8b8;
}
.postContent table:not(.hanging) > tbody > tr:not(:first-child) > *,
.postContent table:not(.hanging) > thead > tr:not(:first-child) > *,
.postContent table:not(.hanging) > tr:not(:first-child) > *,
.commonTable > tbody > tr:not(:first-child) > *,
.commonTable > thead > tr:not(:first-child) > *,
.commonTable > tr:not(:first-child) > * {
  border-top: 1px solid #b8b8b8;
}
.postContent table:not(.hanging).bgPurple > tbody > tr,
.postContent table:not(.hanging).bgPurple > tbody > tr > th,
.postContent table:not(.hanging).bgPurple > thead > tr,
.postContent table:not(.hanging).bgPurple > thead > tr > th,
.commonTable.bgPurple > tbody > tr,
.commonTable.bgPurple > tbody > tr > th,
.commonTable.bgPurple > thead > tr,
.commonTable.bgPurple > thead > tr > th {
  background: #ececf4 !important;
}
.postContent table:not(.hanging).bgPink > tbody > tr,
.postContent table:not(.hanging).bgPink > tbody > tr > th,
.postContent table:not(.hanging).bgPink > thead > tr,
.postContent table:not(.hanging).bgPink > thead > tr > th,
.commonTable.bgPink > tbody > tr,
.commonTable.bgPink > tbody > tr > th,
.commonTable.bgPink > thead > tr,
.commonTable.bgPink > thead > tr > th {
  background: #fcf2f6 !important;
}
.postContent table:not(.hanging).tdTaC > tbody > tr > td,
.postContent table:not(.hanging).tdTaC > thead > tr > td,
.commonTable.tdTaC > tbody > tr > td,
.commonTable.tdTaC > thead > tr > td {
  text-align: center;
}
.postContent table:not(.hanging) th.bgPurple,
.postContent table:not(.hanging) td.bgPurple,
.commonTable th.bgPurple,
.commonTable td.bgPurple {
  background: #ececf4 !important;
}
.postContent table:not(.hanging) th.bgPink,
.postContent table:not(.hanging) td.bgPink,
.commonTable th.bgPink,
.commonTable td.bgPink {
  background: #f9e6ed !important;
}
.postContent table:not(.hanging) th.bgGreen,
.postContent table:not(.hanging) td.bgGreen,
.commonTable th.bgGreen,
.commonTable td.bgGreen {
  background: #def3df !important;
}
.postContent table:not(.hanging) th.bgSky,
.postContent table:not(.hanging) td.bgSky,
.commonTable th.bgSky,
.commonTable td.bgSky {
  background: #cfeaf6 !important;
}
.postContent table:not(.hanging) th.bgWhite,
.postContent table:not(.hanging) td.bgWhite,
.commonTable th.bgWhite,
.commonTable td.bgWhite {
  background: #fff !important;
}
.postContent table:not(.hanging) th.nowrap,
.postContent table:not(.hanging) td.nowrap,
.commonTable th.nowrap,
.commonTable td.nowrap {
  white-space: nowrap;
}
.postContent table:not(.hanging) > tbody > tr > th,
.postContent table:not(.hanging) > tbody > tr > td,
.postContent table:not(.hanging) > thead > tr > th,
.postContent table:not(.hanging) > thead > tr > td,
.commonTable > tbody > tr > th,
.commonTable > tbody > tr > td,
.commonTable > thead > tr > th,
.commonTable > thead > tr > td {
  white-space: normal;
}
.postContent table:not(.hanging) > tbody > tr > th.w115,
.postContent table:not(.hanging) > tbody > tr > td.w115,
.postContent table:not(.hanging) > thead > tr > th.w115,
.postContent table:not(.hanging) > thead > tr > td.w115,
.commonTable > tbody > tr > th.w115,
.commonTable > tbody > tr > td.w115,
.commonTable > thead > tr > th.w115,
.commonTable > thead > tr > td.w115 {
  width: 115px !important;
}
.postContent table:not(.hanging) > tbody > tr > th.w165,
.postContent table:not(.hanging) > tbody > tr > td.w165,
.postContent table:not(.hanging) > thead > tr > th.w165,
.postContent table:not(.hanging) > thead > tr > td.w165,
.commonTable > tbody > tr > th.w165,
.commonTable > tbody > tr > td.w165,
.commonTable > thead > tr > th.w165,
.commonTable > thead > tr > td.w165 {
  width: 165px !important;
}
.postContent table:not(.hanging) > tbody > tr > th.w120,
.postContent table:not(.hanging) > tbody > tr > td.w120,
.postContent table:not(.hanging) > thead > tr > th.w120,
.postContent table:not(.hanging) > thead > tr > td.w120,
.commonTable > tbody > tr > th.w120,
.commonTable > tbody > tr > td.w120,
.commonTable > thead > tr > th.w120,
.commonTable > thead > tr > td.w120 {
  width: 120px !important;
}
.postContent table:not(.hanging) > tbody > tr > th.w150,
.postContent table:not(.hanging) > tbody > tr > td.w150,
.postContent table:not(.hanging) > thead > tr > th.w150,
.postContent table:not(.hanging) > thead > tr > td.w150,
.commonTable > tbody > tr > th.w150,
.commonTable > tbody > tr > td.w150,
.commonTable > thead > tr > th.w150,
.commonTable > thead > tr > td.w150 {
  width: 150px !important;
}
.postContent table:not(.hanging) > tbody > tr > th.w300,
.postContent table:not(.hanging) > tbody > tr > td.w300,
.postContent table:not(.hanging) > thead > tr > th.w300,
.postContent table:not(.hanging) > thead > tr > td.w300,
.commonTable > tbody > tr > th.w300,
.commonTable > tbody > tr > td.w300,
.commonTable > thead > tr > th.w300,
.commonTable > thead > tr > td.w300 {
  width: 300px !important;
}
.postContent table:not(.hanging) > tbody > tr > th,
.postContent table:not(.hanging) > thead > tr > th,
.commonTable > tbody > tr > th,
.commonTable > thead > tr > th {
  background-color: #ebf0f4;
  padding: 12px;
  font-weight: 400;
}
.postContent table:not(.hanging) > tbody > tr > td,
.postContent table:not(.hanging) > thead > tr > td,
.commonTable > tbody > tr > td,
.commonTable > thead > tr > td {
  text-align: left;
  background-color: #fff;
}
.postContent table:not(.hanging) > tbody > tr > td *:last-child,
.postContent table:not(.hanging) > thead > tr > td *:last-child,
.commonTable > tbody > tr > td *:last-child,
.commonTable > thead > tr > td *:last-child {
  margin-bottom: 0;
}
.postContent table:not(.hanging) > tbody > tr > td *:first-child,
.postContent table:not(.hanging) > thead > tr > td *:first-child,
.commonTable > tbody > tr > td *:first-child,
.commonTable > thead > tr > td *:first-child {
  margin-top: 0;
}

.borderBox {
  border: 1px solid #b8b8b8;
  background: #fff;
}
.borderBox.bgLogo {
  background: url(/images/company/csr/idea_bg.png) center no-repeat;
}
.colorBoxSky .boxTitle,
.colorBoxPurple .boxTitle,
.colorBoxPink .boxTitle,
.colorBoxPrimary .boxTitle,
.colorBox .boxTitle {
  color: #34405b;
}
.colorBoxSky .twoCol,
.colorBoxPurple .twoCol,
.colorBoxPink .twoCol,
.colorBoxPrimary .twoCol,
.colorBox .twoCol {
  margin-top: 0;
}
.colorBoxSky .twoCol .img,
.colorBoxPurple .twoCol .img,
.colorBoxPink .twoCol .img,
.colorBoxPrimary .twoCol .img,
.colorBox .twoCol .img {
  text-align: center;
}
.colorBox {
  background: #f2f2f2;
}

.colorBoxPink {
  background: #fcf2f6;
}

.colorBoxPurple {
  background: #ececf4;
}

.colorBoxSky {
  background: #ebf0f4;
}

.colorBoxTitle {
  color: #242e44;
  font-weight: 400;
  line-height: 1.4;
}

.imgLine2 > li {
  text-align: center;
}
.imgLine2 > li img {
  max-width: 100%;
}
.imgLine2 .cap {
  display: block;
}

.imgLine2Full > li {
  text-align: center;
}
.imgLine2Full > li img {
  max-width: 100%;
}
.imgLine2Full .cap {
  display: block;
}

[class^=imgLine3] {
  width: 100%;
  list-style-type: none;
}
[class^=imgLine3] p {
  text-align: left;
  line-height: 2;
}
[class^=imgLine3] .link {
  justify-content: flex-start;
}
[class^=imgLine3] > li {
  text-align: center;
}
[class^=imgLine3] > li img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
[class^=imgLine3] .cap {
  display: block;
  text-align: left;
  margin-top: 4px;
}
[class^=imgLine3] p {
  text-align: left;
}

*:not(.title) + .innerBody [class^=imgLine4]:first-child,
* + [class^=imgLine4] {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

[class^=imgLine4] {
  list-style-type: none;
}
[class^=imgLine4] li img {
  width: 100%;
}
[class^=imgLine4] li .icon_plug {
  display: block;
  margin: 0 auto;
  text-align: center;
}
[class^=imgLine4] li .icon_plug > img {
  width: inherit;
  max-width: 100%;
}

[class^=imgLine5] {
  width: 100%;
  list-style-type: none;
}
[class^=imgLine5] p {
  text-align: left;
  line-height: 2;
}
[class^=imgLine5] .link {
  justify-content: flex-start;
}
[class^=imgLine5] > li {
  text-align: center;
}
[class^=imgLine5] > li img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
[class^=imgLine5] .cap {
  display: block;
  text-align: left;
  margin-top: 4px;
}
[class^=imgLine5] p {
  text-align: left;
}

/*
.flowList {
@if $type == ""{
counter-reset: number 0;
}

> li {
@if $type == ""{
position: relative;
}
@if $type == sp{
position: relative;

&:not(:last-child):not(:nth-child(2n)) {
figure {
&:before {
border-width: 8px 0 8px 8px;
border-color: transparent transparent transparent #e2e2e2;
top: 0;
bottom: 0;
right: -12px;
margin: auto;
}
}
}


}
@if $type == pc{
&:not(:last-child):not(:nth-child(4n)) {
figure {
&:before {
border-width: 13px 0 13px 13px;
border-color: transparent transparent transparent #e2e2e2;
top: 0;
bottom: 0;
right: -7.65%;
margin: auto;
}
}
}
}

&:before {
@if $type == ""{
counter-increment: number 1;
content: counter(number,decimal-leading-zero);
display: flex;
align-items: center;
justify-content: center;
color: #fff;
background: $c_base;
@include fontRo;
position: absolute;
top: 0;
left: 0;
z-index: 2;
}
@if $type == sp{
width: 8.75vw;
height: 8.75vw;
max-width: 35px;
max-height: 35px;
}
@if $type == pc{
width: 3.75vw;
height: 3.75vw;
max-width: 45px;
max-height: 45px;
}
}

figure {
@if $type == ""{
display: block;
position: relative;
}
@if $type == sp{
}
@if $type == pc{
}

&:before {
content: "";
width: 0;
height: 0;
position: absolute;
border-style: solid;

@if $type == sp{
}
@if $type == pc{

}

}
}
}

p {
@if $type == ""{
text-align: center;
}
@if $type == sp{
}
@if $type == pc{
}
}
}
*/
.stepList > div {
  display: none;
  background: #ebf0f4;
  position: relative;
}
.stepList > div:nth-of-type(1) {
  display: block !important;
}
.stepList > div + div:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #34405b transparent transparent transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.stepList > div > dt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: none;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  background: #34405b;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.stepList > div > dt strong {
  display: block;
}

.btnRelated a {
  display: flex;
  justify-content: center;
  text-align: left;
  color: #fff;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.btnRelated a .btnRelatedBg {
  z-index: 0;
}
.btnRelated a .btnRelatedBg:after {
  content: "";
  display: block;
  width: 100%;
  background: rgba(52, 64, 91, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.btnRelated a .btnRelatedBg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.btnRelated a > span {
  display: block;
  position: relative;
  z-index: 2;
}
.btnRelated a > span [class^=icon] {
  position: absolute;
  right: 0;
  top: 0.5em;
  margin-right: 0;
}
.btnRelated.btnOa a {
  background-image: url(../images/product/related_oa.jpg);
}
.btnRelated.btnOa a:after {
  background-image: url(../images/product/icon_oa.png);
}
.btnRelated.btnOaFinishing a {
  background-image: url(../images/product/related_oa_finishing.jpg);
}
.btnRelated.btnOaFinishing a:after {
  background-image: url(../images/product/icon_oa_finishing.png);
}
.btnRelated.btnOaSoketNetwork a {
  background-image: url(../images/product/related_oa_socket_network.jpg);
}
.btnRelated.btnOaSoketNetwork a:after {
  background-image: url(../images/product/icon_oa_socket_network.png);
}
.btnRelated.btnFloorMaterialAbout a {
  background-image: url(../images/product/related_floor_material_about.jpg);
}
.btnRelated.btnFloorMaterialAbout a:after {
  background-image: url(../images/product/icon_floor_material_about.png);
}
.btnRelated.btnBusductLow a {
  background-image: url(../images/product/related_busduct_low.jpg);
}
.btnRelated.btnBusductLow a:after {
  background-image: url(../images/product/icon_busduct_low.png);
}
.btnRelated.btnBusductHigh a {
  background-image: url(../images/product/related_busduct_high.jpg);
}
.btnRelated.btnBusductHigh a:after {
  background-image: url(../images/product/icon_busduct_high.png);
}
.btnRelated.btnFloorOa a {
  background-image: url(../images/product/related_floor_oa.jpg);
}
.btnRelated.btnFloorOa a:after {
  background-image: url(../images/product/icon_floor_oa.png);
}
.btnRelated.btnFloorGreeningRooftop a {
  background-image: url(../images/product/related_floorgreening_rooftop.jpg);
}
.btnRelated.btnFloorGreeningRooftop a:after {
  background-image: url(../images/product/icon_floorgreening_rooftop.png);
}
.btnRelated.btnFloorGreeningWall a {
  background-image: url(../images/product/related_floorgreening_wall.jpg);
}
.btnRelated.btnFloorGreeningWall a:after {
  background-image: url(../images/product/icon_floorgreening_wall.png);
}
.btnRelated.btnQf a {
  background-image: url(../images/product/related_qf.jpg);
}
.btnRelated.btnQf a:after {
  background-image: url(../images/product/icon_qf.png);
}
.btnRelated.btnOa a {
  background-image: url(../images/product/related_oa.jpg);
}
.btnRelated.btnOa a:after {
  background-image: url(../images/product/icon_oa.png);
}
.btnRelated.btnBoxer a {
  background-image: url(../images/company/related_boxer.jpg);
}
.btnRelated.btnBoxer a:after {
  background-image: url(../images/company/icon_boxer.png);
}
.btnRelated.btnFencing a {
  background-image: url(../images/company/related_fencing.jpg);
}
.btnRelated.btnFencing a:after {
  background-image: url(../images/company/icon_fencing.png);
}
.btnRelated.btnInline-hockey a {
  background-image: url(../images/company/related_hockey.jpg);
}
.btnRelated.btnInline-hockey a:after {
  background-image: url(../images/company/icon_hockey.png);
}
.btnRelated.btnFutsal a {
  background-image: url(../images/company/related_soccerfutsal.jpg);
}
.btnRelated.btnFutsal a:after {
  background-image: url(../images/company/icon_soccerfutsal.png);
}
.btnRelated.btnPara-athlete a {
  background-image: url(../images/company/related_regatta.jpg);
}
.btnRelated.btnPara-athlete a:after {
  background-image: url(../images/company/icon_regatta.png);
}
.btnRelated.btnBaseball a {
  background-image: url(../images/company/related_baseball.jpg);
}
.btnRelated.btnBaseball a:after {
  background-image: url(../images/company/icon_baseball.png);
}
.btnRelated.btnRunning a {
  background-image: url(../images/company/related_running.jpg);
}
.btnRelated.btnRunning a:after {
  background-image: url(../images/company/icon_running.png);
}

.imgLineBody .imgLineTitle {
  color: #242e44;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
  position: relative;
}
.imgLineBody .imgLineTitle span {
  display: block;
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
.imgLineBody .imgLineTitle:before {
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #c90056;
}

.photoList .photoListMain img {
  display: block;
  object-fit: cover;
  background-position: center;
  width: 100%;
}
.photoList .photoListSelect {
  display: flex;
  flex-wrap: wrap;
}
.photoList .photoListSelect > li {
  line-height: 0;
  position: relative;
  overflow: hidden;
}
.photoList .photoListSelect > li:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.2s opacity;
  pointer-events: none;
  z-index: 2;
}
.photoList .photoListSelect > li.isActive:before {
  opacity: 1;
}
.photoList .photoListSelect > li img {
  display: block;
  object-fit: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}

*:not(.title) + .innerBody .attentionList:first-child,
* + .attentionList {
  margin-top: 30px;
}

.attentionList {
  list-style-type: none;
}
.attentionList.listRight {
  display: flex;
  justify-content: flex-end;
}
.attentionList > li {
  padding-left: 1em;
  text-indent: -1em;
  line-height: 1.6;
}
.attentionList > li + li {
  margin-top: 3px;
}
.attentionList > li:before {
  content: "※";
}

.modalBtn {
  display: inline-block;
}
.modalBtn:before {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_question.png) no-repeat;
  background-size: contain;
}

.tabMenu ul {
  display: flex;
  justify-content: space-between;
}
.tabMenu ul > li {
  flex: 1;
  text-align: center;
  border-style: solid;
  border-color: transparent transparent #b8b8b8 transparent;
  border-width: 3px 1px 1px 1px;
  transition: 0.2s border-color, 0.2s color;
}
.tabMenu ul > li.isActive {
  color: #c90056;
  border-color: #c90056 #b8b8b8 transparent #b8b8b8;
}

.tabContent:not(.isActive) {
  display: none;
}

.speakerClass:after {
  content: "";
  display: block;
  clear: both;
}
.speakerClass > dt,
.speakerClass > div > dt {
  float: left;
  font-weight: bold;
}
.speakerClass > dt:after,
.speakerClass > div > dt:after {
  content: "：";
  color: #000000;
  font-weight: 400;
}
.twoCol.alT {
  align-items: flex-start;
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .twoCol .img {
    height: 100%;
  }
}

.pickupListItem {
  width: 100%;
}
.pickupListItem .pickupItem > a {
  color: #242e44;
  text-decoration: none;
}
.pickupListItem .pickupItem > a .pickupItemText .pickupItemDate {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  color: #acaaaa;
  line-height: 1;
  position: absolute;
}

.caseListSlider + .btnWrap {
  margin-top: 0;
}

.caseListSlider {
  padding-bottom: 3em;
}
.caseListSlider .slick-list.draggable {
  overflow: visible;
}
.caseListSlider .caseItem {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
  background: #fff;
  overflow: visible;
  transition: 0.2s opacity;
}
.caseListSlider a {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #000;
  text-decoration: none;
}
.caseListSlider .caseItemThumb {
  position: relative;
  overflow: hidden;
}
.caseListSlider .caseItemThumb img {
  object-fit: cover;
  height: 100%;
}
.caseListSlider .caseItemThumb > ul {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}
.caseListSlider .caseItemThumb > ul > li {
  color: #fff;
  text-align: center;
}
.caseListSlider .caseItemThumb > ul > li.caseGreeningRooftop {
  width: 27%;
  background: #439f52;
}
.caseListSlider .caseItemThumb > ul > li.caseGreeningInfra {
  width: 46%;
  background: #8dab15;
}
.caseListSlider .caseItemThumb > ul > li.caseGreeningWall {
  width: 27%;
  background: #48a88e;
}
.caseListSlider .caseItemThumb > ul > li.caseLow {
  width: 50%;
  background: #0099e3;
}
.caseListSlider .caseItemThumb > ul > li.caseHigh {
  width: 50%;
  background: #6875b0;
}
.caseListSlider .caseItemThumb > ul > li.caseDwg, .caseListSlider .caseItemThumb > ul > li.caseDxf, .caseListSlider .caseItemThumb > ul > li.caseJww, .caseListSlider .caseItemThumb > ul > li.casePdf {
  width: 25%;
}
.caseListSlider .caseItemThumb > ul > li.caseDwg a, .caseListSlider .caseItemThumb > ul > li.caseDxf a, .caseListSlider .caseItemThumb > ul > li.caseJww a, .caseListSlider .caseItemThumb > ul > li.casePdf a {
  display: block;
}
.caseListSlider .caseItemText .caseItemTitle {
  line-height: 1.4;
}
.caseListSlider .caseItemText .caseItemInfo > div {
  display: flex;
  align-items: flex-start;
}
.caseListSlider .caseItemText .caseItemInfo > div > dt {
  flex: none;
  color: #7e7d7d;
  text-align: center;
  background: #f1f1f1;
}
.caseListItem {
  display: flex;
  flex-wrap: wrap;
}
.caseListItem .caseItem {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
  background: #fff;
}
.caseListItem a {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #000;
  text-decoration: none;
}
.caseListItem .caseItemThumb {
  position: relative;
  overflow: hidden;
}
.caseListItem .caseItemThumb img {
  object-fit: cover;
  height: 100%;
}
.caseListItem .caseItemThumb > ul {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}
.caseListItem .caseItemThumb > ul > li {
  color: #fff;
  text-align: center;
}
.caseListItem .caseItemThumb > ul > li.caseGreeningRooftop {
  width: 27.66%;
  background: #439f52;
}
.caseListItem .caseItemThumb > ul > li.caseGreeningInfra {
  width: 44.68%;
  background: #8dab15;
}
.caseListItem .caseItemThumb > ul > li.caseGreeningWall {
  width: 27.66%;
  background: #48a88e;
}
.caseListItem .caseItemThumb > ul > li.caseLow {
  width: 50%;
  background: #0099e3;
}
.caseListItem .caseItemThumb > ul > li.caseHigh {
  width: 50%;
  background: #6875b0;
}
.caseListItem .caseItemThumb > ul > li.caseDwg, .caseListItem .caseItemThumb > ul > li.caseDxf, .caseListItem .caseItemThumb > ul > li.caseJww, .caseListItem .caseItemThumb > ul > li.casePdf {
  flex: 1;
  transform: translateY(2px);
}
.caseListItem .caseItemThumb > ul > li.caseDwg a, .caseListItem .caseItemThumb > ul > li.caseDxf a, .caseListItem .caseItemThumb > ul > li.caseJww a, .caseListItem .caseItemThumb > ul > li.casePdf a {
  display: block;
  width: 100%;
  color: #fff;
  margin: auto;
}
.caseListItem .caseItemThumb > ul > li.caseDwg a {
  background: #7361ab;
}
.caseListItem .caseItemThumb > ul > li.caseDxf a {
  background: #16b1a9;
}
.caseListItem .caseItemThumb > ul > li.caseJww a {
  background: #a29d11;
}
.caseListItem .caseItemThumb > ul > li.casePdf a {
  background: #c90056;
}
.caseListItem .caseItemThumb > ul > li.tag {
  color: #fff;
  text-align: center;
  border-right: 1px solid #fff;
}
.caseListItem .caseItemThumb > ul > li.tag:last-child {
  border: none;
}
.caseListItem .caseItemText .caseItemTitle {
  line-height: 1.4;
}
.caseListItem .caseItemText .caseItemInfo > div {
  position: relative;
}
.caseListItem .caseItemText .caseItemInfo > div > dt {
  flex: none;
  color: #7e7d7d;
  text-align: center;
  background: #f1f1f1;
  position: absolute;
  top: 0;
  left: 0;
}
.cadList {
  line-height: 1.4;
}
.cadList > li {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
  background: #fff;
}
.cadList .cadListMain {
  background: #f2f2f2;
}
.cadList .cadListMain .cadListMainNote .cadListMainLink {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.cadList .cadListMain .cadListMainNote .cadListMainLink > li a {
  display: block;
  color: #fff;
  text-decoration: none;
  background: #34405b;
  position: relative;
}
.cadList .cadListMain .cadListMainNote .cadListMainLink > li a > div:before {
  content: "";
  display: block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.cadList .cadListSummary .cadListInfo > div {
  display: flex;
  align-items: flex-start;
}
.cadList .cadListSummary .cadListInfo > div > dt {
  flex: none;
  color: #7e7d7d;
  text-align: center;
  background: #f1f1f1;
}
.pageNav {
  background: #e2e2e2;
  border-top: 1px solid #fff;
}
.pageNav > ul > li {
  text-align: center;
  position: relative;
}
.pageNav > ul > li a {
  color: #242e44;
  text-decoration: none;
}
.pageNav > ul > li.isActive a {
  color: #c90056;
}
.pageNav > ul > li.isActive:before {
  content: "";
  display: block;
  width: 100%;
  background: #c90056;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.pageNav > ul > li > a {
  display: block;
}

[class^=bnrList] > li.bgExistingGreening .bnrArea > a:after {
  background: url(../images/common/bnr_existinggreening.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgAftercare .bnrArea > a:after {
  background: url(../images/common/bnr_aftercare.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgSeminar .bnrArea > a:after {
  background: url(../images/common/bnr_seminar.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgCalculation .bnrArea > a:after {
  background: url(../images/common/bnr_calculation.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgSurveillanceTemperature .bnrArea > a:after {
  background: url(../images/common/bnr_surveillance_temperature.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgSurveillanceElectric .bnrArea > a:after {
  background: url(../images/common/bnr_surveillance_electric.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgFigure .bnrArea > a:after {
  background: url(../images/common/bnr_figure.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgSupport .bnrArea > a:after {
  background: url(../images/common/bnr_support.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgBim .bnrArea > a:after {
  background: url(../images/common/bnr_bim.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgSeminar .bnrArea > a:after {
  background: url(../images/common/bnr_seminar.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgOnline .bnrArea > a:after {
  background: url(../images/common/bnr_online.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgOnline .bnrArea > a:after {
  background: url(../images/common/bnr_online.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgPurchase .bnrArea > a:after {
  background: url(../images/common/bnr_purchase.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgExistingoa .bnrArea > a:after {
  background: url(../images/common/bnr_existingoa.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgExistingGreening .bnrArea > a:after {
  background: url(../images/common/bnr_special-greening.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgGreeningRooftop .bnrArea > a:after {
  background: url(../images/common/bnr_roof-greening-support.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li.bgConstruction .bnrArea > a:after {
  background: url(../images/common/bnr_construction.png) no-repeat center right;
  background-size: contain;
}
[class^=bnrList] > li .bnrArea > a {
  border: 1px solid #c90056;
  position: relative;
  overflow: hidden;
}
[class^=bnrList] > li .bnrArea > a:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
[class^=bnrList] > li .bnrArea > a > p {
  color: #c90056;
  position: relative;
}
.btnList,
.btnList2Col,
.btnList3Col {
  width: 100%;
}
.btnList > li a,
.btnList2Col > li a,
.btnList3Col > li a {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  position: relative;
}
.btnList > li a > [class^=icon],
.btnList2Col > li a > [class^=icon],
.btnList3Col > li a > [class^=icon] {
  margin-right: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}
.btnList > li a > [class^=icon] + div:before,
.btnList2Col > li a > [class^=icon] + div:before,
.btnList3Col > li a > [class^=icon] + div:before {
  display: none;
}
.btnList > li a > div,
.btnList2Col > li a > div,
.btnList3Col > li a > div {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.btnList > li a > div:before,
.btnList2Col > li a > div:before,
.btnList3Col > li a > div:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url(../images/common/icon_dash_pink.svg) no-repeat;
  background-size: contain;
}
.btnList > li a > div > div,
.btnList2Col > li a > div > div,
.btnList3Col > li a > div > div {
  display: flex;
  align-items: center;
  position: relative;
}
.btnList {
  width: 100%;
}
.btnList2Col {
  width: 100%;
}
.btnList3Col {
  width: 100%;
}
.moreBlock {
  display: none;
}

.btnMore {
  text-align: right;
  line-height: 1;
}
.btnMore.isOpen p:before {
  transform: rotate(-90deg);
}
.btnMore > p {
  display: inline-block;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.06em;
  position: relative;
  background: #c90056;
}
.btnMore > p:before {
  content: "";
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  transform: rotate(90deg);
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: transform 0.2s;
}

.pageing {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
.pageing > span {
  display: inline-block;
}
.pageing > span a {
  color: #000;
  text-decoration: none;
}
.pageing > span a.next:after {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_arrow_pink.svg) no-repeat;
  background-size: contain;
}
.pageing > span a.prev:before {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_arrow_pink.svg) no-repeat;
  background-size: contain;
  transform: scale(-1, 1);
}
.pageing a {
  display: block;
  color: #000;
  text-decoration: none;
  border: 1px solid #c90056;
  text-decoration: none;
  text-align: center;
  background: #fff;
}
.pageing .pageCount {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.pageing .pageCount > li a.now {
  color: #fff !important;
  background: #c90056;
}

.newsList > li p {
  margin-top: 0;
}
.newsList > li .newsListDate {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
.newsList > li .newsListTag {
  display: flex;
  color: #fff;
}
.newsList > li .newsListTag span {
  display: block;
}
.newsList > li .newsListTag span.tagProduct, .newsList > li .newsListTag span.tagNews, .newsList > li .newsListTag span.tagPressrelease, .newsList > li .newsListTag span.tagContribution {
  order: 0;
}
.newsList > li .newsListTag span.tagProduct {
  background: #556fb5;
}
.newsList > li .newsListTag span.tagNews {
  background: #c90056;
}
.newsList > li .newsListTag span.tagPressrelease {
  background: #b59355;
}
.newsList > li .newsListTag span.tagContribution {
  background: #46976e;
}
.newsList > li .newsListTag span:not([class]) {
  order: 1;
  background: #34405b;
}
.newsList > li .newsListNote a {
  color: #000;
  text-decoration: none;
}

.modalContent {
  overflow-y: scroll;
}

.attentionNote {
  text-align: center;
  font-weight: bold;
  color: #34405b;
  line-height: 1.91;
}

.icon_plug {
  vertical-align: middle;
  display: inline-block;
}

.boxContent > .boxContentItem {
  border: 1px solid #bdbdbd;
}
.boxContent > .boxContentItem .boxContentHeader {
  background-position: right center;
  background-repeat: no-repeat;
}
.boxContent > .boxContentItem .boxContentHeader .boxContentTitleSub {
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: bold;
}
.boxContent > .boxContentItem .boxContentHeader .boxContentTitle {
  font-weight: 400;
  position: relative;
  line-height: 1.4;
}
.boxContent > .boxContentItem .boxContentHeader .boxContentTitle:before {
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #c90056;
}
.boxContent > .boxContentItem .boxContentHeader .boxContentTitleNote {
  font-weight: 400;
}
.boxContent > .boxContentItemFull {
  border: 1px solid #bdbdbd;
}
.boxContent > .boxContentItemFull .boxContentHeader {
  background-position: right center;
  background-repeat: no-repeat;
}
.boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleSub {
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: bold;
}
.boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle {
  font-weight: 400;
  position: relative;
  line-height: 1.4;
}
.boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle:before {
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #c90056;
}
.boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleNote {
  font-weight: 400;
}

.mfp-image-holder .mfp-content .mfp-figure:after {
  display: none;
}

.btnListSummary > div > dd a {
  display: block;
  min-width: inherit !important;
}

.aboutList > div + div {
  border-top: 1px solid #34405b;
}
.aboutList > div + div > dt:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.aboutList > div > dt {
  color: #fff;
  text-align: center;
  background: #34405b;
  position: relative;
}
.aboutList > div > dt > span {
  display: block;
}
.aboutList > div > dd {
  background: #fff;
}

[class^=aboutListSmall] {
  font-weight: bold;
}
[class^=aboutListSmall] > div {
  border: 2px solid #c90056;
  position: relative;
}
[class^=aboutListSmall] > div > dt {
  color: #fff;
  text-align: center;
  background: #c90056;
  position: relative;
}
[class^=aboutListSmall] > div > dd {
  background: #fff;
}

.page_company_ecology .companyEcologyLead {
  position: relative;
  overflow: hidden;
}
.page_company_ecology .companyEcologyLead:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.page_company_ecology .companyEcologyLead:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.page_company_ecology .companyEcologyAnchorLead {
  text-align: center;
}
.page_company_ecology .anchorListEcology > li {
  background: #f9f9f9;
}
.page_company_ecology .anchorListEcology > li a {
  display: block;
  color: #242e44;
  text-decoration: none;
  line-height: 1.75;
}
.page_company_ecology .anchorListEcology > li a .anchorListEcologyBody {
  position: relative;
}
.page_company_ecology .anchorListEcology > li a .anchorListEcologyBody:before {
  content: "";
  display: block;
  background: url(../images/common/icon_dash_pink.svg) no-repeat;
  background-size: contain;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}
.page_company_ecology .ecologyLeadListWrap {
  position: relative;
}
.page_company_ecology .ecologyLeadListWrap .ecologyLeadTitle {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.page_company_ecology .ecologyLeadListWrap .ecologyList {
  display: flex;
}
.page_company_ecology .busductSummary .busductSummaryText .busductTitleLead {
  color: #242e44;
}
.page_company_ecology #ecologyBusduct .inner .busductSummary .busductTitleLead {
  color: #242e44;
}
.page_company_ecology #ecologyGreen .inner .greenSummary .ecologyTitleLead {
  color: #242e44;
}
.page_company_ecology .iconList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.page_company_ecology .iconList > li {
  text-align: center;
}

.page_company_point .companyPointLead {
  position: relative;
  overflow: hidden;
}
.page_company_point .companyPointLead:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.page_company_point .anchorList > li.anchorListItemBusiness > a {
  background: #292874;
}
.page_company_point .anchorList > li.anchorListItemProduct > a {
  background: #75af2e;
}
.page_company_point .anchorList > li.anchorListItemService > a {
  background: #005ba4;
}
.page_company_point .businessSummary {
  display: flex;
}
.page_company_point .businessSummary .businessSummaryText .companyPointTitleLead {
  color: #242e44;
}
.page_company_point .businessList {
  position: relative;
}
.page_company_point .businessList:after {
  content: "";
  background: url(../images/company/point/business_list_company.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.page_company_point #companyPointProduct .inner .productSummary .companyPointTitleLead {
  color: #242e44;
}
.page_company_point #companyPointProduct .productList > li:not(:first-child) .productListBtn {
  margin-left: 1px;
}
.page_company_point #companyPointService .serviceSummary .serviceSummaryText .companyPointTitleLead {
  color: #242e44;
}
.page_company_point #companyPointService .companyMenuHeader .companyMenuTitle {
  color: #005ba4;
}
.page_company_point #companyPointService .companyMenuWrap > li {
  background: #f2f2f2;
}
.page_company_point #companyPointService .companyMenuWrap > li:nth-child(1) .companyMenuHeader {
  background: #005ba4;
}
.page_company_point #companyPointService .companyMenuWrap > li:nth-child(2) .companyMenuHeader {
  background: #292874;
}
.page_company_point #companyPointService .companyMenuWrap > li:nth-child(3) .companyMenuHeader {
  background: #75af2e;
}
.page_company_point #companyPointService .companyMenuWrap > li:nth-child(4) .companyMenuHeader {
  background: #c90056;
}
.page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader {
  margin: 0;
  color: #fff;
}
.page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader i {
  display: inline-block;
}
.page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li {
  position: relative;
}
.page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  left: 0;
  background: #005aa1;
}
.page_company_learn .companyLearnLead {
  position: relative;
  overflow: hidden;
}
.page_company_learn .companyLearnLead:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.page_company_learn .companyLearnLead:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.page_company_learn .section .companyLearnList strong {
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
.page_company_learn .section .companyLearnList .companyLearnTitle {
  color: #34405b;
  font-weight: bold;
}
.page_company_learn .section .companyLearnList > li {
  border: 1px solid #d3ced0;
}
.page_company_learn .section .companyLearnList > li.companyLearnAchievement .companyLearnText .companyLearnTitle {
  color: #c90056;
}
.page_company_learn .section .companyLearnList > li.companyLearnOffice .companyLearnThumb {
  display: block;
  margin: 0 auto;
}
.pointList > li.addContent .addBlock,
.pointList > li.addContent .addBlockFull {
  background: #f2f2f2;
}
.pointList > li .pointListText .pointListTitle {
  font-weight: 400;
  position: relative;
}
.pointList > li .pointListText .pointListTitle:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #c90056;
  position: absolute;
  left: 0;
  top: 0;
}
.pointList > li .pointListText .pointListTitle span {
  display: block;
  color: #c90056;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-weight: 600;
}

@media all and (-ms-high-contrast: none) {
  .oa .worksDetailInfo {
    background: rgba(236, 236, 244, 0.9) !important;
  }
}
.oa .worksDetailInfo .worksDetailInfoText:before {
  background: rgba(236, 236, 244, 0.9);
}

@media all and (-ms-high-contrast: none) {
  .busduct .worksDetailInfo {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
.busduct .worksDetailInfo .worksDetailInfoText:before {
  background: rgba(226, 234, 241, 0.9) !important;
}

@media all and (-ms-high-contrast: none) {
  .greening .worksDetailInfo {
    background: rgba(235, 244, 236, 0.9) !important;
  }
}
.greening .worksDetailInfo .worksDetailInfoText:before {
  background: rgba(235, 244, 236, 0.9);
}

.worksDetailInfo .worksDetailInfoThumb {
  position: relative;
  overflow: hidden;
}
@media all and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoThumb img {
    height: auto !important;
    object-fit: none;
  }
}
.worksDetailInfo .worksDetailInfoText {
  position: relative;
  overflow: hidden;
}
.worksDetailInfo .worksDetailInfoText:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoTextBg {
  display: block;
  width: calc(100% + 7px);
  height: calc(100% + 7px);
  position: absolute;
  top: -7px;
  left: -7px;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(7px);
  z-index: 0;
}
@media all and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoTextBg {
    background: none !important;
  }
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner {
  position: relative;
  z-index: 2;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader {
  position: relative;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader:before {
  content: "";
  display: block;
  background: #c90056;
  position: absolute;
  left: 0;
  bottom: 0;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitle {
  font-weight: 400;
  line-height: 1.4;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitleSub {
  line-height: 1.4;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoListWrap {
  display: inline-block;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList {
  display: flex;
  flex-wrap: wrap;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li {
  color: #fff;
  background: #34405b;
  line-height: 1.4;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.bd {
  background: #4c95d0;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.oa {
  background: #5958ac;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.gr {
  background: #96c55c;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData {
  line-height: 1.4;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div {
  display: flex;
  align-items: flex-start;
}
.worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div > dt {
  color: #0b0b0b;
  text-align: center;
  background: #fff;
}
*:not(.title) + .innerBody .coutList:first-child,
* + .coutList {
  margin-top: 1.5em;
}

.countList {
  list-style-type: none;
  counter-reset: item;
}
.countList > li {
  padding-left: 20px;
  position: relative;
}
.countList > li:before {
  counter-increment: item;
  content: counter(item) ".";
  position: absolute;
  left: 0;
}

.imgInP .img .cap {
  display: block;
  text-align: left;
}
.contentLead {
  display: flex;
}
.contentLead .summaryInfo .summaryInfoThumb {
  position: relative;
}
.contentLead .summaryInfo .summaryInfoThumb figure img {
  width: 100%;
}
.contentLead .summaryInfo .summaryInfoThumb ul {
  display: flex;
  position: absolute;
  left: 0;
  top: -12px;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    width: 93px;
  }
}
.contentLead .summaryInfo .summaryInfoDataList {
  display: flex;
  background: #ebf0f4;
  flex-wrap: wrap;
}
.contentLead .summaryInfo .summaryInfoDataList > li {
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #5958ac;
}
.contentLead .summaryInfo .summaryInfoData {
  background: #f2f2f2;
}
.contentLead .summaryInfo .summaryInfoData dl:not([class]) > div dt {
  position: relative;
}
.contentLead .summaryText .summaryTextMain .summaryTextTitle {
  font-weight: 500;
  line-height: 1.4;
  position: relative;
}
.contentLead .summaryText .summaryTextMain .summaryTextTitle:before {
  background: #c90056;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.contentLead .summaryText .summaryTextMain .summaryTextTitleSub {
  line-height: 1.4;
}
.productTop.inner.boxShadow .contentLead {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-shadow: 0 0 8px #fff;
}
.productTop.inner.boxShadow .contentLead:before {
  content: "";
  height: 100%;
  position: absolute;
}
.productTop.inner.boxShadow .summaryText {
  width: 100%;
  text-shadow: 0 0 4px #fff;
}
.productTop.inner.boxShadow .summaryText .summaryTextMain .summaryTextTitle:before {
  display: none;
}
.productTop.inner.boxShadow .summaryText .summaryTextMain .summaryTextCatch {
  text-shadow: 0 0 4px #fff;
}

.hanging td:first-child {
  padding-right: 5px;
  white-space: nowrap;
}
.hanging td {
  vertical-align: top;
}

.titleStrong {
  font-weight: bold;
}

.titleStrong + p {
  margin-top: 0;
}

.postContent .postDate {
  color: #acaaaa;
}
.postContent .postTag {
  display: flex;
  justify-content: flex-end;
  color: #fff;
}
.postContent .postTag span {
  display: flex;
}
.postContent .postTag span.tagProduct, .postContent .postTag span.tagNews, .postContent .postTag span.tagPressrelease, .postContent .postTag span.tagContribution {
  order: 0;
}
.postContent .postTag span.tagProduct {
  background: #556fb5;
}
.postContent .postTag span.tagNews {
  background: #c90056;
}
.postContent .postTag span.tagPressrelease {
  background: #b59355;
}
.postContent .postTag span.tagContribution {
  background: #46976e;
}
.postContent .postTag span.tagColumn {
  background: #c90056;
}
.postContent .postTag span:not([class]) {
  order: 1;
  background: #34405b;
}
#footContact {
  border-top: 1px solid #e2e2e2;
}

html > body .gsc-inline-block {
  min-width: 100px;
}

/*240515 add*/
.tourokubtn-ky a {
  display: block;
  color: #fff;
  text-decoration: none;
  background: #34405b;
  position: relative;
  /*>span.icon {
      @if $type ==pc {
          position: relative;
      }

      &:before {
          @if $type =="" {
              content: "";
              display: block;
              background: url(../images/common/icon_dash_white.svg) no-repeat;
              background-size: contain;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
          }

          @if $type ==sp {
              width: 9px;
              height: 9px;
              right: 10px;
          }

          @if $type ==pc {
              width: 9px;
              height: 9px;
              right: -10px;
          }
      }
  }*/
}
.tourokubtn-ky a > span.txt {
  position: relative;
  z-index: 10;
}

main,
.mfp-content {
  word-break: break-all;
}
main .colorRed,
.mfp-content .colorRed {
  color: #c00 !important;
}
main .colorPink,
.mfp-content .colorPink {
  color: #c90056 !important;
}
main .colorBlue,
.mfp-content .colorBlue {
  color: #172a88 !important;
}
main .colorGreen,
.mfp-content .colorGreen {
  color: #279d85 !important;
}
main .colorPurple,
.mfp-content .colorPurple {
  color: #4d4397 !important;
}
main .textBold,
.mfp-content .textBold {
  font-weight: bold;
}
main .taC,
.mfp-content .taC {
  text-align: center !important;
}
main .taL,
.mfp-content .taL {
  text-align: left !important;
}
main .taR,
.mfp-content .taR {
  text-align: right !important;
}
main .Center,
.mfp-content .Center {
  margin: 0 auto !important;
}
main .ovh,
.mfp-content .ovh {
  overflow: hidden !important;
}
main .mw100,
.mfp-content .mw100 {
  max-width: 100% !important;
  width: auto !important;
}
main .w100p,
.mfp-content .w100p {
  width: 100% !important;
}
main .pT0,
.mfp-content .pT0 {
  padding-top: 0 !important;
}
main .pT10,
.mfp-content .pT10 {
  padding-top: 10px !important;
}
main .pT20,
.mfp-content .pT20 {
  padding-top: 20px !important;
}
main .pT30,
.mfp-content .pT30 {
  padding-top: 30px !important;
}
main .pT40,
.mfp-content .pT40 {
  padding-top: 40px !important;
}
main .pB10,
.mfp-content .pB10 {
  padding-bottom: 10px !important;
}
main .pB20,
.mfp-content .pB20 {
  padding-bottom: 20px !important;
}
main .pB30,
.mfp-content .pB30 {
  padding-bottom: 30px !important;
}
main .pB40,
.mfp-content .pB40 {
  padding-bottom: 40px !important;
}
main .mB0,
.mfp-content .mB0 {
  margin-bottom: 0 !important;
}
main .mB5,
.mfp-content .mB5 {
  margin-bottom: 5px !important;
}
main .mB10,
.mfp-content .mB10 {
  margin-bottom: 10px !important;
}
main .mB20,
.mfp-content .mB20 {
  margin-bottom: 20px !important;
}
main .mB30,
.mfp-content .mB30 {
  margin-bottom: 30px !important;
}
main .mB40,
.mfp-content .mB40 {
  margin-bottom: 40px !important;
}
main .mB50,
.mfp-content .mB50 {
  margin-bottom: 50px !important;
}
main .mB55,
.mfp-content .mB55 {
  margin-bottom: 55px !important;
}
main .mB60,
.mfp-content .mB60 {
  margin-bottom: 60px !important;
}
main .mB70,
.mfp-content .mB70 {
  margin-bottom: 70px !important;
}
main .pB5,
.mfp-content .pB5 {
  padding-bottom: 5px !important;
}
main .mT0,
.mfp-content .mT0 {
  margin-top: 0 !important;
}
main .mT5,
.mfp-content .mT5 {
  margin-top: 5px !important;
}
main .mT10,
.mfp-content .mT10 {
  margin-top: 10px !important;
}
main .mT20,
.mfp-content .mT20 {
  margin-top: 20px !important;
}
main .mT30,
.mfp-content .mT30 {
  margin-top: 30px !important;
}
main .mT40,
.mfp-content .mT40 {
  margin-top: 40px !important;
}
main .mT50,
.mfp-content .mT50 {
  margin-top: 50px !important;
}
main .mT55,
.mfp-content .mT55 {
  margin-top: 55px !important;
}
main .mT60,
.mfp-content .mT60 {
  margin-top: 60px !important;
}
main .mT70,
.mfp-content .mT70 {
  margin-top: 70px !important;
}
main .mT80,
.mfp-content .mT80 {
  margin-top: 80px !important;
}
main .mL0,
.mfp-content .mL0 {
  margin-left: 0px !important;
}
main .mL5,
.mfp-content .mL5 {
  margin-left: 5px !important;
}
main .mL10,
.mfp-content .mL10 {
  margin-left: 10px !important;
}
main .mL20,
.mfp-content .mL20 {
  margin-left: 20px !important;
}
main .mL30,
.mfp-content .mL30 {
  margin-left: 30px !important;
}
main .mL40,
.mfp-content .mL40 {
  margin-left: 40px !important;
}
main .mL50,
.mfp-content .mL50 {
  margin-left: 50px !important;
}
main .mR0,
.mfp-content .mR0 {
  margin-right: 0px !important;
}
main .mR5,
.mfp-content .mR5 {
  margin-right: 5px !important;
}
main .mR10,
.mfp-content .mR10 {
  margin-right: 10px !important;
}
main .mR20,
.mfp-content .mR20 {
  margin-right: 20px !important;
}
main .mR30,
.mfp-content .mR30 {
  margin-right: 30px !important;
}
main .mR40,
.mfp-content .mR40 {
  margin-right: 40px !important;
}
main .mR50,
.mfp-content .mR50 {
  margin-right: 50px !important;
}
main .size12_pc,
.mfp-content .size12_pc {
  font-size: 12px !important;
}
main .size13_pc,
.mfp-content .size13_pc {
  font-size: 13px !important;
}
main .size14_pc,
.mfp-content .size14_pc {
  font-size: 14px !important;
}
main .size15_pc,
.mfp-content .size15_pc {
  font-size: 15px !important;
}
main .size16_pc,
.mfp-content .size16_pc {
  font-size: 16px !important;
}
main .size17_pc,
.mfp-content .size17_pc {
  font-size: 17px !important;
}
main .size18_pc,
.mfp-content .size18_pc {
  font-size: 18px !important;
}
main .size19_pc,
.mfp-content .size19_pc {
  font-size: 19px !important;
}
main .size20_pc,
.mfp-content .size20_pc {
  font-size: 20px !important;
}
main .size22_pc,
.mfp-content .size22_pc {
  font-size: 22px !important;
}
main .size24_pc,
.mfp-content .size24_pc {
  font-size: 24px !important;
}
main .size28_pc,
.mfp-content .size28_pc {
  font-size: 28px !important;
}
main .lh225,
.mfp-content .lh225 {
  line-height: 2.25 !important;
}

.imgFixed {
  display: flex;
}
.imgFixed > *.img img {
  display: block;
  margin: 0 auto;
}

.preBox {
  background: #f2f2f2;
  border: 1px solid #ccc;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
}
.preBox pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align-last: auto;
}

@media screen and (max-width: 980px) {
  main,
  .mfp-content {
    line-height: 1.9286;
  }
  main *:not(.title) + .innerBody p:first-child,
  main * + p,
  main p + p,
  .mfp-content *:not(.title) + .innerBody p:first-child,
  .mfp-content * + p,
  .mfp-content p + p {
    margin-top: 20px;
  }
  .sectionHeader {
    margin-bottom: 30px;
  }
  .sectionHeader:not(:first-child) {
    margin-top: 95px;
  }
  .commonLink [class^=icon] {
    margin-left: 12px;
  }
  .iconDashPink {
    margin-left: 7px;
    width: 9px;
    height: 9px;
  }
  .iconDashNavy {
    margin-left: 7px;
    width: 9px;
    height: 9px;
  }
  .pageHeader .breadcrumb {
    display: none !important;
  }
  .pageHeader.sink + .section .inner {
    padding-top: 30px;
  }
  .tag.bd {
    background: #4c95d0;
  }
  .tag.oa {
    background: #5958ac;
  }
  .tag.gr {
    background: #96c55c;
  }
  main[class^=page_product].page2:not(.page_product_about):not(.page_product_factory) .pageHeader + .section .inner:first-child {
    padding-top: 35px;
  }
  .innerSmall .inner {
    max-width: 700px;
    margin: 0 auto;
  }
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 94px;
  }
  /*
  .flowList {
  @if $type == ""{
  counter-reset: number 0;
  }

  > li {
  @if $type == ""{
  position: relative;
  }
  @if $type == sp{
  position: relative;

  &:not(:last-child):not(:nth-child(2n)) {
  figure {
  &:before {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -12px;
  margin: auto;
  }
  }
  }


  }
  @if $type == pc{
  &:not(:last-child):not(:nth-child(4n)) {
  figure {
  &:before {
  border-width: 13px 0 13px 13px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -7.65%;
  margin: auto;
  }
  }
  }
  }

  &:before {
  @if $type == ""{
  counter-increment: number 1;
  content: counter(number,decimal-leading-zero);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $c_base;
  @include fontRo;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  }
  @if $type == sp{
  width: 8.75vw;
  height: 8.75vw;
  max-width: 35px;
  max-height: 35px;
  }
  @if $type == pc{
  width: 3.75vw;
  height: 3.75vw;
  max-width: 45px;
  max-height: 45px;
  }
  }

  figure {
  @if $type == ""{
  display: block;
  position: relative;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }

  &:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;

  @if $type == sp{
  }
  @if $type == pc{

  }

  }
  }
  }

  p {
  @if $type == ""{
  text-align: center;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }
  }
  }
  */
  /*240515 add*/
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    justify-content: flex-start !important;
    height: 200px;
  }
}
@media screen and (max-width: 980px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle,
  main.page2 .pageHeader .pageTitle {
    margin-top: 3px;
    padding: 0 15px 9px;
    font-size: 18px;
    line-height: 1.4;
  }
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle:before,
  main.page2 .pageHeader .pageTitle:before {
    width: 40px;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 134px;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    justify-content: flex-start !important;
    height: 300px;
  }
}
@media screen and (max-width: 980px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle {
    margin-top: 17px;
    padding-bottom: 17px;
    font-size: 23px;
    line-height: 1.2;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before {
    bottom: -2px;
    width: 40px;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote {
    margin-top: 19px;
    font-size: 14px;
    line-height: 1.6429;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
    padding-left: 45px;
  }
  .page_works_index:not(.page2) .pageHeader {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .page_works_index:not(.page2) .pageHeader:before {
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner {
    flex-direction: column;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 40px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderWorksHeaderCase {
    order: 1;
    padding: 20px 15px 0;
    text-align: center;
    font-size: 16px;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageTitle {
    order: 0;
    margin-top: 0;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderNote {
    order: 2;
  }
  .oa.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(244, 244, 255, 0.9);
  }
  .busduct.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(226, 234, 241, 0.9);
  }
  .greening.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(235, 244, 236, 0.8);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner {
    padding-bottom: 80px;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderWorksHeaderCase {
    order: 1;
    text-align: center;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageTitle {
    order: 0;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap {
    width: calc(100% - 30px);
    max-width: 820px;
    margin: 0 auto;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .pageHeaderWorksMapTitle {
    font-size: 18px;
    transition: 0.2s transform;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .pageHeaderWorksMapNote {
    margin-top: 10px;
    transition: 0.2s transform;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList {
    height: 66.6vw;
    max-height: 600px;
    margin-top: 1em;
    background: url(../images/works_greening/map.png) no-repeat center;
    background-size: contain;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li {
    font-size: clamp(1px, 2.4vw, 14px);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnHokkaido {
    top: 25.5%;
    right: 2.9%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTohoku {
    top: 44.5%;
    right: 23.17%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKanto {
    top: 59.16%;
    right: 26.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTokyo {
    top: 73.16%;
    right: 22.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKinki {
    bottom: 11.66%;
    right: 48.29%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKyushu {
    bottom: 0;
    left: 14.24%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChugoku {
    top: 51.67%;
    left: 17.43%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChubu {
    top: 37.66%;
    left: 35.73%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList a i {
    margin-right: 5px;
  }
  .worksList > li + li {
    margin-top: 6px;
  }
  .worksList > li > a {
    padding: 16px 30px 16px 16px;
  }
  .worksList > li > a:before, .worksList > li > a:after {
    width: 9px;
    height: 11px;
    right: 12px;
  }
  .worksList > li > a .worksListTag {
    margin-top: 8px;
  }
  .worksList > li > a .worksListTag > li {
    padding: 1px 6px;
    margin-top: 2px;
    margin-right: 2px;
    font-size: 11px;
  }
  .thumbArea {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto 14px;
  }
  .thumbArea p + p {
    margin-top: 2px;
  }
  *:not(.title) + .innerBody .thumbArea:first-child,
  * + .thumbArea {
    margin-top: 40px;
  }
  .leadBlock {
    padding: 35px 15px;
    background: #f2f2f2;
  }
  .leadBlock .leadCatch {
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 1.4;
    line-height: 1.4;
  }
  .leadBlock .leadNote {
    line-height: 1.7143;
  }
  .leadBlockSmall {
    padding: 35px 15px 0;
  }
  .leadBlockSmall .leadCatch {
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 1.4;
  }
  .leadBlockSmall .leadNote {
    line-height: 1.7143;
  }
  .section .inner {
    max-width: 700px;
    margin: 0 auto;
    padding: 51px 20px 30px;
  }
  .section + .section:not([data-background]) .inner {
    padding-top: 20px;
  }
  .section .workHeader + .contentInner {
    padding-top: 40px;
  }
  .section .contentInner {
    max-width: 700px;
    margin: 0 auto;
    padding: 51px 20px 60px;
  }
  .section .sectionHeader .sectionTitle {
    font-size: 21px;
  }
  *:not(.title) + .innerBody .sectionHeader:first-child,
  * + .sectionHeader {
    margin-top: 50px;
  }
  .sectionHeaderBlock {
    margin-top: 45px;
    padding-top: 39px;
  }
  .sectionHeaderBlock:before {
    width: 100%;
    height: 133px;
  }
  .sectionHeaderBlock .sectionHeader {
    margin-bottom: 45px;
  }
  .sectionHeaderBlock .sectionHeaderLink {
    margin-top: 16px;
    text-align: center;
  }
  h2.title + h3.title,
  .titleBlock + h3.title {
    margin-top: 0;
  }
  * + h3.title {
    margin-top: 40px;
  }
  h3.title {
    padding: 0 10px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.6;
  }
  h3.title:before {
    width: 4px;
  }
  h3.title .titleSub {
    font-size: 14px;
    margin-left: 20px;
  }
  * + h4.title {
    margin-top: 40px;
  }
  h4.title {
    padding: 0;
    font-size: 16px;
    line-height: 1.4;
  }
  h4.title + p {
    margin-top: 8px;
  }
  * + h5.title {
    margin-top: 20px;
  }
  h5.title {
    padding: 0 10px 8px;
    padding-bottom: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.4;
  }
  .postContent * + h2 {
    margin-top: 56px;
  }
  .postContent h2 {
    padding: 0 10px 8px;
    padding-bottom: 8px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.4;
  }
  .titleLine {
    margin-top: 40px;
    padding-left: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.85;
  }
  .titleLine:before {
    width: 25px;
    top: 10px;
  }
  * + .titleCenter {
    margin-top: 40px;
  }
  .titleCenter {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.4;
  }
  * + .titleBold {
    margin-top: 40px;
  }
  .titleBold {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.85;
  }
  h2.title,
  .titleBlock {
    padding: 8px 0;
    margin-bottom: 25px;
    font-size: 20px;
  }
  h2.title:before,
  .titleBlock:before {
    height: 3px;
    width: 70px;
  }
  h2.title.iconIn,
  .titleBlock.iconIn {
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: space-between;
  }
  h2.title .iconArea,
  .titleBlock .iconArea {
    justify-content: flex-end;
    padding-top: 10px;
    margin-bottom: -30px;
  }
  h2.title .iconArea [class^=icon],
  .titleBlock .iconArea [class^=icon] {
    width: 16vw;
    max-width: 60px;
    height: 16vw;
    max-height: 60px;
  }
  * + h2.title,
  * + .titleBlock {
    margin-top: 45px;
  }
  .sectionHeader + .contentsList {
    margin-top: 28px;
  }
  .contentsList + h3.title {
    margin-top: 70px;
  }
  p + .contentsList,
  p + .contentsList3 {
    margin-top: 30px;
  }
  .contentsList.haveIcon .contentsListThumb,
  .contentsList3.haveIcon .contentsListThumb {
    position: relative;
  }
  .contentsList.haveIcon .contentsListThumb:before,
  .contentsList3.haveIcon .contentsListThumb:before {
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    background: #34405b;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }
  .contentsList.haveIcon .contentsListThumb:after,
  .contentsList3.haveIcon .contentsListThumb:after {
    content: "";
    width: 9px;
    height: 9px;
    display: block;
    background: url(../images/common/icon_dash_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    right: 9px;
    bottom: 11px;
    z-index: 2;
  }
  .contentsList > li:nth-child(n+2),
  .contentsList3 > li:nth-child(n+2) {
    margin-top: 60px;
  }
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    width: 100%;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}
@media screen and (max-width: 980px) {
  .contentsList > li .contentsListText,
  .contentsList3 > li .contentsListText {
    margin-top: 7px;
  }
  .contentsList > li .contentsListText .contentsListNote,
  .contentsList3 > li .contentsListText .contentsListNote {
    padding-left: 25px;
    padding-left: 15px;
    line-height: 1.4286;
    font-size: 14px;
  }
  .boxList,
  .boxList2 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .boxList > li,
  .boxList2 > li {
    width: 100%;
    margin-top: 20px;
  }
  .boxList > li:nth-child(n+2),
  .boxList2 > li:nth-child(n+2) {
    margin-top: 35px;
  }
  .boxList > li .boxListBody,
  .boxList2 > li .boxListBody {
    padding: 20px 0 0;
  }
  .boxList > li .boxListTitle,
  .boxList2 > li .boxListTitle {
    padding: 3px 0 1px 15px;
    font-size: 18px;
  }
  .boxList > li .boxListTitle span,
  .boxList2 > li .boxListTitle span {
    margin-bottom: 4px;
    font-size: 12px;
  }
  .boxList > li .boxListTitle:before,
  .boxList2 > li .boxListTitle:before {
    width: 2px;
  }
  * + .borderList,
  * + .borderList3,
  * + .borderList4,
  * + .borderListGap {
    margin-top: 16px;
  }
  .borderList > li,
  .borderList3 > li,
  .borderList4 > li {
    padding: 16px 8px;
  }
  .borderList > li:first-child,
  .borderList3 > li:first-child,
  .borderList4 > li:first-child {
    padding-top: 0;
  }
  .borderList > li + li,
  .borderList3 > li + li,
  .borderList4 > li + li {
    border-top: 1px solid #b8b8b8;
  }
  .borderListGap {
    text-align: center;
  }
  .borderListGap > li {
    padding: 16px 8px;
  }
  .borderListGap > li + li {
    margin-top: 15px;
  }
  .anchorList {
    max-width: 740px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .anchorList > li {
    width: calc(33.3% - 1px);
    margin-right: 1px;
  }
  .anchorList > li:nth-child(n+4) {
    margin-top: 1px;
  }
  .anchorList > li > a {
    padding: 17px 5px 12px;
    font-size: 14px;
  }
  .anchorList > li > a > div {
    display: block;
  }
  .anchorList > li > a > div:after {
    content: "";
    display: block;
    background: url(../images/common/icon_dash_white.svg) no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    width: 11px;
    height: 9px;
    margin: 6px auto 0;
  }
  ul.anchorListSmall {
    max-width: 700px;
    margin: 0 auto;
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  ul.anchorListSmall > li {
    margin: 0;
    width: 100%;
    text-align: left;
  }
  ul.anchorListSmall > li + li {
    border-top: 1px solid #cecece;
  }
  ul.anchorListSmall > li > a {
    padding: 8px;
    padding-right: 38px;
  }
  ul.anchorListSmall > li > a:after {
    width: 9px;
    height: 9px;
    flex: none;
    margin: auto 14px auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
  }
  * + ul.anchorListSmall {
    margin-top: 35px;
  }
  .leadBlock + ul.anchorListSmall,
  .pageHeader + ul.anchorListSmall {
    margin-top: 35px !important;
  }
  .inner ul.anchorListSmall {
    width: 100%;
  }
  ol.anchorListSmall {
    max-width: 700px;
    margin: 0 auto;
    margin: 0 auto;
  }
  ol.anchorListSmall > li {
    padding-left: 18px;
    margin: 0;
  }
  .productLinkList > li:nth-child(n+2),
  .productLinkListFull > li:nth-child(n+2) {
    margin-top: 30px;
  }
  .productLinkList > li h3.title,
  .productLinkListFull > li h3.title {
    margin-bottom: 15px;
  }
  .productLinkList > li > div,
  .productLinkList > li > a,
  .productLinkListFull > li > div,
  .productLinkListFull > li > a {
    display: block;
  }
  .productLinkList > li > div .productLinkListText,
  .productLinkList > li > a .productLinkListText,
  .productLinkListFull > li > div .productLinkListText,
  .productLinkListFull > li > a .productLinkListText {
    margin-top: 15px;
  }
  .productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitle {
    font-size: 16px;
    line-height: 1.35;
  }
  .productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub {
    margin-top: 5px;
    font-size: 13px;
    line-height: 1.35;
  }
  .productLinkList > li > div .productLinkListText .productLinkListNote,
  .productLinkList > li > a .productLinkListText .productLinkListNote,
  .productLinkListFull > li > div .productLinkListText .productLinkListNote,
  .productLinkListFull > li > a .productLinkListText .productLinkListNote {
    margin-top: 9px;
    font-size: 13px;
    line-height: 1.69;
  }
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitle {
    padding-right: 12px;
  }
  .productLinkList > li .productLinkListThumb,
  .productLinkListFull > li .productLinkListThumb {
    text-align: center;
  }
  .productLinkList > li .productLinkListThumb > img,
  .productLinkListFull > li .productLinkListThumb > img {
    width: 100%;
  }
  .productLinkList > li .productLinkListThumb > ul,
  .productLinkListFull > li .productLinkListThumb > ul {
    top: 10px;
    right: 10px;
  }
  .productLinkList > li .productLinkListThumb > ul > li,
  .productLinkListFull > li .productLinkListThumb > ul > li {
    height: 30px;
  }
  .productLinkList > li .productLinkListText,
  .productLinkListFull > li .productLinkListText {
    margin-top: 15px;
    padding: 0 15px 15px;
  }
  .productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitle {
    font-size: 16px;
    line-height: 1.35;
  }
  .productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitleSub {
    margin-top: 5px;
    font-size: 13px;
    line-height: 1.35;
  }
  .productLinkList > li .productLinkListText .productLinkListNote,
  .productLinkListFull > li .productLinkListText .productLinkListNote {
    margin-top: 9px;
    font-size: 13px;
    line-height: 1.69;
  }
  .productLinkListFull > li {
    width: 100%;
  }
  .productLinkListFull > li:nth-child(n+2) {
    margin-top: 4.48%;
  }
  .lineList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .lineList > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }
  .lineList > li:nth-child(n+3) {
    margin-top: 50px;
  }
  .lineList > li:nth-child(odd) {
    padding-right: 15px;
  }
  .lineList > li:nth-child(even) {
    padding-left: 15px;
  }
  .lineList > li:nth-child(odd):before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #bcbcbc;
    position: absolute;
    top: 0;
    right: 0;
  }
  .lineList > li .lineListContent {
    width: 100%;
    max-width: 155px;
    padding: 0;
    margin: 0 auto 15px;
  }
  .lineList > li .lineListContent figure {
    margin-bottom: 14px;
  }
  .lineList > li .btnWrap a {
    display: block;
    max-width: 175px;
    margin: 0 auto;
  }
  .postContent * + ul:not([class]),
  * + ul.commonList,
  * + ol.commonList {
    margin-top: 20px;
  }
  .postContent ul:not([class]).mutual_sp, .postContent ul:not([class]).mutual,
  ul.commonList.mutual_sp,
  ul.commonList.mutual {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .postContent ul:not([class]).mutual_sp > li, .postContent ul:not([class]).mutual > li,
  ul.commonList.mutual_sp > li,
  ul.commonList.mutual > li {
    width: calc(50% - 20px);
  }
  .postContent ul:not([class]).mutual_sp > li:nth-child(odd), .postContent ul:not([class]).mutual > li:nth-child(odd),
  ul.commonList.mutual_sp > li:nth-child(odd),
  ul.commonList.mutual > li:nth-child(odd) {
    margin-right: 10px;
  }
  .postContent ul:not([class]).mutual_sp > li:nth-child(even), .postContent ul:not([class]).mutual > li:nth-child(even),
  ul.commonList.mutual_sp > li:nth-child(even),
  ul.commonList.mutual > li:nth-child(even) {
    margin-left: 10px;
  }
  .postContent ul:not([class]) > li:before,
  ul.commonList > li:before {
    top: 10px;
    margin: auto;
    left: 0;
  }
  .postContent ul:not([class]) > li:before {
    top: 10px;
  }
  ol.commonList > li {
    position: relative;
  }
  ol.commonList > li + li {
    margin-top: 7px;
  }
  .iframeWrap {
    padding-bottom: 56.25%;
  }
  * + .ytWrap {
    margin-top: 2em;
  }
  .ytWrap {
    max-width: 560px;
  }
  .ytTitle {
    margin-top: 10px;
    padding-left: 15px;
  }
  .ytTitle + * {
    margin-top: 10px;
  }
  .ytTitle .iconDashNavy {
    margin-left: 0;
    margin-right: 7px;
  }
  .moreList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .moreList > li {
    width: calc(50% - 10px);
  }
  .moreList > li:nth-child(n+3) {
    margin-top: 20px;
  }
  .moreList > li a {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .moreList > li a .moreListThumb {
    width: calc(100% + 2px);
    margin: auto;
    transform: translateX(-1px);
  }
  .moreList > li a .moreListThumb img {
    object-fit: cover;
    width: inherit;
  }
  .moreList > li a .moreListItemText {
    padding: 8px 10px 41px;
    position: relative;
    background: #fff;
  }
  .moreList > li a .moreListItemText .moreListItemNote {
    font-size: 13px;
    line-height: 1.3846;
  }
  .moreList > li a .moreListItemText .moreListItemMore {
    margin-top: auto;
    font-size: 12px;
    right: 10px;
    bottom: 7px;
  }
  *:not(.title) + .innerBody .qaList:first-child,
  *:not(h2.title):not(.titleBlock) + .qaList {
    margin-top: 20px;
  }
  .qaList > dt {
    padding: 14px 55px 14px 40px;
  }
  .qaList > dt:before {
    width: 24px;
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    left: 0;
  }
  .qaList > dt .qaQuestion:before, .qaList > dt .qaQuestion:after {
    width: 17px;
    right: -39px;
  }
  .qaList > dd {
    padding: 0 0 0 40px;
    margin-bottom: 14px;
  }
  .qaList > dd:before {
    width: 24px;
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    left: 0;
  }
  .qaList > dd .qaAnswer {
    padding: 12px 25px;
  }
  .checkList > li {
    padding-left: 30px;
  }
  .checkList > li:nth-child(n+2) {
    margin-top: 8px;
  }
  .checkList > li:before {
    width: 21px;
    height: 18px;
  }
  *:not(.title) + .innerBody .scrollTable:first-child,
  * + .scrollTable {
    margin-top: 1.5em;
  }
  .scrollTable {
    overflow: auto;
  }
  .scrollTable .commonTable {
    width: 100%;
    min-width: 1000px;
    white-space: nowrap;
  }
  .scrolltext {
    display: block;
    font-size: 12px;
    text-align: right;
  }
  .scrollTable .commonTable:nth-of-type(1) {
    margin-top: 0;
  }
  .postContent table:not(.hanging).small > tbody > tr > th,
  .postContent table:not(.hanging).small > thead > tr > th,
  .postContent table:not(.hanging).small > tr > th,
  .postContent table:not(.hanging).small > tbody > tr > td,
  .postContent table:not(.hanging).small > thead > tr > td,
  .postContent table:not(.hanging).small > tr > td,
  .commonTable.small > tbody > tr > th,
  .commonTable.small > thead > tr > th,
  .commonTable.small > tr > th,
  .commonTable.small > tbody > tr > td,
  .commonTable.small > thead > tr > td,
  .commonTable.small > tr > td {
    padding: 3px;
  }
  .postContent table:not(.hanging).thNowrap > tbody > tr > th,
  .postContent table:not(.hanging).thNowrap > thead > tr > th,
  .postContent table:not(.hanging).thNowrap > tr > th,
  .commonTable.thNowrap > tbody > tr > th,
  .commonTable.thNowrap > thead > tr > th,
  .commonTable.thNowrap > tr > th {
    white-space: nowrap;
  }
  .postContent table:not(.hanging) > tbody > tr > th.w115_sp,
  .postContent table:not(.hanging) > tbody > tr > td.w115_sp,
  .postContent table:not(.hanging) > thead > tr > th.w115_sp,
  .postContent table:not(.hanging) > thead > tr > td.w115_sp,
  .commonTable > tbody > tr > th.w115_sp,
  .commonTable > tbody > tr > td.w115_sp,
  .commonTable > thead > tr > th.w115_sp,
  .commonTable > thead > tr > td.w115_sp {
    width: 115px !important;
  }
  .postContent table:not(.hanging) > tbody > tr > th.w165_sp,
  .postContent table:not(.hanging) > tbody > tr > td.w165_sp,
  .postContent table:not(.hanging) > thead > tr > th.w165_sp,
  .postContent table:not(.hanging) > thead > tr > td.w165_sp,
  .commonTable > tbody > tr > th.w165_sp,
  .commonTable > tbody > tr > td.w165_sp,
  .commonTable > thead > tr > th.w165_sp,
  .commonTable > thead > tr > td.w165_sp {
    width: 165px !important;
  }
  .postContent table:not(.hanging) > tbody > tr > th,
  .postContent table:not(.hanging) > thead > tr > th,
  .commonTable > tbody > tr > th,
  .commonTable > thead > tr > th {
    padding: 5px 10px;
    font-size: 14px;
  }
  .postContent table:not(.hanging) > tbody > tr > td,
  .postContent table:not(.hanging) > thead > tr > td,
  .commonTable > tbody > tr > td,
  .commonTable > thead > tr > td {
    padding: 10px 10px;
  }
  *:not(.title) + .innerBody .borderBox:first-child,
  * + .borderBox {
    margin-top: 20px;
  }
  .borderBox {
    padding: 35px 22px 40px;
  }
  .borderBox.bgLogo {
    background-size: 100% auto;
  }
  .borderBox.small {
    padding: 15px 20px 15px;
  }
  .colorBoxSky,
  .colorBoxPurple,
  .colorBoxPink,
  .colorBoxPrimary,
  .colorBox {
    padding: 35px 22px 40px;
  }
  .colorBoxSky .boxTitle,
  .colorBoxPurple .boxTitle,
  .colorBoxPink .boxTitle,
  .colorBoxPrimary .boxTitle,
  .colorBox .boxTitle {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 1.4;
  }
  .colorBoxSky.small,
  .colorBoxPurple.small,
  .colorBoxPink.small,
  .colorBoxPrimary.small,
  .colorBox.small {
    padding: 15px 20px 15px;
  }
  .colorBoxSky .twoCol .txt,
  .colorBoxPurple .twoCol .txt,
  .colorBoxPink .twoCol .txt,
  .colorBoxPrimary .twoCol .txt,
  .colorBox .twoCol .txt {
    margin-bottom: 18px;
    font-size: 13px;
    line-height: 1.69;
  }
  .colorBoxSky .twoCol .img,
  .colorBoxPurple .twoCol .img,
  .colorBoxPink .twoCol .img,
  .colorBoxPrimary .twoCol .img,
  .colorBox .twoCol .img {
    padding-right: 0;
    padding-left: 0;
  }
  .colorBoxSky .twoCol .txt + .img,
  .colorBoxPurple .twoCol .txt + .img,
  .colorBoxPink .twoCol .txt + .img,
  .colorBoxPrimary .twoCol .txt + .img,
  .colorBox .twoCol .txt + .img {
    margin-top: 1.5em;
    margin-bottom: 0;
  }
  .colorBoxSky .boxTitle,
  .colorBoxPurple .boxTitle,
  .colorBoxPink .boxTitle,
  .colorBoxPrimary .boxTitle,
  .colorBox .boxTitle {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 1.4;
  }
  .colorBoxPrimary {
    max-width: 700px;
    margin: 0 auto;
  }
  .colorBoxPrimary + .anchorListSmall {
    margin-top: 0;
  }
  .colorBoxTitle {
    margin-bottom: 24px;
    font-size: 18px;
    text-align: center;
  }
  *:not(.title) + .innerBody .colorBox:first-child,
  * + .colorBox,
  *:not(.title) + .innerBody .colorBoxPink:first-child,
  * + .colorBoxPink,
  *:not(.title) + .innerBody .colorBoxPurple:first-child,
  * + .colorBoxPurple {
    margin-top: 40px;
  }
  [class^=imgLine].rowSP {
    flex-direction: column;
  }
  [class^=imgLine].rowSP > li {
    width: 100% !important;
  }
  [class^=imgLine].rowSP > li + li {
    margin-top: 30px !important;
  }
  .imgLine2 {
    margin-top: 45px;
  }
  .imgLine2:not(.readContent) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .imgLine2:not(.readContent) > li {
    width: calc(50% - 16px);
  }
  .imgLine2:not(.readContent) > li:nth-child(n+3) {
    margin-top: 30px;
  }
  .imgLine2.readContent > li {
    width: 100%;
  }
  .imgLine2.readContent > li .cap {
    text-align: center;
  }
  .imgLine2.readContent > li + li {
    margin-top: 30px;
  }
  .imgLine2 > li .imgNote {
    line-height: 1.4;
  }
  .imgLine2 .cap {
    margin-top: 8px;
    text-align: left;
    line-height: 1.5;
  }
  .imgLine2Full:not(.readContent) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .imgLine2Full:not(.readContent) > li {
    width: calc(50% - 16px);
  }
  .imgLine2Full:not(.readContent) > li:nth-child(n+3) {
    margin-top: 30px;
  }
  .imgLine2Full.readContent > li {
    width: 100%;
  }
  .imgLine2Full.readContent > li .cap {
    text-align: center;
  }
  .imgLine2Full.readContent > li + li {
    margin-top: 30px;
  }
  .imgLine2Full > li .imgNote {
    line-height: 1.4;
  }
  .imgLine2Full .cap {
    margin-top: 8px;
    text-align: left;
    line-height: 1.5;
  }
  *:not(.title) + .innerBody .imgLine2Full:first-child,
  * + .imgLine2Full {
    margin-top: 30px;
  }
  [class^=imgLine3] {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  [class^=imgLine3] .imgNote {
    line-height: 1.4;
  }
  [class^=imgLine3] p {
    margin-bottom: 15px;
  }
  [class^=imgLine3] > li {
    width: 100%;
    margin-top: 20px;
  }
  [class^=imgLine3] > li:nth-child(2) {
    margin-top: 0;
  }
  [class^=imgLine3] > li:nth-child(n+2) {
    margin-top: 30px;
  }
  [class^=imgLine4] {
    display: flex;
    flex-wrap: wrap;
  }
  [class^=imgLine4] li {
    width: calc(50% - 8px);
    margin-right: 16px;
  }
  [class^=imgLine4] li:nth-of-type(2n) {
    margin-right: 0;
  }
  [class^=imgLine4] li:nth-of-type(n + 3) {
    margin-top: 30px;
  }
  .imgLine5Arrow:not(.imgArrow) > li:before {
    transform: rotate(-90deg);
    top: 0 !important;
    bottom: 0 !important;
    left: inherit !important;
    right: -3.6vw !important;
    margin: auto !important;
    border-width: 1.73vw 1.73vw 0 1.73vw !important;
  }
  .imgLine5Arrow:not(.imgArrow) > li:nth-child(even):before {
    display: none;
  }
  .imgLine5Arrow > li {
    margin-top: 0 !important;
  }
  .imgLine5Arrow > li:nth-of-type(n + 3) {
    margin-top: 60px !important;
  }
  .imgLine1Arrow,
  .imgLine2Arrow,
  .imgLine3Arrow,
  .imgLine4Arrow {
    margin-top: 30px;
  }
  .imgLine1Arrow:not(.imgArrow) > li:not(:last-child):before,
  .imgLine2Arrow:not(.imgArrow) > li:not(:last-child):before,
  .imgLine3Arrow:not(.imgArrow) > li:not(:last-child):before,
  .imgLine4Arrow:not(.imgArrow) > li:not(:last-child):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: #34405b transparent transparent transparent;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .imgLine1Arrow.imgArrow > li:not(:last-child):before,
  .imgLine2Arrow.imgArrow > li:not(:last-child):before,
  .imgLine3Arrow.imgArrow > li:not(:last-child):before,
  .imgLine4Arrow.imgArrow > li:not(:last-child):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: #34405b transparent transparent transparent;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .imgLine1Arrow > li,
  .imgLine2Arrow > li,
  .imgLine3Arrow > li,
  .imgLine4Arrow > li {
    width: 100%;
    margin-right: 0;
    position: relative;
  }
  .imgLine1Arrow > li img,
  .imgLine2Arrow > li img,
  .imgLine3Arrow > li img,
  .imgLine4Arrow > li img {
    display: block;
    margin: 0 auto;
  }
  .imgLine1Arrow > li:nth-of-type(n + 2),
  .imgLine2Arrow > li:nth-of-type(n + 2),
  .imgLine3Arrow > li:nth-of-type(n + 2),
  .imgLine4Arrow > li:nth-of-type(n + 2) {
    margin-top: 30px;
  }
  .imgLine5Arrow {
    margin-top: 30px;
  }
  .imgLine5Arrow:not(.imgArrow) > li:not(:last-child):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: #34405b transparent transparent transparent;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .imgLine5Arrow.imgArrow > li figure {
    display: block;
    position: relative;
  }
  .imgLine5Arrow.imgArrow > li figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    transform: rotate(-90deg);
    top: 0 !important;
    bottom: 0 !important;
    left: inherit !important;
    right: -3.6vw !important;
    margin: auto !important;
    border-style: solid;
    border-color: #34405b transparent transparent transparent;
    border-width: 1.73vw 1.73vw 0 1.73vw !important;
  }
  .imgLine5Arrow.imgArrow > li:nth-child(even) figure:before {
    display: none;
  }
  .imgLine5Arrow > li {
    width: 100%;
    margin-right: 0;
    position: relative;
  }
  .imgLine5Arrow > li img {
    display: block;
    margin: 0 auto;
  }
  .imgLine5Arrow > li:nth-of-type(n + 2) {
    margin-top: 30px;
  }
  [class^=imgLine5] {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  [class^=imgLine5] .imgNote {
    line-height: 1.4;
  }
  [class^=imgLine5] p {
    margin-top: 8px;
  }
  [class^=imgLine5] > li {
    width: 48%;
    margin-top: 20px;
  }
  [class^=imgLine5] > li:nth-child(odd) {
    margin-right: 4%;
  }
  [class^=imgLine5] > li:nth-child(n+3) {
    margin-top: 25px !important;
  }
  [class^=imgLine5] > li img {
    width: 100%;
    max-width: inherit;
  }
  .cap {
    margin-top: 2px;
    text-align: center;
  }
  .stepList {
    margin-top: 1.5em;
  }
  .stepList > div {
    padding: 20px 15px 20px 65px;
  }
  .stepList > div + div {
    margin-top: 34px;
  }
  .stepList > div + div:before {
    border-width: 11px 11px 0 11px;
    top: -23px;
  }
  .stepList > div > dt {
    width: 35px;
    height: 35px;
    left: 15px;
  }
  .stepList > div > dt strong {
    font-size: 18px;
  }
  .flowSummary .flowText {
    margin-top: 1.5em;
  }
  li:not(:first-child) .btnRelated {
    margin-top: 30px;
  }
  .btnRelated a {
    align-items: center;
    height: 35.16vw;
    max-height: 170px;
    font-size: 16px;
    line-height: 1.3;
  }
  .btnRelated a .btnRelatedBg {
    width: 100%;
    height: 35.16vw;
    max-height: 170px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 0;
  }
  .btnRelated a .btnRelatedBg:after {
    height: 35.16vw;
    max-height: 170px;
  }
  .btnRelated a .btnRelatedBg img {
    height: 35.16vw;
    max-height: 170px;
  }
  .btnRelated a > span {
    padding-right: 10%;
  }
  .imgLineBody {
    padding: 20px 15px 0;
  }
  .imgLineBody .imgLineTitle {
    padding: 3px 0 1px 15px;
    font-size: 18px;
  }
  .imgLineBody .imgLineTitle span {
    margin-bottom: 4px;
    font-size: 12px;
  }
  .imgLineBody .imgLineTitle:before {
    width: 2px;
  }
  .photoList .photoListMain {
    height: 49.6vw;
    max-height: 372px;
  }
  .photoList .photoListMain img {
    height: 49.6vw;
    max-height: 372px;
  }
  .photoList .photoListSelect > li {
    width: 23.76%;
    height: 14.73vw;
    max-height: 105px;
    margin-top: 4.4%;
  }
  .photoList .photoListSelect > li:not(:nth-child(4n)) {
    margin-right: 1.65%;
  }
  .photoList .photoListSelect > li:nth-child(n+5) {
    margin-top: 1.65%;
  }
  .photoList .photoListSelect > li:before {
    border: 2px solid #c90056;
  }
  .photoList .photoListSelect > li img {
    height: 14.73vw;
    max-height: 105px;
  }
  .attentionList {
    font-size: 12px;
  }
  .modalBtn:before {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    transform: translateY(3px);
  }
  .tabWrap {
    margin-top: 40px;
  }
  .tabMenu ul > li {
    padding: 14px 10px;
    line-height: 1.4;
  }
  .tabContent {
    padding-top: 33px;
    padding-bottom: 13px;
  }
  .speakerClass {
    margin-bottom: 40px;
    line-height: 1.7143;
  }
  .speakerClass > div {
    margin-top: 17px;
  }
  .twoCol {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .twoCol.imgBeforeSP .txt {
    order: 1;
  }
  .twoCol.imgBeforeSP .img {
    order: 0;
  }
  .twoCol .txt {
    order: 0;
    min-height: 0%;
  }
  .twoCol .txt > *:nth-child(1) {
    margin-top: 0;
  }
  .twoCol .txt + .img {
    margin-bottom: 15px;
  }
  .twoCol .img {
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    text-align: center;
    order: 1;
    margin-top: 1.5em;
  }
  .twoCol .img .cap {
    display: block;
  }
  .twoCol .img img {
    display: block;
    margin: 0 auto;
  }
  .twoCol .img + .txt {
    margin-top: 20px;
  }
  .twoCol .img .ytWrap {
    margin-top: 0;
  }
  .twoCol .img img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .twoCol .img img + img {
    margin-top: 22px;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none), screen and (max-width: 980px) and (-ms-high-contrast: active) {
  .twoCol .img {
    height: 100%;
  }
}
@media screen and (max-width: 980px) {
  * .iconWrap {
    margin-top: 40px;
  }
  .iconWrap + .iconWrap {
    padding-top: 40px;
    border-top: 1px solid #b8b8b8;
  }
  .iconWrap > i {
    display: block;
    width: 180px;
    margin: 0 auto 15px;
    text-align: center;
  }
  .workHeader {
    background: inherit !important;
  }
  .workHeader .workHeaderThumb img {
    width: 100%;
  }
  .workHeader .sectionTitleImg {
    max-width: 224px;
    margin-bottom: 16px;
  }
  .workHeader .workHeaderLead {
    padding: 50px 19px 0;
  }
  .workHeader .workHeaderLead .sectionTitle {
    font-size: 21px;
    line-height: 1.619;
    font-weight: 400;
  }
  .workHeader .workHeaderLead .sectionTitleSub {
    margin-top: 18px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
  .pickupListItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .pickupListItem .pickupItem {
    width: calc(50% - 10px);
    margin: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
  }
  .pickupListItem .pickupItem:nth-child(n+3) {
    margin-top: 20px;
  }
  .pickupListItem .pickupItem > a {
    height: 100%;
    padding-top: 55%;
  }
  .pickupListItem .pickupItem > a .pickupItemThumb img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .pickupListItem .pickupItem > a .pickupItemText {
    height: 100%;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag {
    padding-left: 15px;
    font-size: 12px;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag:before {
    width: 10px;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemNote {
    padding: 10px 15px 28px;
    line-height: 1.6429;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemDate {
    font-size: 12px;
    right: 7px;
    bottom: 8px;
  }
  .caseListSlider .slick-dots {
    bottom: 0;
  }
  .caseListSlider .slick-dots > li {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
  .caseListSlider .slick-dots > li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    background: #303b54;
  }
  .caseListSlider .slick-dots > li.slick-active button {
    background: #c90056;
  }
  .caseListSlider .caseItem {
    width: calc(100vw - 140px);
    margin: 0 15px;
  }
  .caseListSlider .caseItemThumb {
    height: 235px;
  }
  .caseListSlider .caseItemThumb img {
    width: 100%;
  }
  .caseListSlider .caseItemThumb > ul > li {
    font-size: clamp(1px, 2.4vw, 14px);
  }
  .caseListSlider .caseItemText {
    padding: 18px 15px;
  }
  .caseListSlider .caseItemText .caseItemTitle {
    font-size: 14px;
  }
  .caseListSlider .caseItemText .caseItemInfo {
    margin-top: 13px;
  }
  .caseListSlider .caseItemText .caseItemInfo > div + div {
    margin-top: 8px;
  }
  .caseListSlider .caseItemText .caseItemInfo > div > dt {
    width: 56px;
    padding: 3px;
    margin-right: 10px;
    font-size: 11px;
    line-height: 1;
  }
  .caseListSlider .caseItemText .caseItemInfo > div > dd {
    width: calc(100% - 66px);
    font-size: 12px;
    line-height: 1.4;
  }
  .caseListItem {
    justify-content: space-between;
  }
  .caseListItem .caseItem {
    width: 100%;
  }
  .caseListItem .caseItem:nth-child(n+2) {
    margin-top: 60px;
  }
  .caseListItem .caseItemThumb {
    height: 235px;
  }
  .caseListItem .caseItemThumb img {
    width: 100%;
  }
  .caseListItem .caseItemThumb > ul > li {
    padding: 1px 0;
    font-size: 11px;
    font-size: clamp(1px, 2.4vw, 14px);
  }
  .caseListItem .caseItemThumb > ul > li.tag {
    padding: 1px 10px;
    font-size: 11px;
    font-size: clamp(1px, 2.4vw, 14px);
  }
  .caseListItem .caseItemText {
    display: flex;
    flex-direction: column;
    height: calc(100% - 235px);
    padding: 18px 25px;
  }
  .caseListItem .caseItemText .caseItemTitle {
    font-size: 18px;
  }
  .caseListItem .caseItemText .caseItemInfo {
    margin-top: 13px;
  }
  .caseListItem .caseItemText .caseItemInfo + .btnWrap {
    margin-top: 20px;
  }
  .caseListItem .caseItemText .caseItemInfo > div + div {
    margin-top: 8px;
  }
  .caseListItem .caseItemText .caseItemInfo > div > dt {
    width: 75px;
    padding: 3px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1;
  }
  .caseListItem .caseItemText .caseItemInfo > div > dd {
    padding-left: 90px;
    font-size: 14px;
    line-height: 1.4;
  }
  .cadList > li {
    width: 100%;
  }
  .cadList > li:nth-child(n+2) {
    margin-top: 60px;
  }
  .cadList .cadListMain .cadListMainThumb {
    width: 100%;
  }
  .cadList .cadListMain .cadListMainThumb img {
    width: 100% !important;
  }
  .cadList .cadListMain .cadListMainNote {
    padding: 30px 20px;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink {
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li {
    width: 48%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li:nth-child(odd) {
    margin-right: 4%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li:nth-child(n+3) {
    margin-top: 4%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a {
    padding: 4px 20px 4px 20px;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a > div:before {
    width: 9px;
    height: 9px;
    right: 10px;
  }
  .cadList .cadListSummary {
    padding: 18px 25px;
  }
  .cadList .cadListSummary .cadListTitle {
    font-size: 18px;
  }
  .cadList .cadListSummary .cadListInfo {
    margin-top: 13px;
  }
  .cadList .cadListSummary .cadListInfo + .btnWrap {
    margin-top: 20px;
  }
  .cadList .cadListSummary .cadListInfo > div + div {
    margin-top: 8px;
  }
  .cadList .cadListSummary .cadListInfo > div > dt {
    width: 75px;
    padding: 3px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1;
  }
  .cadList .cadListSummary .cadListInfo > div > dd {
    width: calc(100% - 66px);
    font-size: 14px;
    line-height: 1.4;
  }
  .pageNav {
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 11px 17px -9px rgba(41, 41, 41, 0.0008);
  }
  .pageNav > ul {
    display: flex;
  }
  .pageNav > ul > li {
    margin: 0 20px 0 21px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
  }
  .pageNav > ul > li.isActive:before {
    height: 2px;
  }
  .pageNav > ul > li > a {
    padding: 22px;
  }
  [class^=bnrList] + [class^=bnrList] {
    margin-top: 1.5em;
  }
  [class^=bnrList] > li + li {
    margin-top: 1.5em;
  }
  [class^=bnrList] > li .bnrArea > a {
    display: flex;
    align-items: center;
    height: 126px;
  }
  [class^=bnrList] > li .bnrArea > a > p {
    z-index: 1;
  }
  [class^=bnrList] > li .bnrArea > a:after {
    right: -25px;
  }
  [class^=bnrList] > li .bnrArea > a > p {
    padding-left: 15px;
    padding-right: 120px;
    font-size: 18px;
    line-height: 1.44;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
  }
  [class^=bnrList] > li .bnrNote {
    padding: 6px 15px 30px;
  }
  .boxShadow + [class^=btnList] {
    margin-bottom: 30px !important;
  }
  .section > .btnList,
  .section > .btnList2Col,
  .section > .btnList3Col {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 20px;
    margin: 0 auto;
  }
  .inner .btnList {
    padding: 0;
  }
  *:not(.title) + .innerBody .btnList2Col:first-child,
  * + .btnList2Col {
    margin-top: 30px;
  }
  *:not(.title) + .innerBody .btnList3Col:first-child,
  * + .btnList3Col {
    margin-top: 30px;
  }
  .btnList > li,
  .btnList2Col > li,
  .btnList3Col > li {
    width: 100%;
  }
  .btnList > li + li a,
  .btnList2Col > li + li a,
  .btnList3Col > li + li a {
    border-top: none;
  }
  .btnList > li a,
  .btnList2Col > li a,
  .btnList3Col > li a {
    justify-content: center;
    padding: 18px 40px 18px 12px;
    border-top: 1px solid #b8b8b8;
    border-bottom: 1px solid #b8b8b8;
  }
  .btnList > li a > [class^=icon],
  .btnList2Col > li a > [class^=icon],
  .btnList3Col > li a > [class^=icon] {
    right: 11px;
  }
  .btnList > li a > div:before,
  .btnList2Col > li a > div:before,
  .btnList3Col > li a > div:before {
    width: 9px;
    height: 9px;
    right: -32px;
  }
  .btnList.btnListNote > li a,
  .btnList2Col.btnListNote > li a,
  .btnList3Col.btnListNote > li a {
    border-top: 1px solid #b8b8b8;
  }
  .btnList.btnListNote > li:nth-child(n+2) .btnListContent,
  .btnList2Col.btnListNote > li:nth-child(n+2) .btnListContent,
  .btnList3Col.btnListNote > li:nth-child(n+2) .btnListContent {
    padding-top: 40px;
    margin-bottom: 8px;
  }
  .innerBody + .moreBlock,
  .moreBlock {
    margin-top: 40px;
  }
  .innerBody + .moreBlock + .btnMore > p {
    padding-right: 50px;
  }
  .innerBody + .moreBlock + .btnMore > p:before {
    right: 20px;
  }
  .btnMore {
    margin-top: 15px;
  }
  .btnMore > p {
    padding: 8px 43px 8px 22px;
    font-size: 12px;
  }
  .btnMore > p:before {
    width: 10px;
    height: 12px;
    right: 22px;
  }
  .pageing {
    flex-wrap: wrap;
    padding-top: 40px;
  }
  .pageing > span {
    margin: 0 3px 6px;
  }
  .pageing > span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
  }
  .pageing > span a.next:after {
    width: 7px;
    height: 11px;
    margin-left: 10px;
  }
  .pageing > span a.prev:before {
    width: 7px;
    height: 11px;
    margin-right: 10px;
  }
  .pageing a {
    width: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .pageing .pageCount {
    flex-wrap: wrap;
  }
  .pageing .pageCount > li {
    margin: 0 5px 10px;
  }
  .newsList {
    border-top: 1px solid #f2f2f2;
  }
  .newsList > li {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 15px;
    border-bottom: 1px solid #f2f2f2;
  }
  .newsList > li .newsListDate {
    min-width: 80px;
    font-size: 14px;
  }
  .newsList > li .newsListTag {
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    font-size: 11px;
  }
  .newsList > li .newsListTag span {
    padding: 0 1em;
    margin: 3px 1em 4px 0;
    line-height: 20px;
  }
  .newsList > li .newsListWho {
    width: 100%;
    line-height: 1.6;
  }
  .newsList > li .newsListWho .newsListWhoTitle {
    font-size: 15px;
  }
  .newsList > li .newsListNote {
    width: 100%;
    margin-top: 7px;
  }
  .newsList > li .newsListNote a {
    display: inline-block;
  }
  .mfp-container {
    overflow: hidden;
  }
  .mfp-content {
    width: calc(100% - 40px) !important;
    padding: 50px 0 0;
    overflow: hidden;
    background: #fff;
  }
  .modalContent {
    max-height: 60vh;
    padding: 0 20px 0;
    margin-bottom: 50px;
  }
  .mfp-close {
    font-size: 50px;
  }
  * + .attentionNote {
    margin-top: 30px;
  }
  .attentionNote {
    font-size: 16px;
  }
  .icon_plug {
    width: 30px;
  }
  .boxContent > .boxContentItem {
    padding: 20px 15px;
  }
  .boxContent > .boxContentItem .boxContentHeader {
    margin-bottom: 30px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitleSub {
    font-size: 12px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitle {
    padding-bottom: 8px;
    font-size: 18px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitle:before {
    width: 20px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitleNote {
    margin-top: 8px;
    font-size: 16px;
  }
  .boxContent > .boxContentItemFull {
    padding: 20px 15px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader {
    margin-bottom: 30px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleSub {
    font-size: 12px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle {
    padding-bottom: 8px;
    font-size: 18px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle:before {
    width: 20px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleNote {
    margin-top: 8px;
    font-size: 16px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 660px;
    padding-top: 0;
    background: transparent;
  }
  .mfp-image-holder .mfp-content img.mfp-img {
    padding: 50px 0;
  }
  .mfp-image-holder .mfp-close {
    top: 0;
  }
  .btnListSummary > div + div {
    margin-top: 30px;
  }
  .btnListSummary > div > dt {
    margin-bottom: 6px;
    font-size: 16px;
  }
  * + .aboutList {
    margin-top: 30px;
  }
  .aboutList > div + div {
    margin-top: 20px;
  }
  .aboutList > div + div {
    border-top: 1px solid #34405b;
  }
  .aboutList > div > dt {
    padding: 8px 10px;
    font-size: 16px;
  }
  .aboutList > div > dt > span {
    font-size: 12px;
  }
  .aboutList > div > dd {
    border: 1px solid #34405b;
    padding: 12px 10px;
  }
  [class^=aboutListSmall] {
    margin-top: 40px;
  }
  [class^=aboutListSmall] > div + div {
    margin-top: 20px;
  }
  [class^=aboutListSmall] > div > dt {
    padding: 8px 10px;
    font-size: 16px;
  }
  [class^=aboutListSmall] > div > dd {
    padding: 12px 10px;
  }
  .page_company_ecology .companyEcologyLead:after {
    width: 44.2667%;
    max-width: 300px;
    height: 281px;
    background: url(../images/company/ecology/main_right.jpg) no-repeat left bottom;
    background-size: cover;
    z-index: -1;
  }
  .page_company_ecology .companyEcologyLead .content .companyEcologyLeadCatch {
    display: flex;
    align-items: center;
    height: 281px;
    margin-right: 30.4%;
    margin-left: 5.0667%;
    max-width: 242px;
  }
  .page_company_ecology .companyEcologyLead .content .companyEcologyLeadNote {
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 16px auto 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.6429;
  }
  .page_company_ecology .companyEcologyAnchorLead {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .page_company_ecology .anchorListEcology > li + li {
    margin-top: 20px;
  }
  .page_company_ecology .anchorListEcology > li a img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: inherit;
  }
  .page_company_ecology .anchorListEcology > li a .anchorListEcologyBody {
    padding: 20px 15px 50px;
  }
  .page_company_ecology .anchorListEcology > li a .anchorListEcologyBody:before {
    width: 23px;
    height: 19px;
    bottom: 12px;
    transition: 0.2s bottom;
  }
  .page_company_ecology .ecologyLeadListWrap {
    padding-top: 10%;
    margin-top: 35px;
  }
  .page_company_ecology .ecologyLeadListWrap .ecologyLeadTitle {
    width: 40vw;
    max-width: 200px;
    z-index: 1;
  }
  .page_company_ecology .busductSummary .companyEcologyTitle {
    padding: 0 17px;
  }
  .page_company_ecology .busductSummary .busductSummaryText .busductTitleLead {
    margin-top: 17px;
    font-size: 18px;
    line-height: 1.5556;
  }
  .page_company_ecology .busductSummary .busductSummaryText .busductNote {
    margin-top: 25px;
    margin-bottom: 31px;
    font-size: 13px;
    line-height: 2;
  }
  .page_company_ecology #ecologyBusduct {
    position: relative;
  }
  .page_company_ecology #ecologyBusduct:before {
    content: "";
    display: block;
    width: 90%;
    height: 45vw;
    max-height: 194px;
    margin-bottom: 30px;
    margin-left: auto;
    background: url(../images/company/ecology/busduct_image.png) no-repeat right top;
    background-size: contain;
  }
  .page_company_ecology #ecologyBusduct:after {
    display: block;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyBusduct .inner {
    padding-top: 0;
  }
  .page_company_ecology #ecologyBusduct .inner:nth-of-type(1) {
    padding-bottom: 0;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .companyEcologyTitle {
    padding: 0;
    margin-bottom: 17px;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .busductTitleLead {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.5;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .busductNote {
    line-height: 1.8571;
  }
  .page_company_ecology #ecologyOa {
    padding: 46px 0 0;
  }
  .page_company_ecology #ecologyOa:before {
    content: "";
    display: block;
    width: 90%;
    height: 45vw;
    max-height: 194px;
    margin-bottom: 30px;
    margin-right: auto;
    background: url(../images/company/ecology/oa_image.png) no-repeat left top;
    background-size: contain;
  }
  .page_company_ecology #ecologyOa .inner:nth-of-type(1) {
    padding-top: 0;
  }
  .page_company_ecology #ecologyOa .oaSummary .ecologyPointTitle {
    padding: 0;
    margin-bottom: 12px;
    text-align: center;
  }
  .page_company_ecology #ecologyOa .oaSummary .oaSummaryText .oaNote {
    font-size: 14px;
    line-height: 1.8571;
  }
  .page_company_ecology #ecologyGreen {
    padding-top: 46px;
    position: relative;
  }
  .page_company_ecology #ecologyGreen:before {
    content: "";
    display: block;
    width: 90%;
    height: 45vw;
    max-height: 194px;
    margin-bottom: 30px;
    margin-left: auto;
    background: url(../images/company/ecology/greening_image.png) no-repeat right top;
    background-size: contain;
  }
  .page_company_ecology #ecologyGreen:after {
    display: block;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyGreen .inner {
    padding-top: 0;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .companyEcologyTitle {
    padding: 0;
    margin-bottom: 17px;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .ecologyTitleLead {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 1.72;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .greenNote {
    line-height: 1.8571;
  }
  .page_company_ecology .iconList > li {
    width: 50%;
    padding: 0 12px;
  }
  .page_company_ecology .iconList > li:nth-child(n+3) {
    margin-top: 4%;
  }
  .page_company_point .companyPointLead {
    margin-bottom: 34px;
  }
  .page_company_point .companyPointLead:before {
    width: 44.2667%;
    max-width: 166px;
    height: 281px;
    background: url(../images/company/point/main_sp.jpg) no-repeat right bottom;
    background-size: cover;
    z-index: -1;
  }
  .page_company_point .companyPointLead .content .companyPointLeadCatch {
    display: flex;
    align-items: center;
    height: 281px;
    margin-left: 30.4%;
    margin-right: 5.0667%;
    max-width: 242px;
  }
  .page_company_point .companyPointLead .content .companyPointLeadNote {
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 16px auto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.6429;
  }
  .page_company_point .businessSummary {
    flex-direction: column;
  }
  .page_company_point .businessSummary .companyPointTitle {
    padding: 0 17px;
  }
  .page_company_point .businessSummary .businessSummaryText .companyPointTitleLead {
    margin-top: 17px;
    font-size: 18px;
    line-height: 1.5556;
  }
  .page_company_point .businessSummary .businessSummaryText .companyPointNote {
    margin-top: 25px;
    margin-bottom: 31px;
    font-size: 13px;
    line-height: 2;
  }
  .page_company_point .businessList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .page_company_point .businessList:after {
    width: 40.597%;
    max-width: 136px;
    max-height: 136px;
    height: 0;
    padding-top: 40.597%;
  }
  .page_company_point .businessList > li {
    width: 49.5522%;
  }
  .page_company_point .businessList > li:nth-child(n+3) {
    margin-top: 4px;
  }
  .page_company_point #companyPointProduct {
    position: relative;
  }
  .page_company_point #companyPointProduct:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 35px);
    background: #f6f6f6;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_point #companyPointProduct .inner {
    padding-top: 0;
  }
  .page_company_point #companyPointProduct .inner .productSummary .companyPointTitle {
    padding: 0 17px;
    margin-bottom: 17px;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .page_company_point #companyPointProduct .inner .productSummary .companyPointTitleLead {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.5;
  }
  .page_company_point #companyPointProduct .inner .productSummary .companyPointNote {
    margin-bottom: 37px;
    line-height: 1.8571;
  }
  .page_company_point #companyPointProduct .productList > li + li {
    margin-top: 22px;
  }
  .page_company_point #companyPointProduct .productList > li:not(:first-child) .productListBtn {
    margin-left: 1px;
  }
  .page_company_point .productListBtn li + li {
    margin-top: 1px;
  }
  .page_company_point #companyPointService {
    padding: 46px 20px 60px;
  }
  .page_company_point #companyPointService .serviceSummary .companyPointTitle {
    padding: 0 17px;
    margin-bottom: 12px;
    text-align: center;
  }
  .page_company_point #companyPointService .serviceSummary .serviceSummaryText .companyPointTitleLead {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 1.72;
  }
  .page_company_point #companyPointService .serviceSummary .serviceSummaryText .companyPointNote {
    font-size: 14px;
    line-height: 1.8571;
  }
  .page_company_point #companyPointService .companyMenuHeader {
    margin-top: 33px;
    margin-bottom: 19px;
    text-align: center;
  }
  .page_company_point #companyPointService .companyMenuHeader .companyMenuTitle {
    font-size: 18px;
  }
  .page_company_point #companyPointService .companyMenuHeader .companyMenuTitleSub {
    font-size: 13px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li {
    display: flex;
    width: 100%;
  }
  .page_company_point #companyPointService .companyMenuWrap > li + li {
    margin-top: 20px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 31.4371%;
    padding: 10px 10px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader i {
    width: 25px;
    height: 20px;
    margin-bottom: 6px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader h4 {
    font-size: 14px;
    line-height: 1.28;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody {
    width: 68.5629%;
    padding: 19px 18px 24px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li {
    padding-left: 15px;
    font-size: 13px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li + li {
    margin-top: 12px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li:before {
    width: 5px;
    height: 5px;
    top: 0.6em;
  }
  .page_company_learn .companyLearnLead:before {
    width: 36.8%;
    max-width: 128px;
    height: 281px;
    background: url(../images/company/learn/main_sp.jpg) no-repeat right bottom;
    background-size: cover;
    z-index: -1;
  }
  .page_company_learn .companyLearnLead .content .companyLearnLeadCatch {
    display: flex;
    align-items: flex-start;
    height: 281px;
    padding-top: 25px;
    margin-left: 21.3%;
    margin-right: 5.3333%;
    max-width: 250px;
  }
  .page_company_learn .companyLearnLead .content .companyLearnLeadNote {
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 35px auto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.6429;
  }
  .page_company_learn .section .companyLearnList {
    margin-top: 40px;
  }
  .page_company_learn .section .companyLearnList > li {
    padding: 31px 19px 33px;
  }
  .page_company_learn .section .companyLearnList > li + li {
    margin-top: 20px;
  }
  .page_company_learn .section .companyLearnList > li .companyLearnThumb {
    text-align: center;
  }
  .page_company_learn .section .companyLearnList > li .companyLearnText {
    margin-top: 12px;
    text-align: center;
  }
  .page_company_learn .section .companyLearnList > li .companyLearnText .companyLearnTitle {
    font-size: 21px;
    line-height: 1.619;
    font-feature-settings: "palt";
  }
  .page_company_learn .section .companyLearnList > li .companyLearnText .companyLearnNote {
    margin-top: 8px;
    line-height: 1.8571;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText .companyLearnTitle strong {
    font-size: 31px;
    line-height: 1;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText .companyLearnTitle strong span {
    font-size: 54px;
    font-weight: 400;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnOffice .companyLearnThumb {
    display: block;
    margin: 0 auto;
  }
  .page_company_learn .section .companyLearnEnd {
    padding-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .page_company_learn .section .companyLearnEnd p {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 2.125;
  }
  .pointList > li {
    display: flex;
    flex-direction: column;
  }
  .pointList > li.addContent .addBlock,
  .pointList > li.addContent .addBlockFull {
    order: 2;
    margin-top: 20px;
    padding: 35px 22px 40px;
  }
  .pointList > li + li {
    margin-top: 40px;
  }
  .pointList > li .pointListThumb {
    text-align: center;
    order: 1;
    margin-top: 18px;
  }
  .pointList > li .pointListText {
    order: 0;
  }
  .pointList > li .pointListText .pointListTitle {
    padding-top: 3px;
    padding-left: 16px;
    padding-bottom: 3px;
    font-size: 18px;
    line-height: 1.2;
  }
  .pointList > li .pointListText .pointListTitle span {
    margin-bottom: 3px;
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) {
  .oa .worksDetailInfo {
    background: rgba(236, 236, 244, 0.9) !important;
  }
}
@media screen and (max-width: 980px) {
  .oa .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(236, 236, 244, 0.9);
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) {
  .busduct .worksDetailInfo {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media screen and (max-width: 980px) {
  .busduct .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) {
  .greening .worksDetailInfo {
    background: rgba(235, 244, 236, 0.9) !important;
  }
}
@media screen and (max-width: 980px) {
  .greening .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(235, 244, 236, 0.9);
  }
  .worksDetailInfo .worksDetailInfoThumb img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoThumb img {
    height: auto !important;
    object-fit: none;
  }
}
@media screen and (max-width: 980px) {
  .worksDetailInfo .worksDetailInfoText {
    padding: 24px 15px 24px;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoTextBg {
    background: none !important;
  }
}
@media screen and (max-width: 980px) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader {
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader:before {
    width: 40px;
    height: 2px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitle {
    font-size: 21px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitleSub {
    margin-top: 6px;
    font-size: 14px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li {
    padding: 3px 10px;
    margin: 5px 5px 0 0;
    font-size: 13px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.bd {
    background: #4c95d0;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.oa {
    background: #5958ac;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.gr {
    background: #96c55c;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData {
    margin-top: 25px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div + div {
    margin-top: 8px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div > dt {
    min-width: 60px;
    padding: 2px 0 3px;
    margin-right: 10px;
    font-size: 13px;
  }
  .inner.boxShadow {
    padding-bottom: 20px;
  }
  .countList.mutual_sp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
  }
  .countList.mutual_sp > li {
    width: calc(50% - 1em);
  }
  .countList.mutual_sp > li:nth-child(1), .countList.mutual_sp > li:nth-child(2) {
    margin-top: 0;
  }
  .countList > li {
    padding-left: 20px;
    position: relative;
  }
  .countList > li:before {
    counter-increment: item;
    content: counter(item) ".";
    position: absolute;
    left: 0;
  }
  .imgInP .img {
    display: block;
    margin: 20px auto;
    padding-bottom: 10px;
  }
  .imgInP .img.left {
    text-align: center;
  }
  .imgInP .img.right {
    text-align: center;
  }
  .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap;
  }
  .imgInP.sp_img_btm .img {
    display: block;
    order: 2;
    margin: 10px auto 0;
    padding-bottom: 0;
  }
  .imgInP.sp_img_btm .text {
    flex-basis: 100%;
    order: 1;
  }
  .contentLead {
    flex-direction: column;
  }
  .contentLead .summaryInfo {
    order: 1;
  }
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    width: 16.4vw;
    max-width: 93px;
    margin-right: -0.5em;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    width: 93px;
  }
}
@media screen and (max-width: 980px) {
  .contentLead .summaryInfo * + .summaryInfoDataList {
    padding: 12px 12px;
    margin-top: 12px;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li {
    width: calc(50% - 3.5px);
    padding: 7px;
    border-radius: 5px;
    line-height: 1.4;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li:nth-child(odd) {
    margin-right: 7px;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li:nth-child(n+3) {
    margin-top: 7px;
  }
  .contentLead .summaryInfo .summaryInfoData {
    padding: 15px 20px;
    margin-top: 20px;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div + div {
    margin-top: 0.5em;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div dd {
    font-size: 12px;
  }
  .contentLead .summaryText {
    order: 0;
    margin-bottom: 30px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextCatch {
    font-size: 16px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitle {
    padding-bottom: 8px;
    margin-top: 10px;
    font-size: 21px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitle:before {
    width: 60px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitleSub {
    margin-top: 25px;
    font-size: 16px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextNote {
    font-size: 13px;
  }
  .productTop.inner.boxShadow {
    padding: 51px 20px 0;
  }
  .productTop.inner.boxShadow .contentLead {
    background-position: center;
  }
  .productTop.inner.boxShadow .contentLead:before {
    width: 100%;
    background: #fff;
    opacity: 0.5;
    top: 0;
    left: 0;
  }
  .productTop.inner.boxShadow .summaryText {
    align-items: flex-end;
    padding: 80px 20px 70px;
    margin-bottom: 0;
    position: relative;
  }
  .productTop.inner.boxShadow .summaryText .summaryTextMain .summaryTextCatch {
    margin-top: 30px;
  }
  .hanging {
    margin-top: 20px;
  }
  * + .infoData {
    margin-top: 16px;
  }
  .infoData .infoDataThumb figure {
    text-align: center;
  }
  .infoData .infoDataText {
    margin-top: 20px;
  }
  .titleStrong {
    font-size: 16px;
  }
  .postContent {
    line-height: 1.8571;
  }
  .postContent h1 {
    margin-top: 9px;
    margin-bottom: 15px;
    font-size: 21px;
    line-height: 1.619;
  }
  .postContent h1 + .thumbArea {
    padding-right: 0;
    padding-left: 0;
  }
  .postContent .postTag {
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    font-size: 11px;
  }
  .postContent .postTag span {
    padding: 0 1em;
    margin: 3px 1em 4px 0;
    line-height: 20px;
  }
  .postContent .thumbArea {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto 14px;
  }
  .postContent .thumbArea p + p {
    margin-top: 2px;
  }
  .postContent *:not(.title) + .innerBody .thumbArea:first-child,
  .postContent * + .thumbArea {
    margin-top: 30px;
  }
  .tourokubtn-ky a {
    padding: 4px 20px 4px 20px;
    /*>span.icon {
        @if $type ==pc {
            position: relative;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                background: url(../images/common/icon_dash_white.svg) no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 9px;
                height: 9px;
                right: 10px;
            }

            @if $type ==pc {
                width: 9px;
                height: 9px;
                right: -10px;
            }
        }
    }*/
  }
  .tourokubtn-ky a > span.txt {
    position: relative;
    z-index: 10;
  }
  main,
  .mfp-content {
    line-height: 1.9286;
  }
  main .taC_sp,
  .mfp-content .taC_sp {
    text-align: center !important;
  }
  main .taL_sp,
  .mfp-content .taL_sp {
    text-align: left !important;
  }
  main .taR_sp,
  .mfp-content .taR_sp {
    text-align: right !important;
  }
  main .pT0_sp,
  .mfp-content .pT0_sp {
    padding-top: 0 !important;
  }
  main .pT10_sp,
  .mfp-content .pT10_sp {
    padding-top: 10px !important;
  }
  main .pT20_sp,
  .mfp-content .pT20_sp {
    padding-top: 20px !important;
  }
  main .pB0_sp,
  .mfp-content .pB0_sp {
    padding-bottom: 0 !important;
  }
  main .pB10_sp,
  .mfp-content .pB10_sp {
    padding-bottom: 10px !important;
  }
  main .pB20_sp,
  .mfp-content .pB20_sp {
    padding-bottom: 20px !important;
  }
  main .pB30_sp,
  .mfp-content .pB30_sp {
    padding-bottom: 30px !important;
  }
  main .pB40_sp,
  .mfp-content .pB40_sp {
    padding-bottom: 40px !important;
  }
  main .pT30_sp,
  .mfp-content .pT30_sp {
    padding-top: 30px !important;
  }
  main .pT40_sp,
  .mfp-content .pT40_sp {
    padding-top: 40px !important;
  }
  main .mB0_sp,
  .mfp-content .mB0_sp {
    margin-bottom: 0 !important;
  }
  main .mB5_sp,
  .mfp-content .mB5_sp {
    margin-bottom: 5px !important;
  }
  main .mB10_sp,
  .mfp-content .mB10_sp {
    margin-bottom: 10px !important;
  }
  main .mB15_sp,
  .mfp-content .mB15_sp {
    margin-bottom: 15px !important;
  }
  main .mB20_sp,
  .mfp-content .mB20_sp {
    margin-bottom: 20px !important;
  }
  main .mB30_sp,
  .mfp-content .mB30_sp {
    margin-bottom: 30px !important;
  }
  main .mB40_sp,
  .mfp-content .mB40_sp {
    margin-bottom: 40px !important;
  }
  main .mB50_sp,
  .mfp-content .mB50_sp {
    margin-bottom: 50px !important;
  }
  main .mB55_sp,
  .mfp-content .mB55_sp {
    margin-bottom: 55px !important;
  }
  main .mB60_sp,
  .mfp-content .mB60_sp {
    margin-bottom: 60px !important;
  }
  main .mB70_sp,
  .mfp-content .mB70_sp {
    margin-bottom: 70px !important;
  }
  main .mT0_sp,
  .mfp-content .mT0_sp {
    margin-top: 0 !important;
  }
  main .mT5_sp,
  .mfp-content .mT5_sp {
    margin-top: 5px !important;
  }
  main .mT10_sp,
  .mfp-content .mT10_sp {
    margin-top: 10px !important;
  }
  main .mT20_sp,
  .mfp-content .mT20_sp {
    margin-top: 20px !important;
  }
  main .mT30_sp,
  .mfp-content .mT30_sp {
    margin-top: 30px !important;
  }
  main .mT40_sp,
  .mfp-content .mT40_sp {
    margin-top: 40px !important;
  }
  main .mT50_sp,
  .mfp-content .mT50_sp {
    margin-top: 50px !important;
  }
  main .mT55_sp,
  .mfp-content .mT55_sp {
    margin-top: 55px !important;
  }
  main .mT60_sp,
  .mfp-content .mT60_sp {
    margin-top: 60px !important;
  }
  main .mT70_sp,
  .mfp-content .mT70_sp {
    margin-top: 70px !important;
  }
  main .mT80_sp,
  .mfp-content .mT80_sp {
    margin-top: 80px !important;
  }
  main .mL0_sp,
  .mfp-content .mL0_sp {
    margin-left: 0px !important;
  }
  main .mL5_sp,
  .mfp-content .mL5_sp {
    margin-left: 5px !important;
  }
  main .mL10_sp,
  .mfp-content .mL10_sp {
    margin-left: 10px !important;
  }
  main .mL20_sp,
  .mfp-content .mL20_sp {
    margin-left: 20px !important;
  }
  main .mL30_sp,
  .mfp-content .mL30_sp {
    margin-left: 30px !important;
  }
  main .mL40_sp,
  .mfp-content .mL40_sp {
    margin-left: 40px !important;
  }
  main .mL50_sp,
  .mfp-content .mL50_sp {
    margin-left: 50px !important;
  }
  main .mR0_sp,
  .mfp-content .mR0_sp {
    margin-right: 0px !important;
  }
  main .mR5_sp,
  .mfp-content .mR5_sp {
    margin-right: 5px !important;
  }
  main .mR10_sp,
  .mfp-content .mR10_sp {
    margin-right: 10px !important;
  }
  main .mR20_sp,
  .mfp-content .mR20_sp {
    margin-right: 20px !important;
  }
  main .mR30_sp,
  .mfp-content .mR30_sp {
    margin-right: 30px !important;
  }
  main .mR40_sp,
  .mfp-content .mR40_sp {
    margin-right: 40px !important;
  }
  main .mR50_sp,
  .mfp-content .mR50_sp {
    margin-right: 50px !important;
  }
  main .size12_sp,
  .mfp-content .size12_sp {
    font-size: 12px !important;
  }
  main .size13_sp,
  .mfp-content .size13_sp {
    font-size: 13px !important;
  }
  main .size14_sp,
  .mfp-content .size14_sp {
    font-size: 14px !important;
  }
  main .size15_sp,
  .mfp-content .size15_sp {
    font-size: 15px !important;
  }
  main .size16_sp,
  .mfp-content .size16_sp {
    font-size: 16px !important;
  }
  main .size17_sp,
  .mfp-content .size17_sp {
    font-size: 17px !important;
  }
  main .size18_sp,
  .mfp-content .size18_sp {
    font-size: 18px !important;
  }
  main .size19_sp,
  .mfp-content .size19_sp {
    font-size: 19px !important;
  }
  main .size20_sp,
  .mfp-content .size20_sp {
    font-size: 20px !important;
  }
  main .size22_sp,
  .mfp-content .size22_sp {
    font-size: 22px !important;
  }
  main .size24_sp,
  .mfp-content .size24_sp {
    font-size: 24px !important;
  }
  main .size28_sp,
  .mfp-content .size28_sp {
    font-size: 28px !important;
  }
  main .w50p_sp,
  .mfp-content .w50p_sp {
    width: 50% !important;
  }
  main .w100_sp,
  .mfp-content .w100_sp {
    width: 100% !important;
  }
  * + .imgFixed {
    margin-top: 30px;
  }
  .imgFixed {
    flex-direction: column;
  }
  .imgFixed > * {
    width: 100%;
  }
  .imgFixed > *.txt {
    order: 1;
    margin-top: 30px;
  }
  .imgFixed > *.img {
    order: 0;
  }
}
@media print, screen and (min-width: 981px) {
  main a:hover,
  .mfp-content a:hover {
    text-decoration: none;
  }
  main *:not(.title) + .innerBody p:first-child,
  main * + p,
  main p + p,
  .mfp-content *:not(.title) + .innerBody p:first-child,
  .mfp-content * + p,
  .mfp-content p + p {
    margin-top: 20px;
  }
  .sectionHeader {
    margin-bottom: 30px;
  }
  .sectionHeader:not(:first-child) {
    margin-top: 117px;
  }
  .linkColor {
    transition: 0.2s color;
  }
  .linkColor:hover {
    color: #c90056;
  }
  .commonLink:hover [class^=icon] {
    transform: translateX(5px);
  }
  .commonLink [class^=icon] {
    margin-left: 12px;
    transition: 0.2s transform;
  }
  .iconDashPink {
    margin-left: 12px;
    width: 9px;
    height: 11px;
  }
  .iconDashNavy {
    margin-left: 12px;
    width: 9px;
    height: 11px;
  }
  .pageHeader .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
  }
  .pageHeader .breadcrumb > li {
    margin-right: 30px;
    font-size: 14px;
  }
  .pageHeader .breadcrumb > li + li {
    padding-left: 42px;
    position: relative;
  }
  .pageHeader .breadcrumb > li + li:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(-45deg);
    box-sizing: border-box;
  }
  .pageHeader .breadcrumb > li a {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    transition: 0.2s opacity;
  }
  .pageHeader .breadcrumb > li a:hover {
    opacity: 0.7;
  }
  .pageHeader.sink {
    padding-bottom: 50px !important;
  }
  .pageHeader.sink + .section {
    transform: translateY(-35px);
  }
  .pageHeader.sink + .section .inner {
    padding-top: 25px;
    background: #fff;
  }
  .tag.bd {
    background: #4c95d0;
  }
  .tag.oa {
    background: #5958ac;
  }
  .tag.gr {
    background: #96c55c;
  }
  main[class^=page_product].page2:not(.page_product_about):not(.page_product_factory) .pageHeader {
    padding-bottom: 50px !important;
  }
  main[class^=page_product].page2:not(.page_product_about):not(.page_product_factory) .pageHeader + .section {
    transform: translateY(-35px);
  }
  main[class^=page_product].page2:not(.page_product_about):not(.page_product_factory) .pageHeader + .section .inner:first-child {
    padding-top: 25px;
    background: #fff;
  }
  main[class^=page_product].page2 .inner,
  main.page_company_factory .inner,
  main.page_company_profile .inner,
  main.page_company_message .inner,
  main[class^=page_support]:not(.page_support_index) .inner,
  main[class^=page_data]:not(.page_data_index) .inner,
  main[class^=page_contact]:not(.page_contact_index) .inner,
  main[class^=page_works]:not(.page_works_index) .inner {
    max-width: 1080px;
    margin: 0 auto;
  }
  main[class^=page_product].page2 .anchorListSmall,
  main.page_company_factory .anchorListSmall,
  main.page_company_profile .anchorListSmall,
  main.page_company_message .anchorListSmall,
  main[class^=page_support]:not(.page_support_index) .anchorListSmall,
  main[class^=page_data]:not(.page_data_index) .anchorListSmall,
  main[class^=page_contact]:not(.page_contact_index) .anchorListSmall,
  main[class^=page_works]:not(.page_works_index) .anchorListSmall {
    max-width: 1080px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .innerSmall .inner {
    max-width: 1080px;
  }
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    min-height: 200px;
    padding: 69px 0 18px;
  }
  /*
  .flowList {
  @if $type == ""{
  counter-reset: number 0;
  }

  > li {
  @if $type == ""{
  position: relative;
  }
  @if $type == sp{
  position: relative;

  &:not(:last-child):not(:nth-child(2n)) {
  figure {
  &:before {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -12px;
  margin: auto;
  }
  }
  }


  }
  @if $type == pc{
  &:not(:last-child):not(:nth-child(4n)) {
  figure {
  &:before {
  border-width: 13px 0 13px 13px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -7.65%;
  margin: auto;
  }
  }
  }
  }

  &:before {
  @if $type == ""{
  counter-increment: number 1;
  content: counter(number,decimal-leading-zero);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $c_base;
  @include fontRo;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  }
  @if $type == sp{
  width: 8.75vw;
  height: 8.75vw;
  max-width: 35px;
  max-height: 35px;
  }
  @if $type == pc{
  width: 3.75vw;
  height: 3.75vw;
  max-width: 45px;
  max-height: 45px;
  }
  }

  figure {
  @if $type == ""{
  display: block;
  position: relative;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }

  &:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;

  @if $type == sp{
  }
  @if $type == pc{

  }

  }
  }
  }

  p {
  @if $type == ""{
  text-align: center;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }
  }
  }
  */
  /*240515 add*/
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    justify-content: flex-start !important;
    height: 200px;
  }
}
@media print, screen and (min-width: 981px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageHeaderInner,
  main.page2 .pageHeader .pageHeaderInner {
    max-width: 1360px;
    padding: 0 40px;
    margin: 0 auto;
  }
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle,
  main.page2 .pageHeader .pageTitle {
    padding-bottom: 11px;
    margin-bottom: 25px;
    font-size: 26px;
  }
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader .pageTitle:before,
  main.page2 .pageHeader .pageTitle:before {
    width: 70px;
  }
  .page_company_index:not(.page2) .pageHeader,
  .page_product_index:not(.page2) .pageHeader,
  .page_document_index:not(.page2) .pageHeader,
  .page_support_index:not(.page2) .pageHeader,
  .page_data_index:not(.page2) .pageHeader,
  .page_contact_index:not(.page2) .pageHeader,
  .page_worries_index:not(.page2) .pageHeader,
  .page_works_index:not(.page2) .pageHeader,
  .page_news_index:not(.page2) .pageHeader,
  .page_column_index:not(.page2) .pageHeader,
  .page_about_index:not(.page2) .pageHeader {
    height: inherit;
    padding-left: 6%;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 300px;
    padding: 38px 0 18px;
    padding-right: 6%;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    justify-content: flex-start !important;
    height: 300px;
  }
}
@media print, screen and (min-width: 981px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle {
    margin-top: auto;
    padding-bottom: 11px;
    font-size: 36px;
    line-height: 1.2;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageTitle:before {
    bottom: -11px;
    width: 70px;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderNote {
    margin-top: 31px;
  }
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
    padding-left: 45px;
  }
  .page_works_index:not(.page2) .pageHeader {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .page_works_index:not(.page2) .pageHeader:before {
    width: 48.54vw;
    top: 0;
    right: 0;
    bottom: -1px;
    left: 0;
    transform: scaleY(2.9) perspective(6.6em) rotateX(10deg);
    transform-origin: bottom left;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner {
    max-width: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader {
    padding-top: 8.37vw !important;
    padding-bottom: 18px !important;
    width: 28.1522vw;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderWorksHeaderCase {
    font-size: 16px;
    line-height: 1.46;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageTitle {
    margin-top: 15px;
    text-align: left !important;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageTitle:before {
    right: inherit !important;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader .pageHeaderNote {
    text-align: left;
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 2;
  }
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
    padding-left: 0 !important;
  }
  .oa.page_works_index:not(.page2) .pageHeader {
    overflow: hidden;
  }
  .oa.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(244, 244, 255, 0.9);
  }
  .busduct.page_works_index:not(.page2) .pageHeader {
    overflow: hidden;
  }
  .busduct.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(226, 234, 241, 0.9);
  }
  .greening.page_works_index:not(.page2) .pageHeader {
    overflow: hidden;
  }
  .greening.page_works_index:not(.page2) .pageHeader:before {
    background: rgba(235, 244, 236, 0.9);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner {
    display: flex;
    flex-direction: row;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksHeader {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-height: 680px;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap {
    background: url(../images/works_greening/map.png) no-repeat center;
    background-size: 80%;
    width: 59.46vw;
    max-width: 820px;
    margin: 0 auto;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .pageHeaderWorksMapTitle {
    color: #000;
    font-size: 24px;
    transition: 0.2s transform;
    transform: translateY(11.38vh);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .pageHeaderWorksMapNote {
    display: inline-block;
    margin-top: 4px;
    color: #000;
    transition: 0.2s transform;
    transform: translateY(11.38vh);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li {
    transition: 0.2s all;
    width: 18.29%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnHokkaido {
    top: 28.5%;
    right: 4.9%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTohoku {
    top: 44.5%;
    right: 15.17%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKanto {
    top: 62.16%;
    right: 20.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTokyo {
    top: 75.16%;
    right: 19.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKinki {
    bottom: 15.66%;
    left: 32.44%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKyushu {
    bottom: 5.82%;
    left: 4.88%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChugoku {
    top: 51.67%;
    left: 17.43%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChubu {
    top: 37.66%;
    left: 35.73%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList .btnWrap {
    margin-top: 0;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList a {
    padding: 5%;
    font-size: clamp(11px, 1.33vw, 16px);
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList a i {
    margin-right: 10px;
    vertical-align: unset;
  }
  .worksList > li + li {
    margin-top: 6px;
  }
  .worksList > li > a {
    padding: 20px 50px 20px 20px;
    transition: 0.2s background;
  }
  .worksList > li > a:hover {
    background: #f7e4ea;
  }
  .worksList > li > a:hover:before {
    opacity: 0;
  }
  .worksList > li > a:hover:after {
    opacity: 1;
  }
  .worksList > li > a:before, .worksList > li > a:after {
    width: 9px;
    height: 11px;
    right: 24px;
  }
  .worksList > li > a .worksListTag > li {
    padding: 1px 8px;
    margin-top: 8px;
    margin-right: 8px;
    font-size: 12px;
  }
  .thumbArea {
    max-width: 600px;
    margin: 0 auto 40px;
  }
  .thumbArea p + p {
    margin-top: 5px;
  }
  *:not(.title) + .innerBody .thumbArea:first-child,
  * + .thumbArea {
    margin-top: 40px;
  }
  .leadBlock {
    padding: 56px 0;
    position: relative;
  }
  .leadBlock:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% + 110px);
    background: #e2e2e2;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .leadBlock .leadCatch {
    padding-top: 9px;
    margin-bottom: 17px;
    font-size: 24px;
    line-height: 1.4;
  }
  .leadBlock .leadNote {
    font-size: 16px;
    line-height: 2.125;
  }
  .leadBlockSmall {
    padding: 50px 0 0;
    position: relative;
  }
  .leadBlockSmall .leadCatch {
    padding-top: 9px;
    margin-bottom: 17px;
    font-size: 22px;
    line-height: 1.4;
  }
  .leadBlockSmall .leadNote {
    font-size: 16px;
    line-height: 2.125;
    margin-top: 30px;
  }
  .leadBlockSmall .leadNote + * {
    margin-top: 60px;
  }
  .leadBlockSmall .inner {
    padding-right: 40px;
    padding-left: 40px;
  }
  .section .inner {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section + .section:not([data-background]) .inner {
    padding-top: 25px;
  }
  .section .contentInner {
    max-width: 1080px;
    margin: 0 auto;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 80px;
    padding-bottom: 92px;
  }
  *:not(.title) + .innerBody .sectionHeader:first-child,
  * + .sectionHeader {
    margin-top: 60px;
  }
  .sectionHeaderBlock {
    display: flex;
    justify-content: space-between;
  }
  .sectionHeaderBlock:before {
    width: 75%;
    height: 76.92%;
  }
  .sectionHeaderBlock .sectionHeader {
    padding-top: 8.5%;
    margin: 0 auto 0;
  }
  .sectionHeaderBlock .sectionHeader .sectionTitle {
    line-height: 1.53;
  }
  .sectionHeaderBlock .sectionHeaderThumb {
    margin-top: 8.75%;
    width: 62.7%;
  }
  .sectionHeaderBlock .sectionHeaderLink {
    width: 37.3%;
    position: absolute;
    top: 83.76%;
    left: 0;
  }
  .sectionHeaderBlock .sectionHeaderLink .commonBtn.btnM {
    min-width: 200px;
  }
  h2.title + h3.title,
  .titleBlock + h3.title {
    margin-top: 0;
  }
  * + h3.title {
    margin-top: 60px;
  }
  h3.title {
    padding: 0 20px;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.4;
  }
  h3.title:before {
    width: 5px;
  }
  h3.title .titleSub {
    font-size: 18px;
    margin-left: 29px;
  }
  * + h4.title {
    margin-top: 46px;
  }
  h4.title {
    padding: 0;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.4;
  }
  * + h5.title {
    margin-top: 16px;
  }
  h5.title {
    padding: 0 20px 13px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.4;
  }
  .postContent * + h2 {
    margin-top: 73px;
  }
  .postContent h2 {
    padding: 0 20px 13px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
  }
  .titleLine {
    margin-top: 44px;
    padding-left: 50px;
    margin-bottom: 16px;
    font-size: 22px;
  }
  .titleLine:before {
    width: 30px;
    top: 15px;
  }
  * + .titleCenter {
    margin-top: 67px;
  }
  .titleCenter {
    margin-bottom: 30px;
    font-size: 24px;
  }
  * + .titleBold {
    margin-top: 44px;
  }
  .titleBold {
    margin-bottom: 16px;
    font-size: 24px;
  }
  h2.title,
  .titleBlock {
    padding: 15px 0;
    margin-bottom: 30px;
    font-size: 30px;
  }
  h2.title:before,
  .titleBlock:before {
    height: 3px;
    width: 70px;
  }
  h2.title .iconArea,
  .titleBlock .iconArea {
    margin-left: 20px;
    margin-top: -40px;
  }
  h2.title .iconArea [class^=icon],
  .titleBlock .iconArea [class^=icon] {
    width: 80px;
    height: 80px;
  }
  * + h2.title,
  * + .titleBlock {
    margin-top: 50px;
  }
  .sectionHeader + .contentsList {
    margin-top: 42px;
  }
  .contentsList + h3.title {
    margin-top: 137px;
  }
  p + .contentsList,
  p + .contentsList3 {
    margin-top: 40px;
  }
  .contentsList3 > li:nth-child(3n) .btnRowList {
    margin-right: 0;
  }
  .contentsList3 > li .contentsListText .contentsListNote {
    padding-left: 0 !important;
  }
  .contentsList,
  .contentsList3 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .contentsList > li > a:hover .contentsListThumb img,
  .contentsList3 > li > a:hover .contentsListThumb img {
    transform: scale(1.1);
  }
  .contentsList > li .contentsListThumb,
  .contentsList3 > li .contentsListThumb {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 30vh;
    max-height: 270px;
  }
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    width: 100%;
    object-fit: cover;
    height: 30vh;
    max-height: 270px;
    object-position: center;
    transition: 0.2s transform;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}
@media print, screen and (min-width: 981px) {
  .contentsList > li .contentsListText,
  .contentsList3 > li .contentsListText {
    margin-top: 11px;
  }
  .contentsList > li .contentsListText .contentsListNote,
  .contentsList3 > li .contentsListText .contentsListNote {
    padding-left: 38px;
    padding-left: 28px;
    font-size: 18px;
  }
  .contentsList > li {
    width: 48.75%;
  }
  .contentsList > li:nth-child(odd) {
    margin-right: 2.5%;
  }
  .contentsList > li:nth-child(n+3) {
    margin-top: 60px;
  }
  .contentsList3 > li {
    width: 31.66%;
  }
  .contentsList3 > li:not(:nth-child(3n)) {
    margin-right: 2.5%;
  }
  .contentsList3 > li:nth-child(n+4) {
    margin-top: 30px;
  }
  .boxList,
  .boxList2 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em;
    margin-bottom: 24px;
  }
  .boxList > li,
  .boxList2 > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .boxList > li .boxListBody,
  .boxList2 > li .boxListBody {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    padding: 20px 0 0;
  }
  .boxList > li .boxListTitle,
  .boxList2 > li .boxListTitle {
    padding: 10px 5.21% 5px;
  }
  .boxList > li .boxListTitle span,
  .boxList2 > li .boxListTitle span {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .boxList > li .boxListTitle:before,
  .boxList2 > li .boxListTitle:before {
    width: 2px;
  }
  .boxList > li {
    width: 31.6%;
  }
  .boxList > li:not(:nth-child(3n)) {
    margin-right: 2.51%;
  }
  .boxList > li:nth-of-type(3n) {
    margin-right: 0;
  }
  .boxList > li:nth-child(n+4) {
    margin-top: 4.8334%;
  }
  .boxList2 {
    margin-top: 40px;
  }
  .boxList2 > li {
    width: calc(50% - 20px);
  }
  .boxList2 > li:nth-child(odd) {
    margin-right: 10px;
  }
  .boxList2 > li:nth-child(even) {
    margin-left: 10px;
  }
  .boxList2 > li:nth-child(n+3) {
    margin-top: 20px;
  }
  * + .borderList,
  * + .borderList3,
  * + .borderList4,
  * + .borderListGap {
    margin-top: 24px;
  }
  .borderList,
  .borderList3,
  .borderList4 {
    display: flex;
  }
  .borderList > li,
  .borderList3 > li,
  .borderList4 > li {
    flex: 1;
    padding: 23px 16px 23px;
  }
  .borderList > li + li,
  .borderList3 > li + li,
  .borderList4 > li + li {
    border-left: 1px solid #b8b8b8;
  }
  .borderList {
    flex-wrap: wrap;
    position: relative;
  }
  .borderList > li {
    width: 49%;
    flex: inherit;
    border: 1px solid #b8b8b8;
  }
  .borderList > li:not(:nth-child(2n)) {
    margin-right: 2%;
  }
  .borderList > li:nth-child(n+3) {
    margin-top: 2%;
  }
  .borderList3 {
    flex-wrap: wrap;
    position: relative;
  }
  .borderList3 > li {
    width: 33.3%;
    flex: inherit;
  }
  .borderList3 > li:not(:nth-child(3n)) {
    margin-right: 2%;
  }
  .borderList3 > li:nth-child(4n) {
    border-left: none;
  }
  .borderList3 > li:nth-child(4n):before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #b8b8b8;
    position: absolute;
    left: 0;
    transform: translateY(-24px);
  }
  .borderList4 {
    flex-wrap: wrap;
    position: relative;
  }
  .borderList4 > li {
    width: 25%;
    flex: inherit;
  }
  .borderList4 > li:not(:nth-child(4n)) {
    margin-right: 2%;
  }
  .borderList4 > li:nth-child(5n) {
    border-left: none;
  }
  .borderList4 > li:nth-child(5n):before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #b8b8b8;
    position: absolute;
    left: 0;
    transform: translateY(-24px);
  }
  .borderListGap {
    display: flex;
    flex-wrap: wrap;
  }
  .borderListGap > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    padding: 23px 16px 23px;
  }
  .borderListGap > li:not(:nth-child(3n)) {
    margin-right: 2%;
  }
  .borderListGap > li:nth-child(n+4) {
    margin-top: 2%;
  }
  .anchorList {
    max-width: 1278px;
    padding: 0 40px;
    margin: 92px auto 0;
  }
  .anchorList > li {
    width: calc(33.3% - 1px);
    margin-right: 1px;
  }
  .anchorList > li + li {
    margin-left: 0px;
  }
  .anchorList > li:nth-child(n+4) {
    margin-top: 1px;
  }
  .anchorList > li > a {
    padding: 29px 5px 28px;
    font-size: 20px;
    line-height: 1.4;
    position: relative;
    overflow: hidden;
  }
  .anchorList > li > a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background: #13192a;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s width;
  }
  .anchorList > li > a:hover {
    color: #fff;
  }
  .anchorList > li > a:hover:before {
    width: 100%;
  }
  .anchorList > li > a > div {
    position: relative;
    z-index: 1;
  }
  .anchorList > li > a > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .anchorList > li > a > div:before {
    content: "";
    display: block;
    background: url(../images/common/icon_dash_white.svg) no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    width: 10px;
    height: 12px;
    margin-right: 18px;
  }
  .btnRowList {
    margin-right: 1px;
  }
  .btnRowList .btnS > div,
  .btnRowList .btnM > div,
  .btnRowList .btnL > div {
    padding-right: 25px;
    padding-left: 25px;
  }
  ul.anchorListSmall + .section .inner:nth-child(1) {
    padding-top: 55px;
  }
  * + .anchorListSmall {
    padding-top: 16px 0;
  }
  ul.anchorListSmall {
    width: calc(100% - 80px);
    max-width: 1200px;
    padding: 0 0 16px;
    margin: 0 auto;
  }
  ul.anchorListSmall.col3 > li {
    margin: 0 0 0;
    width: 33%;
  }
  ul.anchorListSmall > li {
    margin: 0 0 0;
    width: 24.8%;
  }
  ul.anchorListSmall > li:not(:nth-child(4n)) {
    margin-right: 0.26%;
  }
  ul.anchorListSmall > li:nth-child(n+5) {
    margin-top: 4px;
  }
  ul.anchorListSmall > li > a {
    justify-content: center;
    min-height: 60px;
    padding: 8px 11px 8px 11px;
    font-size: 16px;
    transition: 0.2s color;
    line-height: 1.375;
  }
  ul.anchorListSmall > li > a:hover {
    color: #c90056;
  }
  ul.anchorListSmall > li > a:hover:after {
    background-image: url(../images/common/icon_dash_pink.svg);
  }
  ul.anchorListSmall > li > a:after {
    width: 9px;
    height: 9px;
    margin: auto 0 auto 11px;
    transition: 0.2s opacity;
  }
  * + ul.anchorListSmall {
    margin-top: 40px;
  }
  .leadBlock + ul.anchorListSmall,
  .pageHeader + ul.anchorListSmall {
    margin-top: 97px !important;
  }
  .inner ul.anchorListSmall {
    width: 100%;
  }
  ol.anchorListSmall {
    margin: 0 auto;
  }
  ol.anchorListSmall > li {
    padding-left: 24px;
    margin: 0;
  }
  ol.anchorListSmall > li:before {
    top: 0;
  }
  ol.anchorListSmall > li > a {
    transition: 0.2s color;
  }
  ol.anchorListSmall > li > a:hover {
    color: #0c77ae;
  }
  .productLinkList,
  .productLinkListFull {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .productLinkList > li,
  .productLinkListFull > li {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .productLinkList > li:nth-child(n+3),
  .productLinkListFull > li:nth-child(n+3) {
    margin-top: 4.5%;
  }
  .productLinkList > li > div,
  .productLinkList > li > a,
  .productLinkListFull > li > div,
  .productLinkListFull > li > a {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .productLinkList > li > div .productLinkListText,
  .productLinkList > li > a .productLinkListText,
  .productLinkListFull > li > div .productLinkListText,
  .productLinkListFull > li > a .productLinkListText {
    width: 46.5%;
    padding: 36px 0 26px;
  }
  .productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitle {
    font-size: 18px;
    line-height: 1.4;
  }
  .productLinkList > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li > div .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitleSub {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.4;
  }
  .productLinkList > li > div .productLinkListText .productLinkListNote,
  .productLinkList > li > a .productLinkListText .productLinkListNote,
  .productLinkListFull > li > div .productLinkListText .productLinkListNote,
  .productLinkListFull > li > a .productLinkListText .productLinkListNote {
    margin-top: 6px;
    font-size: 14px;
    line-height: 2;
  }
  .productLinkList > li > a:hover .productLinkListThumb > img,
  .productLinkListFull > li > a:hover .productLinkListThumb > img {
    transform: scale(1.1);
  }
  .productLinkList > li > a .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li > a .productLinkListText .productLinkListHeader .productLinkListTitle {
    padding-right: 15px;
  }
  .productLinkList > li .productLinkListThumb,
  .productLinkListFull > li .productLinkListThumb {
    display: flex;
    align-items: center;
    margin: 15px 0 15px 15px;
    width: 44.28%;
    overflow: hidden;
  }
  .productLinkList > li .productLinkListThumb > img,
  .productLinkListFull > li .productLinkListThumb > img {
    display: block;
    transition: 0.2s transform;
  }
  .productLinkList > li .productLinkListThumb > ul,
  .productLinkListFull > li .productLinkListThumb > ul {
    top: 12px;
    right: 0;
  }
  .productLinkList > li .productLinkListText,
  .productLinkListFull > li .productLinkListText {
    width: 49.3%;
    margin: 0 auto;
  }
  .productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitle,
  .productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitle {
    font-size: 18px;
    line-height: 1.4;
  }
  .productLinkList > li .productLinkListText .productLinkListHeader .productLinkListTitleSub,
  .productLinkListFull > li .productLinkListText .productLinkListHeader .productLinkListTitleSub {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.4;
  }
  .productLinkList > li .productLinkListText .productLinkListNote,
  .productLinkListFull > li .productLinkListText .productLinkListNote {
    margin-top: 18px;
    font-size: 14px;
    line-height: 2;
  }
  .productLinkListFull > li {
    width: 100%;
  }
  .productLinkListFull > li:nth-child(n+2) {
    margin-top: 4.5%;
  }
  .productLinkListFull > li .productLinkListThumb {
    width: 48.7%;
  }
  .lineList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 97px;
  }
  .lineList > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    padding: 0 15px;
  }
  .lineList > li:nth-child(n+6) {
    margin-top: 90px;
  }
  .lineList > li:before, .lineList > li:after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 50px);
    background: #bcbcbc;
    position: absolute;
    top: 0;
  }
  .lineList > li:before {
    display: none;
    left: 0;
  }
  .lineList > li:after {
    right: 0;
  }
  .lineList > li:nth-child(1):before, .lineList > li:nth-child(6n):before {
    display: block;
  }
  .lineList > li .lineListContent {
    max-width: 155px;
    padding: 0;
    margin: 0 auto 25px;
  }
  .lineList > li .lineListContent figure {
    margin-bottom: 14px;
  }
  .lineList > li .btnWrap a {
    display: block;
    max-width: 175px;
    margin: 0 auto;
  }
  .postContent * + ul:not([class]),
  * + ul.commonList,
  * + ol.commonList {
    margin-top: 20px;
  }
  .postContent ul:not([class]).mutual_pc, .postContent ul:not([class]).mutual,
  ul.commonList.mutual_pc,
  ul.commonList.mutual {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .postContent ul:not([class]).mutual_pc > li, .postContent ul:not([class]).mutual > li,
  ul.commonList.mutual_pc > li,
  ul.commonList.mutual > li {
    width: calc(50% - 20px);
  }
  .postContent ul:not([class]).mutual_pc > li:nth-child(odd), .postContent ul:not([class]).mutual > li:nth-child(odd),
  ul.commonList.mutual_pc > li:nth-child(odd),
  ul.commonList.mutual > li:nth-child(odd) {
    margin-right: 10px;
  }
  .postContent ul:not([class]).mutual_pc > li:nth-child(even), .postContent ul:not([class]).mutual > li:nth-child(even),
  ul.commonList.mutual_pc > li:nth-child(even),
  ul.commonList.mutual > li:nth-child(even) {
    margin-left: 10px;
  }
  .postContent ul:not([class]).mutual_pc.mutual_pc, .postContent ul:not([class]).mutual_pc.mutual, .postContent ul:not([class]).mutual.mutual_pc, .postContent ul:not([class]).mutual.mutual,
  ul.commonList.mutual_pc.mutual_pc,
  ul.commonList.mutual_pc.mutual,
  ul.commonList.mutual.mutual_pc,
  ul.commonList.mutual.mutual {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .postContent ul:not([class]).mutual_pc.mutual_pc > li, .postContent ul:not([class]).mutual_pc.mutual > li, .postContent ul:not([class]).mutual.mutual_pc > li, .postContent ul:not([class]).mutual.mutual > li,
  ul.commonList.mutual_pc.mutual_pc > li,
  ul.commonList.mutual_pc.mutual > li,
  ul.commonList.mutual.mutual_pc > li,
  ul.commonList.mutual.mutual > li {
    width: calc(50% - 20px);
  }
  .postContent ul:not([class]).mutual_pc.mutual_pc > li:nth-child(odd), .postContent ul:not([class]).mutual_pc.mutual > li:nth-child(odd), .postContent ul:not([class]).mutual.mutual_pc > li:nth-child(odd), .postContent ul:not([class]).mutual.mutual > li:nth-child(odd),
  ul.commonList.mutual_pc.mutual_pc > li:nth-child(odd),
  ul.commonList.mutual_pc.mutual > li:nth-child(odd),
  ul.commonList.mutual.mutual_pc > li:nth-child(odd),
  ul.commonList.mutual.mutual > li:nth-child(odd) {
    margin-right: 10px;
  }
  .postContent ul:not([class]).mutual_pc.mutual_pc > li:nth-child(even), .postContent ul:not([class]).mutual_pc.mutual > li:nth-child(even), .postContent ul:not([class]).mutual.mutual_pc > li:nth-child(even), .postContent ul:not([class]).mutual.mutual > li:nth-child(even),
  ul.commonList.mutual_pc.mutual_pc > li:nth-child(even),
  ul.commonList.mutual_pc.mutual > li:nth-child(even),
  ul.commonList.mutual.mutual_pc > li:nth-child(even),
  ul.commonList.mutual.mutual > li:nth-child(even) {
    margin-left: 10px;
  }
  .postContent ul:not([class]) > li:before,
  ul.commonList > li:before {
    top: 12px;
    margin: auto;
    left: 0;
  }
  .postContent ul:not([class]) > li:before {
    top: 14px;
  }
  ol.commonList.mutual_pc, ol.commonList.mutual {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ol.commonList.mutual_pc > li, ol.commonList.mutual > li {
    width: calc(50% - 20px);
  }
  ol.commonList.mutual_pc > li:nth-child(odd), ol.commonList.mutual > li:nth-child(odd) {
    margin-right: 10px;
  }
  ol.commonList.mutual_pc > li:nth-child(even), ol.commonList.mutual > li:nth-child(even) {
    margin-left: 10px;
  }
  ol.commonList.mutual_pc > li:nth-of-type(2), ol.commonList.mutual > li:nth-of-type(2) {
    margin-top: 0 !important;
  }
  ol.commonList > li + li {
    margin-top: 7px;
  }
  .iframeWrap {
    padding-bottom: 37.25%;
  }
  .ytTitle {
    margin-top: 22px;
    margin-left: 18px;
  }
  .ytTitle + * {
    margin-top: 34px;
  }
  .ytTitle .iconDashNavy {
    margin-left: 0;
    margin-right: 12px;
  }
  #sectionAdd .sectionHeader {
    margin-bottom: 20px;
  }
  #sectionAdd .inner {
    max-width: 1080px;
  }
  .moreList {
    display: flex;
    flex-wrap: wrap;
  }
  .moreList > li {
    width: 23.5%;
  }
  .moreList > li:not(:nth-child(4n)) {
    margin-right: 2%;
  }
  .moreList > li:nth-child(n+5) {
    margin-top: 2%;
  }
  .moreList > li a {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .moreList > li a:hover .moreListThumb {
    transform: scale(1.1);
  }
  .moreList > li a .moreListThumb {
    width: 100%;
    height: 145px;
    margin: auto;
    transition: 0.3s transform;
  }
  .moreList > li a .moreListThumb img {
    max-width: inherit;
    width: 100%;
    height: 145px;
    object-fit: cover;
    object-position: center;
  }
  .moreList > li a .moreListItemText {
    height: calc(100% - 145px);
    padding: 14px 18px 34px 22px;
    position: relative;
    background: #fff;
  }
  .moreList > li a .moreListItemText .moreListItemNote {
    font-size: 14px;
  }
  .moreList > li a .moreListItemText .moreListItemMore {
    margin-top: auto;
    font-size: 12px;
    right: 18px;
    bottom: 8px;
  }
  *:not(.title) + .innerBody .qaList:first-child,
  *:not(h2.title):not(.titleBlock) + .qaList {
    margin-top: 40px;
  }
  .qaList > dt {
    padding: 35px 94px 35px 94px;
  }
  .qaList > dt .qaQuestion {
    transition: 0.2s color;
  }
  .qaList > dt:hover .qaQuestion {
    color: #c90056;
  }
  .qaList > dt:before {
    width: 37px;
    height: 37px;
    font-size: 20px;
    line-height: 37px;
    left: 26px;
  }
  .qaList > dt .qaQuestion:before, .qaList > dt .qaQuestion:after {
    width: 21px;
    right: -90px;
  }
  .qaList > dd {
    padding: 0 0 0 94px;
    margin-bottom: 28px;
  }
  .qaList > dd:before {
    width: 37px;
    height: 37px;
    font-size: 20px;
    line-height: 37px;
    left: 26px;
  }
  .qaList > dd .qaAnswer {
    padding: 34px 24px;
  }
  .qaList > dd .qaAnswer p,
  .qaList > dd .qaAnswer div {
    line-height: 2.14;
  }
  .checkList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .checkList > li {
    width: 48%;
    padding-left: 30px;
  }
  .checkList > li:nth-child(n+3) {
    margin-top: 8px;
  }
  .checkList > li:before {
    width: 21px;
    height: 18px;
  }
  .scrollTable {
    overflow: auto;
  }
  .scrolltext {
    display: none !important;
  }
  .postContent table:not(.hanging):not(.notEqual) > tbody > tr > th,
  .postContent table:not(.hanging):not(.notEqual) > thead > tr > th,
  .commonTable:not(.notEqual) > tbody > tr > th,
  .commonTable:not(.notEqual) > thead > tr > th {
    width: 14%;
  }
  .postContent table:not(.hanging):not(.notEqual) > tbody > tr > th + th + td,
  .postContent table:not(.hanging):not(.notEqual) > thead > tr > th + th + td,
  .commonTable:not(.notEqual) > tbody > tr > th + th + td,
  .commonTable:not(.notEqual) > thead > tr > th + th + td {
    width: 72%;
  }
  .postContent table:not(.hanging):not(.notEqual) > tbody > tr > th,
  .postContent table:not(.hanging):not(.notEqual) > thead > tr > th,
  .commonTable:not(.notEqual) > tbody > tr > th,
  .commonTable:not(.notEqual) > thead > tr > th {
    width: 14%;
  }
  .postContent table:not(.hanging):not(.notEqual) > tbody > tr > th + th + td,
  .postContent table:not(.hanging):not(.notEqual) > thead > tr > th + th + td,
  .commonTable:not(.notEqual) > tbody > tr > th + th + td,
  .commonTable:not(.notEqual) > thead > tr > th + th + td {
    width: 72%;
  }
  .postContent table:not(.hanging),
  .commonTable {
    margin: 1.5em auto 0;
  }
  .postContent table:not(.hanging).small > tbody > tr > th,
  .postContent table:not(.hanging).small > thead > tr > th,
  .postContent table:not(.hanging).small > tr > th,
  .postContent table:not(.hanging).small > tbody > tr > td,
  .postContent table:not(.hanging).small > thead > tr > td,
  .postContent table:not(.hanging).small > tr > td,
  .commonTable.small > tbody > tr > th,
  .commonTable.small > thead > tr > th,
  .commonTable.small > tr > th,
  .commonTable.small > tbody > tr > td,
  .commonTable.small > thead > tr > td,
  .commonTable.small > tr > td {
    padding: 5px;
  }
  .postContent table:not(.hanging) > tbody > tr > th.w115_pc,
  .postContent table:not(.hanging) > tbody > tr > td.w115_pc,
  .postContent table:not(.hanging) > thead > tr > th.w115_pc,
  .postContent table:not(.hanging) > thead > tr > td.w115_pc,
  .commonTable > tbody > tr > th.w115_pc,
  .commonTable > tbody > tr > td.w115_pc,
  .commonTable > thead > tr > th.w115_pc,
  .commonTable > thead > tr > td.w115_pc {
    width: 115px !important;
  }
  .postContent table:not(.hanging) > tbody > tr > th.w165_pc,
  .postContent table:not(.hanging) > tbody > tr > td.w165_pc,
  .postContent table:not(.hanging) > thead > tr > th.w165_pc,
  .postContent table:not(.hanging) > thead > tr > td.w165_pc,
  .commonTable > tbody > tr > th.w165_pc,
  .commonTable > tbody > tr > td.w165_pc,
  .commonTable > thead > tr > th.w165_pc,
  .commonTable > thead > tr > td.w165_pc {
    width: 165px !important;
  }
  .postContent table:not(.hanging) > tbody > tr > th,
  .postContent table:not(.hanging) > thead > tr > th,
  .commonTable > tbody > tr > th,
  .commonTable > thead > tr > th {
    padding: 13px 15px 12px;
    font-size: 18px;
  }
  .postContent table:not(.hanging) > tbody > tr > td,
  .postContent table:not(.hanging) > thead > tr > td,
  .commonTable > tbody > tr > td,
  .commonTable > thead > tr > td {
    padding: 13px 15px 12px;
  }
  *:not(.title) + .innerBody .borderBox:first-child,
  * + .borderBox {
    margin-top: 30px;
  }
  .borderBox {
    padding: 50px 60px;
  }
  .borderBox.bgLogo {
    background-size: 60% auto;
  }
  .borderBox.small {
    padding: 20px 45px;
  }
  p + .colorBoxSky,
  p + .colorBoxPurple,
  p + .colorBoxPink,
  p + .colorBox {
    margin-top: 40px;
  }
  .colorBoxSky > *:last-child,
  .colorBoxPurple > *:last-child,
  .colorBoxPink > *:last-child,
  .colorBoxPrimary > *:last-child,
  .colorBox > *:last-child {
    margin-bottom: 0;
  }
  .colorBoxSky,
  .colorBoxPurple,
  .colorBoxPink,
  .colorBoxPrimary,
  .colorBox {
    padding: 60px 60px;
  }
  .colorBoxSky .boxTitle,
  .colorBoxPurple .boxTitle,
  .colorBoxPink .boxTitle,
  .colorBoxPrimary .boxTitle,
  .colorBox .boxTitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.4;
  }
  .colorBoxSky.small,
  .colorBoxPurple.small,
  .colorBoxPink.small,
  .colorBoxPrimary.small,
  .colorBox.small {
    padding: 25px 45px;
  }
  .colorBoxSky > p:not(.colorBoxTitle):not(.boxTitle),
  .colorBoxPurple > p:not(.colorBoxTitle):not(.boxTitle),
  .colorBoxPink > p:not(.colorBoxTitle):not(.boxTitle),
  .colorBoxPrimary > p:not(.colorBoxTitle):not(.boxTitle),
  .colorBox > p:not(.colorBoxTitle):not(.boxTitle) {
    font-size: 16px;
  }
  .colorBoxSky .twoCol .txt,
  .colorBoxPurple .twoCol .txt,
  .colorBoxPink .twoCol .txt,
  .colorBoxPrimary .twoCol .txt,
  .colorBox .twoCol .txt {
    width: 46.3277%;
  }
  .colorBoxSky .twoCol .img,
  .colorBoxPurple .twoCol .img,
  .colorBoxPink .twoCol .img,
  .colorBoxPrimary .twoCol .img,
  .colorBox .twoCol .img {
    width: 50.8475%;
  }
  .colorBoxSky .boxTitle,
  .colorBoxPurple .boxTitle,
  .colorBoxPink .boxTitle,
  .colorBoxPrimary .boxTitle,
  .colorBox .boxTitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.4;
  }
  .colorBoxPrimary {
    max-width: 920px;
    padding-top: 60px;
    padding-bottom: 100px;
    margin: 0 auto;
  }
  .colorBoxPrimary + .anchorListSmall {
    margin-top: 0;
    max-width: 1000px;
  }
  .colorBoxTitle {
    margin-top: 18px;
    margin-bottom: 36px;
    font-size: 30px;
    text-align: center;
  }
  *:not(.title) + .innerBody .colorBox:first-child,
  * + .colorBox,
  *:not(.title) + .innerBody .colorBoxPink:first-child,
  * + .colorBoxPink,
  *:not(.title) + .innerBody .colorBoxPurple:first-child,
  * + .colorBoxPurple {
    margin-top: 40px;
  }
  .imgLine2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 892px;
    margin: 60px auto;
  }
  .imgLine2 > li {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
  .imgLine2 > li:nth-child(n+3) {
    margin-top: 20px;
  }
  .imgLine2.small li {
    width: calc(33% - 8px);
  }
  .imgLine2 .cap {
    margin-top: 10px;
    text-align: left;
  }
  .imgLine2Full {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto 0;
  }
  .imgLine2Full > li {
    width: calc(50% - 18px);
  }
  .imgLine2Full > li:nth-child(n+3) {
    margin-top: 60px;
  }
  .imgLine2Full.small li {
    width: calc(33% - 8px);
  }
  .imgLine2Full .cap {
    margin-top: 10px;
    text-align: left;
  }
  .imgLine1Arrow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
  }
  .imgLine1Arrow:not(.imgArrow) > li:not(:last-child):not(:nth-child(2n)):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: #34405b transparent transparent transparent;
    bottom: -24px;
    right: 0;
    left: 0;
    margin: auto;
  }
  .imgLine1Arrow.imgArrow > li:not(:nth-child(1)) figure {
    display: block;
    position: relative;
  }
  .imgLine1Arrow.imgArrow > li:not(:nth-child(1)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: #34405b transparent transparent transparent;
    bottom: -24px;
    right: 0;
    left: 0;
    margin: auto;
  }
  .imgLine1Arrow > li {
    width: 100%;
    position: relative;
  }
  .imgLine1Arrow > li:nth-child(n+2) {
    margin-top: 36px;
  }
  .imgLine2Arrow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
  }
  .imgLine2Arrow:not(.imgArrow) > li:not(:last-child):not(:nth-child(2n)):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -4.9%;
    margin: auto;
  }
  .imgLine2Arrow.imgArrow > li:not(:nth-child(2n)) figure {
    display: block;
    position: relative;
  }
  .imgLine2Arrow.imgArrow > li:not(:nth-child(2n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 21px 0 21px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -4.9%;
    margin: auto;
  }
  .imgLine2Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(2n)) figure {
    display: block;
    position: relative;
  }
  .imgLine2Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(2n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -4.9%;
    margin: auto;
  }
  .imgLine2Arrow > li {
    width: calc(50% - 18px);
    position: relative;
  }
  .imgLine2Arrow > li:nth-child(n+3) {
    margin-top: 36px;
  }
  *:not(.title) + .innerBody .imgLine2Full:first-child,
  * + .imgLine2Full {
    margin-top: 40px;
  }
  [class^=imgLine3] {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em;
    margin-bottom: 24px;
  }
  [class^=imgLine3] p {
    margin-top: 23px;
  }
  [class^=imgLine3] > li {
    width: 31.6%;
  }
  [class^=imgLine3] > li:not(:nth-child(3n)) {
    margin-right: 2.51%;
  }
  [class^=imgLine3] > li:nth-of-type(3n) {
    margin-right: 0;
  }
  [class^=imgLine3] > li:nth-child(n+4) {
    margin-top: 2.4167%;
  }
  .imgLine3Arrow > li {
    position: relative;
  }
  .imgLine3Arrow:not(.imgArrow) > li:not(:last-child):not(:nth-child(3n)):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -5.65%;
    margin: auto;
  }
  .imgLine3Arrow.imgArrow > li:not(:nth-child(3n)) figure {
    display: block;
    position: relative;
  }
  .imgLine3Arrow.imgArrow > li:not(:nth-child(3n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 21px 0 21px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -5.65%;
    margin: auto;
  }
  .imgLine3Arrow.imgArrow.imgArrowEquilateral.imgArrow > li:not(:nth-child(3n)) figure {
    display: block;
    position: relative;
  }
  .imgLine3Arrow.imgArrow.imgArrowEquilateral.imgArrow > li:not(:nth-child(3n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -5.65%;
    margin: auto;
  }
  [class^=imgLine4] {
    display: flex;
    flex-wrap: wrap;
  }
  [class^=imgLine4] li {
    width: 23%;
  }
  [class^=imgLine4] li:not(:nth-child(4n)) {
    margin-right: 2.5%;
  }
  [class^=imgLine4] li:nth-of-type(n + 5) {
    margin-top: 30px;
  }
  .imgLine4Arrow:not(.imgArrow) > li:not(:last-child):not(:nth-child(4n)):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -7.65%;
    margin: auto;
  }
  .imgLine4Arrow.imgArrow > li:not(:nth-child(4n)) figure {
    display: block;
    position: relative;
  }
  .imgLine4Arrow.imgArrow > li:not(:nth-child(4n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 21px 0 21px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -7.65%;
    margin: auto;
  }
  .imgLine4Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(4n)) figure {
    display: block;
    position: relative;
  }
  .imgLine4Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(4n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -7.65%;
    margin: auto;
  }
  .imgLine4Arrow > li {
    position: relative;
  }
  .imgLine5Arrow:not(.imgArrow) > li:not(:last-child):not(:nth-child(5n)):before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -10.65%;
    margin: auto;
  }
  .imgLine5Arrow.imgArrow > li:not(:nth-child(5n)) figure {
    display: block;
    position: relative;
  }
  .imgLine5Arrow.imgArrow > li:not(:nth-child(5n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 21px 0 21px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -10.65%;
    margin: auto;
  }
  .imgLine5Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(5n)) figure {
    display: block;
    position: relative;
  }
  .imgLine5Arrow.imgArrow.imgArrowEquilateral > li:not(:nth-child(5n)) figure:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent #34405b;
    top: 0;
    bottom: 0;
    right: -10.65%;
    margin: auto;
  }
  .imgLine5Arrow > li {
    position: relative;
  }
  [class^=imgLine5] {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em;
    margin-bottom: 24px;
  }
  [class^=imgLine5] p {
    margin-top: 8px;
    font-size: 14px;
  }
  [class^=imgLine5] > li {
    width: 17.9%;
  }
  [class^=imgLine5] > li:not(:nth-child(5n)) {
    margin-right: 2.51%;
  }
  [class^=imgLine5] > li:nth-of-type(5n) {
    margin-right: 0;
  }
  [class^=imgLine5] > li:nth-child(n+6) {
    margin-top: 2.4167%;
  }
  .cap {
    margin-top: 5px;
    text-align: center;
  }
  .stepList {
    margin-top: 1.5em;
  }
  .stepList > div {
    padding: 32px 30px 32px 110px;
  }
  .stepList > div + div {
    margin-top: 78px;
  }
  .stepList > div + div:before {
    border-width: 22px 21.5px 0 21.5px;
    top: -48px;
  }
  .stepList > div > dt {
    width: 50px;
    height: 50px;
    left: 30px;
  }
  .stepList > div > dt strong {
    font-size: 22px;
  }
  .flowSummary {
    display: flex;
    /*
     .flowList {
     width: 23%;

     > li {
     width: 100%;
    }
    }
     */
  }
  .flowSummary .flowText {
    width: 74.5%;
    margin-left: 2.5%;
  }
  .btnRelated a {
    align-items: center;
    height: 14.16vw;
    max-height: 170px;
    font-size: 18px;
    line-height: 1.3;
    transition: 0.2s background-size;
  }
  .btnRelated a:hover .btnRelatedBg img {
    transform: scale(1.1);
  }
  .btnRelated a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background: rgba(48, 59, 84, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s width;
  }
  .btnRelated a:hover {
    background-size: 110%;
  }
  .btnRelated a .btnRelatedBg {
    width: 100%;
    height: 170px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 0;
  }
  .btnRelated a .btnRelatedBg:after {
    height: 170px;
  }
  .btnRelated a .btnRelatedBg img {
    min-height: 170px;
    transition: 0.3s transform;
  }
  .btnRelated a > span {
    padding-right: 10%;
  }
  .imgLineBody {
    padding: 11% 7% 5%;
  }
  .imgLineBody .imgLineTitle {
    padding: 10px 5.21% 5px;
    font-size: 18px;
  }
  .imgLineBody .imgLineTitle span {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .imgLineBody .imgLineTitle:before {
    width: 2px;
  }
  .photoList .photoListMain {
    height: 25vw;
    max-height: 272px;
  }
  .photoList .photoListMain img {
    height: 25vw;
    max-height: 272px;
  }
  .photoList .photoListSelect {
    width: 100%;
    margin-top: 20px;
  }
  .photoList .photoListSelect > li {
    width: 23.76%;
    cursor: pointer;
    transition: 0.2s opacity;
  }
  .photoList .photoListSelect > li:hover {
    opacity: 0.7;
  }
  .photoList .photoListSelect > li:not(:nth-child(4n)) {
    margin-right: 1.65%;
  }
  .photoList .photoListSelect > li:nth-child(n+5) {
    margin-top: 1.65%;
  }
  .photoList .photoListSelect > li:before {
    border: 2px solid #c90056;
  }
  .photoList .photoListSelect > li img {
    width: 100%;
    height: 88px;
  }
  .modalBtn:before {
    width: 21px;
    height: 21px;
    margin-right: 5px;
    transform: translateY(3px);
  }
  .tabWrap {
    margin-top: 60px;
  }
  .tabMenu ul > li {
    padding: 15px 10px 23px;
    font-size: 18px;
  }
  .tabMenu ul > li:hover {
    color: #c90056;
  }
  .tabContent {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .speakerClass {
    line-height: 2;
  }
  .speakerClass > div {
    margin-top: 20px;
  }
  .innerBody + .twoCol {
    margin-top: 20px;
  }
  .twoCol:not(:last-child) {
    margin-bottom: 60px;
  }
  .twoCol {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  .twoCol .txt {
    flex: 1;
    width: 46.3277%;
  }
  .twoCol .txt > *:nth-child(1) {
    margin-top: 0;
  }
  .twoCol .txt + .img {
    margin-left: 40px;
  }
  .twoCol .img {
    width: 50.8475%;
    max-width: 480px;
    text-align: center;
  }
  .twoCol .img .cap {
    display: block;
  }
  .twoCol .img + .txt {
    margin-left: 30px;
  }
  .twoCol .img .ytWrap {
    margin-top: 0;
  }
  .twoCol .img img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .twoCol .img img + img {
    margin-top: 30px;
  }
}
@media print and (-ms-high-contrast: none), print and (-ms-high-contrast: active), screen and (min-width: 981px) and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: active) {
  .twoCol .img {
    height: 100%;
  }
}
@media print, screen and (min-width: 981px) {
  * .iconWrap {
    margin-top: 20px;
  }
  .iconWrap {
    display: flex;
    align-items: center;
  }
  .iconWrap > i {
    flex: none;
    width: 180px;
    margin-right: 20px;
  }
  .iconWrap > i + * {
    margin-top: 0;
  }
  .workHeader {
    min-height: 534px;
    padding-left: 40px;
    padding-right: 40px;
    background-size: cover !important;
    background-position: top center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .workHeader .sectionTitleImg {
    margin-bottom: 73px;
  }
  .workHeader .workHeaderLead {
    width: 100%;
    max-width: 1000px;
    min-height: 534px;
    padding-top: 99px;
    padding-right: 38.9%;
    padding-right: clamp(10px, 36.9%, 390px);
    padding-bottom: 44px;
    margin: 0 auto;
    position: relative;
  }
  .workHeader .workHeaderLead .sectionTitle {
    font-size: 30px;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: -0.04em;
  }
  .workHeader .workHeaderLead .sectionTitleSub {
    margin-top: 43px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }
  .pickupListItem {
    flex-wrap: wrap;
  }
  .pickupListItem .pickupItem {
    width: 31.5789%;
  }
  .pickupListItem .pickupItem:not(:first-child) {
    margin-left: 0;
  }
  .pickupListItem .pickupItem:not(:nth-child(3n)) {
    margin-right: 2.6317%;
  }
  .pickupListItem .pickupItem:nth-child(n+4) {
    margin-top: 2.6317%;
  }
  .pickupListItem .pickupItem > a {
    height: 100%;
    padding-top: 55%;
  }
  .pickupListItem .pickupItem > a .pickupItemThumb img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: none;
  }
  .pickupListItem .pickupItem > a .pickupItemText {
    height: 100%;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag {
    padding-top: 1px;
    font-size: 16px;
    line-height: 1.875;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag:before {
    bottom: 12px;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemNote {
    padding: 20px 30px 40px;
    font-size: 16px;
    line-height: 1.875;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemDate {
    right: 15px;
    bottom: 12px;
  }
  .caseListSlider {
    margin: 0 -17px;
  }
  .caseListSlider .slick-arrow {
    width: 56px;
    height: 56px;
    transition: 0.2s background-position;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
    top: calc(50% - 56px);
    border-radius: 50%;
  }
  .caseListSlider .slick-arrow.slick-prev {
    background: #fff url(../images/common/icon_arrow_pink.svg) no-repeat center;
    background-size: 10px 17px;
    left: -17px;
    transform: scale(-1, 1);
    z-index: 1;
  }
  .caseListSlider .slick-arrow.slick-next {
    background: #fff url(../images/common/icon_arrow_pink.svg) no-repeat center;
    background-size: 10px 17px;
    transform: scale(1, -1);
    right: -17px;
  }
  .caseListSlider .slick-arrow:hover {
    background-position: right 43% center;
  }
  .caseListSlider .caseItem {
    margin: 0 17px;
  }
  .caseListSlider .caseItem:not(.slick-active) {
    opacity: 0;
    pointer-events: none;
  }
  .caseListSlider .caseItem.slick-active {
    opacity: 1;
  }
  .caseListSlider a:hover .caseItemThumb img {
    transform: scale(1.08);
  }
  .caseListSlider .caseItemThumb {
    height: 235px;
  }
  .caseListSlider .caseItemThumb img {
    transition: 0.2s transform;
  }
  .caseListSlider .caseItemThumb > ul > li {
    padding: 1px 0;
    font-size: 11px;
    font-size: clamp(1px, 1vw, 12px);
  }
  .caseListSlider .caseItemText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 235px);
    padding: 18px 25px;
  }
  .caseListSlider .caseItemText .caseItemTitle {
    font-size: 14px;
  }
  .caseListSlider .caseItemText .caseItemInfo {
    margin-top: 13px;
  }
  .caseListSlider .caseItemText .caseItemInfo > div + div {
    margin-top: 8px;
  }
  .caseListSlider .caseItemText .caseItemInfo > div > dt {
    width: 56px;
    padding: 3px;
    margin-right: 10px;
    font-size: 11px;
    line-height: 1;
  }
  .caseListSlider .caseItemText .caseItemInfo > div > dd {
    width: calc(100% - 66px);
    font-size: 12px;
    line-height: 1.4;
  }
  .caseListItem .caseItem {
    width: 31.5%;
  }
  .caseListItem .caseItem:not(:nth-child(3n)) {
    margin-right: 2%;
  }
  .caseListItem .caseItem:nth-child(n+4) {
    margin-top: 45px;
  }
  .caseListItem a:hover .caseItemThumb img {
    transform: scale(1.08);
  }
  .caseListItem .caseItemThumb {
    height: 232px;
  }
  .caseListItem .caseItemThumb img {
    width: 100%;
    transition: 0.2s transform;
  }
  .caseListItem .caseItemThumb > ul > li {
    padding: 1px 0;
    font-size: 11px;
    font-size: clamp(1px, 1vw, 12px);
  }
  .caseListItem .caseItemThumb > ul > li.caseDwg a, .caseListItem .caseItemThumb > ul > li.caseDxf a, .caseListItem .caseItemThumb > ul > li.caseJww a, .caseListItem .caseItemThumb > ul > li.casePdf a {
    height: 100%;
    transition: 0.2s opacity;
  }
  .caseListItem .caseItemThumb > ul > li.caseDwg a:hover, .caseListItem .caseItemThumb > ul > li.caseDxf a:hover, .caseListItem .caseItemThumb > ul > li.caseJww a:hover, .caseListItem .caseItemThumb > ul > li.casePdf a:hover {
    opacity: 0.8;
  }
  .caseListItem .caseItemThumb > ul > li.tag {
    padding: 1px 12px;
    font-size: 11px;
    font-size: clamp(1px, 1vw, 12px);
  }
  .caseListItem .caseItemText {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 30px;
  }
  .caseListItem .caseItemText .caseItemTitle {
    font-size: 20px;
  }
  .caseListItem .caseItemText .caseItemInfo {
    margin-top: 13px;
  }
  .caseListItem .caseItemText .caseItemInfo + .btnWrap {
    margin-top: 20px;
  }
  .caseListItem .caseItemText .caseItemInfo > div + div {
    margin-top: 8px;
  }
  .caseListItem .caseItemText .caseItemInfo > div > dt {
    width: 75px;
    padding: 5px 3px;
    margin-right: 14px;
    font-size: 13px;
    line-height: 1;
  }
  .caseListItem .caseItemText .caseItemInfo > div > dd {
    padding-left: 90px;
    font-size: 16px;
    line-height: 1.4;
  }
  .cadList {
    display: flex;
    flex-wrap: wrap;
  }
  .cadList > li {
    width: 48.125%;
  }
  .cadList > li:not(:nth-child(even)) {
    margin-right: 3.75%;
  }
  .cadList > li:nth-child(n+3) {
    margin-top: 3.75%;
  }
  .cadList .cadListMain {
    display: flex;
  }
  .cadList .cadListMain .cadListMainThumb {
    width: calc(100% - 265px);
  }
  .cadList .cadListMain .cadListMainThumb img {
    object-fit: cover;
    height: 100%;
    max-height: 125px;
  }
  .cadList .cadListMain .cadListMainNote {
    display: flex;
    align-items: center;
    width: 265px;
    padding: 20px 20px;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li {
    width: 48%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li:nth-child(odd) {
    margin-right: 4%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li:nth-child(n+3) {
    margin-top: 4%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a {
    padding: 4px 25px 4px 25px;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s width;
    z-index: 0;
    background: #13192a;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a:hover:before {
    width: 100%;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a > div {
    position: relative;
  }
  .cadList .cadListMain .cadListMainNote .cadListMainLink > li a > div:before {
    width: 9px;
    height: 9px;
    right: -10px;
  }
  .cadList .cadListSummary {
    width: 100%;
    padding: 20px 30px;
  }
  .cadList .cadListSummary .cadListTitle {
    font-size: 20px;
  }
  .cadList .cadListSummary .cadListInfo {
    margin-top: 13px;
  }
  .cadList .cadListSummary .cadListInfo + .btnWrap {
    margin-top: 20px;
  }
  .cadList .cadListSummary .cadListInfo > div + div {
    margin-top: 8px;
  }
  .cadList .cadListSummary .cadListInfo > div > dt {
    width: 75px;
    padding: 5px 3px;
    margin-right: 14px;
    font-size: 13px;
    line-height: 1;
  }
  .cadList .cadListSummary .cadListInfo > div > dd {
    width: calc(100% - 66px);
    font-size: 16px;
    line-height: 1.4;
  }
  .pageNav {
    width: 100%;
    box-shadow: 0 11px 17px -9px rgba(41, 41, 41, 0.0008);
  }
  .pageNav > ul {
    display: flex;
    justify-content: center;
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
    padding-top: 48px;
  }
  .pageNav > ul > li {
    width: calc(14.2857142857% - 10px);
    width: calc(20% - 10px);
    margin: 0 5px;
    cursor: pointer;
  }
  .pageNav > ul > li.isActive:before {
    height: 2px;
  }
  .pageNav > ul > li > a {
    padding: 14px 0;
    transition: 0.2s color;
  }
  .pageNav > ul > li > a:hover {
    color: #c90056;
  }
  .bnrList2 > li {
    width: calc(50% - 15px);
  }
  .bnrList2 > li:nth-child(n+3) {
    margin-top: 30px;
  }
  .bnrList2 > li .bnrArea > a {
    height: 167px;
  }
  .bnrList3 > li {
    width: calc(33.3% - 18px);
  }
  .bnrList3 > li:nth-child(n+4) {
    margin-top: 30px;
  }
  .bnrList3 > li .bnrArea > a {
    height: 167px;
  }
  [class^=bnrList] + [class^=bnrList] {
    margin-top: 30px;
  }
  [class^=bnrList] {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  [class^=bnrList] > li .bnrArea > a {
    display: flex;
    align-items: center;
  }
  [class^=bnrList] > li .bnrArea > a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background: #c90056;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s width;
  }
  [class^=bnrList] > li .bnrArea > a:hover:before {
    width: 100%;
  }
  [class^=bnrList] > li .bnrArea > a:hover > p {
    color: #fff;
    text-shadow: 1px 1px 2px rgb(201, 0, 86);
  }
  [class^=bnrList] > li .bnrArea > a:after {
    right: 0;
  }
  [class^=bnrList] > li .bnrArea > a > p {
    padding-left: 26px;
    font-size: 22px;
    line-height: 1.3636;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    transition: 0.2s color, 0.2s text-shadow;
    position: relative;
    z-index: 10;
  }
  [class^=bnrList] > li .bnrNote {
    padding-top: 21px;
    font-size: 15px;
    text-align: center;
  }
  .boxShadow + .btnList {
    margin-top: 40px;
  }
  .btnList + .inner {
    margin-top: 70px;
  }
  .section > .btnList,
  .section > .btnList2Col,
  .section > .btnList3Col {
    max-width: 1160px;
    padding: 0 40px;
    margin-right: auto;
    margin-left: auto;
  }
  .inner .btnList {
    padding: 0;
  }
  *:not(.title) + .innerBody .btnList2Col:first-child,
  * + .btnList2Col {
    margin-top: 60px;
  }
  *:not(.title) + .innerBody .btnList3Col:first-child,
  * + .btnList3Col {
    margin-top: 30px;
  }
  .btnList,
  .btnList2Col,
  .btnList3Col {
    display: flex;
    flex-wrap: wrap;
  }
  .btnList > li a,
  .btnList2Col > li a,
  .btnList3Col > li a {
    height: 100%;
    width: 100%;
    min-height: 80px;
    padding: 14px 36px 14px 10px;
    border-bottom: 1px solid #b8b8b8;
    font-size: 18px;
    line-height: 1.227;
    transition: 0.2s color;
  }
  .btnList > li a:hover,
  .btnList2Col > li a:hover,
  .btnList3Col > li a:hover {
    color: #c90056;
  }
  .btnList > li a > [class^=icon],
  .btnList2Col > li a > [class^=icon],
  .btnList3Col > li a > [class^=icon] {
    right: 11px;
  }
  .btnList > li a > div:before,
  .btnList2Col > li a > div:before,
  .btnList3Col > li a > div:before {
    width: 9px;
    height: 9px;
    right: -20px;
  }
  .btnList .iconDownload,
  .btnList2Col .iconDownload,
  .btnList3Col .iconDownload {
    width: 34px;
    height: 30px;
  }
  .btnList.btnListNote > li,
  .btnList2Col.btnListNote > li,
  .btnList3Col.btnListNote > li {
    display: flex;
    flex-direction: column;
  }
  .btnList.btnListNote > li a,
  .btnList2Col.btnListNote > li a,
  .btnList3Col.btnListNote > li a {
    border-top: 1px solid #b8b8b8;
  }
  .btnList.btnListNote > li .btnListContent,
  .btnList2Col.btnListNote > li .btnListContent,
  .btnList3Col.btnListNote > li .btnListContent {
    margin-bottom: 8px;
  }
  .btnList {
    justify-content: flex-start;
  }
  .btnList > li {
    width: 22.3%;
  }
  .btnList > li:not(:nth-child(4n)) {
    margin-right: 3.583%;
  }
  .btnList > li:not(:nth-child(n+5)) a {
    border-top: 1px solid #b8b8b8;
  }
  .btnList > li:nth-child(n+5) .btnListContent {
    padding-top: 40px;
  }
  .btnList2Col > li {
    width: 48.125%;
  }
  .btnList2Col > li:nth-child(odd) {
    margin-right: 3.75%;
  }
  .btnList2Col > li:not(:nth-child(n+3)) a {
    border-top: 1px solid #b8b8b8;
  }
  .btnList2Col > li:nth-child(n+3) .btnListContent {
    padding-top: 40px;
  }
  .btnList2Col > li a > div:before {
    right: -21px;
  }
  .btnList3Col {
    display: flex;
    flex-wrap: wrap;
  }
  .btnList3Col > li {
    width: 31.25%;
  }
  .btnList3Col > li:not(:nth-child(n+4)) a {
    border-top: 1px solid #b8b8b8;
  }
  .btnList3Col > li:not(:nth-child(3n)) {
    margin-right: 3.125%;
  }
  .btnList3Col > li:nth-child(n+4) .btnListContent {
    padding-top: 40px;
  }
  .btnList3Col > li a > div:before {
    right: -21px;
  }
  .innerBody + .moreBlock,
  .moreBlock {
    margin-top: 50px;
  }
  .innerBody + .moreBlock + .btnMore > p {
    padding-right: 50px;
  }
  .innerBody + .moreBlock + .btnMore > p:before {
    right: 20px;
  }
  .btnMore {
    margin-top: 33px;
  }
  .btnMore.isOpen p:hover:before {
    transform: translateY(-3px) rotate(-90deg);
  }
  .btnMore > p {
    min-width: 134px;
    padding: 8px 43px 8px 22px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
  }
  .btnMore > p:hover:before {
    transform: translateY(3px) rotate(90deg);
  }
  .btnMore > p:before {
    width: 10px;
    height: 12px;
    right: 22px;
  }
  .pageing {
    align-items: center;
    max-width: 980px;
    width: 95%;
    padding-top: 58px;
  }
  .pageing > span {
    margin: 0 10px 10px;
  }
  .pageing > span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 91px;
    transition: 0.2s color;
  }
  .pageing > span a:hover {
    color: #c90056;
  }
  .pageing > span a.next:after {
    width: 9px;
    height: 13px;
    margin-left: 10px;
  }
  .pageing > span a.prev:before {
    width: 9px;
    height: 15px;
    margin-right: 10px;
  }
  .pageing a {
    width: 44px;
    line-height: 45px;
    font-size: 16px;
  }
  .pageing .pageCount {
    flex-wrap: wrap;
  }
  .pageing .pageCount > li {
    margin: 0 10px 10px;
  }
  .pageing .pageCount > li a {
    transition: 0.2s color;
  }
  .pageing .pageCount > li a:hover {
    color: #c90056;
  }
  .newsList {
    border-top: 1px solid #f2f2f2;
  }
  .newsList > li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 26px 20px;
  }
  .newsList > li .newsListDate {
    width: 108px;
    flex: none;
  }
  .newsList > li .newsListTag {
    flex-direction: column;
    width: 160px;
    margin-right: 30px;
    flex: none;
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #fff;
  }
  .newsList > li .newsListTag span {
    border-top: 1px solid #fff;
  }
  .newsList > li .newsListWho {
    margin-left: 70px;
    width: 310px;
    padding-right: 20px;
    font-size: 13px;
  }
  .newsList > li .newsListWho .newsListWhoTitle {
    font-size: 15px;
  }
  .newsList > li .newsListNote a {
    display: inline-block;
    transition: 0.2s color;
  }
  .newsList > li .newsListNote a:hover {
    color: #c90056;
  }
  .mfp-content {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .modalContent {
    padding: 46px 70px;
    max-height: 80vh;
    background: #fff;
  }
  .mfp-close {
    font-size: 60px;
    right: 60px;
    top: 60px;
  }
  * + .attentionNote {
    margin-top: 30px;
  }
  .attentionNote {
    font-size: 24px;
  }
  .boxContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .boxContent > .boxContentItem {
    width: 48.3%;
    padding: 35px 4%;
  }
  .boxContent > .boxContentItem:nth-child(n+3) {
    margin-top: 42px;
  }
  .boxContent > .boxContentItem .boxContentHeader {
    margin-bottom: 48px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitleSub {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitle {
    padding-bottom: 16px;
    font-size: 24px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitle:before {
    width: 30px;
  }
  .boxContent > .boxContentItem .boxContentHeader .boxContentTitleNote {
    margin-top: 15px;
    font-size: 18px;
  }
  .boxContent > .boxContentItemFull {
    width: 100%;
    padding: 35px 4%;
  }
  .boxContent > .boxContentItemFull:nth-child(n+3) {
    margin-top: 42px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader {
    margin-bottom: 48px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleSub {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle {
    padding-bottom: 16px;
    font-size: 24px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitle:before {
    width: 30px;
  }
  .boxContent > .boxContentItemFull .boxContentHeader .boxContentTitleNote {
    margin-top: 15px;
    font-size: 18px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 1000px;
    padding: 0;
  }
  .mfp-image-holder .mfp-content img.mfp-img {
    padding: 80px 0;
  }
  .mfp-image-holder .mfp-close {
    top: 30px;
  }
  .btnListSummary > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btnListSummary > div + div {
    margin-top: 20px;
  }
  .btnListSummary > div > dt {
    width: calc(100% - 175px);
    padding-right: 15px;
    line-height: 1.4;
  }
  .btnListSummary > div > dd {
    width: 175px;
  }
  .btnListSummary > div > dd a {
    font-size: 14px;
  }
  .btnListSummary > div > dd a div > [class^=icon] {
    transform: none;
  }
  * + .aboutList {
    margin-top: 20px;
  }
  .aboutList {
    border-top: 1px solid #34405b;
    border-bottom: 1px solid #34405b;
    border-right: 1px solid #34405b;
  }
  .aboutList > div {
    display: flex;
    position: relative;
  }
  .aboutList > div + div {
    border-top: 1px solid #34405b;
  }
  .aboutList > div > dt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    padding: 15px 10px;
  }
  .aboutList > div > dt > span {
    font-size: 18px;
  }
  .aboutList > div > dd {
    width: 80%;
    padding: 15px 26px;
  }
  [class^=aboutListSmall] {
    margin-top: 20px;
  }
  [class^=aboutListSmall] > div {
    display: flex;
    position: relative;
  }
  [class^=aboutListSmall] > div + div {
    margin-top: 15px;
  }
  [class^=aboutListSmall] > div > dt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 210px;
    padding: 19px 10px;
    font-size: 18px;
  }
  [class^=aboutListSmall] > div > dd {
    width: calc(100% - 210px);
    padding: 19px 55px;
  }
  .page_company_ecology .companyEcologyLead {
    width: 100%;
    height: 536px;
  }
  .page_company_ecology .companyEcologyLead + .section .inner {
    padding-top: 53px;
  }
  .page_company_ecology .companyEcologyLead:before {
    width: 48.58%;
    height: 536px;
    background: url(../images/company/ecology/main_left.jpg) no-repeat right bottom;
    background-size: cover;
  }
  .page_company_ecology .companyEcologyLead:after {
    width: 51.3586%;
    height: 536px;
    background: url(../images/company/ecology/main_right.jpg) no-repeat left bottom;
    background-size: cover;
    transition: 0.2s right;
  }
  .page_company_ecology .companyEcologyLead .content {
    padding-top: 76px;
    margin: 0 auto;
    position: relative;
    max-width: 500px;
    transform: translateX(-13.9348vw);
    transition: 0.2s transform;
  }
  .page_company_ecology .companyEcologyLead .content .companyEcologyLeadCatch {
    width: 500px;
  }
  .page_company_ecology .companyEcologyLead .content .companyEcologyLeadNote {
    margin-top: 22px;
    font-size: 16px;
    line-height: 2;
  }
  .page_company_ecology .companyEcologyAnchorLead {
    margin-bottom: 23px;
    font-size: 24px;
  }
  .page_company_ecology .anchorListEcology {
    display: flex;
  }
  .page_company_ecology .anchorListEcology > li {
    width: 33.3%;
  }
  .page_company_ecology .anchorListEcology > li a:hover .anchorListEcologyBody:before {
    bottom: 10px;
  }
  .page_company_ecology .anchorListEcology > li a .anchorListEcologyBody {
    padding: 20px 25px 50px;
  }
  .page_company_ecology .anchorListEcology > li a .anchorListEcologyBody:before {
    width: 23px;
    height: 19px;
    bottom: 18px;
    transition: 0.2s bottom;
  }
  .page_company_ecology .ecologyLeadListWrap {
    padding-top: 100px;
    margin-top: 55px;
  }
  .page_company_ecology .busductSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .page_company_ecology .busductSummary .busductSummaryText {
    margin-top: 20px;
  }
  .page_company_ecology .busductSummary .busductSummaryText .busductTitleLead {
    font-size: 24px;
    line-height: 1.5833;
  }
  .page_company_ecology .busductSummary .busductSummaryText .busductNote {
    margin-top: 36px;
    font-size: 14px;
    line-height: 2;
  }
  .page_company_ecology #ecologyBusduct {
    position: relative;
  }
  .page_company_ecology #ecologyBusduct:before {
    content: "";
    display: block;
    width: 100%;
    height: 500px;
    background: #f6f6f6;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyBusduct:after {
    content: "";
    display: block;
    width: 51.087%;
    height: 612px;
    background: url(../images/company/ecology/busduct_image.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyBusduct .inner {
    padding-top: 80px;
  }
  .page_company_ecology #ecologyBusduct .inner:nth-of-type(1) {
    padding-top: 40px;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary {
    width: 51.5%;
    min-height: 490px;
    max-width: 600px;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .companyEcologyTitle {
    margin-right: -12px;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .busductTitleLead {
    font-size: 24px;
    line-height: 1.5833;
  }
  .page_company_ecology #ecologyBusduct .inner .busductSummary .busductNote {
    margin-top: 10px;
    font-size: 20px;
    line-height: 1.9;
  }
  .page_company_ecology #ecologyOa {
    padding-top: 80px;
    padding-bottom: 50px;
    position: relative;
  }
  .page_company_ecology #ecologyOa:before {
    content: "";
    display: block;
    width: 100%;
    height: 500px;
    background: #f6f6f6;
    position: absolute;
    top: 80px;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyOa:after {
    content: "";
    display: block;
    width: 51.087%;
    height: 492px;
    background: url(../images/company/ecology/oa_image.png) no-repeat right bottom;
    background-size: cover;
    position: absolute;
    top: 40px;
    left: 0;
  }
  .page_company_ecology #ecologyOa .oaSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 446px;
    margin-left: 54.0761%;
    margin-bottom: 90px;
  }
  .page_company_ecology #ecologyOa .oaSummary .ecologyPointTitle {
    margin-right: -61px;
  }
  .page_company_ecology #ecologyOa .oaSummary .oaSummaryText {
    max-width: 500px;
    margin: 0;
  }
  .page_company_ecology #ecologyOa .oaSummary .oaSummaryText .oaNote {
    margin-top: 20px;
    font-size: 20px;
    line-height: 1.9;
  }
  .page_company_ecology #ecologyGreen {
    position: relative;
  }
  .page_company_ecology #ecologyGreen:before {
    content: "";
    display: block;
    width: 100%;
    height: 470px;
    background: #f6f6f6;
    position: absolute;
    top: 80px;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyGreen:after {
    content: "";
    display: block;
    width: 51.087%;
    height: 492px;
    background: url(../images/company/ecology/greening_image.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_ecology #ecologyGreen .inner {
    padding-top: 80px;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 51.5%;
    max-width: 600px;
    min-height: 467px;
    margin-bottom: 75px;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .greenSummaryText {
    margin-top: 20px;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .companyEcologyTitle {
    margin-right: -12px;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .ecologyTitleLead {
    font-size: 24px;
    line-height: 1.5833;
  }
  .page_company_ecology #ecologyGreen .inner .greenSummary .greenNote {
    margin-top: 10px;
    font-size: 20px;
    line-height: 1.9;
  }
  .page_company_ecology .iconList {
    max-width: 1000px;
    margin: 0 auto;
    justify-content: center;
  }
  .page_company_ecology .iconList > li {
    width: 33.3%;
    padding: 24px;
  }
  .page_company_point .companyPointLead {
    width: 100%;
    height: 536px;
    background: url(../images/company/point/point_main.jpg) no-repeat center;
    background-size: cover;
  }
  .page_company_point .companyPointLead .content {
    padding-top: 96px;
    margin: 0 auto;
    position: relative;
    max-width: 410px;
    transform: translateX(18.0652vw);
    transition: 0.2s transform;
  }
  .page_company_point .companyPointLead .content .companyPointLeadCatch {
    width: 350px;
  }
  .page_company_point .companyPointLead .content .companyPointLeadNote {
    margin-top: 22px;
    font-size: 16px;
    line-height: 2;
  }
  .page_company_point .anchorList > li.anchorListItemBusiness > a:before {
    background: #181754;
  }
  .page_company_point .anchorList > li.anchorListItemProduct > a:before {
    background: #55931b;
  }
  .page_company_point .anchorList > li.anchorListItemService > a:before {
    background: #003d86;
  }
  .page_company_point .businessSummary {
    align-items: center;
    justify-content: space-between;
  }
  .page_company_point .businessSummary .companyPointTitle {
    order: 1;
    width: 46.5%;
  }
  .page_company_point .businessSummary .businessSummaryText {
    width: 50%;
    margin-top: 50px;
  }
  .page_company_point .businessSummary .businessSummaryText .companyPointTitleLead {
    font-size: 24px;
    line-height: 1.5833;
  }
  .page_company_point .businessSummary .businessSummaryText .companyPointNote {
    margin-top: 36px;
    font-size: 14px;
    line-height: 2;
  }
  .page_company_point .businessList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 48px;
  }
  .page_company_point .businessList:after {
    width: 20.9333%;
    max-width: 250px;
    max-height: 250px;
    height: 0;
    padding-top: 20.9333%;
  }
  .page_company_point .businessList > li {
    width: 49.33%;
  }
  .page_company_point .businessList > li:nth-child(n+3) {
    margin-top: 16px;
  }
  .page_company_point #companyPointProduct {
    position: relative;
  }
  .page_company_point #companyPointProduct:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 177px);
    background: #f6f6f6;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_point #companyPointProduct:after {
    content: "";
    display: block;
    width: 53.913%;
    height: 612px;
    background: url(../images/company/point/product_image.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .page_company_point #companyPointProduct .inner .productSummary {
    width: 46.5%;
    padding-top: 36px;
    min-height: 532px;
  }
  .page_company_point #companyPointProduct .inner .productSummary .companyPointTitleLead {
    font-size: 24px;
    line-height: 1.5833;
  }
  .page_company_point #companyPointProduct .inner .productSummary .companyPointNote {
    margin-top: 36px;
    font-size: 14px;
    line-height: 2;
  }
  .page_company_point #companyPointProduct .productList {
    display: flex;
    margin-top: 63px;
    margin-bottom: 15px;
  }
  .page_company_point #companyPointProduct .productList > li:not(:first-child) .productListBtn {
    margin-left: 1px;
  }
  .page_company_point .productListBtn li + li {
    margin-top: 1px;
  }
  .page_company_point #companyPointService {
    padding-top: 170px;
    padding-bottom: 130px;
    position: relative;
  }
  .page_company_point #companyPointService:before {
    content: "";
    display: block;
    width: 55.8152%;
    height: 604px;
    background: url(../images/company/point/service_image.png) no-repeat right bottom;
    background-size: cover;
    position: absolute;
    top: 110px;
    left: 0;
  }
  .page_company_point #companyPointService .serviceSummary {
    max-width: 567px;
    min-height: 544px;
    margin-left: 54.0761%;
  }
  .page_company_point #companyPointService .serviceSummary .companyPointTitle {
    width: 100%;
  }
  .page_company_point #companyPointService .serviceSummary .serviceSummaryText {
    width: 75.0883%;
    margin: 0 auto;
  }
  .page_company_point #companyPointService .serviceSummary .serviceSummaryText .companyPointTitleLead {
    margin-top: 16px;
    font-size: 24px;
    text-align: left;
    line-height: 1.5833;
  }
  .page_company_point #companyPointService .serviceSummary .serviceSummaryText .companyPointNote {
    margin-top: 38px;
    font-size: 14px;
    line-height: 2;
  }
  .page_company_point #companyPointService .companyMenuHeader {
    margin-top: 74px;
    margin-bottom: 30px;
    text-align: center;
  }
  .page_company_point #companyPointService .companyMenuHeader .companyMenuTitle {
    font-size: 24px;
  }
  .page_company_point #companyPointService .companyMenuHeader .companyMenuTitleSub {
    font-size: 14px;
  }
  .page_company_point #companyPointService .companyMenuWrap {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .page_company_point #companyPointService .companyMenuWrap > li {
    width: 31.6667%;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader {
    padding: 29px 10px 15px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader i {
    width: 50px;
    height: 40px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuHeader h4 {
    margin-top: 11px;
    font-size: 16px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody {
    padding: 30px 22px 52px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li {
    padding-left: 15px;
    font-size: 14px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li + li {
    margin-top: 12px;
  }
  .page_company_point #companyPointService .companyMenuWrap > li .companyMenuBody .companyMenuList > li:before {
    width: 5px;
    height: 5px;
    top: 0.75em;
  }
  .page_company_learn .companyLearnLead {
    display: flex;
    align-items: center;
    width: 100%;
    height: 536px;
  }
  .page_company_learn .companyLearnLead:before {
    width: 22.8261%;
    height: 536px;
    background: url(../images/company/learn/main_left.jpg) no-repeat right bottom;
    background-size: cover;
  }
  .page_company_learn .companyLearnLead:after {
    width: 51.6304%;
    height: 536px;
    background: url(../images/company/learn/main_right.jpg) no-repeat left bottom;
    background-size: cover;
    transition: 0.2s right;
  }
  .page_company_learn .companyLearnLead .content {
    padding-bottom: 45px;
    margin: 0 auto;
    position: relative;
    max-width: 500px;
    width: 37.1739%;
    transform: translateX(-13.5vw);
    transition: 0.2s transform;
  }
  .page_company_learn .companyLearnLead .content .companyLearnLeadNote {
    padding-right: 1.8em;
    padding-left: 1.8em;
    margin-top: 34px;
    font-size: 16px;
    line-height: 2;
    font-weight: bold;
    text-align: center;
  }
  .page_company_learn .section .companyLearnList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 95px;
  }
  .page_company_learn .section .companyLearnList > li {
    width: calc(50% - 8px);
    padding: 49px 7px 60px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear, .page_company_learn .section .companyLearnList > li.companyLearnTechnology, .page_company_learn .section .companyLearnList > li.companyLearnJob, .page_company_learn .section .companyLearnList > li.companyLearnPillar, .page_company_learn .section .companyLearnList > li.companyLearnAchievement, .page_company_learn .section .companyLearnList > li.companyLearnOffice {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnNote, .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnNote, .page_company_learn .section .companyLearnList > li.companyLearnJob .companyLearnNote, .page_company_learn .section .companyLearnList > li.companyLearnPillar .companyLearnNote, .page_company_learn .section .companyLearnList > li.companyLearnAchievement .companyLearnNote, .page_company_learn .section .companyLearnList > li.companyLearnOffice .companyLearnNote {
    text-align: left;
  }
  .page_company_learn .section .companyLearnList > li + li {
    margin-top: 15px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear {
    padding-right: 0;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnThumb {
    width: 54.424%;
    order: 1;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText {
    width: 36.0833%;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
    order: 0;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText .companyLearnTitle {
    margin-bottom: 16px;
    font-size: 36px;
    font-size: clamp(10px, 2.6667vw, 36px);
    line-height: 1.5em;
    text-align: left;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText .companyLearnTitle strong {
    font-size: 3.91vw;
    font-size: clamp(10px, 3.91vw, 47px);
  }
  .page_company_learn .section .companyLearnList > li.companyLearnYear .companyLearnText .companyLearnTitle strong span {
    font-size: 7vw;
    font-size: clamp(10px, 7vw, 84px);
    font-weight: 400;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology {
    padding: 68px 0 0;
    align-items: flex-end;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnThumb {
    width: 54.1667%;
    margin-right: 5%;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnText {
    width: 43.75%;
    padding: 0 0 68px;
    margin: 0 auto;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnText .companyLearnTitle {
    text-align: left;
    font-size: clamp(10px, 2.6667vw, 32px);
    line-height: 3rem;
    font-feature-settings: "palt";
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnText .companyLearnTitle strong {
    font-size: clamp(10px, 3vw, 36px);
  }
  .page_company_learn .section .companyLearnList > li.companyLearnTechnology .companyLearnText .companyLearnNote {
    margin-top: 12px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnJob {
    padding: 41px 0;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnJob .companyLearnThumb {
    width: 47.75%;
    margin: 0 auto;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnJob .companyLearnText {
    width: 34.9167%;
    margin-right: auto;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnJob .companyLearnText .companyLearnTitle {
    font-size: clamp(10px, 2.6667vw, 32px);
    line-height: 3rem;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnJob .companyLearnText .companyLearnNote {
    margin-top: 14px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnPillar .companyLearnThumb {
    order: 1;
    width: 47.75%;
    margin: 0 auto 0 0;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnPillar .companyLearnText {
    order: 0;
    width: 49.9333%;
    padding-left: 7.9167%;
    padding-right: 0;
    text-align: left;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnPillar .companyLearnText .companyLearnTitle {
    font-size: clamp(10px, 2.6667vw, 32px);
    line-height: 3rem;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnPillar .companyLearnText .companyLearnNote {
    margin-top: 14px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnAchievement {
    padding: 7.75% 15px 8.16%;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnAchievement .companyLearnThumb {
    width: 43.08%;
    margin-left: 7.08%;
    margin-right: 8.33%;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnAchievement .companyLearnText {
    padding: 0;
    margin-right: auto;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnAchievement .companyLearnText .companyLearnTitle {
    font-size: 36px;
    text-align: left;
    line-height: 1.4;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnOffice {
    padding: 40px 15px 38px;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnOffice .companyLearnThumb {
    display: block;
    margin: 0 auto;
  }
  .page_company_learn .section .companyLearnList > li .companyLearnText {
    padding: 0 15px;
    margin-top: 2px;
    text-align: center;
    font-size: 16px;
    line-height: 2.125;
  }
  .page_company_learn .section .companyLearnList > li .companyLearnText .noteLine1 {
    margin-top: 18px;
  }
  .pointList.col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .pointList.col > li {
    width: 100%;
  }
  .pointList.col > li.col2 {
    width: 48%;
  }
  .pointList.col > li.col2 .pointListThumb,
  .pointList.col > li.col2 .pointListText {
    width: 100%;
  }
  .pointList > li {
    display: flex;
  }
  .pointList > li.addContent {
    flex-wrap: wrap;
  }
  .pointList > li.addContent * + .addBlock,
  .pointList > li.addContent * + .addBlockFull {
    margin-top: 40px;
    padding: 60px;
  }
  .pointList > li.addContent .addBlock {
    display: flex;
    width: 100%;
  }
  .pointList > li.addContent .addBlockFull {
    width: 100%;
  }
  .pointList > li + li {
    margin-top: 60px;
  }
  .pointList > li .pointListThumb {
    width: 48%;
    margin-right: 4%;
  }
  .pointList > li .pointListText {
    width: 48%;
  }
  .pointList > li .pointListText .pointListTitle {
    padding-top: 5px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.2;
  }
  .pointList > li .pointListText .pointListTitle span {
    margin-bottom: 3px;
    font-size: 14px;
  }
}
@media print and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: none) {
  .oa .worksDetailInfo {
    background: rgba(236, 236, 244, 0.9) !important;
  }
}
@media print, screen and (min-width: 981px) {
  .oa .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(236, 236, 244, 0.9);
  }
}
@media print and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: none) {
  .busduct .worksDetailInfo {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media print, screen and (min-width: 981px) {
  .busduct .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media print and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: none) {
  .greening .worksDetailInfo {
    background: rgba(235, 244, 236, 0.9) !important;
  }
}
@media print, screen and (min-width: 981px) {
  .greening .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(235, 244, 236, 0.9);
  }
  .worksDetailInfo {
    display: flex;
  }
  .worksDetailInfo .worksDetailInfoThumb {
    width: 50%;
  }
  .worksDetailInfo .worksDetailInfoThumb img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}
@media print and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoThumb img {
    height: auto !important;
    object-fit: none;
  }
}
@media print, screen and (min-width: 981px) {
  .worksDetailInfo .worksDetailInfoText {
    display: flex;
    align-items: center;
    width: 50%;
    min-height: 430px;
    padding: 78px 50px 78px;
  }
}
@media print and (-ms-high-contrast: none), screen and (min-width: 981px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoTextBg {
    background: none !important;
  }
}
@media print, screen and (min-width: 981px) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner {
    width: 100%;
    padding-right: 56px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader {
    padding-bottom: 20px;
    margin-bottom: 34px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader:before {
    width: 58px;
    height: 2px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitle {
    font-size: 32px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoHeader .worksDetailHeaderTitleSub {
    margin-top: 6px;
    font-size: 15px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList {
    margin-bottom: 36px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li {
    padding: 5px 20px 6px;
    margin: 7px 7px 0 0;
    font-size: 13px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.bd {
    background: #4c95d0;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.oa {
    background: #5958ac;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.gr {
    background: #96c55c;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div + div {
    margin-top: 8px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div > dt {
    min-width: 70px;
    padding: 4px 0 5px;
    margin-right: 20px;
    font-size: 14px;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoData > div > dd {
    padding: 1px 0;
  }
  .inner.boxShadow {
    padding: 40px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }
  .countList.mutual_pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
  }
  .countList.mutual_pc > li {
    width: calc(50% - 20px);
  }
  .countList.mutual_pc > li:nth-child(1), .countList.mutual_pc > li:nth-child(2) {
    margin-top: 0;
  }
  .countList > li {
    padding-left: 20px;
    position: relative;
  }
  .countList > li:before {
    counter-increment: item;
    content: counter(item) ".";
    position: absolute;
    left: 0;
  }
  .imgInP {
    overflow: hidden;
  }
  .imgInP .img {
    max-width: 480px;
  }
  .imgInP .img img {
    width: 100%;
    height: auto;
  }
  .imgInP .img.left {
    float: left;
    margin-right: 20px;
  }
  .imgInP .img.right {
    float: right;
    margin-left: 20px;
  }
  .contentLead {
    justify-content: space-between;
    max-width: 1120px;
    padding-top: 15px;
    margin: 0 auto;
  }
  .contentLead .summaryInfo {
    width: 47%;
  }
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    max-width: 93px;
    margin-right: -16px;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    width: 93px;
  }
}
@media print, screen and (min-width: 981px) {
  .contentLead .summaryInfo * + .summaryInfoDataList {
    padding: 16px 19px 16px;
    margin-top: 18px;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li {
    width: calc(50% - 3.5px);
    padding: 9px 8px 8px;
    font-size: 16px;
    border-radius: 5px;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li:nth-child(odd) {
    margin-right: 7px;
  }
  .contentLead .summaryInfo .summaryInfoDataList > li:nth-child(n+3) {
    margin-top: 7px;
  }
  .contentLead .summaryInfo .summaryInfoData {
    padding: 20px 7.63%;
    margin-top: 20px;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) {
    font-size: 15px;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div {
    display: flex;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div dt {
    width: 6em;
    padding-right: 0.5em;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div dt:before {
    content: ":";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.4em;
    margin: auto;
  }
  .contentLead .summaryInfo .summaryInfoData dl:not([class]) > div dd {
    width: calc(100% - 5em);
  }
  .contentLead .summaryText {
    display: flex;
    justify-content: space-between;
    width: 48%;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextCatch {
    font-size: 18px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitle {
    padding-bottom: 13px;
    margin-top: 16px;
    font-size: 32px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitle:before {
    width: 60px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextTitleSub {
    margin-top: 50px;
    font-size: 22px;
  }
  .contentLead .summaryText .summaryTextMain .summaryTextNote {
    font-size: 15px;
  }
  .contentLead .summaryText .summaryTextCategory {
    margin-top: 20px;
  }
  .productTop.inner.boxShadow {
    padding: 40px !important;
  }
  .productTop.inner.boxShadow .contentLead {
    min-height: 444px;
    padding-top: 0;
    background-position: center right;
  }
  .productTop.inner.boxShadow .contentLead:before {
    width: 130%;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 97%);
    top: 0;
    left: -60%;
    opacity: 0.9;
  }
  .productTop.inner.boxShadow .summaryText {
    padding: 0 40px 0;
    flex-direction: row;
    align-items: center;
  }
  .productTop.inner.boxShadow .summaryText .summaryTextMain .summaryTextCatch {
    margin-top: 33px;
    line-height: 2.2;
    position: relative;
  }
  .hanging {
    margin-top: 20px;
    font-size: 13px;
  }
  * + .infoData {
    margin-top: 16px;
  }
  .infoData {
    display: flex;
  }
  .infoData .infoDataThumb {
    width: 48%;
    margin-right: 4%;
  }
  .infoData .infoDataText {
    width: 48%;
    font-size: 14px;
  }
  * + .titleStrong {
    margin-top: 14px;
  }
  .titleStrong {
    font-size: 18px;
  }
  .postContent {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 2.25;
  }
  .postContent .postDate {
    font-size: 16px;
    line-height: 1.2;
  }
  .postContent h1 {
    margin-top: 15px;
    margin-bottom: 34px;
    font-size: 28px;
    line-height: 1.4;
  }
  .postContent h1 + .thumbArea {
    max-width: inherit;
    width: 100%;
  }
  .postContent h1 + .thumbArea img {
    max-width: inherit;
    width: 100%;
  }
  .postContent .postTag {
    flex: none;
    font-size: 13px;
    text-align: center;
  }
  .postContent .postTag span {
    padding: 0 1em;
    margin: 3px 1em 4px 0;
  }
  .postContent .postTag + .thumbArea {
    max-width: inherit;
  }
  .postContent .thumbArea {
    max-width: 600px;
    margin: 0 auto 40px;
  }
  .postContent .thumbArea p + p {
    margin-top: 5px;
  }
  .postContent *:not(.title) + .innerBody .thumbArea:first-child,
  .postContent * + .thumbArea {
    margin-top: 40px;
  }
  .tourokubtn-ky a {
    padding: 4px 10px 4px 10px;
    /*>span.icon {
        @if $type ==pc {
            position: relative;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                background: url(../images/common/icon_dash_white.svg) no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 9px;
                height: 9px;
                right: 10px;
            }

            @if $type ==pc {
                width: 9px;
                height: 9px;
                right: -10px;
            }
        }
    }*/
  }
  .tourokubtn-ky a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s width;
    z-index: 0;
    background: #13192a;
  }
  .tourokubtn-ky a:hover:before {
    width: 100%;
  }
  .tourokubtn-ky a > span.txt {
    position: relative;
    z-index: 10;
  }
  main .pT10_pc,
  .mfp-content .pT10_pc {
    padding-top: 10px !important;
  }
  main .pT20_pc,
  .mfp-content .pT20_pc {
    padding-top: 20px !important;
  }
  main .pT30_pc,
  .mfp-content .pT30_pc {
    padding-top: 30px !important;
  }
  main .pT40_pc,
  .mfp-content .pT40_pc {
    padding-top: 40px !important;
  }
  main .pB10_pc,
  .mfp-content .pB10_pc {
    padding-bottom: 10px !important;
  }
  main .pB20_pc,
  .mfp-content .pB20_pc {
    padding-bottom: 20px !important;
  }
  main .pB30_pc,
  .mfp-content .pB30_pc {
    padding-bottom: 30px !important;
  }
  main .pB40_pc,
  .mfp-content .pB40_pc {
    padding-bottom: 40px !important;
  }
  main .mB0_pc,
  .mfp-content .mB0_pc {
    margin-bottom: 0 !important;
  }
  main .mB5_pc,
  .mfp-content .mB5_pc {
    margin-bottom: 5px !important;
  }
  main .mB10_pc,
  .mfp-content .mB10_pc {
    margin-bottom: 10px !important;
  }
  main .mB15_pc,
  .mfp-content .mB15_pc {
    margin-bottom: 15px !important;
  }
  main .mB20_pc,
  .mfp-content .mB20_pc {
    margin-bottom: 20px !important;
  }
  main .mB30_pc,
  .mfp-content .mB30_pc {
    margin-bottom: 30px !important;
  }
  main .mB40_pc,
  .mfp-content .mB40_pc {
    margin-bottom: 40px !important;
  }
  main .mB50_pc,
  .mfp-content .mB50_pc {
    margin-bottom: 50px !important;
  }
  main .mB55_pc,
  .mfp-content .mB55_pc {
    margin-bottom: 55px !important;
  }
  main .mB60_pc,
  .mfp-content .mB60_pc {
    margin-bottom: 60px !important;
  }
  main .mB70_pc,
  .mfp-content .mB70_pc {
    margin-bottom: 70px !important;
  }
  main .mT0_pc,
  .mfp-content .mT0_pc {
    margin-top: 0 !important;
  }
  main .mT5_pc,
  .mfp-content .mT5_pc {
    margin-top: 5px !important;
  }
  main .mT10_pc,
  .mfp-content .mT10_pc {
    margin-top: 10px !important;
  }
  main .mT20_pc,
  .mfp-content .mT20_pc {
    margin-top: 20px !important;
  }
  main .mT30_pc,
  .mfp-content .mT30_pc {
    margin-top: 30px !important;
  }
  main .mT40_pc,
  .mfp-content .mT40_pc {
    margin-top: 40px !important;
  }
  main .mT50_pc,
  .mfp-content .mT50_pc {
    margin-top: 50px !important;
  }
  main .mT55_pc,
  .mfp-content .mT55_pc {
    margin-top: 55px !important;
  }
  main .mT70_pc,
  .mfp-content .mT70_pc {
    margin-top: 70px !important;
  }
  main .mT80_pc,
  .mfp-content .mT80_pc {
    margin-top: 80px !important;
  }
  main .mL0_pc,
  .mfp-content .mL0_pc {
    margin-left: 0px !important;
  }
  main .mL5_pc,
  .mfp-content .mL5_pc {
    margin-left: 5px !important;
  }
  main .mL10_pc,
  .mfp-content .mL10_pc {
    margin-left: 10px !important;
  }
  main .mL20_pc,
  .mfp-content .mL20_pc {
    margin-left: 20px !important;
  }
  main .mL30_pc,
  .mfp-content .mL30_pc {
    margin-left: 30px !important;
  }
  main .mL40_pc,
  .mfp-content .mL40_pc {
    margin-left: 40px !important;
  }
  main .mL50_pc,
  .mfp-content .mL50_pc {
    margin-left: 50px !important;
  }
  main .mL55_pc,
  .mfp-content .mL55_pc {
    margin-left: 55px !important;
  }
  main .mL60_pc,
  .mfp-content .mL60_pc {
    margin-left: 60px !important;
  }
  main .mL70_pc,
  .mfp-content .mL70_pc {
    margin-left: 70px !important;
  }
  main .mR0_pc,
  .mfp-content .mR0_pc {
    margin-right: 0px !important;
  }
  main .mR5_pc,
  .mfp-content .mR5_pc {
    margin-right: 5px !important;
  }
  main .mR10_pc,
  .mfp-content .mR10_pc {
    margin-right: 10px !important;
  }
  main .mR20_pc,
  .mfp-content .mR20_pc {
    margin-right: 20px !important;
  }
  main .mR30_pc,
  .mfp-content .mR30_pc {
    margin-right: 30px !important;
  }
  main .mR40_pc,
  .mfp-content .mR40_pc {
    margin-right: 40px !important;
  }
  main .mR50_pc,
  .mfp-content .mR50_pc {
    margin-right: 50px !important;
  }
  main .mR55_pc,
  .mfp-content .mR55_pc {
    margin-right: 55px !important;
  }
  main .mR60_pc,
  .mfp-content .mR60_pc {
    margin-right: 60px !important;
  }
  main .mR70_pc,
  .mfp-content .mR70_pc {
    margin-right: 70px !important;
  }
  main .size12,
  .mfp-content .size12 {
    font-size: 12px !important;
  }
  main .size13,
  .mfp-content .size13 {
    font-size: 13px !important;
  }
  main .size14,
  .mfp-content .size14 {
    font-size: 14px !important;
  }
  main .size15,
  .mfp-content .size15 {
    font-size: 15px !important;
  }
  main .size16,
  .mfp-content .size16 {
    font-size: 16px !important;
  }
  main .size17,
  .mfp-content .size17 {
    font-size: 17px !important;
  }
  main .size18,
  .mfp-content .size18 {
    font-size: 18px !important;
  }
  main .size19,
  .mfp-content .size19 {
    font-size: 19px !important;
  }
  main .size20,
  .mfp-content .size20 {
    font-size: 20px !important;
  }
  main .size22,
  .mfp-content .size22 {
    font-size: 22px !important;
  }
  main .size24,
  .mfp-content .size24 {
    font-size: 24px !important;
  }
  main .size28,
  .mfp-content .size28 {
    font-size: 28px !important;
  }
  main .hoverAlpha,
  .mfp-content .hoverAlpha {
    transition: 0.2s opacity;
  }
  main .hoverAlpha:hover,
  .mfp-content .hoverAlpha:hover {
    opacity: 0.8;
  }
  main .hoverScale,
  .mfp-content .hoverScale {
    display: block;
    overflow: hidden;
  }
  main .hoverScale > *,
  .mfp-content .hoverScale > * {
    transition: 0.3s transform;
  }
  main .hoverScale:hover > *,
  .mfp-content .hoverScale:hover > * {
    transform: scale(1.1);
  }
  * + .imgFixed {
    margin-top: 30px;
  }
  .imgFixed > *.img {
    flex: none;
  }
  .imgFixed > *:nth-child(odd) {
    margin-right: 55px;
  }
  .imgFixed > *:nth-child(n+3) {
    margin-top: 30px;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .tag.bd {
    background: #4c95d0;
  }
  .tag.oa {
    background: #5958ac;
  }
  .tag.gr {
    background: #96c55c;
  }
  /*
  .flowList {
  @if $type == ""{
  counter-reset: number 0;
  }

  > li {
  @if $type == ""{
  position: relative;
  }
  @if $type == sp{
  position: relative;

  &:not(:last-child):not(:nth-child(2n)) {
  figure {
  &:before {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -12px;
  margin: auto;
  }
  }
  }


  }
  @if $type == pc{
  &:not(:last-child):not(:nth-child(4n)) {
  figure {
  &:before {
  border-width: 13px 0 13px 13px;
  border-color: transparent transparent transparent #e2e2e2;
  top: 0;
  bottom: 0;
  right: -7.65%;
  margin: auto;
  }
  }
  }
  }

  &:before {
  @if $type == ""{
  counter-increment: number 1;
  content: counter(number,decimal-leading-zero);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $c_base;
  @include fontRo;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  }
  @if $type == sp{
  width: 8.75vw;
  height: 8.75vw;
  max-width: 35px;
  max-height: 35px;
  }
  @if $type == pc{
  width: 3.75vw;
  height: 3.75vw;
  max-width: 45px;
  max-height: 45px;
  }
  }

  figure {
  @if $type == ""{
  display: block;
  position: relative;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }

  &:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;

  @if $type == sp{
  }
  @if $type == pc{

  }

  }
  }
  }

  p {
  @if $type == ""{
  text-align: center;
  }
  @if $type == sp{
  }
  @if $type == pc{
  }
  }
  }
  */
  /*240515 add*/
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index) .pageHeader,
  main.page2 .pageHeader {
    justify-content: flex-start !important;
    height: 200px;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner {
    justify-content: flex-start !important;
    height: 300px;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .page_company_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_product_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_document_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_support_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_data_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_contact_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_worries_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_works_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_news_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_column_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb,
  .page_about_index:not(.page2) .pageHeader .pageHeaderInner .breadcrumb {
    padding-left: 45px;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap {
    background-size: 90%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnHokkaido {
    top: 30.5%;
    right: 7.9%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTohoku {
    right: 15.17%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKinki {
    bottom: 17.66%;
    right: 45.29%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKanto {
    top: 60.16%;
    right: 20.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnTokyo {
    top: 70.16%;
    right: 19.78%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnKyushu {
    bottom: 11%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChugoku {
    top: 52.67%;
  }
  .greening.page_works_index:not(.page2) .pageHeader .pageHeaderInner .pageHeaderWorksMap .worksBtnList > li.btnChubu {
    top: 39.66%;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentsList > li .contentsListThumb img,
  .contentsList3 > li .contentsListThumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .scrollTable {
    overflow: auto;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none), screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: active) {
  .twoCol .img {
    height: 100%;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .aboutList > div + div {
    border-top: 1px solid #34405b;
  }
  .page_company_ecology .companyEcologyLead:after {
    right: -10vw;
  }
  .page_company_point .companyPointLead .content {
    transform: translateX(25.0652vw);
  }
  .page_company_point #companyPointProduct .productList > li:not(:first-child) .productListBtn {
    margin-left: 1px;
  }
  .page_company_learn .companyLearnLead .content {
    transform: translateX(-14vw);
  }
  .page_company_learn .companyLearnLead .content .companyLearnLeadNote {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
  .page_company_learn .section .companyLearnList > li.companyLearnOffice .companyLearnThumb {
    display: block;
    margin: 0 auto;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) {
  .oa .worksDetailInfo {
    background: rgba(236, 236, 244, 0.9) !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .oa .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(236, 236, 244, 0.9);
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) {
  .busduct .worksDetailInfo {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .busduct .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(226, 234, 241, 0.9) !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) {
  .greening .worksDetailInfo {
    background: rgba(235, 244, 236, 0.9) !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .greening .worksDetailInfo .worksDetailInfoText:before {
    background: rgba(235, 244, 236, 0.9);
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoThumb img {
    height: auto !important;
    object-fit: none;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoTextBg {
    background: none !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.bd {
    background: #4c95d0;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.oa {
    background: #5958ac;
  }
  .worksDetailInfo .worksDetailInfoText .worksDetailInfoInner .worksDetailInfoList > li.tag.gr {
    background: #96c55c;
  }
  .countList > li {
    padding-left: 20px;
    position: relative;
  }
  .countList > li:before {
    counter-increment: item;
    content: counter(item) ".";
    position: absolute;
    left: 0;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  .contentLead .summaryInfo .summaryInfoThumb ul > li {
    width: 93px;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .tourokubtn-ky a {
    /*>span.icon {
        @if $type ==pc {
            position: relative;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                background: url(../images/common/icon_dash_white.svg) no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 9px;
                height: 9px;
                right: 10px;
            }

            @if $type ==pc {
                width: 9px;
                height: 9px;
                right: -10px;
            }
        }
    }*/
  }
  .tourokubtn-ky a > span.txt {
    position: relative;
    z-index: 10;
  }
  .imgFixed {
    flex-direction: column;
  }
  .imgFixed > * {
    width: 100%;
  }
  .imgFixed > *.txt {
    order: 1;
    margin-top: 30px;
  }
  .imgFixed > *.img {
    order: 0;
  }
  .imgFixed > *:nth-child(odd) {
    margin-right: 0;
  }
  .imgFixed > *:nth-child(n+3) {
    margin-top: 0;
  }
}