@charset "UTF-8";

// IMPPRT INIT

@import "_setting";

$type: "";
@import "_magnific";
@import "_second";
@import "_second_common";


// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_second";
 @import "_second_common";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_second";
 @import "_second_common";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
	$type: tb;
	@import "_second";
 @import "_second_common";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
