main,
.mfp-content {
 @if $type == ""{
  word-break: break-all;
  .colorRed { color:  #c00 !important;}
  .colorPink {color: $c_base !important;}
  .colorBlue {color: #172a88 !important;}
  .colorGreen {color: #279d85 !important;}
  .colorPurple {color: #4d4397 !important;}
  .textBold {font-weight: bold;}
  .taC{text-align: center!important;}
  .taL{text-align: left!important;}
  .taR{text-align: right!important;}
  .Center{margin: 0 auto!important;}
  .ovh{overflow: hidden!important;}
  .mw100{max-width: 100%!important;
   width: auto!important;}
  .w100p{width: 100%!important;}
  .pT0 {padding-top: 0 !important;}
  .pT10 {padding-top: 10px !important;}
  .pT20 {padding-top: 20px !important;}
  .pT30 {padding-top: 30px !important;}
  .pT40 {padding-top: 40px !important;}
  .pB10 {padding-bottom: 10px !important;}
  .pB20 {padding-bottom: 20px !important;}
  .pB30 {padding-bottom: 30px !important;}
  .pB40 {padding-bottom: 40px !important;}
  .mB0{margin-bottom: 0!important;}
  .mB5{margin-bottom: 5px!important;}
  .mB10{margin-bottom: 10px!important;}
  .mB20{margin-bottom: 20px!important;}
  .mB30{margin-bottom: 30px!important;}
  .mB40{margin-bottom: 40px!important;}
  .mB50{margin-bottom: 50px!important;}
  .mB55{margin-bottom: 55px!important;}
  .mB60{margin-bottom: 60px!important;}
  .mB70{margin-bottom: 70px!important;}
  .pB5 {padding-bottom: 5px!important;}
  .mT0{margin-top: 0!important;}
  .mT5{margin-top: 5px!important;}
  .mT10{margin-top: 10px!important;}
  .mT20{margin-top: 20px!important;}
  .mT30{margin-top: 30px!important;}
  .mT40{margin-top: 40px!important;}
  .mT50{margin-top: 50px!important;}
  .mT55{margin-top: 55px!important;}
  .mT60{margin-top: 60px!important;}
  .mT70{margin-top: 70px!important;}
  .mT80{margin-top: 80px!important;}
  .mL0{margin-left: 0px!important;}
  .mL5{margin-left: 5px!important;}
  .mL10{margin-left: 10px!important;}
  .mL20{margin-left: 20px!important;}
  .mL30{margin-left: 30px!important;}
  .mL40{margin-left: 40px!important;}
  .mL50{margin-left: 50px!important;}
  .mR0{margin-right: 0px!important;}
  .mR5{margin-right: 5px!important;}
  .mR10{margin-right: 10px!important;}
  .mR20{margin-right: 20px!important;}
  .mR30{margin-right: 30px!important;}
  .mR40{margin-right: 40px!important;}
  .mR50{margin-right: 50px!important;}
  .size12_pc{font-size: 12px !important;}
  .size13_pc{font-size: 13px !important;}
  .size14_pc{font-size: 14px !important;}
  .size15_pc{font-size: 15px !important;}
  .size16_pc{font-size: 16px!important;}
  .size17_pc{font-size: 17px!important;}
  .size18_pc{font-size: 18px!important;}
  .size19_pc{font-size: 19px!important;}
  .size20_pc{font-size: 20px!important;}
  .size22_pc{font-size: 22px!important;}
  .size24_pc{font-size: 24px!important;}
  .size28_pc{font-size: 28px!important;}
  .lh225 {line-height: 2.25 !important;}
 }

 @if $type == sp{
  line-height: 1.9286;

  .taC_sp{text-align: center!important;}
  .taL_sp{text-align: left!important;}
  .taR_sp{text-align: right!important;}
  .pT0_sp {	padding-top: 0 !important;}
  .pT10_sp {padding-top: 10px !important;}
  .pT20_sp {padding-top: 20px !important;}
  .pB0_sp { padding-bottom: 0 !important;}
  .pB10_sp {padding-bottom: 10px !important;}
  .pB20_sp {padding-bottom: 20px !important;}
  .pB30_sp {padding-bottom: 30px !important;}
  .pB40_sp {padding-bottom: 40px !important;}
  .pT30_sp {padding-top: 30px !important;}
  .pT40_sp {padding-top: 40px !important;}
  .mB0_sp{margin-bottom: 0!important;}
  .mB5_sp{margin-bottom: 5px!important;}
  .mB10_sp{margin-bottom: 10px!important;}
  .mB15_sp{margin-bottom: 15px!important;}
  .mB20_sp{margin-bottom: 20px!important;}
  .mB30_sp{margin-bottom: 30px!important;}
  .mB40_sp{margin-bottom: 40px!important;}
  .mB50_sp{margin-bottom: 50px!important;}
  .mB55_sp{margin-bottom: 55px!important;}
  .mB60_sp{margin-bottom: 60px!important;}
  .mB70_sp{margin-bottom: 70px!important;}
  .mT0_sp{margin-top: 0!important;}
  .mT5_sp{margin-top: 5px!important;}
  .mT10_sp{margin-top: 10px!important;}
  .mT20_sp{margin-top: 20px!important;}
  .mT30_sp{margin-top: 30px!important;}
  .mT40_sp{margin-top: 40px!important;}
  .mT50_sp{margin-top: 50px!important;}
  .mT55_sp{margin-top: 55px!important;}
  .mT60_sp{margin-top: 60px!important;}
  .mT70_sp{margin-top: 70px!important;}
  .mT80_sp{margin-top: 80px!important;}
  .mL0_sp{margin-left: 0px!important;}
  .mL5_sp{margin-left: 5px!important;}
  .mL10_sp{margin-left: 10px!important;}
  .mL20_sp{margin-left: 20px!important;}
  .mL30_sp{margin-left: 30px!important;}
  .mL40_sp{margin-left: 40px!important;}
  .mL50_sp{margin-left: 50px!important;}
  .mR0_sp{margin-right: 0px!important;}
  .mR5_sp{margin-right: 5px!important;}
  .mR10_sp{margin-right: 10px!important;}
  .mR20_sp{margin-right: 20px!important;}
  .mR30_sp{margin-right: 30px!important;}
  .mR40_sp{margin-right: 40px!important;}
  .mR50_sp{margin-right: 50px!important;}
  .size12_sp{font-size: 12px !important;}
  .size13_sp{font-size: 13px !important;}
  .size14_sp{font-size: 14px !important;}
  .size15_sp{font-size: 15px !important;}
  .size16_sp{font-size: 16px!important;}
  .size17_sp{font-size: 17px!important;}
  .size18_sp{font-size: 18px!important;}
  .size19_sp{font-size: 19px!important;}
  .size20_sp{font-size: 20px!important;}
  .size22_sp{font-size: 22px!important;}
  .size24_sp{font-size: 24px!important;}
  .size28_sp{font-size: 28px!important;}
  .w50p_sp{width: 50%!important;}
  .w100_sp {
   width: 100% !important;
  }
 }
 @if $type == pc{
  .pT10_pc {padding-top: 10px !important;}
  .pT20_pc {padding-top: 20px !important;}
  .pT30_pc {padding-top: 30px !important;}
  .pT40_pc {padding-top: 40px !important;}
  .pB10_pc {padding-bottom: 10px !important;}
  .pB20_pc {padding-bottom: 20px !important;}
  .pB30_pc {padding-bottom: 30px !important;}
  .pB40_pc {padding-bottom: 40px !important;}
  .mB0_pc{margin-bottom: 0!important;}
  .mB5_pc{margin-bottom: 5px!important;}
  .mB10_pc{margin-bottom: 10px!important;}
  .mB15_pc{margin-bottom: 15px!important;}
  .mB20_pc{margin-bottom: 20px!important;}
  .mB30_pc{margin-bottom: 30px!important;}
  .mB40_pc{margin-bottom: 40px!important;}
  .mB50_pc{margin-bottom: 50px!important;}
  .mB55_pc{margin-bottom: 55px!important;}
  .mB60_pc{margin-bottom: 60px!important;}
  .mB70_pc{margin-bottom: 70px!important;}
  .mT0_pc{margin-top: 0!important;}
  .mT5_pc{margin-top: 5px!important;}
  .mT10_pc{margin-top: 10px!important;}
  .mT20_pc{margin-top: 20px!important;}
  .mT30_pc{margin-top: 30px!important;}
  .mT40_pc{margin-top: 40px!important;}
  .mT50_pc{margin-top: 50px!important;}
  .mT55_pc{margin-top: 55px!important;}
  .mT70_pc{margin-top: 70px!important;}
  .mT80_pc{margin-top: 80px!important;}
  .mL0_pc{margin-left: 0px!important;}
  .mL5_pc{margin-left: 5px!important;}
  .mL10_pc{margin-left: 10px!important;}
  .mL20_pc{margin-left: 20px!important;}
  .mL30_pc{margin-left: 30px!important;}
  .mL40_pc{margin-left: 40px!important;}
  .mL50_pc{margin-left: 50px!important;}
  .mL55_pc{margin-left: 55px!important;}
  .mL60_pc{margin-left: 60px!important;}
  .mL70_pc{margin-left: 70px!important;}
  .mR0_pc{margin-right: 0px!important;}
  .mR5_pc{margin-right: 5px!important;}
  .mR10_pc{margin-right: 10px!important;}
  .mR20_pc{margin-right: 20px!important;}
  .mR30_pc{margin-right: 30px!important;}
  .mR40_pc{margin-right: 40px!important;}
  .mR50_pc{margin-right: 50px!important;}
  .mR55_pc{margin-right: 55px!important;}
  .mR60_pc{margin-right: 60px!important;}
  .mR70_pc{margin-right: 70px!important;}
  .size12{font-size: 12px !important;}
  .size13{font-size: 13px !important;}
  .size14{font-size: 14px !important;}
  .size15{font-size: 15px !important;}
  .size16{font-size: 16px!important;}
  .size17{font-size: 17px!important;}
  .size18{font-size: 18px!important;}
  .size19{font-size: 19px!important;}
  .size20{font-size: 20px!important;}
  .size22{font-size: 22px!important;}
  .size24{font-size: 24px!important;}
  .size28{font-size: 28px!important;}
  .hoverAlpha {
        transition: .2s opacity;

        &:hover {
            opacity: .8;
        }
    }
   .hoverScale {
        display: block;
        overflow: hidden;

        > * {
            transition: .3s transform;
        }

        &:hover {
            > * {
                transform: scale(1.1);
            }
        }  
    }
}

}

* + .imgFixed {
 @if $type == ""{
 }
 @if $type == sp{
  margin-top: 30px;
 }
 @if $type == pc{
  margin-top: 30px;
 }
}

.imgFixed {
 @if $type == ""{
  display: flex;
 }
 @if $type == sp{
  flex-direction: column;
 }
 @if $type == pc{
 }
 @if $type == tb{
  flex-direction: column;
 }

 > * {
  @if $type == ""{
   &.img {
    img {
     display: block;
     margin: 0 auto;
    }
   }
  }
  @if $type == sp{
   width: 100%;

   &.txt {
    order: 1;
    margin-top: 30px;
   }

   &.img {
    order: 0;
   }
  }
  @if $type == pc{
   &.img {
    flex: none;
   }

   &:nth-child(odd) {
    margin-right: 55px;
   }

   &:nth-child(n + 3) {
    margin-top: 30px;
   }
  }

  @if $type == tb{
   width: 100%;

   &.txt {
    order: 1;
    margin-top: 30px;
   }

   &.img {
    order: 0;
   }

   &:nth-child(odd) {
    margin-right: 0;
   }

   &:nth-child(n + 3) {
    margin-top: 0;
   }
  }

 }
}

.preBox{
 @if $type == ""{
  background: #f2f2f2;
  border: 1px solid #ccc;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
 }


 pre{
  @if $type == ""{
   overflow: auto;
   white-space: pre-wrap;
   word-wrap: break-word;
   text-align-last: auto;
  }
 }
}