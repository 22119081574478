main,
.mfp-content {
    @if $type =="" {
        overflow: hidden;
    }

    @if $type ==sp {
        line-height: 1.9286;
    }

    @if $type ==pc {}

    a {
        @if $type =="" {
            color: #005ba4;
            text-decoration: underline;
        }

        @if $type ==sp {}

        @if $type ==pc {
            &:hover {
                text-decoration: none;
            }
        }
    }

    *:not(.title)+.innerBody p:first-child,
    *+p,
    p+p {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 20px;
        }

        @if $type ==pc {
            margin-top: 20px;
        }
    }
}

.sectionHeader {
    @if $type =="" {}

    @if $type ==sp {
        margin-bottom: 30px;
    }

    @if $type ==pc {
        margin-bottom: 30px;
    }

    &:not(:first-child) {
        @if $type ==sp {
            margin-top: 95px;
        }

        @if $type ==pc {
            margin-top: 117px;
        }
    }
}

.linkColor {
    @if $type =="" {
        color: #005ba4;
    }

    @if $type ==sp {}

    @if $type ==pc {
        transition: .2s color;

        &:hover {
            color: $c_base;
        }
    }
}

.commonLink {
    @if $type ==pc {
        &:hover {
            [class^="icon"] {
                transform: translateX(5px);
            }
        }
    }

    [class^="icon"] {
        @if $type =="" {}

        @if $type ==sp {
            margin-left: 12px;
        }

        @if $type ==pc {
            margin-left: 12px;
            transition: .2s transform;
        }
    }
}

.sectionLead {
    @if $type =="" {
        line-height: 2;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

[data-background="navy"] {
    @if $type =="" {
        color: #fff;
        background: $c_navy;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    .inner {
        @if $type =="" {
            color: #fff;
        }

        @if $type ==sp {}

        @if $type ==pc {}
    }
}

[data-background="purple"] {
    @if $type =="" {
        background: #ececf4;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

[data-background="pink"] {
    @if $type =="" {
        background: #fcf2f6;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

[data-background="gray"] {
    @if $type =="" {
        background: #e2e2e2;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    .inner {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {}
    }
}

[data-background="white"] {
    @if $type =="" {
        background: #fff;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    .inner {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {}
    }
}

.iconDashPink {
    @if $type =="" {
        display: inline-block;
        background: url(../images/common/icon_dash_pink.svg) center no-repeat;
    }

    @if $type ==sp {
        margin-left: 7px;
        width: 9px;
        height: 9px;
    }

    @if $type ==pc {
        margin-left: 12px;
        width: 9px;
        height: 11px;
    }
}

.iconDashNavy {
    @if $type =="" {
        display: inline-block;
        background: url(../images/common/icon_dash_navy.svg) no-repeat;
        background-size: contain;
    }

    @if $type ==sp {
        margin-left: 7px;
        width: 9px;
        height: 9px;
    }

    @if $type ==pc {
        margin-left: 12px;
        width: 9px;
        height: 11px;
    }
}

.pageHeader {
    @if $type =="" {
        background: $c_navy;
    }

    .breadcrumb {
        @if $type =="" {
            color: #fff;

            br {
                display: none !important;
            }
        }

        @if $type ==sp {
            display: none !important;
        }

        @if $type ==pc {
            display: flex;
            flex-wrap: wrap;
            margin-top: auto;

            >li {
                margin-right: 30px;
                font-size: 14px;

                +li {
                    padding-left: 42px;
                    position: relative;

                    &:before {
                        content: "";
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-right: 2px solid #fff;
                        border-bottom: 2px solid #fff;
                        position: absolute;
                        left: -2px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transform: rotate(-45deg);
                        box-sizing: border-box;
                    }
                }

                a {
                    display: inline-block;
                    color: #fff;
                    font-size: 14px;
                    text-decoration: none;
                    transition: .2s opacity;

                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }
    }
}

.pageHeader {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    &.sink {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            padding-bottom: 50px !important;
        }

        +.section {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                transform: translateY(-35px);
            }

            .inner {
                @if $type =="" {}

                @if $type ==sp {
                    padding-top: 30px;
                }

                @if $type ==pc {
                    padding-top: 25px;
                    background: #fff;
                }
            }
        }
    }
}

.tag {
    &.bd {
        background: #4c95d0;
    }

    &.oa {
        background: #5958ac;
    }

    &.gr {
        background: #96c55c;
    }
}

//sinkと同じ
main[class^="page_product"].page2:not(.page_product_about):not(.page_product_factory) {
    .pageHeader {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            padding-bottom: 50px !important;
        }

        +.section {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                transform: translateY(-35px);
            }

            .inner:first-child {
                @if $type =="" {}

                @if $type ==sp {
                    padding-top: 35px;
                }

                @if $type ==pc {
                    padding-top: 25px;
                    background: #fff;
                }
            }
        }
    }
}

main[class^="page_product"].page2,
main.page_company_factory,
main.page_company_profile,
main.page_company_message,
main[class^="page_support"]:not(.page_support_index),
main[class^="page_data"]:not(.page_data_index),
main[class^="page_contact"]:not(.page_contact_index),
main[class^="page_works"]:not(.page_works_index) {
    @if $type ==pc {
        .inner {
            max-width: 1080px;
            margin: 0 auto;
        }

        .anchorListSmall {
            max-width: 1080px;
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

.innerSmall {
    @if $type ==sp {
        .inner {
            @include maxSP;
        }
    }

    @if $type ==pc {
        .inner {
            max-width: 1080px;
        }
    }
}

main:not(.page_company_index):not(.page_product_index):not(.page_document_index):not(.page_support_index):not(.page_data_index):not(.page_contact_index):not(.page_worries_index):not(.page_works_index):not(.page_news_index):not(.page_column_index):not(.page_about_index),
main.page2 {
    .pageHeader {
        @if $type =="" {
            color: #fff;
        }

        @if $type ==sp {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 94px;
        }

        @if $type ==pc {
            min-height: 200px;
            padding: 69px 0 18px;
        }

        @media all and (-ms-high-contrast: none) and (min-width: 981px) {
            justify-content: flex-start !important;
            height: 200px;
        }

        .pageHeaderInner {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                max-width: 1360px;
                padding: 0 40px;
                margin: 0 auto;
            }
        }

        .pageTitle {
            @if $type =="" {
                text-align: center;
                font-weight: 400;
                position: relative;
            }

            @if $type ==sp {
                margin-top: 3px;
                padding: 0 15px 9px;
                font-size: 18px;
                line-height: 1.4;
            }

            @if $type ==pc {
                padding-bottom: 11px;
                margin-bottom: 25px;
                font-size: 26px;
            }

            &:before {
                @if $type =="" {
                    //content: "";
                    height: 2px;
                    background: $c_base;
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }

                @if $type ==sp {
                    width: 40px;
                }

                @if $type ==pc {
                    width: 70px;
                }
            }
        }
    }
}

.page_company_index:not(.page2),
.page_product_index:not(.page2),
.page_document_index:not(.page2),
.page_support_index:not(.page2),
.page_data_index:not(.page2),
.page_contact_index:not(.page2),
.page_worries_index:not(.page2),
.page_works_index:not(.page2),
.page_news_index:not(.page2),
.page_column_index:not(.page2),
.page_about_index:not(.page2) {
    .pageHeader {
        @if $type ==sp {}

        @if $type ==pc {
            height: inherit;
            padding-left: 6%;
        }


        .pageHeaderInner {
            @if $type =="" {
                color: #fff;
                background: $c_navy;
            }

            @if $type ==sp {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 134px;
            }

            @if $type ==pc {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                min-height: 300px;
                padding: 38px 0 18px;
                padding-right: 6%;
            }

            @media all and (-ms-high-contrast: none) and (min-width: 981px) {
                justify-content: flex-start !important;
                height: 300px;
            }

            .pageTitle {
                @if $type =="" {
                    font-weight: 400;
                    text-align: center;
                    position: relative;
                }

                @if $type ==sp {
                    margin-top: 17px;
                    padding-bottom: 17px;
                    font-size: 23px;
                    line-height: 1.2;
                }

                @if $type ==pc {
                    margin-top: auto;
                    padding-bottom: 11px;
                    font-size: 36px;
                    line-height: 1.2;
                }

                &:before {
                    @if $type =="" {
                        // content: "";
                        height: 2px;
                        background: $c_base;
                        position: absolute;
                        right: 0;
                        left: 0;

                        margin: auto;
                    }

                    @if $type ==sp {
                        bottom: -2px;
                        width: 40px;
                    }

                    @if $type ==pc {
                        bottom: -11px;
                        width: 70px;
                    }
                }
            }

            .pageHeaderNote {
                @if $type =="" {
                    text-align: center;
                }

                @if $type ==sp {
                    margin-top: 19px;
                    font-size: 14px;
                    line-height: 1.6429;
                }

                @if $type ==pc {
                    margin-top: 31px;
                }
            }

            .breadcrumb {
                padding-left: 45px;
            }
        }
    }
}

[class^="page_voice"],
[class^="page_company"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/company/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_product"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/product/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_document"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/document/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_support"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/support/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_data"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/data/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_contact"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/contact/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_worries"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/worries/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_works"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/works_greening/page_works_second.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_news"]:not(.page_news_index) {
    .pageHeader {
        @if $type =="" {
            background: url(../images/news/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

[class^="page_column"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/column/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}


[class^="page_about"] {
    .pageHeader {
        @if $type =="" {
            background: url(../images/about/header_bg.jpg) no-repeat center;
            background-size: cover;
        }
    }
}

.page_company_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/company/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_product_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/product/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_document_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/document/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_support_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/support/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_data_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/data/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_contact_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/contact/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_worries_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/worries/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_works_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            position: relative;
            //border-bottom: 1px solid #fff;
        }

        @if $type ==sp {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        @if $type ==pc {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        &:before {
            @if $type =="" {
                content: "";
                position: absolute;
                //opacity: .8;
            }

            @if $type ==sp {
                width: 100%;
                //height: calc(100% - 80px);
                right: 0;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }

            @if $type ==pc {
                width: 48.54vw;
                top: 0;
                right: 0;
                bottom: -1px;
                left: 0;
                transform: scaleY(2.9) perspective(6.6em) rotateX(10deg);
                transform-origin: bottom left;
            }
        }

        .pageHeaderInner {
            @if $type =="" {
                position: relative;
                background: none !important;
            }

            @if $type ==sp {
                flex-direction: column;
            }

            @if $type ==pc {
                max-width: inherit !important;
                padding: 0 !important;
                margin: 0 !important;
            }

            .pageHeaderWorksHeader {
                @if $type =="" {
                    color: #000000;
                }

                @if $type ==sp {
                    display: flex;
                    flex-direction: column;
                    padding-top: 80px;
                    padding-bottom: 40px;
                    padding-right: 15px;
                    padding-left: 15px;
                }

                @if $type ==pc {
                    padding-top: 8.37vw !important;
                    padding-bottom: 18px !important;
                    width: 28.1522vw;
                }

                .pageHeaderWorksHeaderCase {
                    @if $type =="" {
                        letter-spacing: 0.01em;
                    }

                    @if $type ==sp {
                        order: 1;
                        padding: 20px 15px 0;
                        text-align: center;
                        font-size: 16px;
                    }

                    @if $type ==pc {
                        font-size: 16px;
                        line-height: 1.46;
                    }
                }

                .pageTitle {
                    @if $type =="" {}

                    @if $type ==sp {
                        order: 0;
                        margin-top: 0;
                    }

                    @if $type ==pc {
                        margin-top: 15px;
                        text-align: left !important;
                    }

                    &:before {
                        @if $type =="" {}

                        @if $type ==sp {}

                        @if $type ==pc {
                            right: inherit !important;
                        }
                    }
                }

                .pageHeaderNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        order: 2;
                    }

                    @if $type ==pc {
                        text-align: left;
                        margin-bottom: 100px;
                        font-size: 16px;
                        line-height: 2;
                    }
                }
            }

            .breadcrumb {
                @if $type =="" {
                    color: #000000;

                    a {
                        color: #000000;
                    }

                    >li+li {
                        &:before {
                            border-width: 0 1px 1px 0;
                            border-color: #000000;
                        }
                    }
                }

                @if $type ==sp {}

                @if $type ==pc {
                    padding-left: 0 !important;
                    //margin: auto 0 35px;
                }
            }
        }
    }

    .pageNav {
        @if $type ==pc {}
    }
}

// 施工事例
.oa.page_works_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: url(../images/works_oa/header_bg.jpg) no-repeat center bottom;
            background-size: cover;


        }

        @if $type ==pc {
            overflow: hidden;
        }

        &:before {

            //background: #ce891c;
            @if $type ==sp {
                background: rgba(#f4f4ff, .9);
            }

            @if $type ==pc {
                background: rgba(#f4f4ff, .9);
            }
        }

    }
}

.busduct.page_works_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: url(../images/works_busduct/header_bg.jpg) no-repeat center;
            background-size: cover;
        }

        @if $type ==pc {
            overflow: hidden;
        }

        &:before {

            //background: #97c91b;
            @if $type ==sp {
                background: rgba(#e2eaf1, .9);
            }

            @if $type ==pc {
                background: rgba(#e2eaf1, .9);
            }
        }
    }
}

.greening.page_works_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: url(../images/works_greening/header_bg.jpg) no-repeat center;
            background-size: cover;


        }

        @if $type ==pc {
            overflow: hidden;
        }

        &:before {

            //background: #97c91b;
            @if $type ==sp {
                background: rgba(#ebf4ec, .8);
            }

            @if $type ==pc {
                background: rgba(#ebf4ec, .9);
            }
        }

        .pageHeaderInner {
            @if $type =="" {}

            @if $type ==sp {
                padding-bottom: 80px;
            }

            @if $type ==pc {
                display: flex;
                //align-items: center;
                flex-direction: row;
            }

            .pageHeaderWorksHeader {
                @if $type ==sp {
                    display: flex;
                    flex-direction: column;
                    padding-top: 80px;
                    padding-right: 15px;
                    padding-left: 15px;

                    .pageHeaderWorksHeaderCase {
                        order: 1;
                        text-align: center;
                    }

                    .pageTitle {
                        order: 0;
                    }
                }

                @if $type ==pc {
                    margin-right: auto;
                    display: flex;
                    flex-direction: column;
                    max-height: 680px;
                }
            }

            .pageHeaderWorksMap {
                @if $type =="" {
                    color: #000;
                    position: relative;
                }

                @if $type ==sp {
                    width: calc(100% - 30px);
                    max-width: 820px;
                    margin: 0 auto;
                }

                @if $type ==tb {
                    background-size: 90%;
                }

                @if $type ==pc {
                    background: url(../images/works_greening/map.png) no-repeat center;
                    //background-size: contain;
                    background-size: 80%;
                    width: 59.46vw;
                    max-width: 820px;
                    //height: 36.6vw;
                    //max-height: 680px;
                    margin: 0 auto;
                }

                .pageHeaderWorksMapTitle {
                    @if $type =="" {
                        line-height: 1.2;
                    }

                    @if $type ==sp {
                        font-size: 18px;
                        transition: .2s transform;
                    }

                    @if $type ==pc {
                        color: #000;
                        font-size: 24px;
                        transition: .2s transform;
                        //transform: translateY(2rem);
                        transform: translateY(11.38vh);
                    }

                    @if $type ==tb {
                        //transform: translateY(-4rem);
                    }
                }

                .pageHeaderWorksMapNote {
                    @if $type =="" {
                        //border-top: 1px solid #fff;
                    }

                    @if $type ==sp {
                        //padding-top: 10px;
                        margin-top: 10px;
                        transition: .2s transform;
                    }

                    @if $type ==pc {
                        display: inline-block;
                        //padding-top: 10px;
                        margin-top: 4px;
                        color: #000;
                        transition: .2s transform;
                        //transform: translateY(2rem);
                        transform: translateY(11.38vh);
                    }

                    @if $type ==tb {
                        //transform: translateY(-4rem);
                    }
                }

                .worksBtnList {
                    @if $type ==sp {
                        height: 66.6vw;
                        max-height: 600px;
                        margin-top: 1em;
                        background: url(../images/works_greening/map.png) no-repeat center;
                        background-size: contain;
                    }


                    >li {
                        @if $type =="" {
                            display: block;
                            position: absolute;
                        }

                        @if $type ==sp {
                            font-size: clamp(1px, 2.4vw, 14px);

                            &.btnHokkaido {
                                top: 25.5%;
                                right: 2.9%;
                            }

                            &.btnTohoku {
                                top: 44.5%;
                                right: 23.17%;
                            }

                            &.btnKanto {
                                top: 59.16%;
                                right: 26.78%;
                            }

                            &.btnTokyo {
                                top: 73.16%;
                                right: 22.78%;
                            }

                            &.btnKinki {
                                bottom: 11.66%;
                                right: 48.29%;
                            }

                            &.btnKyushu {
                                bottom: 0;
                                left: 14.24%;
                            }

                            &.btnChugoku {
                                top: 51.67%;
                                left: 17.43%;
                            }

                            &.btnChubu {
                                top: 37.66%;
                                left: 35.73%;
                            }
                        }

                        @if $type ==pc {
                            transition: .2s all;

                            width: 18.29%;

                            &.btnHokkaido {
                                //top: 25.5%;
                                //right: 2.9%;
                                top: 28.5%;
                                right: 4.9%;
                            }

                            &.btnTohoku {
                                top: 44.5%;
                                right: 15.17%;
                            }

                            &.btnKanto {
                                top: 62.16%;
                                right: 20.78%;
                            }

                            &.btnTokyo {
                                top: 75.16%;
                                right: 19.78%;
                            }

                            &.btnKinki {
                                bottom: 15.66%;
                                //right: 48.29%;
                                left: 32.44%;
                            }

                            &.btnKyushu {
                                //bottom: 0;
                                bottom: 5.82%;
                                //left: 14.24%;
                                left: 4.88%;
                            }

                            &.btnChugoku {
                                top: 51.67%;
                                left: 17.43%;
                            }

                            &.btnChubu {
                                top: 37.66%;
                                left: 35.73%;
                            }
                        }

                        @if $type ==tb {

                            &.btnHokkaido {
                                top: 30.5%;
                                right: 7.9%;
                            }

                            &.btnTohoku {
                                right: 15.17%;
                            }

                            &.btnKinki {
                                //bottom: 8.66%;
                                bottom: 17.66%;
                                right: 45.29%;
                            }

                            &.btnKanto {
                                top: 60.16%;
                                right: 20.78%;
                            }

                            &.btnTokyo {
                                top: 70.16%;
                                right: 19.78%;
                            }

                            &.btnKyushu {
                                //bottom: -3%;
                                bottom: 11%;
                            }

                            &.btnChugoku {
                                //top: 47.67%;
                                top: 52.67%;
                            }

                            &.btnChubu {
                                //top: 32.66%;
                                top: 39.66%;
                            }
                        }
                    }

                    .btnWrap {
                        @if $type =="" {
                            text-align: right;
                        }

                        @if $type ==sp {
                        }

                        @if $type ==pc {
                            margin-top: 0;
                        }
                    }


                    a {
                        @if $type =="" {
                            display: block;
                        }

                        @if $type ==sp {}

                        @if $type ==pc {
                            padding: 5%;
                            font-size: clamp(11px, 1.33vw, 16px);

                        }

                        i {
                            @if $type =="" {
                                margin-left: 0;
                            }

                            @if $type ==sp {
                                margin-right: 5px;
                            }

                            @if $type ==pc {
                                margin-right: 10px;
                                vertical-align: unset;
                            }
                        }
                    }
                }
            }

        }
    }
}

.worksList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    >li {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            +li {
                margin-top: 6px;
            }
        }

        @if $type ==pc {
            +li {
                margin-top: 6px;
            }
        }

        >a {
            @if $type =="" {
                display: block;
                background: #e2e2e2;
                text-decoration: none;
            }

            @if $type ==sp {
                padding: 16px 30px 16px 16px;
            }

            @if $type ==pc {
                padding: 20px 50px 20px 20px;
                transition: .2s background;

                &:hover {
                    background: #f7e4ea;

                    &:before {
                        opacity: 0;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }

            &:before,
            &:after {
                @if $type =="" {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: .2s opacity;
                }

                @if $type ==sp {
                    width: 9px;
                    height: 11px;
                    right: 12px;
                }

                @if $type ==pc {
                    width: 9px;
                    height: 11px;
                    right: 24px;
                }
            }

            &:before {
                @if $type =="" {
                    opacity: 1;
                    background: url(../images/common/icon_dash_black.svg) no-repeat;
                }
            }

            &:after {
                @if $type =="" {
                    opacity: 0;
                    background: url(../images/common/icon_dash_pink.svg) no-repeat;
                }
            }

            .worksListTitle {
                @if $type =="" {
                    color: $c_base;
                    font-weight: bold;
                }

                @if $type ==sp {}

                @if $type ==pc {}
            }

            .worksListTag {
                @if $type =="" {
                    display: flex;
                    flex-wrap: wrap;
                }

                @if $type ==sp {
                    margin-top: 8px;
                }

                @if $type ==pc {}

                >li {
                    @if $type =="" {
                        display: block;
                        color: #fff;

                        &.caseGreeningRooftop {
                            background: #439f52;
                        }

                        &.caseGreeningInfra {
                            background: #8dab15;
                        }

                        &.caseGreeningWall {
                            background: #48a88e;
                        }

                        &.caseSubProduct {
                            background: $c_navy;
                        }

                        &.caseSubStatus {
                            background: $c_navy;
                        }
                    }

                    @if $type ==sp {
                        padding: 1px 6px;
                        margin-top: 2px;
                        margin-right: 2px;
                        font-size: 11px;
                    }

                    @if $type ==pc {
                        padding: 1px 8px;
                        margin-top: 8px;
                        margin-right: 8px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.page_news_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/news/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_column_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/column/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}

.page_about_index:not(.page2) {
    .pageHeader {
        @if $type =="" {
            background: none;
        }

        .pageHeaderInner {
            @if $type =="" {
                background: url(../images/about/header_bg.jpg) no-repeat center;
                background-size: cover;
            }
        }
    }
}


.thumbArea {
    @if $type =="" {}

    @if $type ==sp {
        padding-right: 20px;
        padding-left: 20px;
        margin: 0 auto 14px;
    }

    @if $type ==pc {
        max-width: 600px;
        margin: 0 auto 40px;
    }

    p+p {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 2px;
            //font-size: 12px;
        }

        @if $type ==pc {
            margin-top: 5px;
            //font-size: 13px;
        }
    }
}

*:not(.title)+.innerBody .thumbArea:first-child,
*+.thumbArea {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

.leadBlock {
    @if $type =="" {}

    @if $type ==sp {
        padding: 35px 15px;
        background: #f2f2f2;
    }

    @if $type ==pc {
        padding: 56px 0;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: calc(100% + 110px);
            background: #e2e2e2;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }

    .leadCatch {
        @if $type =="" {
            color: $c_navy;
            text-align: center;
        }

        @if $type ==sp {
            margin-bottom: 17px;
            font-size: 18px;
            line-height: 1.4;
            line-height: 1.4;
        }

        @if $type ==pc {
            padding-top: 9px;
            margin-bottom: 17px;
            font-size: 24px;
            line-height: 1.4;
        }
    }

    .leadNote {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            line-height: 1.7143;
        }

        @if $type ==pc {
            font-size: 16px;
            line-height: 2.125;
        }
    }
}

.leadBlockSmall {
    @if $type =="" {}

    @if $type ==sp {
        padding: 35px 15px 0;
    }

    @if $type ==pc {
        padding: 50px 0 0;
        position: relative;
    }

    .leadCatch {
        @if $type =="" {
            color: $c_navy;
            text-align: center;
        }

        @if $type ==sp {
            margin-bottom: 17px;
            font-size: 18px;
            line-height: 1.4;
        }

        @if $type ==pc {
            padding-top: 9px;
            margin-bottom: 17px;
            font-size: 22px;
            line-height: 1.4;
        }
    }

    .leadNote {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            line-height: 1.7143;
        }

        @if $type ==pc {
            font-size: 16px;
            line-height: 2.125;
            margin-top: 30px;
        }

        +* {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                margin-top: 60px;
            }
        }
    }

    .inner {
        @if $type ==pc {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

.section {
    @if $type =="" {}

    .inner {
        @if $type =="" {}

        @if $type ==sp {
            @include maxSP;
            padding: 51px 20px 30px;
        }

        @if $type ==pc {
            @include maxPC;
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    +.section:not([data-background]) .inner {
        @if $type ==sp {
            padding-top: 20px;
        }

        @if $type ==pc {
            padding-top: 25px;
        }
    }

    .workHeader+.contentInner {
        @if $type ==sp {
            padding-top: 40px;
        }

        @if $type ==pc {}
    }

    .contentInner {
        @if $type =="" {}

        @if $type ==sp {
            @include maxSP;
            padding: 51px 20px 60px;
        }

        @if $type ==pc {
            max-width: 1080px;
            margin: 0 auto;
            padding-right: 40px;
            padding-left: 40px;
            padding-top: 80px;
            padding-bottom: 92px;
        }
    }

    .sectionHeader {
        .sectionTitle {
            @if $type ==sp {
                font-size: 21px;
            }

            @if $type ==pc {}
        }
    }

}

// title
*:not(.title)+.innerBody .sectionHeader:first-child,
*+.sectionHeader {
    @if $type ==sp {
        margin-top: 50px;
    }

    @if $type ==pc {
        margin-top: 60px;
    }
}

.sectionHeaderBlock {
    @if $type =="" {
        position: relative;
    }

    @if $type ==sp {
        margin-top: 45px;
        padding-top: 39px;
    }

    @if $type ==pc {
        display: flex;
        justify-content: space-between;
    }

    &:before {
        @if $type =="" {
            content: "";
            display: block;
            background: #f2f2f2;
            position: absolute;
            top: 0;
            left: 0;
        }

        @if $type ==sp {
            width: 100%;
            height: 133px;
        }

        @if $type ==pc {
            width: 75%;
            height: 76.92%;
        }
    }

    .sectionHeader,
    .sectionHeaderThumb,
    .sectionHeaderLink {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {}

        @if $type ==pc {}
    }

    .sectionHeader {
        @if $type =="" {}

        @if $type ==sp {
            margin-bottom: 45px;
        }

        @if $type ==pc {
            padding-top: 8.5%;
            margin: 0 auto 0;
        }

        .sectionTitle {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                line-height: 1.53;
            }
        }
    }

    .sectionHeaderThumb {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            margin-top: 8.75%;
            width: 62.7%;
        }
    }

    .sectionHeaderLink {
        @if $type =="" {
            line-height: 1;
        }

        @if $type ==sp {
            margin-top: 16px;
            text-align: center;
        }

        @if $type ==pc {
            width: 37.3%;
            position: absolute;
            top: 83.76%;
            left: 0;
        }

        .commonBtn.btnM {
            @if $type ==pc {
                min-width: 200px;
            }
        }
    }
}

h2.title+h3.title,
.titleBlock+h3.title {
    @if $type ==sp {
        margin-top: 0;
    }

    @if $type ==pc {
        margin-top: 0;
    }
}

*+h3.title {
    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 60px;
    }
}

h3.title {
    @if $type =="" {
        font-weight: 400;
        position: relative;
    }

    @if $type ==sp {
        padding: 0 10px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 1.6;
    }

    @if $type ==pc {
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 1.4;
    }

    &:before {
        @if $type =="" {
            content: "";
            display: block;
            height: 100%;
            background: $c_base;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
        }

        @if $type ==sp {
            width: 4px;
        }

        @if $type ==pc {
            width: 5px;
        }
    }

    .titleSub {
        @if $type =="" {}

        @if $type ==sp {
            font-size: 14px;
            margin-left: 20px;
        }

        @if $type ==pc {
            font-size: 18px;
            margin-left: 29px;
        }
    }
}

*+h4.title {
    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 46px;
    }
}

h4.title {
    @if $type =="" {
        color: $c_navy;
        font-weight: bold;
    }

    @if $type ==sp {
        padding: 0;
        font-size: 16px;
        line-height: 1.4;

        +p {
            margin-top: 8px;
        }
    }

    @if $type ==pc {
        padding: 0;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 1.4;
    }
}

*+h5.title {
    @if $type ==sp {
        margin-top: 20px;
    }

    @if $type ==pc {
        margin-top: 16px;
    }
}

h5.title {
    @if $type =="" {
        font-weight: 600;
    }

    @if $type ==sp {
        padding: 0 10px 8px;
        padding-bottom: 8px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.4;
    }

    @if $type ==pc {
        padding: 0 20px 13px;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 1.4;
    }
}

.postContent *+h2 {
    @if $type ==sp {
        margin-top: 56px;
    }

    @if $type ==pc {
        margin-top: 73px;
    }
}

.postContent h2 {
    @if $type =="" {
        font-weight: 400;
        border-bottom: 1px solid #b8b8b8;
    }

    @if $type ==sp {
        padding: 0 10px 8px;
        padding-bottom: 8px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 1.4;
    }

    @if $type ==pc {
        padding: 0 20px 13px;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 1.4;
    }
}

.titleLine {
    @if $type =="" {
        line-height: 1.5455;
        font-weight: bold;
        position: relative;
    }

    @if $type ==sp {
        margin-top: 40px;
        padding-left: 40px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.85;
    }

    @if $type ==pc {
        margin-top: 44px;
        padding-left: 50px;
        margin-bottom: 16px;
        font-size: 22px;
    }

    &:before {
        @if $type =="" {
            content: "";
            display: block;
            height: 2px;
            background: #c90056;
            position: absolute;
            left: 0;
        }

        @if $type ==sp {
            width: 25px;
            top: 10px;
        }

        @if $type ==pc {
            width: 30px;
            top: 15px;
        }
    }
}

*+.titleCenter {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 67px;
    }
}

.titleCenter {
    @if $type =="" {
        line-height: 1.58;
        font-weight: 400;
        text-align: center;
        color: #242e44;
    }

    @if $type ==sp {
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 1.4;
    }

    @if $type ==pc {
        margin-bottom: 30px;
        font-size: 24px;
    }
}

*+.titleBold {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 44px;
    }
}

.titleBold {
    @if $type =="" {
        line-height: 1.58;
        color: $c_navy;
        font-weight: bold;
    }

    @if $type ==sp {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 1.85;
    }

    @if $type ==pc {
        margin-bottom: 16px;
        font-size: 24px;
    }
}

h2.title,
.titleBlock {
    @if $type =="" {
        line-height: 1.4;
        font-weight: 400;
        border-bottom: 3px solid #f1f1f1;
        position: relative;
    }

    @if $type ==sp {
        padding: 8px 0;
        margin-bottom: 25px;
        font-size: 20px;
    }

    @if $type ==pc {
        padding: 15px 0;
        margin-bottom: 30px;
        font-size: 30px;
    }

    &:before {
        @if $type =="" {
            //content: "";
            display: block;
            background: $c_base;
            position: absolute;
            left: 0;
            bottom: -3px;
        }

        @if $type ==sp {
            height: 3px;
            width: 70px;
        }

        @if $type ==pc {
            height: 3px;
            width: 70px;
        }
    }

    &.iconIn {
        @if $type =="" {
            display: flex;
            justify-content: space-between;
        }

        @if $type ==sp {
            margin-bottom: 40px;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .iconArea {
        @if $type =="" {
            display: flex;
        }

        @if $type ==sp {
            justify-content: flex-end;
            padding-top: 10px;
            margin-bottom: -30px;
        }

        @if $type ==pc {
            margin-left: 20px;
            margin-top: -40px;
        }
    }

    .iconArea [class^="icon"] {
        @if $type =="" {
            margin-left: 10px;
        }

        @if $type ==sp {
            width: 16vw;
            max-width: 60px;
            height: 16vw;
            max-height: 60px;
        }

        @if $type ==pc {
            width: 80px;
            height: 80px;

        }
    }
}

.iconBoxing {
    @if $type =="" {
        background: url(../images/company/icon_boxing.png) no-repeat;
        background-size: contain;
    }
}

.iconBoxer {
    @if $type =="" {
        background: url(../images/company/icon_boxer.png) no-repeat;
        background-size: contain;
    }
}

.iconHockey {
    @if $type =="" {
        background: url(../images/company/icon_hockey.png) no-repeat;
        background-size: contain;
    }
}

.iconSupportCsr {
    @if $type =="" {
        background: url(../images/company/icon_support.png) no-repeat;
        background-size: contain;
    }
}

.iconFencing {
    @if $type =="" {
        background: url(../images/company/icon_fencing.png) no-repeat;
        background-size: contain;
    }
}

.iconFencing_sister {
    @if $type =="" {
        background: url(../images/company/icon_fencing_sister.png) no-repeat;
        background-size: contain;
    }
}

.iconSponsor {
    @if $type =="" {
        background: url(../images/company/icon_sponsor.png) no-repeat;
        background-size: contain;
    }
}

.iconSoccerFutsal {
    @if $type =="" {
        background: url(../images/company/icon_soccerfutsal.png) no-repeat;
        background-size: contain;
    }
}

.iconRegatta {
    @if $type =="" {
        background: url(../images/company/icon_regatta.png) no-repeat;
        background-size: contain;
    }
}

.iconBaseball {
    @if $type =="" {
        background: url(../images/company/icon_baseball.png) no-repeat;
        background-size: contain;
    }
}

.iconClub {
    @if $type =="" {
        background: url(../images/company/icon_club.png) no-repeat;
        background-size: contain;
    }
}

.iconRunning {
    @if $type =="" {
        background: url(../images/company/icon_running.png) no-repeat;
        background-size: contain;
    }
}


*+h2.title,
*+.titleBlock {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 45px;
    }

    @if $type ==pc {
        margin-top: 50px;
    }
}


// list
.sectionHeader+.contentsList {
    @if $type ==sp {
        margin-top: 28px;
    }

    @if $type ==pc {
        margin-top: 42px;
    }
}

.contentsList+h3.title {
    @if $type ==sp {
        margin-top: 70px;
    }

    @if $type ==pc {
        margin-top: 137px;
    }
}

p+.contentsList,
p+.contentsList3 {
    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

.contentsList3 {
    >li {
        @if $type ==pc {
            &:nth-child(3n) {
                .btnRowList {
                    margin-right: 0;
                }
            }

            .contentsListText {
                .contentsListNote {
                    padding-left: 0 !important;
                }
            }
        }
    }
}

.contentsList,
.contentsList3 {
    @if $type =="" {}

    @if $type ==sp {
        &.haveIcon {
            .contentsListThumb {
                position: relative;

                &:before {
                    content: "";
                    width: 30px;
                    height: 30px;
                    display: block;
                    background: $c_navy;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    z-index: 1;
                }

                &:after {
                    content: "";
                    width: 9px;
                    height: 9px;
                    display: block;
                    background: url(../images/common/icon_dash_white.svg) no-repeat;
                    background-size: contain;
                    position: absolute;
                    right: 9px;
                    bottom: 11px;
                    z-index: 2;
                }
            }
        }
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;

    }

    >li {
        @if $type =="" {}

        @if $type ==sp {

            &:nth-child(n + 2) {
                margin-top: 60px;
            }
        }

        @if $type ==pc {}

        >* {
            @if $type =="" {
                display: block;
                text-decoration: none;
            }

            @if $type ==sp {}
        }

        >a {
            @if $type ==pc {
                &:hover {
                    .contentsListThumb {

                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        .contentsListThumb {
            @if $type =="" {}

            @if $type ==sp {
                img {
                    width: 100%;
                }
            }

            @if $type ==pc {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 30vh;
                max-height: 270px;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 30vh;
                    max-height: 270px;
                    object-position: center;
                    transition: .2s transform;
                }
            }

            @media all and (-ms-high-contrast: none) and (min-width: 981px) {
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                    z-index: -1;
                }
            }
        }

        .contentsListText {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 7px;
            }

            @if $type ==pc {
                margin-top: 11px;
            }

            .contentsListNote {
                @if $type =="" {
                    color: #000;
                    position: relative;
                }

                @if $type ==sp {
                    padding-left: 25px;
                    padding-left: 15px;
                    line-height: 1.4286;
                    font-size: 14px;
                }

                @if $type ==pc {
                    padding-left: 38px;
                    padding-left: 28px;
                    font-size: 18px;
                }
            }
        }

    }
}

.contentsList {
    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 48.75%;

            &:nth-child(odd) {
                margin-right: 2.5%;
            }

            &:nth-child(n + 3) {
                margin-top: 60px;
            }
        }
    }
}

.contentsList3 {
    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 31.66%;

            &:not(:nth-child(3n)) {
                margin-right: 2.5%;
            }

            &:nth-child(n + 4) {
                margin-top: 30px;
            }
        }
    }
}

.boxList,
.boxList2 {
    @if $type =="" {
        width: 100%;
        list-style-type: none;
    }

    @if $type ==sp {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.5em;
        margin-bottom: 24px;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {
            width: 100%;
            margin-top: 20px;

            &:nth-child(n + 2) {
                margin-top: 35px;
            }
        }

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .boxListBody {
            @if $type =="" {}

            @if $type ==sp {
                padding: 20px 0 0;
            }

            @if $type ==pc {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                height: 100%;
                padding: 20px 0 0;
            }
        }

        .boxListTitle {
            @if $type =="" {
                color: #242e44;
                font-weight: 500;
                text-align: left;
                line-height: 1.2;
                position: relative;
            }

            @if $type ==sp {
                padding: 3px 0 1px 15px;
                font-size: 18px;
            }

            @if $type ==pc {
                padding: 10px 5.21% 5px;
            }

            span {
                @if $type =="" {
                    display: block;
                    color: $c_base;
                    @include fontRo;
                }

                @if $type ==sp {
                    margin-bottom: 4px;
                    font-size: 12px;
                }

                @if $type ==pc {
                    margin-bottom: 4px;
                    font-size: 14px;
                }
            }

            &:before {
                @if $type =="" {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: $c_base;
                }

                @if $type ==sp {
                    width: 2px;
                }

                @if $type ==pc {
                    width: 2px;
                }
            }
        }
    }
}

.boxList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 31.6%;

            &:not(:nth-child(3n)) {
                margin-right: 2.51%;
            }

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            &:nth-child(n + 4) {
                margin-top: 4.8334%;
            }
        }
    }
}

.boxList2 {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 40px;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: calc(50% - 20px);

            &:nth-child(odd) {
                margin-right: 10px;
            }

            &:nth-child(even) {
                margin-left: 10px;
            }

            &:nth-child(n + 3) {
                margin-top: 20px;
            }
        }
    }
}

*+.borderList,
*+.borderList3,
*+.borderList4,
*+.borderListGap {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 16px;
    }

    @if $type ==pc {
        margin-top: 24px;
    }
}

.borderList,
.borderList3,
.borderList4
{
@if $type =="" {
    text-align: center;
}

@if $type ==sp {}

@if $type ==pc {
    display: flex;
}

>li {
    @if $type =="" {}

    @if $type ==sp {
        padding: 16px 8px;

        &:first-child {
            padding-top: 0;
        }

        +li {
            border-top: 1px solid #b8b8b8;
        }
    }

    @if $type ==pc {
        flex: 1;
        padding: 23px 16px 23px;

        +li {
            border-left: 1px solid #b8b8b8;
        }
    }
}
}

.borderList {
    @if $type ==pc {
        flex-wrap: wrap;
        position: relative;
    }

    >li {
        @if $type ==pc {
            width: 49%;
            flex: inherit;
            border: 1px solid #b8b8b8;


            &:not(:nth-child(2n)) {
                margin-right: 2%;
            }

            &:nth-child(n + 3) {
                margin-top: 2%;
            }

        }
    }
}

.borderList3 {
    @if $type ==pc {
        flex-wrap: wrap;
        position: relative;
    }

    >li {
        @if $type ==pc {
            width: 33.3%;
            flex: inherit;

            &:not(:nth-child(3n)) {
                margin-right: 2%;
            }

            &:nth-child(4n) {
                border-left: none;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #b8b8b8;
                    position: absolute;
                    left: 0;
                    transform: translateY(-24px);
                }
            }
        }
    }
}

.borderList4 {
    @if $type ==pc {
        flex-wrap: wrap;
        position: relative;
    }

    >li {
        @if $type ==pc {
            width: 25%;
            flex: inherit;

            &:not(:nth-child(4n)) {
                margin-right: 2%;
            }

            &:nth-child(5n) {
                border-left: none;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #b8b8b8;
                    position: absolute;
                    left: 0;
                    transform: translateY(-24px);
                }
            }
        }
    }
}


.borderListGap {
    @if $type =="" {}

    @if $type ==sp {
        text-align: center;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
    }

    >li {
        @if $type =="" {
            border: 1px solid #b8b8b8;
            text-align: center;
            background: #fff;
        }

        @if $type ==sp {
            padding: 16px 8px;

            +li {
                margin-top: 15px;
            }
        }

        @if $type ==pc {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32%;
            padding: 23px 16px 23px;

            &:not(:nth-child(3n)) {
                margin-right: 2%;
            }

            &:nth-child(n + 4) {
                margin-top: 2%;
            }
        }
    }
}



.anchorList {
    @if $type =="" {
        display: flex;
        flex-wrap: wrap;
    }

    @if $type ==sp {
        max-width: 740px;
        padding: 0 20px;
        margin: 0 auto;
    }

    @if $type ==pc {
        max-width: 1278px;
        padding: 0 40px;
        margin: 92px auto 0;
    }

    >li {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            width: calc(33.3% - 1px);
            margin-right: 1px;

            &:nth-child(n + 4) {
                margin-top: 1px;
            }
        }

        @if $type ==pc {
            width: calc(33.3% - 1px);
            margin-right: 1px;

            +li {
                margin-left: 0px;
            }

            &:nth-child(n + 4) {
                margin-top: 1px;
            }
        }

        >a {
            @if $type =="" {
                display: block;
                color: #fff;
                text-align: center;
                text-decoration: none;
                background: $c_navy;
            }

            @if $type ==sp {
                padding: 17px 5px 12px;
                font-size: 14px;
            }

            @if $type ==pc {
                padding: 29px 5px 28px;
                font-size: 20px;
                line-height: 1.4;
                position: relative;
                overflow: hidden;

                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 100%;
                    background: #13192a;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: .2s width;
                }

                &:hover {
                    color: #fff;

                    &:before {
                        width: 100%;
                    }
                }

                >div {
                    position: relative;
                    z-index: 1;
                }
            }

            >div {
                @if $type ==sp {
                    display: block;
                }

                @if $type ==pc {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:before {
                    @if $type ==pc {
                        content: "";
                        display: block;
                        background: url(../images/common/icon_dash_white.svg) no-repeat;
                        background-size: contain;
                        transform: rotate(90deg);
                        width: 10px;
                        height: 12px;
                        margin-right: 18px;
                    }
                }

                &:after {
                    @if $type ==sp {
                        content: "";
                        display: block;
                        background: url(../images/common/icon_dash_white.svg) no-repeat;
                        background-size: contain;
                        transform: rotate(90deg);
                        width: 11px;
                        height: 9px;
                        margin: 6px auto 0;
                    }
                }
            }

        }
    }
}

.btnRowList {
    @if $type =="" {

        >li+li {
            border-top: 1px solid #fff;
        }
    }

    @if $type ==pc {
        margin-right: 1px;
    }

    .btnS,
    .btnM,
    .btnL {
        @if $type =="" {
            min-width: inherit;
        }

        @if $type ==pc {
            >div {
                padding-right: 25px;
                padding-left: 25px;
            }

        }
    }
}


ul.anchorListSmall+.section .inner:nth-child(1) {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        padding-top: 55px;
    }
}

*+.anchorListSmall {
    @if $type ==pc {
        padding-top: 16px 0;
    }
}

ul.anchorListSmall {

    @if $type =="" {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    @if $type ==sp {
        @include maxSP;
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    @if $type ==pc {
        width: calc(100% - 80px);
        max-width: 1200px;
        padding: 0 0 16px;
        margin: 0 auto;

        &.col3 {
            >li {
                margin: 0 0 0;
                width: 33%;
            }
        }
    }



    >li {
        @if $type =="" {}

        @if $type ==sp {
            margin: 0;
            width: 100%;
            text-align: left;

            +li {
                border-top: 1px solid #cecece;
            }
        }

        @if $type ==pc {
            margin: 0 0 0;
            width: 24.8%;

            &:not(:nth-child(4n)) {
                margin-right: 0.26%;
            }

            &:nth-child(n + 5) {
                margin-top: 4px;
            }
        }



        >a {
            @if $type =="" {
                display: flex;
                height: 100%;
                align-items: center;
                color: $c_navy;
                position: relative;
                text-decoration: none;
                background: #f2f2f2;
            }

            @if $type ==sp {
                padding: 8px;
                padding-right: 38px;
            }

            @if $type ==pc {
                justify-content: center;
                min-height: 60px;
                padding: 8px 11px 8px 11px;
                font-size: 16px;
                transition: .2s color;
                line-height: 1.375;

                &:hover {
                    color: $c_base;

                    &:after {
                        background-image: url(../images/common/icon_dash_pink.svg);
                    }
                }
            }

            &:after {
                @if $type =="" {
                    content: "";
                    display: inline-block;
                    transform: rotate(90deg);
                    flex: none;
                    background: url(../images/common/icon_dash_navy.svg) no-repeat center;
                    background-size: contain;
                    margin: auto;
                    overflow: hidden;
                }

                @if $type ==sp {
                    width: 9px;
                    height: 9px;
                    flex: none;
                    margin: auto 14px auto 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0px;
                }

                @if $type ==pc {
                    width: 9px;
                    height: 9px;
                    margin: auto 0 auto 11px;
                    transition: .2s opacity;
                }
            }

        }
    }
}

*+ul.anchorListSmall {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 35px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

.leadBlock+ul.anchorListSmall,
.pageHeader+ul.anchorListSmall {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 35px !important;
    }

    @if $type ==pc {
        margin-top: 97px !important;
    }
}


.inner ul.anchorListSmall {
    @if $type ==sp {
        width: 100%;
    }

    @if $type ==pc {
        width: 100%;
    }
}

ol.anchorListSmall {
    @if $type =="" {
        counter-reset: item;
    }

    @if $type ==sp {
        @include maxSP;
        margin: 0 auto;
    }

    @if $type ==pc {
        margin: 0 auto;
    }

    >li {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            padding-left: 18px;
            margin: 0;
        }

        @if $type ==pc {
            padding-left: 24px;
            margin: 0;
        }

        &:before {
            @if $type =="" {
                counter-increment: item;
                content: counter(item)'.';
                position: absolute;
                left: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {
                top: 0;
            }

        }

        >a {
            @if $type =="" {
                display: inline-block;
                position: relative;
                text-decoration: none;
            }

            @if $type ==sp {}

            @if $type ==pc {
                transition: .2s color;

                &:hover {
                    color: #0c77ae;
                }
            }

        }
    }
}

.productLinkList,
.productLinkListFull {
    @if $type =="" {}

    @if $type ==sp {
        //margin-bottom: 54px;
    }

    @if $type ==pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        //margin-bottom: 105px;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {

            &:nth-child(n + 2) {
                margin-top: 30px;
            }

            h3.title {
                margin-bottom: 15px;
            }
        }

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            width: 49%;

            &:nth-child(n + 3) {
                margin-top: 4.5%;
            }
        }

        >div,
        >a {
            @if $type =="" {
                color: #000;
                text-decoration: none;
                box-shadow: 0 0px 16px rgba(42, 42, 42, 0.1);
            }

            @if $type ==sp {
                display: block;
            }

            @if $type ==pc {
                display: flex;
                justify-content: space-between;
                height: 100%;
                //align-items: center;
            }

            .productLinkListText {
                @if $type =="" {}

                @if $type ==sp {
                    margin-top: 15px;
                }

                @if $type ==pc {
                    width: 46.5%;
                    padding: 36px 0 26px;
                }

                .productLinkListHeader {
                    @if $type =="" {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        position: relative;
                    }

                    @if $type ==sp {}

                    @if $type ==pc {}


                    .productLinkListTitle {
                        @if $type =="" {
                            position: relative;
                        }

                        @if $type ==sp {
                            font-size: 16px;
                            line-height: 1.35;
                        }

                        @if $type ==pc {
                            font-size: 18px;
                            line-height: 1.4;
                        }
                    }

                    .productLinkListTitleSub {
                        @if $type =="" {
                            color: #888888;
                        }

                        @if $type ==sp {
                            margin-top: 5px;
                            font-size: 13px;
                            line-height: 1.35;
                        }

                        @if $type ==pc {
                            margin-top: 5px;
                            font-size: 14px;
                            line-height: 1.4;
                        }
                    }
                }

                .productLinkListNote {
                    @if $type =="" {
                        color: #000;
                    }

                    @if $type ==sp {
                        margin-top: 9px;
                        font-size: 13px;
                        line-height: 1.69;
                    }

                    @if $type ==pc {
                        margin-top: 6px;
                        font-size: 14px;
                        line-height: 2;
                    }
                }
            }
        }

        >a {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                &:hover {

                    .productLinkListThumb {
                        >img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .productLinkListText {
                .productLinkListHeader {
                    .productLinkListTitle {
                        @if $type ==sp {
                            padding-right: 12px;
                        }

                        @if $type ==pc {
                            padding-right: 15px;
                        }

                    }
                }
            }
        }

        .productLinkListThumb {
            @if $type =="" {
                position: relative;
            }

            @if $type ==sp {
                text-align: center;

                >img {
                    width: 100%;
                }
            }

            @if $type ==pc {
                display: flex;
                align-items: center;
                margin: 15px 0 15px 15px;
                width: 44.28%;
                overflow: hidden;

                >img {
                    display: block;

                    transition: .2s transform;
                }
            }

            >ul {
                @if $type =="" {
                    display: flex;
                    flex-wrap: wrap;
                    position: absolute;
                }

                @if $type ==sp {
                    top: 10px;
                    right: 10px;
                }

                @if $type ==pc {
                    top: 12px;
                    right: 0;
                }

                >li {
                    @if $type =="" {
                        margin-left: 7px;
                        margin-bottom: 7px;
                    }

                    @if $type ==sp {
                        height: 30px;
                    }

                    @if $type ==pc {}
                }
            }
        }

        .productLinkListText {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 15px;
                padding: 0 15px 15px;
            }

            @if $type ==pc {
                width: 49.3%;
                margin: 0 auto;
            }

            .productLinkListHeader {
                @if $type =="" {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                }

                @if $type ==sp {}

                @if $type ==pc {}



                .productLinkListTitle {
                    @if $type =="" {
                        font-weight: bold;
                        position: relative;
                    }

                    @if $type ==sp {
                        font-size: 16px;
                        line-height: 1.35;
                    }

                    @if $type ==pc {
                        font-size: 18px;
                        line-height: 1.4;
                    }
                }

                .productLinkListTitleSub {
                    @if $type =="" {
                        color: #888888;
                    }

                    @if $type ==sp {
                        margin-top: 5px;
                        font-size: 13px;
                        line-height: 1.35;
                    }

                    @if $type ==pc {
                        margin-top: 5px;
                        font-size: 14px;
                        line-height: 1.4;
                    }
                }
            }

            .productLinkListNote {
                @if $type =="" {
                    color: #000;
                }

                @if $type ==sp {
                    margin-top: 9px;
                    font-size: 13px;
                    line-height: 1.69;
                }

                @if $type ==pc {
                    margin-top: 18px;
                    font-size: 14px;
                    line-height: 2;
                }
            }
        }

    }
}

.productLinkListFull {
    @if $type =="" {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @if $type ==sp {
        //margin-bottom: 54px;
    }

    @if $type ==pc {
        //margin-bottom: 105px;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {
            width: 100%;

            &:nth-child(n + 2) {
                margin-top: 4.48%;
            }
        }

        @if $type ==pc {
            width: 100%;

            &:nth-child(n + 2) {
                margin-top: 4.5%;
            }
        }

        .productLinkListThumb {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                width: 48.7%;
            }
        }
    }
}

.lineList {
    @if $type =="" {}

    @if $type ==sp {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 97px;
    }

    >li {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;

            &:nth-child(n + 3) {
                margin-top: 50px;
            }

            &:nth-child(odd) {
                padding-right: 15px;
            }

            &:nth-child(even) {
                padding-left: 15px;
            }
        }

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 20%;
            padding: 0 15px;

            &:nth-child(n + 6) {
                margin-top: 90px;
            }
        }

        &:before,
        &:after {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                content: "";
                display: block;
                width: 1px;
                height: calc(100% - 50px);
                background: #bcbcbc;
                position: absolute;
                top: 0;
            }
        }

        &:before {
            @if $type ==pc {
                display: none;
                left: 0;
            }
        }

        &:after {
            @if $type ==pc {
                right: 0;
            }
        }

        &:nth-child(odd):before {
            @if $type ==sp {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background: #bcbcbc;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &:nth-child(1):before,
        &:nth-child(6n):before {
            @if $type ==pc {
                display: block;
            }
        }

        .lineListContent {
            @if $type =="" {
                line-height: 1.286;
            }

            @if $type ==sp {
                width: 100%;
                max-width: 155px;
                padding: 0;
                margin: 0 auto 15px;
            }

            @if $type ==pc {
                max-width: 155px;
                padding: 0;
                margin: 0 auto 25px;
            }

            figure {
                @if $type =="" {
                    display: block;
                }

                @if $type ==sp {
                    margin-bottom: 14px;
                }

                @if $type ==pc {
                    margin-bottom: 14px;
                }
            }
        }

        .btnWrap {
            @if $type =="" {
                margin-top: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            a {
                @if $type =="" {}

                @if $type ==sp {
                    display: block;
                    max-width: 175px;
                    margin: 0 auto;
                }

                @if $type ==pc {
                    display: block;
                    max-width: 175px;
                    margin: 0 auto;
                }
            }
        }

    }

}

.postContent *+ul:not([class]),
*+ul.commonList,
*+ol.commonList
{
@if $type =="" {}

@if $type ==sp {
    margin-top: 20px;
}

@if $type ==pc {
    margin-top: 20px;
}
}

.postContent ul:not([class]),
ul.commonList {
    @if $type =="" {
        text-align: left;
    }

    @if $type ==sp {

        &.mutual_sp,
        &.mutual {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            >li {
                width: calc(50% - 20px);

                &:nth-child(odd) {
                    margin-right: 10px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }
            }
        }
    }

    @if $type ==pc {

        &.mutual_pc,
        &.mutual {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            >li {
                width: calc(50% - 20px);

                &:nth-child(odd) {
                    margin-right: 10px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }
            }


            &.mutual_pc,
            &.mutual {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                >li {
                    width: calc(50% - 20px);

                    &:nth-child(odd) {
                        margin-right: 10px;
                    }

                    &:nth-child(even) {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    >li {
        @if $type =="" {
            padding-left: 1em;
            position: relative;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #333d51;
                position: absolute;
            }

            @if $type ==sp {
                top: 10px;
                margin: auto;
                left: 0;
            }

            @if $type ==pc {
                top: 12px;
                margin: auto;
                left: 0;
            }

        }

        a {
            @if $type =="" {
                display: inline-block;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }
    }
}

.postContent ul:not([class]) {
    >li {
        &:before {
            @if $type ==sp {
                top: 10px;
            }

            @if $type ==pc {
                top: 14px;
            }
        }
    }
}

ol.commonList {
    @if $type =="" {
        counter-reset: number 0;
    }

    @if $type ==pc {

        &.mutual_pc,
        &.mutual {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            >li {
                width: calc(50% - 20px);

                &:nth-child(odd) {
                    margin-right: 10px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }

                &:nth-of-type(2) {
                    margin-top: 0 !important;
                }
            }
        }
    }

    >li {
        @if $type =="" {
            padding-left: 1.5em;
            position: relative;
        }

        @if $type ==sp {
            position: relative;
        }

        @if $type ==pc {}

        +li {
            @if $type ==sp {
                margin-top: 7px;
            }

            @if $type ==pc {
                margin-top: 7px;
            }
        }

        &:before {
            @if $type =="" {
                counter-increment: number 1;
                content: "0" counter(number) ".";
                display: flex;
                align-items: center;
                justify-content: center;
                @include fontRo;
                font-weight: bold;
                color: $c_base;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }

        &:nth-child(n + 10) {
            &:before {
                @if $type =="" {
                    content: counter(number) ".";
                }
            }
        }

    }
}

.iframeWrap {
    @if $type =="" {
        width: 100%;
        margin-top: 1.5em;
        position: relative;
    }

    @if $type ==sp {
        padding-bottom: 56.25%;
    }

    @if $type ==pc {
        padding-bottom: 37.25%;
    }

    iframe {
        @if $type =="" {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

*+.ytWrap {
    @if $type ==sp {
        margin-top: 2em;
    }

    >div {
        @if $type =="" {
            margin-top: 24px;
        }
    }
}

.ytWrap {
    @if $type =="" {
        width: 100%;
        margin: 0 auto 0;
        position: relative;
    }

    @if $type ==sp {
        max-width: 560px;
    }

    >div {
        @if $type =="" {
            width: 100%;
            position: relative;
            padding-bottom: 56.25%;
        }
    }

    iframe {
        @if $type =="" {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

}

.ytTitle {
    @if $type =="" {
        text-align: left;
        line-height: 1.4;
    }

    @if $type ==sp {
        margin-top: 10px;
        padding-left: 15px;
    }

    @if $type ==pc {
        margin-top: 22px;
        margin-left: 18px;
    }

    +* {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 10px;
        }

        @if $type ==pc {
            margin-top: 34px;
        }
    }

    .iconDashNavy {
        @if $type =="" {}

        @if $type ==sp {
            margin-left: 0;
            margin-right: 7px;
        }

        @if $type ==pc {
            margin-left: 0;
            margin-right: 12px;
        }
    }
}

#sectionAdd {
    .sectionHeader {
        @if $type ==pc {
            margin-bottom: 20px;
        }
    }

    .inner {
        @if $type ==pc {
            max-width: 1080px;
        }

        .imgLine3 {
            @if $type =="" {
                margin-bottom: 0;
            }
        }
    }

}

.moreList {
    @if $type =="" {}

    @if $type ==sp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
    }

    >li {
        @if $type =="" {
            background: #fff;
        }

        @if $type ==sp {
            width: calc(50% - 10px);

            &:nth-child(n + 3) {
                margin-top: 20px;
            }
        }

        @if $type ==pc {
            width: 23.5%;

            &:not(:nth-child(4n)) {
                margin-right: 2%;
            }

            &:nth-child(n + 5) {
                margin-top: 2%;
            }
        }

        a {
            @if $type =="" {
                display: block;
                color: #000;
                text-decoration: none;
            }

            @if $type ==sp {
                display: block;
                height: 100%;
                position: relative;
                overflow: hidden;
            }

            @if $type ==pc {
                display: block;
                height: 100%;
                position: relative;
                overflow: hidden;

                &:hover {
                    .moreListThumb {
                        transform: scale(1.1);
                    }
                }
            }

            .moreListThumb {
                @if $type =="" {}

                @if $type ==sp {
                    width: calc(100% + 2px);
                    margin: auto;
                    transform: translateX(-1px);

                    img {
                        object-fit: cover;
                        width: inherit;
                    }
                }

                @if $type ==pc {
                    width: 100%;
                    height: 145px;
                    margin: auto;
                    transition: .3s transform;


                    img {
                        max-width: inherit;
                        width: 100%;
                        height: 145px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .moreListItemText {
                @if $type =="" {}

                @if $type ==sp {
                    padding: 8px 10px 41px;
                    position: relative;
                    background: #fff;
                }

                @if $type ==pc {
                    height: calc(100% - 145px);
                    padding: 14px 18px 34px 22px;
                    position: relative;
                    background: #fff;
                }

                .moreListItemNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        font-size: 13px;
                        line-height: 1.3846;
                    }

                    @if $type ==pc {
                        font-size: 14px;
                    }
                }

                .moreListItemMore {
                    @if $type =="" {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        @include fontRo;
                        font-weight: bold;
                        color: $c_base;
                        position: absolute;


                        &:before {
                            content: "";
                            display: inline-block;
                            width: 16px;
                            height: 1px;
                            margin-right: 5px;
                            background: $c_base;
                        }
                    }

                    @if $type ==sp {
                        margin-top: auto;
                        font-size: 12px;
                        right: 10px;
                        bottom: 7px;
                    }

                    @if $type ==pc {
                        margin-top: auto;
                        font-size: 12px;
                        right: 18px;
                        bottom: 8px;
                    }
                }

            }

        }
    }
}

*:not(.title)+.innerBody .qaList:first-child,
*:not(h2.title):not(.titleBlock)+.qaList {
    @if $type ==sp {
        margin-top: 20px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

.qaList {
    @if $type =="" {
        border-bottom: 1px solid #b8b8b8;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    >dt {
        @if $type =="" {
            position: relative;
            cursor: pointer;

            &:not(:first-child) {
                border-top: 1px solid #b8b8b8;
            }
        }

        @if $type ==sp {
            padding: 14px 55px 14px 40px;
        }

        @if $type ==pc {
            padding: 35px 94px 35px 94px;

            .qaQuestion {
                transition: .2s color;
            }

            &:hover {
                .qaQuestion {
                    color: $c_base;
                }
            }
        }


        &:before {
            @if $type =="" {
                content: "Q";
                display: block;
                color: #fff;
                text-align: center;
                @include fontRo;
                background: $c_base;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 24px;
                height: 24px;
                font-size: 18px;
                line-height: 24px;
                left: 0;
            }

            @if $type ==pc {
                width: 37px;
                height: 37px;
                font-size: 20px;
                line-height: 37px;
                left: 26px;
            }
        }

        .qaQuestion {
            @if $type =="" {
                position: relative;
            }

            @if $type ==sp {}

            &:before,
            &:after {
                @if $type =="" {
                    content: "";
                    display: block;
                    height: 2px;
                    background: $c_base;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: .2s transform;
                }

                @if $type ==sp {
                    width: 17px;
                    right: -39px;
                }

                @if $type ==pc {
                    width: 21px;
                    right: -90px;
                }
            }

            &:after {
                @if $type =="" {
                    transform: rotate(90deg);
                }

                @if $type ==sp {}
            }
        }

        &.open {
            .qaQuestion {
                &:after {
                    @if $type =="" {
                        transform: rotate(0);
                    }

                    @if $type ==sp {}
                }
            }
        }
    }

    >dd {
        @if $type =="" {
            display: none;
            position: relative;
        }

        @if $type ==sp {
            padding: 0 0 0 40px;
            margin-bottom: 14px;
        }

        @if $type ==pc {
            padding: 0
                /*159px*/
                0 0 94px;
            margin-bottom: 28px;
        }

        &:before {
            @if $type =="" {
                content: "A";
                display: block;
                text-align: center;
                color: #fff;
                @include fontRo;
                background: $c_navy;
                position: absolute;
                top: 0;
                opacity: 0;
                transition: .2s .2s opacity;
            }

            @if $type ==sp {
                width: 24px;
                height: 24px;
                font-size: 18px;
                line-height: 24px;
                left: 0;
            }

            @if $type ==pc {
                width: 37px;
                height: 37px;
                font-size: 20px;
                line-height: 37px;
                left: 26px;
            }
        }

        .qaAnswer {
            @if $type =="" {
                background: #f6f6f6;
            }

            @if $type ==sp {
                padding: 12px 25px;
            }

            @if $type ==pc {
                padding: 34px 24px;

                p,
                div {
                    line-height: 2.14;
                }
            }
        }

        &.open {
            &:before {
                @if $type =="" {
                    opacity: 1;
                }
            }
        }
    }
}

.checkList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    >li {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            padding-left: 30px;

            &:nth-child(n + 2) {
                margin-top: 8px;
            }
        }

        @if $type ==pc {
            width: 48%;
            padding-left: 30px;

            &:nth-child(n + 3) {
                margin-top: 8px;
            }
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                background: url(../images/common/icon_check.svg) no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                top: .35em;
            }

            @if $type ==sp {
                width: 21px;
                height: 18px;
            }

            @if $type ==pc {
                width: 21px;
                height: 18px;
            }
        }
    }
}

*:not(.title)+.innerBody .scrollTable:first-child,
*+.scrollTable {
    @if $type ==sp {
        margin-top: 1.5em;
    }
}

.scrollTable {
    overflow: auto;


    .commonTable {
        @if $type ==sp {
            width: 100%;
            min-width: 1000px;
            white-space: nowrap;
        }
    }
}

.scrolltext {
    @if $type ==sp {
        display: block;
        font-size: 12px;
        text-align: right;
    }

    @if $type ==pc {
        display: none !important;
    }
}

.scrollTable .commonTable {
    @if $type ==sp {
        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
}

.postContent table:not(.hanging):not(.notEqual),
.commonTable:not(.notEqual) {

    >tbody>tr,
    >thead>tr {
        >th {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                width: 14%;
            }

            &+th+td {
                @if $type ==pc {
                    width: 72%;
                }
            }

        }
    }
}

.postContent table:not(.hanging):not(.notEqual),
.commonTable:not(.notEqual) {

    >tbody>tr,
    >thead>tr {
        >th {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                width: 14%;
            }

            &+th+td {
                @if $type ==pc {
                    width: 72%;
                }
            }
        }
    }
}

.postContent table:not(.hanging),
.commonTable {
    @if $type =="" {
        width: 100%;
        border-collapse: collapse;
        border-spacing: inherit;
        margin-top: 1.5em;
        text-align: center;
        word-break: break-all;
        border-bottom: 1px solid #b8b8b8;
    }

    @if $type ==sp {}

    @if $type ==pc {
        margin: 1.5em auto 0;
    }

    &.small {

        >tbody>tr>th,
        >thead>tr>th,
        >tr>th,
        >tbody>tr>td,
        >thead>tr>td,
        >tr>td {
            @if $type =="" {
                text-align: center;
            }

            @if $type ==sp {
                padding: 3px;
            }

            @if $type ==pc {
                padding: 5px;
            }
        }

    }

    &.thNowrap {

        >tbody>tr>th,
        >thead>tr>th,
        >tr>th {
            @if $type ==sp {
                white-space: nowrap;
            }
        }
    }

    &.tdC {

        >tbody>tr,
        >thead>tr {
            >td {
                @if $type =="" {
                    text-align: center;
                }
            }
        }
    }

    &:not(.skeletonTable) {
        @if $type =="" {
            border-top: 1px solid #b8b8b8;
        }

        @if $type ==sp {}

        @if $type ==pc {}
    }

    &.skeletonTable {
        @if $type =="" {
            table-layout: fixed;
        }

        >tbody>tr>th:nth-of-type(1),
        >thead>tr>th:nth-of-type(1),
        >tr>th:nth-of-type(1) {
            @if $type =="" {
                width: 130px !important;
            }
        }

        >tbody>tr:not(.skeleton),
        >thead>tr:not(.skeleton),
        >tr:not(.skeleton) {
            @if $type =="" {
                border-top: 1px solid #b8b8b8;
            }
        }

        >tbody>tr>td,
        >thead>tr>td,
        >tr>td {
            @if $type =="" {
                vertical-align: top;
            }
        }
    }

    >tbody>tr.skeleton th,
    >tbody>tr.skeleton td,
    >thead>tr.skeleton th,
    >thead>tr.skeleton td,
    >tr.skeleton th,
    >tr.skeleton td {
        @if $type =="" {
            background: none;
            border: none;
            text-align: center;
            padding-top: 0;
            padding-bottom: 25px;
        }
    }



    >tbody>tr>*:first-child,
    >thead>tr>*:first-child,
    >tr>*:first-child {
        @if $type =="" {
            border-left: 1px solid #b8b8b8;
        }
    }

    >tbody>tr>*,
    >thead>tr>*,
    >tr>* {
        @if $type =="" {
            border-right: 1px solid #b8b8b8;
        }
    }


    >tbody>tr:not(:first-child)>*,
    >thead>tr:not(:first-child)>*,
    >tr:not(:first-child)>* {
        @if $type =="" {
            border-top: 1px solid #b8b8b8;
        }
    }

    &.bgPurple {

        >tbody>tr,
        >tbody>tr>th,
        >thead>tr,
        >thead>tr>th {
            @if $type =="" {
                background: #ececf4 !important;
            }
        }
    }

    &.bgPink {

        >tbody>tr,
        >tbody>tr>th,
        >thead>tr,
        >thead>tr>th {
            @if $type =="" {
                background: #fcf2f6 !important;
            }
        }
    }


    &.tdTaC {

        >tbody>tr>td,
        >thead>tr>td {
            @if $type =="" {
                text-align: center;
            }
        }
    }

    th.bgPurple,
    td.bgPurple {
        @if $type =="" {
            background: #ececf4 !important;
        }
    }

    th.bgPink,
    td.bgPink {
        @if $type =="" {
            background: #f9e6ed !important;
        }
    }


    th.bgGreen,
    td.bgGreen {
        @if $type =="" {
            background: #def3df !important;
        }
    }

    th.bgSky,
    td.bgSky {
        @if $type =="" {
            background: #cfeaf6 !important;
        }
    }


    th.bgWhite,
    td.bgWhite {
        @if $type =="" {
            background: #fff !important;
        }
    }

    th.nowrap,
    td.nowrap {
        @if $type =="" {
            white-space: nowrap;
        }
    }

    >tbody>tr,
    >thead>tr {

        >th,
        >td {
            @if $type =="" {
                white-space: normal;
            }

            &.w115 {
                @if $type =="" {
                    width: 115px !important;
                }
            }

            &.w115_sp {
                @if $type ==sp {
                    width: 115px !important;
                }
            }

            &.w115_pc {
                @if $type ==pc {
                    width: 115px !important;
                }
            }

            &.w165 {
                @if $type =="" {
                    width: 165px !important;
                }
            }

            &.w165_sp {
                @if $type ==sp {
                    width: 165px !important;
                }
            }

            &.w165_pc {
                @if $type ==pc {
                    width: 165px !important;
                }
            }

            &.w120 {
                @if $type =="" {
                    width: 120px !important;
                }
            }

            &.w150 {
                @if $type =="" {
                    width: 150px !important;
                }
            }

            &.w300 {
                @if $type =="" {
                    width: 300px !important;
                }
            }
        }
    }

    >tbody>tr,
    >thead>tr {
        >th {
            @if $type =="" {
                background-color: #ebf0f4;
                padding: 12px;
                font-weight: 400;

            }

            @if $type ==sp {
                padding: 5px 10px;
                font-size: 14px;
            }

            @if $type ==pc {
                padding: 13px 15px 12px;
                font-size: 18px;
            }

            &+th+td {
                @if $type ==pc {}
            }

        }
    }

    >tbody>tr,
    >thead>tr {
        >td {
            @if $type =="" {
                text-align: left;
                background-color: #fff;
            }

            *:last-child {
                @if $type =="" {
                    margin-bottom: 0;
                }
            }

            *:first-child {
                @if $type =="" {
                    margin-top: 0;
                }
            }

            @if $type ==sp {
                padding: 10px 10px;
            }

            @if $type ==pc {
                padding: 13px 15px 12px;
            }

        }
    }
}


*:not(.title)+.innerBody .borderBox:first-child,
*+.borderBox {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 20px;
    }

    @if $type ==pc {
        margin-top: 30px;
    }
}

.borderBox {
    @if $type =="" {
        border: 1px solid #b8b8b8;
        background: #fff;
    }

    @if $type ==sp {
        padding: 35px 22px 40px;
    }

    @if $type ==pc {
        padding: 50px 60px;
    }

    &.bgLogo {
        @if $type =="" {
            background: url(/images/company/csr/idea_bg.png) center no-repeat;
        }

        @if $type ==sp {
            background-size: 100% auto;
        }

        @if $type ==pc {
            background-size: 60% auto;
        }
    }

    &.small {
        @if $type ==sp {
            padding: 15px 20px 15px;
        }

        @if $type ==pc {
            padding: 20px 45px;
        }
    }
}

p+.colorBoxSky,
p+.colorBoxPurple,
p+.colorBoxPink,
p+.colorBox {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 40px;
    }
}

.colorBoxSky>*:last-child,
.colorBoxPurple>*:last-child,
.colorBoxPink>*:last-child,
.colorBoxPrimary>*:last-child,
.colorBox>*:last-child {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-bottom: 0;
    }
}

.colorBoxSky,
.colorBoxPurple,
.colorBoxPink,
.colorBoxPrimary,
.colorBox {
    @if $type =="" {}

    @if $type ==sp {
        padding: 35px 22px 40px;
    }

    @if $type ==pc {
        padding: 60px 60px;
    }

    .boxTitle {
        @if $type =="" {
            color: $c_navy;
        }

        @if $type ==sp {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 1.4;
        }

        @if $type ==pc {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 1.4;
        }
    }

    &.small {
        @if $type ==sp {
            padding: 15px 20px 15px;
        }

        @if $type ==pc {
            padding: 25px 45px;
        }
    }

    >p:not(.colorBoxTitle):not(.boxTitle) {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            font-size: 16px;
        }
    }

    .twoCol {
        @if $type =="" {
            margin-top: 0;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        .txt {
            @if $type =="" {}

            @if $type ==sp {
                margin-bottom: 18px;
                font-size: 13px;
                line-height: 1.69;
            }

            @if $type ==pc {
                width: 46.3277%;
            }
        }

        .img {
            @if $type =="" {
                text-align: center;
            }

            @if $type ==sp {
                padding-right: 0;
                padding-left: 0;
            }

            @if $type ==pc {
                width: 50.8475%;
            }
        }

        .txt+.img {
            @if $type ==sp {
                margin-top: 1.5em;
                margin-bottom: 0;
            }
        }
    }

    .boxTitle {
        @if $type =="" {}

        @if $type ==sp {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 1.4;
        }

        @if $type ==pc {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 1.4;
        }
    }
}

.colorBox {
    @if $type =="" {
        background: #f2f2f2;
    }
}

.colorBoxPink {
    @if $type =="" {
        background: #fcf2f6;
    }
}

.colorBoxPurple {
    @if $type =="" {
        background: #ececf4;
    }
}

.colorBoxSky {
    @if $type =="" {
        background: #ebf0f4;
    }
}

.colorBoxPrimary {
    @if $type ==sp {
        max-width: 700px;
        margin: 0 auto;
    }

    @if $type ==pc {
        max-width: 920px;
        padding-top: 60px;
        padding-bottom: 100px;
        margin: 0 auto;
    }

    +.anchorListSmall {
        @if $type ==sp {
            margin-top: 0;
        }

        @if $type ==pc {
            margin-top: 0;
            max-width: 1000px;
        }
    }
}

.colorBoxTitle {
    @if $type =="" {
        color: #242e44;
        font-weight: 400;
        line-height: 1.4;
    }

    @if $type ==sp {
        margin-bottom: 24px;
        font-size: 18px;
        text-align: center;
    }

    @if $type ==pc {
        margin-top: 18px;
        margin-bottom: 36px;
        font-size: 30px;
        text-align: center;
    }
}

*:not(.title)+.innerBody .colorBox:first-child,
*+.colorBox,
*:not(.title)+.innerBody .colorBoxPink:first-child,
*+.colorBoxPink,
*:not(.title)+.innerBody .colorBoxPurple:first-child,
*+.colorBoxPurple {
    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

[class^="imgLine"].rowSP {
    @if $type =="" {}

    @if $type ==sp {
        flex-direction: column;

        >li {
            width: 100% !important;

            +li {
                margin-top: 30px !important;
            }
        }
    }

    @if $type ==pc {}
}

.imgLine2 {
    @if $type ==sp {
        margin-top: 45px;

        &:not(.readContent) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            >li {
                width: calc(50% - 16px);

                &:nth-child(n + 3) {
                    margin-top: 30px;
                }
            }
        }

        &.readContent {
            >li {
                width: 100%;

                .cap {
                    text-align: center;
                }

                +li {
                    margin-top: 30px;
                }
            }
        }

    }

    @if $type ==pc {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 892px;
        margin: 60px auto;
    }

    >li {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            .imgNote {
                line-height: 1.4;
            }
        }

        @if $type ==pc {
            width: calc(50% - 20px);
            margin-right: 10px;
            margin-left: 10px;

            &:nth-child(n + 3) {
                margin-top: 20px;
            }
        }

        img {
            @if $type =="" {
                max-width: 100%;
            }
        }

    }

    &.small li {
        @if $type ==pc {
            width: calc(33% - 8px);
        }
    }

    .cap {
        @if $type =="" {
            display: block;
        }

        @if $type ==sp {
            margin-top: 8px;
            text-align: left;
            //font-size: 12px;
            line-height: 1.5;
        }

        @if $type ==pc {
            margin-top: 10px;
            text-align: left;
            //font-size: 14px;
        }
    }
}


.imgLine2Full {
    @if $type ==sp {
        &:not(.readContent) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            >li {
                width: calc(50% - 16px);

                &:nth-child(n + 3) {
                    margin-top: 30px;
                }

            }
        }

        &.readContent {
            >li {
                width: 100%;

                .cap {
                    text-align: center;
                }

                +li {
                    margin-top: 30px;
                }
            }
        }
    }

    @if $type ==pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto 0;
    }

    >li {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            .imgNote {
                line-height: 1.4;
            }
        }

        @if $type ==pc {
            width: calc(50% - 18px);

            &:nth-child(n + 3) {
                margin-top: 60px;
            }
        }

        img {
            @if $type =="" {
                max-width: 100%;
            }
        }
    }

    &.small li {
        @if $type ==pc {
            width: calc(33% - 8px);
        }

        .cap {
            @if $type ==sp {}

            @if $type ==pc {
                //font-size: 13px;
            }
        }
    }

    .cap {
        @if $type =="" {
            display: block;
        }

        @if $type ==sp {
            margin-top: 8px;
            text-align: left;
            line-height: 1.5;
            //font-size: 12px;
        }

        @if $type ==pc {
            margin-top: 10px;
            text-align: left;
            //font-size: 14px;
        }
    }
}

.imgLine1Arrow {
    @if $type ==pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):not(:nth-child(2n)):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 13px 0 13px;
                    border-color: $c_navy transparent transparent transparent;
                    bottom: -24px;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li:not(:nth-child(1)) {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 13px 13px 0 13px;
                        border-color: $c_navy transparent transparent transparent;
                        bottom: -24px;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }
                }
            }
        }

        >li {
            width: 100%;
            position: relative;

            &:nth-child(n + 2) {
                margin-top: 36px;
            }

        }
    }
}

.imgLine2Arrow {
    @if $type ==pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):not(:nth-child(2n)):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 0 13px 13px;
                    border-color: transparent transparent transparent $c_navy;
                    top: 0;
                    bottom: 0;
                    right: -4.9%;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li:not(:nth-child(2n)) {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 21px 0 21px 13px;
                        border-color: transparent transparent transparent $c_navy;
                        top: 0;
                        bottom: 0;
                        right: -4.9%;
                        margin: auto;
                    }
                }
            }

            &.imgArrowEquilateral {
                >li:not(:nth-child(2n)) {
                    figure {
                        display: block;
                        position: relative;
    
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            position: absolute;
                            border-style: solid;
                            border-width: 13px 0 13px 13px;
                            border-color: transparent transparent transparent $c_navy;
                            top: 0;
                            bottom: 0;
                            right: -4.9%;
                            margin: auto;
                        }
                    }
                }
            }
        }

        >li {
            width: calc(50% - 18px);
            position: relative;

            &:nth-child(n + 3) {
                margin-top: 36px;
            }

        }
    }
}


*:not(.title)+.innerBody .imgLine2Full:first-child,
*+.imgLine2Full {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 40px;
    }
}

[class^="imgLine3"] {
    @if $type =="" {
        width: 100%;
        list-style-type: none;
    }

    @if $type ==sp {
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        .imgNote {
            line-height: 1.4;
        }
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.5em;
        margin-bottom: 24px;
    }

    p {
        @if $type =="" {
            text-align: left;
            line-height: 2;
        }

        @if $type ==sp {
            margin-bottom: 15px;
        }

        @if $type ==pc {
            margin-top: 23px;
            // font-size: 14px;
        }
    }

    .link {
        @if $type =="" {
            justify-content: flex-start;
        }
    }

    >li {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            width: 100%;
            margin-top: 20px;

            &:nth-child(2) {
                margin-top: 0;
            }

            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }

        @if $type ==pc {
            width: 31.6%;

            &:not(:nth-child(3n)) {
                margin-right: 2.51%;
            }

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            &:nth-child(n + 4) {
                margin-top: 2.4167%;
            }
        }

        img {
            @if $type =="" {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }

    .cap {
        @if $type =="" {
            display: block;
            text-align: left;
            //font-size: 14px;
            margin-top: 4px;
        }
    }

    p {
        @if $type =="" {
            text-align: left;
        }
    }
}

.imgLine3Arrow {
    @if $type ==pc {
        >li {
            position: relative;
        }

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):not(:nth-child(3n)):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 0 13px 13px;
                    border-color: transparent transparent transparent $c_navy;
                    top: 0;
                    bottom: 0;
                    right: -5.65%;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li:not(:nth-child(3n)) {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 21px 0 21px 13px;
                        border-color: transparent transparent transparent $c_navy;
                        top: 0;
                        bottom: 0;
                        right: -5.65%;
                        margin: auto;
                    }
                }
            }

            &.imgArrowEquilateral {
                &.imgArrow {
                    >li:not(:nth-child(3n)) {
                        figure {
                            display: block;
                            position: relative;
        
                            &:before {
                                content: "";
                                width: 0;
                                height: 0;
                                position: absolute;
                                border-style: solid;
                                border-width: 13px 0 13px 13px;
                                border-color: transparent transparent transparent $c_navy;
                                top: 0;
                                bottom: 0;
                                right: -5.65%;
                                margin: auto;
                            }
                        }
                    }
                }
            }

        }
    }
}

//*:not(.title) + .innerBody .flowList:first-child,
*:not(.title)+.innerBody [class^="imgLine4"]:first-child,
//* + .flowList,
*+[class^="imgLine4"] {
    @if $type =="" {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

//.flowList,
[class^="imgLine4"] {
    @if $type =="" {
        list-style-type: none;
    }

    @if $type ==sp {
        display: flex;
        flex-wrap: wrap;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        @if $type ==sp {
            width: calc(50% - 8px);
            margin-right: 16px;

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            &:nth-of-type(n + 3) {
                margin-top: 30px;
            }

        }

        @if $type ==pc {
            width: 23%;

            &:not(:nth-child(4n)) {
                margin-right: 2.5%;
            }

            &:nth-of-type(n + 5) {
                margin-top: 30px;
            }
        }

        img {
            @if $type =="" {
                width: 100%;
            }
        }

        .icon_plug {
            @if $type =="" {
                display: block;
                margin: 0 auto;
                text-align: center;
            }
        }

        .icon_plug>img {
            @if $type =="" {
                width: inherit;
                max-width: 100%;
            }
        }

    }
}

.imgLine4Arrow {
    @if $type ==pc {

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):not(:nth-child(4n)):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 0 13px 13px;
                    border-color: transparent transparent transparent $c_navy;
                    top: 0;
                    bottom: 0;
                    right: -7.65%;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li:not(:nth-child(4n)) {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 21px 0 21px 13px;
                        border-color: transparent transparent transparent $c_navy;
                        top: 0;
                        bottom: 0;
                        right: -7.65%;
                        margin: auto;
                    }
                }
            }

            &.imgArrowEquilateral {
                >li:not(:nth-child(4n)) {
                    figure {
                        display: block;
                        position: relative;
    
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            position: absolute;
                            border-style: solid;
                            border-width: 13px 0 13px 13px;
                            border-color: transparent transparent transparent $c_navy;
                            top: 0;
                            bottom: 0;
                            right: -7.65%;
                            margin: auto;
                        }
                    }
                }
            }
        }

        >li {
            position: relative;
        }
    }
}

.imgLine5Arrow {
    &:not(.imgArrow) {
        >li {
            @if $type ==sp {
                &:before {
                    transform: rotate(-90deg);
                    top: 0 !important;
                    bottom: 0 !important;
                    left: inherit !important;
                    right: -3.6vw !important;
                    margin: auto !important;
                    border-width: 1.73vw 1.73vw 0 1.73vw !important;
                }

                &:nth-child(even):before {
                    display: none;
                }
            }

            @if $type ==pc {
                &:not(:last-child):not(:nth-child(5n)):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 0 13px 13px;
                    border-color: transparent transparent transparent $c_navy;
                    top: 0;
                    bottom: 0;
                    right: -10.65%;
                    margin: auto;
                }
            }

        }
    }

    &.imgArrow {

        @if $type ==pc {
            >li:not(:nth-child(5n)) {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 21px 0 21px 13px;
                        border-color: transparent transparent transparent $c_navy;
                        top: 0;
                        bottom: 0;
                        right: -10.65%;
                        margin: auto;
                    }
                }
            }

            &.imgArrowEquilateral {
                >li:not(:nth-child(5n)) {
                    figure {
                        display: block;
                        position: relative;
    
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            position: absolute;
                            border-style: solid;
                            border-width: 13px 0 13px 13px;
                            border-color: transparent transparent transparent $c_navy;
                            top: 0;
                            bottom: 0;
                            right: -10.65%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    >li {
        @if $type ==sp {
            margin-top: 0 !important;

            &:nth-of-type(n + 3) {
                margin-top: 60px !important;
            }
        }

        @if $type ==pc {
            position: relative;
        }
    }
}

.imgLine1Arrow,
.imgLine2Arrow,
.imgLine3Arrow,
.imgLine4Arrow {
    @if $type ==sp {
        margin-top: 30px;

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 13px 0 13px;
                    border-color: $c_navy transparent transparent transparent;
                    bottom: -24px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li {
                &:not(:last-child):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 13px 0 13px;
                    border-color: $c_navy transparent transparent transparent;
                    bottom: -24px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        >li {
            width: 100%;
            margin-right: 0;
            position: relative;

            img {
                display: block;
                margin: 0 auto;
            }

            &:nth-of-type(n + 2) {
                margin-top: 30px;
            }

        }
    }

}

.imgLine5Arrow {
    @if $type ==sp {
        margin-top: 30px;

        &:not(.imgArrow) {
            >li {
                &:not(:last-child):before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    border-style: solid;
                    border-width: 13px 13px 0 13px;
                    border-color: $c_navy transparent transparent transparent;
                    bottom: -24px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        &.imgArrow {
            >li {
                figure {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        transform: rotate(-90deg);
                        top: 0 !important;
                        bottom: 0 !important;
                        left: inherit !important;
                        right: -3.6vw !important;
                        margin: auto !important;
                        border-style: solid;
                        border-color: $c_navy transparent transparent transparent;
                        border-width: 1.73vw 1.73vw 0 1.73vw !important;
                    }
                }

                &:nth-child(even) figure:before {
                    display: none;
                }
            }
        }

        >li {
            width: 100%;
            margin-right: 0;
            position: relative;

            img {
                display: block;
                margin: 0 auto;
            }

            &:nth-of-type(n + 2) {
                margin-top: 30px;
            }
        }
    }
}

[class^="imgLine5"] {
    @if $type =="" {
        width: 100%;
        list-style-type: none;
    }

    @if $type ==sp {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        .imgNote {
            line-height: 1.4;
        }
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.5em;
        margin-bottom: 24px;
    }

    p {
        @if $type =="" {
            text-align: left;
            line-height: 2;
        }

        @if $type ==sp {
            margin-top: 8px;
        }

        @if $type ==pc {
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .link {
        @if $type =="" {
            justify-content: flex-start;
        }
    }

    >li {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            width: 48%;
            margin-top: 20px;

            &:nth-child(odd) {
                margin-right: 4%;
            }

            &:nth-child(n + 3) {
                margin-top: 25px !important;
            }
        }

        @if $type ==pc {
            width: 17.9%;

            &:not(:nth-child(5n)) {
                margin-right: 2.51%;
            }

            &:nth-of-type(5n) {
                margin-right: 0;
            }

            &:nth-child(n + 6) {
                margin-top: 2.4167%;
            }
        }

        img {
            @if $type =="" {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }

            @if $type ==sp {
                width: 100%;
                max-width: inherit;
            }
        }
    }

    .cap {
        @if $type =="" {
            display: block;
            text-align: left;
            //font-size: 14px;
            margin-top: 4px;
        }
    }

    p {
        @if $type =="" {
            text-align: left;
        }
    }
}

.cap {
    @if $type ==sp {
        margin-top: 2px;
        text-align: center;
        //font-size: 12px;
    }

    @if $type ==pc {
        margin-top: 5px;
        text-align: center;
        //font-size: 13px;
    }

}

/*
.flowList {
@if $type == ""{
counter-reset: number 0;
}

> li {
@if $type == ""{
position: relative;
}
@if $type == sp{
position: relative;

&:not(:last-child):not(:nth-child(2n)) {
figure {
&:before {
border-width: 8px 0 8px 8px;
border-color: transparent transparent transparent #e2e2e2;
top: 0;
bottom: 0;
right: -12px;
margin: auto;
}
}
}


}
@if $type == pc{
&:not(:last-child):not(:nth-child(4n)) {
figure {
&:before {
border-width: 13px 0 13px 13px;
border-color: transparent transparent transparent #e2e2e2;
top: 0;
bottom: 0;
right: -7.65%;
margin: auto;
}
}
}
}

&:before {
@if $type == ""{
counter-increment: number 1;
content: counter(number,decimal-leading-zero);
display: flex;
align-items: center;
justify-content: center;
color: #fff;
background: $c_base;
@include fontRo;
position: absolute;
top: 0;
left: 0;
z-index: 2;
}
@if $type == sp{
width: 8.75vw;
height: 8.75vw;
max-width: 35px;
max-height: 35px;
}
@if $type == pc{
width: 3.75vw;
height: 3.75vw;
max-width: 45px;
max-height: 45px;
}
}

figure {
@if $type == ""{
display: block;
position: relative;
}
@if $type == sp{
}
@if $type == pc{
}

&:before {
content: "";
width: 0;
height: 0;
position: absolute;
border-style: solid;

@if $type == sp{
}
@if $type == pc{

}

}
}
}

p {
@if $type == ""{
text-align: center;
}
@if $type == sp{
}
@if $type == pc{
}
}
}
*/

.stepList {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 1.5em;
    }

    @if $type ==pc {
        margin-top: 1.5em;
    }

    >div {
        @if $type =="" {
            display: none;
            background: #ebf0f4;
            position: relative;

            &:nth-of-type(1) {
                display: block !important;
            }
        }

        @if $type ==sp {
            padding: 20px 15px 20px 65px;
        }

        @if $type ==pc {
            padding: 32px 30px 32px 110px;
        }

        +div {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 34px;
            }

            @if $type ==pc {
                margin-top: 78px;
            }

            &:before {
                @if $type =="" {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: $c_navy transparent transparent transparent;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                @if $type ==sp {
                    border-width: 11px 11px 0 11px;
                    top: -23px;
                }

                @if $type ==pc {
                    border-width: 22px 21.5px 0 21.5px;
                    top: -48px;
                }
            }
        }

        >dt {
            @if $type =="" {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: none;
                color: #fff;
                font-weight: bold;
                line-height: 1;
                background: $c_navy;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 35px;
                height: 35px;
                left: 15px;
            }

            @if $type ==pc {
                width: 50px;
                height: 50px;
                left: 30px;
            }

            strong {
                @if $type =="" {
                    display: block;
                }

                @if $type ==sp {
                    font-size: 18px;
                }

                @if $type ==pc {
                    font-size: 22px;
                }
            }

        }

    }
}


.flowSummary {
    @if $type ==sp {
        .flowText {
            margin-top: 1.5em;
        }
    }

    @if $type ==pc {
        display: flex;

        /*
  .flowList {
  width: 23%;

  > li {
  width: 100%;
 }
 }
  */

        .flowText {
            width: calc(100% - 25.5%);
            margin-left: 2.5%;
        }
    }
}

@if $type ==sp {
    li:not(:first-child) .btnRelated {
        margin-top: 30px;
    }
}

.btnRelated {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    a {
        @if $type =="" {
            display: flex;
            justify-content: center;
            text-align: left;
            color: #fff;
            text-decoration: none;
            background-size: cover;
            background-position: center;
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {
            align-items: center;
            height: 35.16vw;
            max-height: 170px;
            font-size: 16px;
            line-height: 1.3;
        }

        @if $type ==pc {
            align-items: center;
            height: 14.16vw;
            max-height: 170px;
            font-size: 18px;
            line-height: 1.3;
            transition: .2s background-size;

            &:hover {
                .btnRelatedBg {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 100%;
                background: rgba(48, 59, 84, .7);
                position: absolute;
                top: 0;
                left: 0;
                transition: .2s width;
            }

            &:hover {
                background-size: 110%;

                &:before {
                    //width: 100%;
                }
            }
        }

        .btnRelatedBg {
            @if $type =="" {
                z-index: 0;
            }

            @if $type ==sp {
                width: 100%;
                height: 35.16vw;
                max-height: 170px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                height: 0;
            }

            @if $type ==pc {
                width: 100%;
                height: 170px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                height: 0;
            }

            &:after {
                @if $type =="" {
                    content: "";
                    display: block;
                    width: 100%;
                    background: rgba($c_navy, .6);
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                @if $type ==sp {
                    height: 35.16vw;
                    max-height: 170px;
                }

                @if $type ==pc {
                    height: 170px;
                }
            }

            img {
                @if $type =="" {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                @if $type ==sp {
                    height: 35.16vw;
                    max-height: 170px
                }

                @if $type ==pc {
                    min-height: 170px;
                    transition: .3s transform;
                }

            }
        }

        >span {
            @if $type =="" {
                display: block;
                position: relative;
                z-index: 2;
            }

            @if $type ==sp {
                padding-right: 10%;
            }

            @if $type ==pc {
                padding-right: 10%;
            }

            [class^="icon"] {
                @if $type =="" {
                    position: absolute;
                    right: 0;
                    top: 0.5em;
                    margin-right: 0;
                }

                @if $type ==sp {}

                @if $type ==pc {}
            }

        }

        //  &:after {
        //   @if $type == ""{
        //    content: "";
        //    position: absolute;
        //    background-size: contain;
        //    background-position: center;
        //    background-repeat: no-repeat;
        //   }
        //   @if $type == sp{
        //    width: 16vw;
        //    max-width: 80px;
        //    height: 16vw;
        //    max-height: 80px;
        //    top: -15px;
        //    right: 4%;
        //   }
        //   @if $type == pc{
        //    width: 20%;
        //    height: 0;
        //    padding-top: 20%;
        //    top: -15px;
        //    right: 9%;
        //   }
        //  }
    }

    &.btnOa a {
        @if $type =="" {
            background-image: url(../images/product/related_oa.jpg);

            &:after {
                background-image: url(../images/product/icon_oa.png);
            }
        }
    }

    &.btnOaFinishing a {
        @if $type =="" {
            background-image: url(../images/product/related_oa_finishing.jpg);

            &:after {
                background-image: url(../images/product/icon_oa_finishing.png);
            }
        }
    }

    &.btnOaSoketNetwork a {
        @if $type =="" {
            background-image: url(../images/product/related_oa_socket_network.jpg);

            &:after {
                background-image: url(../images/product/icon_oa_socket_network.png);
            }
        }
    }

    &.btnFloorMaterialAbout a {
        @if $type =="" {
            background-image: url(../images/product/related_floor_material_about.jpg);

            &:after {
                background-image: url(../images/product/icon_floor_material_about.png);
            }
        }
    }

    &.btnBusductLow a {
        @if $type =="" {
            background-image: url(../images/product/related_busduct_low.jpg);

            &:after {
                background-image: url(../images/product/icon_busduct_low.png);
            }
        }
    }

    &.btnBusductHigh a {
        @if $type =="" {
            background-image: url(../images/product/related_busduct_high.jpg);

            &:after {
                background-image: url(../images/product/icon_busduct_high.png);
            }
        }
    }

    &.btnFloorOa a {
        @if $type =="" {
            background-image: url(../images/product/related_floor_oa.jpg);

            &:after {
                background-image: url(../images/product/icon_floor_oa.png);
            }
        }
    }

    &.btnFloorGreeningRooftop a {
        @if $type =="" {
            background-image: url(../images/product/related_floorgreening_rooftop.jpg);

            &:after {
                background-image: url(../images/product/icon_floorgreening_rooftop.png);
            }
        }
    }

    &.btnFloorGreeningWall a {
        @if $type =="" {
            background-image: url(../images/product/related_floorgreening_wall.jpg);

            &:after {
                background-image: url(../images/product/icon_floorgreening_wall.png);
            }
        }
    }

    &.btnQf a {
        @if $type =="" {
            background-image: url(../images/product/related_qf.jpg);

            &:after {
                background-image: url(../images/product/icon_qf.png);
            }
        }
    }

    &.btnOa a {
        @if $type =="" {
            background-image: url(../images/product/related_oa.jpg);

            &:after {
                background-image: url(../images/product/icon_oa.png);
            }
        }
    }

    &.btnBoxer a {
        @if $type =="" {
            background-image: url(../images/company/related_boxer.jpg);

            &:after {
                background-image: url(../images/company/icon_boxer.png);
            }
        }
    }

    &.btnFencing a {
        @if $type =="" {
            background-image: url(../images/company/related_fencing.jpg);

            &:after {
                background-image: url(../images/company/icon_fencing.png);
            }
        }
    }

    &.btnInline-hockey a {
        @if $type =="" {
            background-image: url(../images/company/related_hockey.jpg);

            &:after {
                background-image: url(../images/company/icon_hockey.png);
            }
        }
    }

    &.btnFutsal a {
        @if $type =="" {
            background-image: url(../images/company/related_soccerfutsal.jpg);

            &:after {
                background-image: url(../images/company/icon_soccerfutsal.png);
            }
        }
    }

    &.btnPara-athlete a {
        @if $type =="" {
            background-image: url(../images/company/related_regatta.jpg);

            &:after {
                background-image: url(../images/company/icon_regatta.png);
            }
        }
    }

    &.btnBaseball a {
        @if $type =="" {
            background-image: url(../images/company/related_baseball.jpg);

            &:after {
                background-image: url(../images/company/icon_baseball.png);
            }
        }
    }

    &.btnRunning a {
        @if $type =="" {
            background-image: url(../images/company/related_running.jpg);

            &:after {
                background-image: url(../images/company/icon_running.png);
            }
        }
    }

}

.imgLineBody {
    @if $type =="" {}

    @if $type ==sp {
        padding: 20px 15px 0;
    }

    @if $type ==pc {
        padding: 11% 7% 5%;
    }

    .imgLineTitle {
        @if $type =="" {
            color: #242e44;
            font-weight: 500;
            text-align: left;
            line-height: 1.2;
            position: relative;
        }

        @if $type ==sp {
            padding: 3px 0 1px 15px;
            font-size: 18px;
        }

        @if $type ==pc {
            padding: 10px 5.21% 5px;
            font-size: 18px;
        }

        span {
            @if $type =="" {
                display: block;
                color: $c_base;
                @include fontRo;
            }

            @if $type ==sp {
                margin-bottom: 4px;
                font-size: 12px;
            }

            @if $type ==pc {
                margin-bottom: 4px;
                font-size: 14px;
            }
        }

        &:before {
            @if $type =="" {
                content: "";
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: $c_base;
            }

            @if $type ==sp {
                width: 2px;
            }

            @if $type ==pc {
                width: 2px;
            }
        }
    }
}

.photoList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    .photoListMain {
        @if $type =="" {}

        @if $type ==sp {
            height: 49.6vw;
            max-height: 372px;
        }

        @if $type ==pc {
            height: 25vw;
            max-height: 272px;
        }

        img {
            @if $type =="" {
                display: block;
                object-fit: cover;
                background-position: center;
                width: 100%;
            }

            @if $type ==sp {
                height: 49.6vw;
                max-height: 372px;
            }

            @if $type ==pc {
                height: 25vw;
                max-height: 272px;
            }

        }
    }

    .photoListSelect {
        @if $type =="" {
            display: flex;
            flex-wrap: wrap;
        }

        @if $type ==sp {}

        @if $type ==pc {
            width: 100%;
            margin-top: 20px;
        }

        >li {
            @if $type =="" {
                line-height: 0;
                position: relative;
                overflow: hidden;
            }

            @if $type ==sp {
                width: 23.76%;
                height: 14.73vw;
                max-height: 105px;
                margin-top: 4.4%;

                &:not(:nth-child(4n)) {
                    margin-right: 1.65%;
                }

                &:nth-child(n + 5) {
                    margin-top: 1.65%;
                }

            }

            @if $type ==pc {
                width: 23.76%;
                cursor: pointer;
                transition: .2s opacity;

                &:hover {
                    opacity: .7;
                }

                &:not(:nth-child(4n)) {
                    margin-right: 1.65%;
                }

                &:nth-child(n + 5) {
                    margin-top: 1.65%;
                }
            }

            &:before {
                @if $type =="" {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-sizing: border-box;
                    opacity: 0;
                    transition: .2s opacity;
                    pointer-events: none;
                    z-index: 2;
                }

                @if $type ==sp {
                    border: 2px solid $c_base;
                }

                @if $type ==pc {
                    border: 2px solid $c_base;
                }
            }

            &.isActive {
                @if $type ==sp {}

                @if $type ==pc {}

                &:before {
                    @if $type =="" {
                        opacity: 1;
                    }
                }
            }

            img {
                @if $type =="" {
                    display: block;
                    object-fit: cover;
                    background-position: center;
                    width: 100%;
                    object-fit: cover;
                }

                @if $type ==sp {
                    height: 14.73vw;
                    max-height: 105px;
                }

                @if $type ==pc {
                    width: 100%;
                    height: 88px;
                }
            }
        }
    }
}

*:not(.title)+.innerBody .attentionList:first-child,
*+.attentionList {
    @if $type =="" {
        margin-top: 30px;
    }
}

.attentionList {
    @if $type =="" {
        list-style-type: none;
    }

    @if $type ==sp {
        font-size: 12px;
    }

    @if $type ==pc {}

    &.listRight {
        @if $type =="" {
            display: flex;
            justify-content: flex-end;
        }
    }

    >li {
        @if $type =="" {
            padding-left: 1em;
            text-indent: -1em;
            line-height: 1.6;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        +li {
            @if $type =="" {
                margin-top: 3px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }

        &:before {
            @if $type =="" {
                content: "※"
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }
    }
}

.modalBtn {
    @if $type =="" {
        display: inline-block;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    &:before {
        @if $type =="" {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_question.png) no-repeat;
            background-size: contain;

        }

        @if $type ==sp {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            transform: translateY(3px);
        }

        @if $type ==pc {
            width: 21px;
            height: 21px;
            margin-right: 5px;
            transform: translateY(3px);
        }
    }
}

.tabWrap {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 60px;
    }
}

.tabMenu {
    ul {
        @if $type =="" {
            display: flex;
            justify-content: space-between;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        >li {
            @if $type =="" {
                flex: 1;
                text-align: center;
                border-style: solid;
                border-color: transparent transparent #b8b8b8 transparent;
                border-width: 3px 1px 1px 1px;
                transition: .2s border-color, .2s color;
            }

            @if $type ==sp {
                padding: 14px 10px;
                line-height: 1.4;
            }

            @if $type ==pc {
                padding: 15px 10px 23px;
                font-size: 18px;

                &:hover {
                    color: $c_base;
                }
            }

            &.isActive {
                @if $type =="" {
                    color: $c_base;
                    border-color: $c_base #b8b8b8 transparent #b8b8b8;
                }

                @if $type ==sp {}

                @if $type ==pc {}
            }

        }
    }

}

.tabContent {
    @if $type =="" {
        &:not(.isActive) {
            display: none;
        }
    }

    @if $type ==sp {
        padding-top: 33px;
        padding-bottom: 13px;
    }

    @if $type ==pc {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.speakerClass {
    @if $type =="" {
        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    @if $type ==sp {
        margin-bottom: 40px;
        line-height: 1.7143;
    }

    @if $type ==pc {
        line-height: 2;
    }

    >dt,
    >div>dt {
        @if $type =="" {
            float: left;
            font-weight: bold;

            &:after {
                content: "：";
                color: #000000;
                font-weight: 400;
            }
        }

        @if $type ==sp {}

        @if $type ==pc {}
    }

    >div {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 17px;
        }

        @if $type ==pc {
            margin-top: 20px;
        }
    }
}

.innerBody+.twoCol {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 20px;
    }
}

.twoCol:not(:last-child) {
    @if $type ==sp {}

    @if $type ==pc {
        margin-bottom: 60px;
    }
}

.twoCol {
    &.alT {
        @if $type =="" {
            align-items: flex-start;
        }
    }

    @if $type ==sp {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &.imgBeforeSP {
            .txt {
                order: 1;
            }

            .img {
                order: 0;
            }
        }

        .txt {
            order: 0;
            min-height: 0%;

            >*:nth-child(1) {
                margin-top: 0;
            }

            &+.img {
                margin-bottom: 15px;
            }
        }

        .img {
            width: 100%;
            padding-right: 14px;
            padding-left: 14px;
            text-align: center;
            order: 1;
            margin-top: 1.5em;

            .cap {
                display: block;
            }

            img {
                display: block;
                margin: 0 auto;
            }

            &+.txt {
                margin-top: 20px;
            }

            .ytWrap {
                margin-top: 0;
            }

            img {
                max-width: 100%;
                width: auto;
                height: auto;

                &+img {
                    margin-top: 22px;
                }
            }
        }

    }

    @if $type ==pc {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .txt {
            flex: 1;
            width: 46.3277%;

            >*:nth-child(1) {
                margin-top: 0;
            }

            &+.img {
                margin-left: 40px;
            }

        }

        .img {
            width: 50.8475%;
            max-width: 480px;
            text-align: center;

            .cap {
                display: block;
            }

            &+.txt {
                margin-left: 30px;
            }

            .ytWrap {
                margin-top: 0;
            }

            img {
                max-width: 100%;
                width: auto;
                height: auto;

                &+img {
                    margin-top: 30px;
                }
            }
        }
    }

    @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        .img {
            height: 100%;
        }
    }
}

* .iconWrap {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 20px;
    }
}

.iconWrap {
    @if $type =="" {}

    @if $type ==sp {
        +.iconWrap {
            padding-top: 40px;
            border-top: 1px solid #b8b8b8;
        }
    }

    @if $type ==pc {
        display: flex;
        align-items: center;
    }

    >i {
        @if $type =="" {}

        @if $type ==sp {
            display: block;
            width: 180px;
            margin: 0 auto 15px;
            text-align: center;
        }

        @if $type ==pc {
            flex: none;
            width: 180px;
            margin-right: 20px;

            +* {
                margin-top: 0;
            }
        }
    }
}

.workHeader {
    @if $type =="" {}

    @if $type ==sp {
        background: inherit !important;

        .workHeaderThumb {
            img {
                width: 100%;
            }
        }
    }

    @if $type ==pc {
        min-height: 534px;
        padding-left: 40px;
        padding-right: 40px;
        background-size: cover !important;
        background-position: top center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }

    .sectionTitleImg {
        @if $type =="" {}

        @if $type ==sp {
            max-width: 224px;
            margin-bottom: 16px;
        }

        @if $type ==pc {
            margin-bottom: 73px;
        }
    }

    .workHeaderLead {
        @if $type =="" {}

        @if $type ==sp {
            padding: 50px 19px 0;
        }

        @if $type ==pc {
            width: 100%;
            max-width: 1000px;
            min-height: 534px;
            padding-top: 99px;
            padding-right: 38.9%;
            padding-right: clamp(10px, 36.9%, 390px);
            padding-bottom: 44px;
            margin: 0 auto;
            position: relative;
        }

        .sectionTitle {
            @if $type =="" {}

            @if $type ==sp {
                font-size: 21px;
                line-height: 1.619;
                font-weight: 400;
            }

            @if $type ==pc {
                font-size: 30px;
                line-height: 1.6;
                font-weight: 400;
                letter-spacing: -.04em;
            }
        }

        .sectionTitleSub {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 18px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
            }

            @if $type ==pc {
                margin-top: 43px;
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;
            }
        }
    }
}

.pickupListItem {
    @if $type =="" {
        width: 100%;
    }

    @if $type ==sp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @if $type ==pc {
        flex-wrap: wrap;
    }

    .pickupItem {
        @if $type =="" {}

        @if $type ==sp {
            width: calc(50% - 10px);
            margin: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, .23);

            &:nth-child(n + 3) {
                margin-top: 20px;
            }
        }

        @if $type ==pc {
            width: 31.5789%;

            &:not(:first-child) {
                margin-left: 0;
            }

            &:not(:nth-child(3n)) {
                margin-right: 2.6317%;
            }

            &:nth-child(n + 4) {
                margin-top: 2.6317%;
            }
        }

        >a {
            @if $type =="" {
                color: #242e44;
                text-decoration: none;
            }

            @if $type ==sp {
                height: 100%;
                padding-top: 55%;
            }

            @if $type ==pc {
                height: 100%;
                padding-top: 55%;
            }

            .pickupItemThumb {
                @if $type =="" {}

                @if $type ==sp {
                }

                @if $type ==pc {}

                img {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    @if $type ==pc {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        transform: none;
                    }
                }
            }

            .pickupItemText {
                @if $type =="" {}

                @if $type ==sp {
                    height: 100%;
                }

                @if $type ==pc {
                    height: 100%;
                }

                .pickupItemTag {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding-left: 15px;

                        font-size: 12px;
                    }

                    @if $type ==pc {
                        padding-top: 1px;
                        font-size: 16px;
                        line-height: 1.875;
                    }

                    &:before {
                        @if $type =="" {}

                        @if $type ==sp {
                            width: 10px;
                        }

                        @if $type ==pc {
                            bottom: 12px;
                        }
                    }
                }

                .pickupItemNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding: 10px 15px 28px;
                        line-height: 1.6429;
                    }

                    @if $type ==pc {
                        padding: 20px 30px 40px;
                        font-size: 16px;
                        line-height: 1.875;
                    }
                }

                .pickupItemDate {
                    @if $type =="" {
                        @include fontRo;
                        color: #acaaaa;
                        line-height: 1;
                        position: absolute;
                    }

                    @if $type ==sp {
                        font-size: 12px;
                        right: 7px;
                        bottom: 8px;
                    }

                    @if $type ==pc {
                        right: 15px;
                        bottom: 12px;
                    }
                }
            }
        }
    }
}


.caseListSlider+.btnWrap {
    @if $type =="" {
        margin-top: 0;
    }
}

.caseListSlider {
    @if $type =="" {
        padding-bottom: 3em;
    }

    @if $type ==sp {}

    @if $type ==pc {
        margin: 0 -17px;
    }

    .slick-list.draggable {
        @if $type =="" {
            overflow: visible;
        }

        @if $type ==sp {}

        @if $type ==pc {}
    }

    .slick-arrow {
        @if $type ==pc {
            width: 56px;
            height: 56px;
            transition: .2s background-position;
            box-shadow: 0 0 10px rgba(0, 0, 0, .23);
            top: calc(50% - 56px);
            border-radius: 50%;

            &.slick-prev {
                background: #fff url(../images/common/icon_arrow_pink.svg) no-repeat center;
                background-size: 10px 17px;
                left: -17px;
                transform: scale(-1, 1);
                z-index: 1;
            }

            &.slick-next {
                background: #fff url(../images/common/icon_arrow_pink.svg) no-repeat center;
                background-size: 10px 17px;
                transform: scale(1, -1);
                right: -17px;
            }

            &:hover {
                background-position: right 43% center;
            }
        }
    }

    .slick-dots {
        @if $type ==sp {
            bottom: 0;

            >li {
                width: 8px;
                height: 8px;
                margin: 0 5px;

                button {
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    border-radius: 50%;
                    background: #303b54;
                }

                &.slick-active {
                    button {
                        background: $c_base;
                    }
                }
            }
        }
    }

    .caseItem {
        @if $type =="" {
            box-shadow: 0 0 10px rgba(0, 0, 0, .23);
            background: #fff;
            overflow: visible;
            transition: .2s opacity;
        }

        @if $type ==sp {
            width: calc(100vw - 140px);
            margin: 0 15px;
        }

        @if $type ==pc {
            margin: 0 17px;

            &:not(.slick-active) {
                opacity: 0;
                pointer-events: none;
            }

            &.slick-active {
                opacity: 1;
            }
        }
    }

    a {
        @if $type =="" {
            display: flex;
            flex-direction: column;
            height: 100%;
            color: #000;
            text-decoration: none;
        }

        @if $type ==sp {}

        @if $type ==pc {
            &:hover {
                .caseItemThumb img {
                    transform: scale(1.08);
                }
            }
        }
    }

    .caseItemThumb {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {
            height: 235px;
        }

        @if $type ==pc {
            height: 235px;
        }

        img {
            @if $type =="" {
                object-fit: cover;
                height: 100%;
            }

            @if $type ==sp {
                width: 100%;
            }

            @if $type ==pc {
                transition: .2s transform;
            }
        }

        >ul {
            @if $type =="" {
                width: 100%;
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            >li {
                @if $type =="" {
                    color: #fff;
                    text-align: center;

                    &.caseGreeningRooftop {
                        width: 27%;
                        background: #439f52;
                    }

                    &.caseGreeningInfra {
                        width: 46%;
                        background: #8dab15;
                    }

                    &.caseGreeningWall {
                        width: 27%;
                        background: #48a88e;
                    }

                    &.caseLow {
                        width: 50%;
                        background: #0099e3;
                    }

                    &.caseHigh {
                        width: 50%;
                        background: #6875b0;
                    }

                    &.caseDwg,
                    &.caseDxf,
                    &.caseJww,
                    &.casePdf {
                        width: 25%;

                        a {
                            display: block;
                        }
                    }
                }

                @if $type ==sp {
                    font-size: clamp(1px, 2.4vw, 14px);
                }

                @if $type ==pc {
                    padding: 1px 0;
                    font-size: 11px;
                    font-size: clamp(1px, 1vw, 12px);
                }
            }
        }
    }

    .caseItemText {
        @if $type =="" {}

        @if $type ==sp {
            padding: 18px 15px;
        }

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 235px);
            padding: 18px 25px;
        }

        .caseItemTitle {
            @if $type =="" {
                line-height: 1.4;
            }

            @if $type ==sp {
                font-size: 14px;
            }

            @if $type ==pc {
                font-size: 14px;
            }
        }

        .caseItemInfo {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 13px;
            }

            @if $type ==pc {
                margin-top: 13px;
            }

            >div {
                @if $type =="" {
                    display: flex;
                    align-items: flex-start;
                }

                @if $type ==sp {
                    +div {
                        margin-top: 8px;
                    }
                }

                @if $type ==pc {
                    +div {
                        margin-top: 8px;
                    }
                }

                >dt {
                    @if $type =="" {
                        flex: none;
                        color: #7e7d7d;
                        text-align: center;
                        background: #f1f1f1;
                    }

                    @if $type ==sp {
                        width: 56px;
                        padding: 3px;
                        margin-right: 10px;
                        font-size: 11px;
                        line-height: 1;
                    }

                    @if $type ==pc {
                        width: 56px;
                        padding: 3px;
                        margin-right: 10px;
                        font-size: 11px;
                        line-height: 1;
                    }
                }

                >dd {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: calc(100% - 66px);
                        font-size: 12px;
                        line-height: 1.4;
                    }

                    @if $type ==pc {
                        width: calc(100% - 66px);
                        font-size: 12px;
                        line-height: 1.4;
                    }
                }
            }

        }
    }
}

.caseListItem {
    @if $type =="" {
        display: flex;
        flex-wrap: wrap;
    }

    @if $type ==sp {
        justify-content: space-between;
    }

    @if $type ==pc {}

    .caseItem {
        @if $type =="" {
            box-shadow: 0 0 10px rgba(0, 0, 0, .23);
            background: #fff;
        }

        @if $type ==sp {
            width: 100%;

            &:nth-child(n + 2) {
                margin-top: 60px;
            }
        }

        @if $type ==pc {
            //width: 23.5%;
            width: 31.5%;

            &:not(:nth-child(3n)) {
                margin-right: 2%;
            }

            &:nth-child(n + 4) {
                margin-top: 45px;
            }
        }
    }

    a {
        @if $type =="" {
            display: flex;
            flex-direction: column;
            height: 100%;
            color: #000;
            text-decoration: none;
        }

        @if $type ==sp {}

        @if $type ==pc {
            &:hover {
                .caseItemThumb img {
                    transform: scale(1.08);
                }
            }
        }
    }

    .caseItemThumb {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {
            height: 235px;
        }

        @if $type ==pc {
            //height: 174px;
            height: 232px;
        }

        img {
            @if $type =="" {
                object-fit: cover;
                height: 100%;
            }

            @if $type ==sp {
                width: 100%;
            }

            @if $type ==pc {
                width: 100%;
                transition: .2s transform;
            }
        }

        >ul {
            @if $type =="" {
                width: 100%;
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            >li {
                @if $type =="" {
                    color: #fff;
                    text-align: center;

                    &.caseGreeningRooftop {
                        width: 27.66%;
                        background: #439f52;
                    }

                    &.caseGreeningInfra {
                        width: 44.68%;
                        background: #8dab15;
                    }

                    &.caseGreeningWall {
                        width: 27.66%;
                        background: #48a88e;
                    }

                    &.caseLow {
                        width: 50%;
                        background: #0099e3;
                    }

                    &.caseHigh {
                        width: 50%;
                        background: #6875b0;
                    }

                    &.caseDwg,
                    &.caseDxf,
                    &.caseJww,
                    &.casePdf {
                        flex: 1;
                        transform: translateY(2px);

                        a {
                            display: block;
                            width: 100%;
                            color: #fff;
                            margin: auto;
                        }
                    }

                    &.caseDwg a {
                        background: #7361ab;
                    }

                    &.caseDxf a {
                        background: #16b1a9;
                    }

                    &.caseJww a {
                        background: #a29d11;
                    }

                    &.casePdf a {
                        background: #c90056;
                    }

                }

                @if $type ==sp {
                    padding: 1px 0;
                    font-size: 11px;
                    font-size: clamp(1px, 2.4vw, 14px);
                }

                @if $type ==pc {
                    padding: 1px 0;
                    font-size: 11px;
                    font-size: clamp(1px, 1vw, 12px);

                    &.caseDwg,
                    &.caseDxf,
                    &.caseJww,
                    &.casePdf {

                        a {
                            height: 100%;
                            transition: .2s opacity;

                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                }

                &.tag {
                    @if $type =="" {
                        color: #fff;
                        text-align: center;
                        border-right: 1px solid #fff;

                        &:last-child {
                            border: none;
                        }
                    }

                    @if $type ==sp {
                        padding: 1px 10px;
                        font-size: 11px;
                        font-size: clamp(1px, 2.4vw, 14px);
                    }

                    @if $type ==pc {
                        padding: 1px 12px;
                        font-size: 11px;
                        font-size: clamp(1px, 1vw, 12px);
                    }
                }
            }
        }
    }

    .caseItemText {
        @if $type =="" {}

        @if $type ==sp {
            display: flex;
            flex-direction: column;
            //justify-content: space-between;
            height: calc(100% - 235px);
            padding: 18px 25px;
        }

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            //justify-content: space-between;
            //height: calc(100% - 174px);
            //height: calc(100% - 232px);
            padding: 25px 25px 30px;
        }

        .caseItemTitle {
            @if $type =="" {
                line-height: 1.4;
            }

            @if $type ==sp {
                font-size: 18px;
            }

            @if $type ==pc {
                font-size: 20px;
            }
        }

        .caseItemInfo {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 13px;
            }

            @if $type ==pc {
                margin-top: 13px;
            }

            +.btnWrap {
                @if $type =="" {}

                @if $type ==sp {
                    margin-top: 20px;
                }

                @if $type ==pc {
                    margin-top: 20px;
                }
            }

            >div {
                @if $type =="" {
                    position: relative;
                }

                @if $type ==sp {
                    +div {
                        margin-top: 8px;
                    }
                }

                @if $type ==pc {
                    +div {
                        margin-top: 8px;
                    }
                }

                >dt {
                    @if $type =="" {
                        flex: none;
                        color: #7e7d7d;
                        text-align: center;
                        background: #f1f1f1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    @if $type ==sp {
                        width: 75px;
                        padding: 3px;
                        margin-right: 10px;
                        font-size: 12px;
                        line-height: 1;
                    }

                    @if $type ==pc {
                        width: 75px;
                        padding: 5px 3px;
                        margin-right: 14px;
                        font-size: 13px;
                        line-height: 1;
                    }
                }

                >dd {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding-left: 90px;
                        font-size: 14px;
                        line-height: 1.4;
                    }

                    @if $type ==pc {
                        padding-left: 90px;
                        font-size: 16px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
}

.cadList {
    @if $type =="" {
        line-height: 1.4;
    }

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
    }

    >li {
        @if $type =="" {
            box-shadow: 0 0 10px rgba(0, 0, 0, .23);
            background: #fff;
        }

        @if $type ==sp {
            width: 100%;

            &:nth-child(n + 2) {
                margin-top: 60px;
            }
        }

        @if $type ==pc {
            //width: 23.5%;
            width: calc(50% - 1.875%);

            &:not(:nth-child(even)) {
                margin-right: 3.75%;
            }

            &:nth-child(n + 3) {
                margin-top: 3.75%;
            }
        }
    }

    .cadListMain {
        @if $type =="" {
            background: #f2f2f2;
        }

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
        }

        .cadListMainThumb {
            @if $type =="" {}

            @if $type ==sp {
                width: 100%;
            }

            @if $type ==pc {
                width: calc(100% - 265px);
            }

            img {
                @if $type =="" {}

                @if $type ==sp {
                    width: 100% !important;
                }

                @if $type ==pc {
                    object-fit: cover;
                    height: 100%;
                    max-height: 125px;
                }

            }
        }

        .cadListMainNote {
            @if $type =="" {}

            @if $type ==sp {
                padding: 30px 20px;
            }

            @if $type ==pc {
                display: flex;
                align-items: center;
                width: 265px;
                padding: 20px 20px;
            }

            .cadListMainLink {
                @if $type =="" {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }

                @if $type ==sp {
                    max-width: 400px;
                    margin: 0 auto;
                    justify-content: space-between;
                }

                @if $type ==pc {}

                >li {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: 48%;
                        

                        &:nth-child(odd) {
                            margin-right: 4%;
                        }

                        &:nth-child(n + 3) {
                            margin-top: 4%;
                        }
                    }

                    @if $type ==pc {
                        width: 48%;

                        &:nth-child(odd) {
                            margin-right: 4%;
                        }

                        &:nth-child(n + 3) {
                            margin-top: 4%;
                        }
                    }

                    a {
                        @if $type =="" {
                            display: block;
                            color: #fff;
                            text-decoration: none;
                            background: $c_navy;
                            position: relative;
                        }

                        @if $type ==sp {
                            padding: 4px 20px 4px 20px;
                        }

                        @if $type ==pc {
                            padding: 4px 25px 4px 25px;
                        }

                        @if $type ==pc {
                            &:before {
                                content: "";
                                display: block;
                                width: 0;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                transition: .2s width;
                                z-index: 0;
                                background: #13192a;
                            }

                            &:hover {
                                &:before {
                                    width: 100%;
                                }
                            }
                        }

                        >div {
                            @if $type ==pc {
                                position: relative;
                            }

                            &:before {
                                @if $type =="" {
                                    content: "";
                                    display: block;
                                    background: url(../images/common/icon_dash_white.svg) no-repeat;
                                    background-size: contain;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                }

                                @if $type ==sp {
                                    width: 9px;
                                    height: 9px;
                                    right: 10px;
                                }

                                @if $type ==pc {
                                    width: 9px;
                                    height: 9px;
                                    right: -10px;
                                }
                            }
                        }

                    }
                }

            }
        }
    }

    .cadListSummary {
        @if $type =="" {}

        @if $type ==sp {
            padding: 18px 25px;
        }

        @if $type ==pc {
            width: 100%;
            padding: 20px 30px;
        }

        .cadListTitle {
            @if $type =="" {}

            @if $type ==sp {
                font-size: 18px;
            }

            @if $type ==pc {
                font-size: 20px;
            }
        }

        .cadListInfo {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 13px;
            }

            @if $type ==pc {
                margin-top: 13px;
            }

            +.btnWrap {
                @if $type =="" {}

                @if $type ==sp {
                    margin-top: 20px;
                }

                @if $type ==pc {
                    margin-top: 20px;
                }
            }

            >div {
                @if $type =="" {
                    display: flex;
                    align-items: flex-start;
                }

                @if $type ==sp {
                    +div {
                        margin-top: 8px;
                    }
                }

                @if $type ==pc {
                    +div {
                        margin-top: 8px;
                    }
                }

                >dt {
                    @if $type =="" {
                        flex: none;
                        color: #7e7d7d;
                        text-align: center;
                        background: #f1f1f1;
                    }

                    @if $type ==sp {
                        width: 75px;
                        padding: 3px;
                        margin-right: 10px;
                        font-size: 12px;
                        line-height: 1;
                    }

                    @if $type ==pc {
                        width: 75px;
                        padding: 5px 3px;
                        margin-right: 14px;
                        font-size: 13px;
                        line-height: 1;
                    }
                }

                >dd {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: calc(100% - 66px);
                        font-size: 14px;
                        line-height: 1.4;
                    }

                    @if $type ==pc {
                        width: calc(100% - 66px);
                        font-size: 16px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }

}

//_::-webkit-full-page-media, _:future, :root .cadList .cadListMain .cadListMainThumb img {
//        height: 125px;
//}

.pageNav {
    @if $type =="" {
        background: #e2e2e2;
        border-top: 1px solid #fff;
    }

    @if $type ==sp {
        width: 100%;
        overflow-x: auto;
        box-shadow: 0 11px 17px -9px rgba(41, 41, 41, 0.08%);
    }

    @if $type ==pc {
        width: 100%;
        box-shadow: 0 11px 17px -9px rgba(41, 41, 41, 0.08%);
    }

    >ul {
        @if $type =="" {}

        @if $type ==sp {
            display: flex;
        }

        @if $type ==pc {
            display: flex;
            justify-content: center;
            @include maxPC;
            padding-top: 48px;
        }

        >li {
            @if $type =="" {
                text-align: center;
                position: relative;
            }

            @if $type ==sp {
                margin: 0 20px 0 21px;
                font-size: 14px;
                font-weight: bold;
                white-space: nowrap;
            }

            @if $type ==pc {
                width: calc(14.2857142857% - 10px);

                width: calc(20% - 10px);
                margin: 0 5px;
                cursor: pointer;
            }

            a {
                @if $type =="" {
                    color: #242e44;
                    text-decoration: none;
                }
            }

            &.isActive {

                a {
                    @if $type =="" {
                        color: $c_base;
                    }
                }


                &:before {
                    @if $type =="" {
                        content: "";
                        display: block;
                        width: 100%;
                        background: $c_base;
                        position: absolute;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }

                    @if $type ==sp {
                        height: 2px;
                    }

                    @if $type ==pc {
                        height: 2px;
                    }
                }
            }

            >a {
                @if $type =="" {
                    display: block;
                }

                @if $type ==sp {
                    padding: 22px;
                }

                @if $type ==pc {
                    padding: 14px 0;
                    transition: .2s color;

                    &:hover {
                        color: $c_base;
                    }
                }
            }
        }
    }

}



[class^=bnrList]+[class^=bnrList] {
    @if $type ==sp {
        margin-top: 1.5em;
    }
}

.bnrList2 {

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: calc(50% - 15px);

            &:nth-child(n + 3) {
                margin-top: 30px;
            }
        }

        .bnrArea {
            >a {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    height: 167px;
                }
            }
        }
    }
}


.bnrList3 {

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: calc(33.3% - 18px);

            &:nth-child(n + 4) {
                margin-top: 30px;
            }
        }

        .bnrArea {
            >a {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    height: 167px;
                }

            }
        }
    }
}

[class^=bnrList]+[class^=bnrList] {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 30px;
    }
}

[class^=bnrList] {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    >li {
        @if $type =="" {
            &.bgExistingGreening .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_existinggreening.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgAftercare .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_aftercare.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgSeminar .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_seminar.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgCalculation .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_calculation.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgSurveillanceTemperature .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_surveillance_temperature.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgSurveillanceElectric .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_surveillance_electric.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgFigure .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_figure.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgSupport .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_support.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgBim .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_bim.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgSeminar .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_seminar.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgOnline .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_online.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgOnline .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_online.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgPurchase .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_purchase.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgExistingoa .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_existingoa.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgExistingGreening .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_special-greening.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgGreeningRooftop .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_roof-greening-support.png) no-repeat center right;
                    background-size: contain;
                }
            }

            &.bgConstruction .bnrArea>a:after {
                @if $type =="" {
                    background: url(../images/common/bnr_construction.png) no-repeat center right;
                    background-size: contain;
                }
            }
        }

        @if $type ==sp {
            +li {
                margin-top: 1.5em;
            }
        }

        @if $type ==pc {}

        .bnrArea {
            >a {
                @if $type =="" {
                    border: 1px solid #c90056;
                    position: relative;
                    overflow: hidden;
                }

                @if $type ==sp {
                    display: flex;
                    align-items: center;
                    height: 126px;

                    >p {
                        z-index: 1;
                    }
                }

                @if $type ==pc {
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        display: block;
                        width: 0;
                        height: 100%;
                        background: $c_base;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: .3s width;
                    }

                    &:hover {
                        &:before {
                            width: 100%;
                        }

                        >p {
                            color: #fff;
                            text-shadow: 1px 1px 2px rgba(201, 0, 86, 1);
                        }
                    }
                }

                &:after {
                    @if $type =="" {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                    }

                    @if $type ==sp {
                        right: -25px;
                    }

                    @if $type ==pc {
                        right: 0;
                    }
                }

                >p {
                    @if $type =="" {
                        color: $c_base;
                        position: relative;
                    }

                    @if $type ==sp {
                        padding-left: 15px;
                        padding-right: 120px;
                        font-size: 18px;
                        line-height: 1.44;
                        text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
                    }

                    @if $type ==pc {
                        padding-left: 26px;
                        font-size: 22px;
                        line-height: 1.3636;
                        text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
                        transition: .2s color, .2s text-shadow;
                        position: relative;
                        z-index: 10;
                    }
                }
            }
        }

        .bnrNote {
            @if $type =="" {}

            @if $type ==sp {
                padding: 6px 15px 30px;
            }

            @if $type ==pc {
                padding-top: 21px;
                font-size: 15px;
                text-align: center;
            }
        }
    }

}


// btnList
.boxShadow+[class^="btnList"] {
    @if $type ==sp {
        margin-bottom: 30px !important;
    }
}

.boxShadow+.btnList {
    @if $type =="" {}

    @if $type ==pc {
        margin-top: 40px;
    }
}

.btnList+.inner {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 70px;
    }
}

.section>.btnList,
.section>.btnList2Col,
.section>.btnList3Col {
    @if $type =="" {}

    @if $type ==sp {
        @include maxSP;
        padding: 0 20px;
        margin: 0 auto;
    }

    @if $type ==pc {
        max-width: 1160px;
        padding: 0 40px;
        margin-right: auto;
        margin-left: auto;
    }
}


.inner {
    .btnList {
        @if $type =="" {}

        @if $type ==sp {
            padding: 0;
        }

        @if $type ==pc {
            padding: 0;
        }
    }
}

*:not(.title)+.innerBody .btnList2Col:first-child,
*+.btnList2Col {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 60px;
    }
}

*:not(.title)+.innerBody .btnList3Col:first-child,
*+.btnList3Col {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 30px;
    }
}

.btnList,
.btnList2Col,
.btnList3Col {
    @if $type =="" {
        width: 100%;
    }

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {
            width: 100%;

            +li {
                a {
                    border-top: none;
                }
            }
        }

        @if $type ==pc {}

        a {
            @if $type =="" {
                display: flex;
                align-items: center;
                color: #000000;
                text-decoration: none;
                position: relative;

            }

            @if $type ==sp {
                justify-content: center;
                padding: 18px 40px 18px 12px;
                border-top: 1px solid #b8b8b8;
                border-bottom: 1px solid #b8b8b8;
            }

            @if $type ==pc {
                height: 100%;
                width: 100%;
                min-height: 80px;
                padding: 14px 36px 14px 10px;
                border-bottom: 1px solid #b8b8b8;
                font-size: 18px;
                line-height: 1.227;
                transition: .2s color;

                &:hover {
                    color: $c_base;
                }
            }

            >[class^="icon"] {
                @if $type =="" {
                    margin-right: 0;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }

                @if $type ==sp {
                    right: 11px;
                }

                @if $type ==pc {
                    right: 11px;
                }

                &+div {
                    &:before {
                        @if $type =="" {
                            display: none;
                        }
                    }
                }
            }

            >div {
                @if $type =="" {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    position: relative;
                }

                &:before {
                    @if $type =="" {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        background: url(../images/common/icon_dash_pink.svg) no-repeat;
                        background-size: contain;
                    }

                    @if $type ==sp {
                        width: 9px;
                        height: 9px;
                        right: -32px;
                    }

                    @if $type ==pc {
                        width: 9px;
                        height: 9px;
                        right: -20px;
                    }
                }

                >div {
                    @if $type =="" {
                        display: flex;
                        align-items: center;
                        position: relative;
                    }
                }
            }
        }
    }

    .iconDownload {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 34px;
            height: 30px;
        }
    }

    &.btnListNote {
        >li {
            @if $type =="" {}

            @if $type ==sp {
                a {
                    border-top: 1px solid #b8b8b8;
                }
            }

            @if $type ==pc {
                display: flex;
                flex-direction: column;

                a {
                    border-top: 1px solid #b8b8b8;
                }
            }

            .btnListContent {
                @if $type =="" {}

                @if $type ==pc {
                    margin-bottom: 8px;
                }

            }

            &:nth-child(n + 2) {
                .btnListContent {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding-top: 40px;
                        margin-bottom: 8px;
                    }

                }
            }
        }
    }
}



.btnList {
    @if $type =="" {
        width: 100%;
    }

    @if $type ==sp {}

    @if $type ==pc {
        justify-content: flex-start;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 22.3%;


            &:not(:nth-child(4n)) {
                margin-right: 3.583%;
            }

            &:not(:nth-child(n + 5)) {
                a {
                    border-top: 1px solid #b8b8b8;
                }
            }

            &:nth-child(n + 5) .btnListContent {
                padding-top: 40px;
            }
        }

        a {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {}
        }
    }


}


.btnList2Col {
    @if $type =="" {
        width: 100%;
    }

    >li {
        @if $type ==pc {
            width: calc(50% - 1.875%);

            &:nth-child(odd) {
                margin-right: 3.75%;
            }

            &:not(:nth-child(n + 3)) {
                a {
                    border-top: 1px solid #b8b8b8;
                }
            }

            &:nth-child(n + 3) .btnListContent {
                padding-top: 40px;
            }
        }

        a {
            >div {
                &:before {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        right: -21px;
                    }
                }
            }
        }
    }
}

.btnList3Col {
    @if $type =="" {
        width: 100%;
    }

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            width: 31.25%;

            &:not(:nth-child(n + 4)) {
                a {
                    border-top: 1px solid #b8b8b8;
                }
            }

            &:not(:nth-child(3n)) {
                margin-right: 3.125%;
            }

            &:nth-child(n + 4) .btnListContent {
                padding-top: 40px;
            }

        }

        a {
            >div {
                &:before {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        right: -21px;
                    }
                }
            }
        }
    }

}

.innerBody+.moreBlock,
.moreBlock {
    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 50px;
    }
}

.moreBlock {
    @if $type =="" {
        display: none;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

.innerBody+.moreBlock+.btnMore {
    >p {
        @if $type =="" {}

        @if $type ==sp {
            padding-right: 50px;
        }

        @if $type ==pc {
            padding-right: 50px;
        }

        &:before {
            @if $type =="" {}

            @if $type ==sp {
                right: 20px;
            }

            @if $type ==pc {
                right: 20px;
            }

        }
    }
}

.btnMore {
    @if $type =="" {
        text-align: right;
        line-height: 1;
    }

    @if $type ==sp {
        margin-top: 15px;
    }

    @if $type ==pc {
        margin-top: 33px;
    }

    &.isOpen {
        p {
            @if $type ==pc {
                &:hover {
                    &:before {
                        transform: translateY(-3px) rotate(-90deg);
                    }
                }
            }

            &:before {
                @if $type =="" {
                    transform: rotate(-90deg);
                }

                @if $type ==sp {}

                @if $type ==pc {}
            }
        }
    }

    >p {
        @if $type =="" {
            display: inline-block;
            @include fontRo;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.06em;
            position: relative;
            background: $c_base;
        }

        @if $type ==sp {
            padding: 8px 43px 8px 22px;
            font-size: 12px;
        }

        @if $type ==pc {
            min-width: 134px;
            padding: 8px 43px 8px 22px;
            font-size: 13px;
            text-align: center;
            cursor: pointer;

            &:hover {
                &:before {
                    transform: translateY(3px) rotate(90deg);
                }
            }
        }

        &:before {
            @if $type =="" {
                content: "";
                background: url(../images/common/icon_dash_white.svg) no-repeat;
                transform: rotate(90deg);
                position: absolute;
                right: 0px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: transform .2s;
            }

            @if $type ==sp {
                width: 10px;
                height: 12px;
                right: 22px;
            }

            @if $type ==pc {
                width: 10px;
                height: 12px;
                right: 22px;
            }
        }
    }
}

.pageing {
    @if $type =="" {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        @include fontRo;
    }

    @if $type ==sp {
        flex-wrap: wrap;
        padding-top: 40px;
    }

    @if $type ==pc {
        align-items: center;
        max-width: 980px;
        width: 95%;
        padding-top: 58px;
    }

    >span {
        @if $type =="" {
            display: inline-block;
        }

        @if $type ==sp {
            margin: 0 3px 6px;
        }

        @if $type ==pc {
            margin: 0 10px 10px;
        }

        a {
            @if $type =="" {
                color: #000;
                text-decoration: none;
            }

            @if $type ==sp {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 85px;
            }

            @if $type ==pc {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 91px;
                transition: .2s color;

                &:hover {
                    color: $c_base;
                }
            }

            &.next:after {
                @if $type =="" {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/icon_arrow_pink.svg) no-repeat;
                    background-size: contain;
                }

                @if $type ==sp {
                    width: 7px;
                    height: 11px;
                    margin-left: 10px;
                }

                @if $type ==pc {
                    width: 9px;
                    height: 13px;
                    margin-left: 10px;
                }
            }

            &.prev:before {
                @if $type =="" {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/icon_arrow_pink.svg) no-repeat;
                    background-size: contain;
                    transform: scale(-1, 1);
                }

                @if $type ==sp {
                    width: 7px;
                    height: 11px;
                    margin-right: 10px;
                }

                @if $type ==pc {
                    width: 9px;
                    height: 15px;
                    margin-right: 10px;
                }
            }

        }

    }

    a {
        @if $type =="" {
            display: block;
            color: #000;
            text-decoration: none;
            border: 1px solid $c_base;
            text-decoration: none;
            text-align: center;
            background: #fff;
        }

        @if $type ==sp {
            width: 30px;
            line-height: 30px;
            font-size: 14px;
        }

        @if $type ==pc {
            width: 44px;
            line-height: 45px;
            font-size: 16px;
        }
    }

    .pageCount {
        @if $type =="" {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
        }

        @if $type ==sp {
            flex-wrap: wrap;
        }

        @if $type ==pc {
            flex-wrap: wrap;
        }

        >li {
            @if $type ==sp {
                margin: 0 5px 10px;
            }

            @if $type ==pc {
                margin: 0 10px 10px;
            }

            a {
                @if $type ==pc {
                    transition: .2s color;

                    &:hover {
                        color: $c_base;
                    }
                }

                &.now {
                    @if $type =="" {
                        color: #fff !important;
                        background: $c_base;

                    }
                }

            }
        }
    }
}


.newsList {
    @if $type =="" {}

    @if $type ==sp {
        border-top: 1px solid #f2f2f2;
    }

    @if $type ==pc {
        border-top: 1px solid #f2f2f2;
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {
            display: flex;
            flex-wrap: wrap;
            padding: 18px 15px;
            border-bottom: 1px solid #f2f2f2;
        }

        @if $type ==pc {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 26px 20px;
        }

        p {
            @if $type =="" {
                margin-top: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }

        .newsListDate {
            @if $type =="" {
                @include fontRo;
            }

            @if $type ==sp {
                min-width: 80px;
                font-size: 14px;
            }

            @if $type ==pc {
                width: 108px;
                flex: none;
            }
        }

        .newsListTag {
            @if $type =="" {
                display: flex;
                color: #fff;
            }

            @if $type ==sp {
                flex-wrap: wrap;
                align-items: center;
                text-align: center;
                font-size: 11px;
            }

            @if $type ==pc {
                flex-direction: column;
                width: 160px;
                margin-right: 30px;
                flex: none;
                font-size: 13px;
                text-align: center;
                border-bottom: 1px solid #fff;
            }

            span {
                @if $type =="" {
                    display: block;

                    &.tagProduct,
                    &.tagNews,
                    &.tagPressrelease,
                    &.tagContribution {
                        order: 0;
                    }

                    &.tagProduct {
                        background: #556fb5;
                    }

                    &.tagNews {
                        background: #c90056;
                    }

                    &.tagPressrelease {
                        background: #b59355;
                    }

                    &.tagContribution {
                        background: #46976e;
                    }

                    &:not([class]) {
                        order: 1;
                        background: $c_navy;
                    }
                }

                @if $type ==sp {
                    padding: 0 1em;
                    margin: 3px 1em 4px 0;
                    line-height: 20px;
                }

                @if $type ==pc {
                    border-top: 1px solid #fff;
                }
            }

        }

        .newsListWho {
            @if $type =="" {}

            @if $type ==sp {
                width: 100%;
                line-height: 1.6;
            }

            @if $type ==pc {
                margin-left: 70px;
                width: 310px;
                padding-right: 20px;
                font-size: 13px;
            }

            .newsListWhoTitle {
                @if $type =="" {}

                @if $type ==sp {
                    font-size: 15px;
                }

                @if $type ==pc {
                    font-size: 15px;
                }
            }
        }

        .newsListNote {
            @if $type =="" {
                a {
                    color: #000;
                    text-decoration: none;
                }
            }

            @if $type ==sp {
                width: 100%;
                margin-top: 7px;

                a {
                    display: inline-block;
                }
            }

            @if $type ==pc {
                a {
                    display: inline-block;
                    transition: .2s color;

                    &:hover {
                        color: $c_base;
                    }
                }
            }


        }
    }
}

//magnificPopup


.mfp-container {
    @if $type ==sp {
        overflow: hidden;
    }
}

.mfp-content {
    @if $type =="" {}

    @if $type ==sp {
        width: calc(100% - 40px) !important;
        padding: 50px 0 0;
        overflow: hidden;
        background: #fff;
    }

    @if $type ==pc {
        @include maxPC;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.modalContent {
    @if $type =="" {
        overflow-y: scroll;
    }

    @if $type ==sp {
        max-height: 60vh;
        padding: 0 20px 0;
        margin-bottom: 50px;
    }

    @if $type ==pc {
        padding: 46px 70px;
        max-height: 80vh;
        background: #fff;
    }
}

.mfp-close {
    @if $type =="" {}

    @if $type ==sp {
        font-size: 50px;
    }

    @if $type ==pc {
        font-size: 60px;
        right: 60px;
        top: 60px;
    }
}

*+.attentionNote {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 30px;
    }
}

.attentionNote {
    @if $type =="" {
        text-align: center;
        font-weight: bold;
        color: $c_navy;
        line-height: 1.91;
    }

    @if $type ==sp {
        font-size: 16px;
    }

    @if $type ==pc {
        font-size: 24px;
    }
}

.icon_plug {
    @if $type =="" {
        vertical-align: middle;
        display: inline-block;
    }

    @if $type ==sp {
        width: 30px;
    }

    @if $type ==pc {}
}

.boxContent {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    >.boxContentItem {
        @if $type =="" {
            border: 1px solid #bdbdbd;
        }

        @if $type ==sp {
            padding: 20px 15px;
        }

        @if $type ==pc {
            width: 48.3%;
            padding: 35px 4%;

            &:nth-child(n + 3) {
                margin-top: 42px;
            }
        }

        .boxContentHeader {
            @if $type =="" {
                background-position: right center;
                background-repeat: no-repeat;
            }

            @if $type ==sp {
                margin-bottom: 30px;
            }

            @if $type ==pc {
                margin-bottom: 48px;
            }

            .boxContentTitleSub {
                @if $type =="" {
                    color: $c_base;
                    @include fontRo;
                    font-weight: bold;
                }

                @if $type ==sp {
                    font-size: 12px;
                }

                @if $type ==pc {
                    margin-bottom: 5px;
                    font-size: 14px;
                }
            }

            .boxContentTitle {
                @if $type =="" {
                    font-weight: 400;
                    position: relative;
                    line-height: 1.4;
                }

                @if $type ==sp {
                    padding-bottom: 8px;
                    font-size: 18px;
                }

                @if $type ==pc {
                    padding-bottom: 16px;
                    font-size: 24px;
                }

                &:before {
                    @if $type =="" {
                        content: "";
                        height: 2px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: $c_base;
                    }

                    @if $type ==sp {
                        width: 20px;
                    }

                    @if $type ==pc {
                        width: 30px;
                    }
                }
            }

            .boxContentTitleNote {
                @if $type =="" {
                    font-weight: 400;
                }

                @if $type ==sp {
                    margin-top: 8px;
                    font-size: 16px;
                }

                @if $type ==pc {
                    margin-top: 15px;
                    font-size: 18px;
                }
            }
        }
    }

    >.boxContentItemFull {
        @if $type =="" {
            border: 1px solid #bdbdbd;
        }

        @if $type ==sp {
            padding: 20px 15px;
        }

        @if $type ==pc {
            width: 100%;
            padding: 35px 4%;

            &:nth-child(n + 3) {
                margin-top: 42px;
            }
        }

        .boxContentHeader {
            @if $type =="" {
                background-position: right center;
                background-repeat: no-repeat;
            }

            @if $type ==sp {
                margin-bottom: 30px;
            }

            @if $type ==pc {
                margin-bottom: 48px;
            }

            .boxContentTitleSub {
                @if $type =="" {
                    color: $c_base;
                    @include fontRo;
                    font-weight: bold;
                }

                @if $type ==sp {
                    font-size: 12px;
                }

                @if $type ==pc {
                    margin-bottom: 5px;
                    font-size: 14px;
                }
            }

            .boxContentTitle {
                @if $type =="" {
                    font-weight: 400;
                    position: relative;
                    line-height: 1.4;
                }

                @if $type ==sp {
                    padding-bottom: 8px;
                    font-size: 18px;
                }

                @if $type ==pc {
                    padding-bottom: 16px;
                    font-size: 24px;
                }

                &:before {
                    @if $type =="" {
                        content: "";
                        height: 2px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: $c_base;
                    }

                    @if $type ==sp {
                        width: 20px;
                    }

                    @if $type ==pc {
                        width: 30px;
                    }
                }
            }

            .boxContentTitleNote {
                @if $type =="" {
                    font-weight: 400;
                }

                @if $type ==sp {
                    margin-top: 8px;
                    font-size: 16px;
                }

                @if $type ==pc {
                    margin-top: 15px;
                    font-size: 18px;
                }
            }
        }
    }
}

.mfp-image-holder .mfp-content {
    @if $type =="" {
    }
    @if $type ==sp {
        max-width: 660px;
        padding-top: 0;
        background: transparent;
    }
    @if $type ==pc {
        max-width: 1000px;
        padding: 0;
    }

    img.mfp-img {
        @if $type =="" {
        }
        @if $type ==sp {
            padding: 50px 0;
        }
        @if $type ==pc {
            padding: 80px 0;
        }
    }

    .mfp-figure:after {
        @if $type =="" {
            display: none;
        }
        @if $type ==sp {
        }
        @if $type ==pc {
        }
    }
}

.mfp-image-holder .mfp-close {
    @if $type =="" {
    }
    @if $type ==sp {
        top: 0;
    }
    @if $type ==pc {
        top: 30px;
    }
}


.btnListSummary {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    >div {
        @if $type =="" {}

        @if $type ==sp {
            +div {
                margin-top: 30px;
            }
        }

        @if $type ==pc {
            display: flex;
            justify-content: space-between;
            align-items: center;

            +div {
                margin-top: 20px;
            }
        }

        >dt {
            @if $type =="" {}

            @if $type ==sp {
                margin-bottom: 6px;
                font-size: 16px;
            }

            @if $type ==pc {
                width: calc(100% - 175px);
                padding-right: 15px;
                line-height: 1.4;
            }
        }

        >dd {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                width: 175px;
            }

            a {
                @if $type =="" {
                    display: block;
                    min-width: inherit !important;
                }

                @if $type ==sp {}

                @if $type ==pc {
                    font-size: 14px;

                    div>[class^=icon] {
                        transform: none;
                    }
                }
            }
        }
    }
}

*+.aboutList {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 30px;
    }

    @if $type ==pc {
        margin-top: 20px;
    }
}

.aboutList {
    @if $type =="" {}

    @if $type ==sp {

        >div {
            +div {
                margin-top: 20px;
            }
        }
    }

    @if $type ==pc {
        border-top: 1px solid $c_navy;
        border-bottom: 1px solid $c_navy;
        border-right: 1px solid $c_navy;
    }

    >div {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
            position: relative;


        }

        +div {
            border-top: 1px solid $c_navy;

            >dt {
                &:before {
                    @if $type =="" {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: #fff;
                        position: absolute;
                        top: -1px;
                        left: 0;
                    }

                    @if $type ==sp {}

                    @if $type ==pc {}
                }
            }
        }

        >dt {
            @if $type =="" {
                color: #fff;
                text-align: center;
                background: $c_navy;
                position: relative;
            }

            @if $type ==sp {
                padding: 8px 10px;
                font-size: 16px;
            }

            @if $type ==pc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 20%;
                padding: 15px 10px;
            }

            >span {
                @if $type =="" {
                    display: block;
                }

                @if $type ==sp {
                    font-size: 12px;
                }

                @if $type ==pc {
                    font-size: 18px;
                }
            }
        }

        >dd {
            @if $type =="" {
                background: #fff;
            }

            @if $type ==sp {
                border: 1px solid $c_navy;
                padding: 12px 10px;
            }

            @if $type ==pc {
                width: 80%;
                padding: 15px 26px;
            }

        }
    }
}


[class^="aboutListSmall"] {
    @if $type =="" {
        font-weight: bold;
    }

    @if $type ==sp {
        margin-top: 40px;
    }

    @if $type ==pc {
        margin-top: 20px
    }

    >div {
        @if $type =="" {
            border: 2px solid $c_base;
            position: relative;
        }

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
            position: relative;
        }

        +div {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 20px;
            }

            @if $type ==pc {
                margin-top: 15px;
            }
        }

        >dt {
            @if $type =="" {
                color: #fff;
                text-align: center;
                background: $c_base;
                position: relative;
            }

            @if $type ==sp {
                padding: 8px 10px;
                font-size: 16px;
            }

            @if $type ==pc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 210px;
                padding: 19px 10px;
                font-size: 18px;
            }

        }

        >dd {
            @if $type =="" {
                background: #fff;
            }

            @if $type ==sp {
                padding: 12px 10px;
            }

            @if $type ==pc {
                width: calc(100% - 210px);
                padding: 19px 55px;
            }

        }
    }
}




//ecology point 共同カイテックの製品エコロジー
.page_company_ecology {
    .companyEcologyLead {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {}

        @if $type ==pc {
            width: 100%;
            height: 536px;

            +.section .inner {
                padding-top: 53px;
            }
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }

            @if $type ==sp {}

            @if $type ==pc {
                width: 48.58%;
                height: 536px;
                background: url(../images/company/ecology/main_left.jpg) no-repeat right bottom;
                background-size: cover;
            }
        }

        &:after {
            @if $type =="" {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
            }

            @if $type ==sp {
                width: 44.2667%;
                max-width: 300px;
                height: 281px;
                background: url(../images/company/ecology/main_right.jpg) no-repeat left bottom;
                background-size: cover;
                z-index: -1;
            }

            @if $type ==pc {
                width: 51.3586%;
                height: 536px;
                background: url(../images/company/ecology/main_right.jpg) no-repeat left bottom;
                background-size: cover;
                transition: .2s right;
            }

            @if $type ==tb {
                right: -10vw;
            }
        }

        .content {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                padding-top: 76px;
                margin: 0 auto;
                position: relative;
                max-width: 500px;
                transform: translateX(-13.9348vw);
                transition: .2s transform;
            }

            @if $type ==tb {}

            .companyEcologyLeadCatch {
                @if $type =="" {}

                @if $type ==sp {
                    display: flex;
                    align-items: center;
                    height: 281px;
                    margin-right: 30.4%;
                    margin-left: 5.0667%;
                    max-width: 242px;
                }

                @if $type ==pc {
                    width: 500px;
                }
            }

            .companyEcologyLeadNote {
                @if $type =="" {}

                @if $type ==sp {
                    max-width: 740px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin: 16px auto 0;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.6429;
                }

                @if $type ==pc {
                    margin-top: 22px;
                    font-size: 16px;
                    line-height: 2;
                }
            }
        }
    }

    .companyEcologyAnchorLead {
        @if $type =="" {
            text-align: center;
        }

        @if $type ==sp {
            margin-bottom: 15px;
            font-size: 18px;
        }

        @if $type ==pc {
            margin-bottom: 23px;
            font-size: 24px;
        }
    }

    .anchorListEcology {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
        }

        >li {
            @if $type =="" {
                background: #f9f9f9;
            }

            @if $type ==sp {
                +li {
                    margin-top: 20px;
                }
            }

            @if $type ==pc {
                width: 33.3%;
            }

            a {
                @if $type =="" {
                    display: block;
                    color: #242e44;
                    text-decoration: none;
                    line-height: 1.75;
                }

                @if $type ==sp {
                    img {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                        max-width: inherit;
                    }
                }

                @if $type ==pc {
                    &:hover {
                        .anchorListEcologyBody {
                            &:before {
                                bottom: 10px;
                            }
                        }
                    }
                }

                .anchorListEcologyBody {
                    @if $type =="" {
                        position: relative;
                    }

                    @if $type ==sp {
                        padding: 20px 15px 50px;
                    }

                    @if $type ==pc {
                        padding: 20px 25px 50px;
                    }

                    &:before {
                        @if $type =="" {
                            content: "";
                            display: block;
                            background: url(../images/common/icon_dash_pink.svg) no-repeat;
                            background-size: contain;
                            transform: rotate(90deg);
                            position: absolute;
                            right: 0;
                            left: 0;
                            margin: auto;
                        }

                        @if $type ==sp {
                            width: 23px;
                            height: 19px;
                            bottom: 12px;
                            transition: .2s bottom;
                        }

                        @if $type ==pc {
                            width: 23px;
                            height: 19px;
                            bottom: 18px;
                            transition: .2s bottom;
                        }
                    }
                }
            }
        }
    }

    .ecologyLeadListWrap {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            padding-top: 10%;
            margin-top: 35px;
        }

        @if $type ==pc {
            padding-top: 100px;
            margin-top: 55px;
        }

        .ecologyLeadTitle {
            @if $type =="" {
                text-align: center;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 40vw;
                max-width: 200px;
                z-index: 1;
            }

            @if $type ==pc {}
        }

        .ecologyList {
            @if $type =="" {
                display: flex;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }
    }

    .busductSummary {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
            flex-direction: column;
            justify-content: center;

        }

        .companyEcologyTitle {
            @if $type =="" {}

            @if $type ==sp {
                padding: 0 17px;
            }

            @if $type ==pc {}
        }

        .busductSummaryText {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                margin-top: 20px;
            }

            .busductTitleLead {
                @if $type =="" {
                    color: #242e44;
                }

                @if $type ==sp {
                    margin-top: 17px;
                    font-size: 18px;
                    line-height: 1.5556;
                }

                @if $type ==pc {
                    font-size: 24px;
                    line-height: 1.5833;
                }
            }

            .busductNote {
                @if $type =="" {}

                @if $type ==sp {
                    margin-top: 25px;
                    margin-bottom: 31px;
                    font-size: 13px;
                    line-height: 2;
                }

                @if $type ==pc {
                    margin-top: 36px;
                    font-size: 14px;
                    line-height: 2;
                }
            }
        }
    }

    #ecologyBusduct {
        @if $type =="" {}

        @if $type ==sp {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 90%;
                height: 45vw;
                max-height: 194px;
                margin-bottom: 30px;
                margin-left: auto;
                background: url(../images/company/ecology/busduct_image.png) no-repeat right top;
                background-size: contain;
            }

            &:after {
                //content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: #f6f6f6;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
            }

        }

        @if $type ==pc {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 500px;
                background: #f6f6f6;
                position: absolute;
                top: 40px;
                right: 0;
                z-index: -1;
            }

            &:after {
                content: "";
                display: block;
                width: 51.087%;
                height: 612px;
                background: url(../images/company/ecology/busduct_image.png) no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }

        .inner {
            @if $type =="" {}

            @if $type ==sp {
                padding-top: 0;

                &:nth-of-type(1) {
                    padding-bottom: 0;
                }
            }

            @if $type ==pc {
                padding-top: 80px;

                &:nth-of-type(1) {
                    padding-top: 40px;
                }
            }

            .busductSummary {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    width: 51.5%;
                    min-height: 490px;
                    max-width: 600px;
                }

                .companyEcologyTitle {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding: 0;
                        margin-bottom: 17px;
                        font-size: 20px;
                        text-align: center;
                        line-height: 1.5;
                    }

                    @if $type ==pc {
                        margin-right: -12px;
                    }
                }

                .busductTitleLead {
                    @if $type =="" {
                        color: #242e44;
                    }

                    @if $type ==sp {
                        margin-bottom: 24px;
                        font-size: 20px;
                        line-height: 1.5;
                    }

                    @if $type ==pc {
                        font-size: 24px;
                        line-height: 1.5833;
                    }
                }

                .busductNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        line-height: 1.8571;
                    }

                    @if $type ==pc {
                        margin-top: 10px;
                        font-size: 20px;
                        line-height: 1.9;
                    }
                }
            }
        }
    }

    #ecologyOa {
        @if $type =="" {}

        @if $type ==sp {
            padding: 46px 0 0;
        }

        @if $type ==pc {
            padding-top: 80px;
            padding-bottom: 50px;
            position: relative;
        }

        &:before {
            @if $type =="" {}

            @if $type ==sp {
                content: "";
                display: block;
                width: 90%;
                height: 45vw;
                max-height: 194px;
                margin-bottom: 30px;
                margin-right: auto;
                background: url(../images/company/ecology/oa_image.png) no-repeat left top;
                background-size: contain;
            }

            @if $type ==pc {
                content: "";
                display: block;
                width: 100%;
                height: 500px;
                background: #f6f6f6;
                position: absolute;
                top: 80px;
                right: 0;
                z-index: -1;
            }
        }

        &:after {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                content: "";
                display: block;
                width: 51.087%;
                height: 492px;
                background: url(../images/company/ecology/oa_image.png) no-repeat right bottom;
                background-size: cover;
                position: absolute;
                top: 40px;
                left: 0;
            }
        }

        .inner {
            @if $type ==sp {
                &:nth-of-type(1) {
                    padding-top: 0;
                }
            }
        }

        .oaSummary {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                display: flex;
                flex-direction: column;
                justify-content: center;
               // max-width: 500px;
                min-height: 446px;
                margin-left: 54.0761%;
                margin-bottom: 90px;
            }

            .ecologyPointTitle {
                @if $type =="" {}

                @if $type ==sp {
                    padding: 0;
                    margin-bottom: 12px;
                    text-align: center;
                }

                @if $type ==pc {
                    margin-right: -61px;
                }

            }

            .oaSummaryText {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    max-width: 500px;
                    margin: 0;
                }

                .oaNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        font-size: 14px;
                        line-height: 1.8571;
                    }

                    @if $type ==pc {
                        margin-top: 20px;
                        font-size: 20px;
                        line-height: 1.9;
                    }
                }
            }
        }
    }

    #ecologyGreen {
        @if $type =="" {}

        @if $type ==sp {
            padding-top: 46px;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 90%;
                height: 45vw;
                max-height: 194px;
                margin-bottom: 30px;
                margin-left: auto;
                background: url(../images/company/ecology/greening_image.png) no-repeat right top;
                background-size: contain;
            }

            &:after {
                //content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: #f6f6f6;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
        }

        @if $type ==pc {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 470px;
                background: #f6f6f6;
                position: absolute;
                top: 80px;
                right: 0;
                z-index: -1;
            }

            &:after {
                content: "";
                display: block;
                width: 51.087%;
                height: 492px;
                background: url(../images/company/ecology/greening_image.png) no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }

        .inner {
            @if $type =="" {}

            @if $type ==sp {
                padding-top: 0;
            }

            @if $type ==pc {
                padding-top: 80px;
            }

            .greenSummary {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    width: 51.5%;
                    max-width: 600px;
                    min-height: 467px;
                    margin-bottom: 75px;
                }

                .greenSummaryText {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        margin-top: 20px;
                    }
                }

                .companyEcologyTitle {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding: 0;
                        margin-bottom: 17px;
                        font-size: 20px;
                        text-align: center;
                        line-height: 1.5;
                    }

                    @if $type ==pc {
                        margin-right: -12px;
                    }
                }

                .ecologyTitleLead {
                    @if $type =="" {
                        color: #242e44;
                    }

                    @if $type ==sp {
                        margin-bottom: 22px;
                        font-size: 18px;
                        line-height: 1.72;
                    }

                    @if $type ==pc {
                        font-size: 24px;
                        line-height: 1.5833;
                    }
                }

                .greenNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        line-height: 1.8571;
                    }

                    @if $type ==pc {
                        margin-top: 10px;
                        font-size: 20px;
                        line-height: 1.9;
                    }
                }
            }
        }
    }

    .iconList {
        @if $type =="" {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        @if $type ==sp {}

        @if $type ==pc {
            max-width: 1000px;
            margin: 0 auto;
            justify-content: center;
        }

        >li {
            @if $type =="" {
                text-align: center;
            }

            @if $type ==sp {
                width: 50%;
                padding: 0 12px;

                &:nth-child(n + 3) {
                    margin-top: 4%;
                }
            }

            @if $type ==pc {
                width: 33.3%;
                padding: 24px;
            }
        }
    }
}

//company point 共同カイテックの3つの力
.page_company_point {
    .companyPointLead {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {
            margin-bottom: 34px;
        }

        @if $type ==pc {
            width: 100%;
            height: 536px;
            background: url(../images/company/point/point_main.jpg) no-repeat center;
            background-size: cover;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }

            @if $type ==sp {
                width: 44.2667%;
                max-width: 166px;
                height: 281px;
                background: url(../images/company/point/main_sp.jpg) no-repeat right bottom;
                background-size: cover;
                z-index: -1;
            }
        }

        //     @if $type ==pc {
        //         width: 58.3696%;
        //         height: 536px;
        //         background: url(../images/company/point/main_left.jpg) no-repeat right bottom;
        //         background-size: cover;
        //     }
        // }

        // &:after {
        //     @if $type =="" {
        //         content: "";
        //         display: block;
        //         position: absolute;
        //         right: 0;
        //         top: 0;
        //         z-index: -1;
        //     }

        //     @if $type ==sp {}

        //     @if $type ==pc {
        //         width: 22.2826%;
        //         height: 536px;
        //         background: url(../images/company/point/main_right.jpg) no-repeat left bottom;
        //         background-size: cover;
        //         transition: .2s right;
        //     }

        //     @if $type ==tb {
        //         right: -10vw;
        //     }
        // }

        .content {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                padding-top: 96px;
                margin: 0 auto;
                position: relative;
                max-width: 410px;
                transform: translateX(18.0652vw);
                transition: .2s transform;
            }

            @if $type ==tb {
                transform: translateX(25.0652vw);
            }

            .companyPointLeadCatch {
                @if $type =="" {}

                @if $type ==sp {
                    display: flex;
                    align-items: center;
                    height: 281px;
                    margin-left: 30.4%;
                    margin-right: 5.0667%;
                    max-width: 242px;
                }

                @if $type ==pc {
                    width: 350px;
                }
            }

            .companyPointLeadNote {
                @if $type =="" {}

                @if $type ==sp {
                    max-width: 740px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin: 16px auto;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.6429;
                }

                @if $type ==pc {
                    margin-top: 22px;
                    font-size: 16px;
                    line-height: 2;
                }
            }
        }
    }

    .anchorList {
        >li {
            &.anchorListItemBusiness {
                >a {
                    @if $type =="" {
                        background: #292874;
                    }

                    @if $type ==pc {
                        &:before {
                            background: #181754;
                        }
                    }
                }
            }

            &.anchorListItemProduct {
                >a {
                    @if $type =="" {
                        background: #75af2e;
                    }

                    @if $type ==pc {
                        &:before {
                            background: #55931b;
                        }
                    }
                }
            }

            &.anchorListItemService {
                >a {
                    @if $type =="" {
                        background: #005ba4;
                    }

                    @if $type ==pc {
                        &:before {
                            background: #003d86;
                        }
                    }
                }
            }
        }
    }

    .businessSummary {
        @if $type =="" {
            display: flex;
        }

        @if $type ==sp {
            flex-direction: column;
        }

        @if $type ==pc {
            align-items: center;
            justify-content: space-between;
        }

        .companyPointTitle {
            @if $type =="" {}

            @if $type ==sp {
                padding: 0 17px;
            }

            @if $type ==pc {
                order: 1;
                width: 46.5%;
            }
        }

        .businessSummaryText {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                width: 50%;
                margin-top: 50px;
            }

            .companyPointTitleLead {
                @if $type =="" {
                    color: #242e44;
                }

                @if $type ==sp {
                    margin-top: 17px;
                    font-size: 18px;
                    line-height: 1.5556;
                }

                @if $type ==pc {
                    font-size: 24px;
                    line-height: 1.5833;
                }
            }

            .companyPointNote {
                @if $type =="" {}

                @if $type ==sp {
                    margin-top: 25px;
                    margin-bottom: 31px;
                    font-size: 13px;
                    line-height: 2;
                }

                @if $type ==pc {
                    margin-top: 36px;
                    font-size: 14px;
                    line-height: 2;
                }
            }

        }
    }

    .businessList {
        @if $type =="" {
            position: relative;
        }

        @if $type ==sp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @if $type ==pc {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 50px;
            margin-bottom: 48px;
        }

        &:after {
            @if $type =="" {
                content: "";
                background: url(../images/company/point/business_list_company.png) no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 40.597%;
                max-width: 136px;
                max-height: 136px;
                height: 0;
                padding-top: 40.597%;
            }

            @if $type ==pc {
                width: 20.9333%;
                max-width: 250px;
                max-height: 250px;
                height: 0;
                padding-top: 20.9333%;
            }
        }

        >li {
            @if $type =="" {}

            @if $type ==sp {
                width: 49.5522%;

                &:nth-child(n + 3) {
                    margin-top: 4px;
                }
            }

            @if $type ==pc {
                width: 49.33%;

                &:nth-child(n + 3) {
                    margin-top: 16px;
                }

            }
        }

    }

    #companyPointProduct {
        @if $type =="" {}

        @if $type ==sp {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: calc(100% - 35px);
                background: #f6f6f6;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
        }

        @if $type ==pc {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: calc(100% - 177px);
                background: #f6f6f6;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
            }

            &:after {
                content: "";
                display: block;
                width: 53.913%;
                height: 612px;
                background: url(../images/company/point/product_image.png) no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }

        .inner {
            @if $type =="" {}

            @if $type ==sp {
                padding-top: 0;
            }

            @if $type ==pc {}

            .productSummary {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    width: 46.5%;
                    padding-top: 36px;
                    min-height: 532px;
                }

                .companyPointTitle {
                    @if $type =="" {}

                    @if $type ==sp {
                        padding: 0 17px;
                        margin-bottom: 17px;
                        font-size: 20px;
                        text-align: center;
                        line-height: 1.5;
                    }

                    @if $type ==pc {}
                }

                .companyPointTitleLead {
                    @if $type =="" {
                        color: #242e44;
                    }

                    @if $type ==sp {
                        margin-bottom: 24px;
                        font-size: 20px;
                        line-height: 1.5;
                    }

                    @if $type ==pc {
                        font-size: 24px;
                        line-height: 1.5833;
                    }
                }

                .companyPointNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        margin-bottom: 37px;
                        line-height: 1.8571;
                    }

                    @if $type ==pc {
                        margin-top: 36px;
                        font-size: 14px;
                        line-height: 2;
                    }
                }

            }
        }

        .productList {
            @if $type =="" {}

            @if $type ==sp {
                >li {
                    +li {
                        margin-top: 22px;
                    }
                }
            }

            @if $type ==pc {
                display: flex;
                margin-top: 63px;
                margin-bottom: 15px;
            }

            >li:not(:first-child) {
                .productListBtn {
                    margin-left: 1px;
                }
            }
        }

    }

    .productListBtn {
        li {
            @if $type =="" {}

            @if $type ==sp {
                +li {
                    margin-top: 1px;
                }
            }

            @if $type ==pc {
                +li {
                    margin-top: 1px;
                }
            }
        }
    }

    #companyPointService {
        @if $type =="" {}

        @if $type ==sp {
            padding: 46px 20px 60px;
        }

        @if $type ==pc {
            padding-top: 170px;
            padding-bottom: 130px;
            position: relative;
        }

        &:before {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                content: "";
                display: block;
                width: 55.8152%;
                height: 604px;
                background: url(../images/company/point/service_image.png) no-repeat right bottom;
                background-size: cover;
                position: absolute;
                top: 110px;
                left: 0;
            }
        }

        .serviceSummary {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                max-width: 567px;
                min-height: 544px;
                margin-left: 54.0761%;
            }

            .companyPointTitle {
                @if $type =="" {}

                @if $type ==sp {
                    padding: 0 17px;
                    margin-bottom: 12px;
                    text-align: center;
                }

                @if $type ==pc {}

                @if $type ==pc {
                    width: 100%;
                }
            }

            .serviceSummaryText {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    width: 75.0883%;
                    margin: 0 auto;
                }

                .companyPointTitleLead {
                    @if $type =="" {
                        color: #242e44;
                    }

                    @if $type ==sp {
                        margin-bottom: 22px;
                        font-size: 18px;
                        line-height: 1.72;
                    }

                    @if $type ==pc {
                        margin-top: 16px;
                        font-size: 24px;
                        text-align: left;
                        line-height: 1.5833;
                    }
                }

                .companyPointNote {
                    @if $type =="" {}

                    @if $type ==sp {
                        font-size: 14px;
                        line-height: 1.8571;
                    }

                    @if $type ==pc {
                        margin-top: 38px;
                        font-size: 14px;
                        line-height: 2;
                    }
                }
            }
        }

        .companyMenuHeader {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 33px;
                margin-bottom: 19px;
                text-align: center;
            }

            @if $type ==pc {
                margin-top: 74px;
                margin-bottom: 30px;
                text-align: center;
            }

            .companyMenuTitle {
                @if $type =="" {
                    color: #005ba4;
                }

                @if $type ==sp {
                    font-size: 18px;
                }

                @if $type ==pc {
                    font-size: 24px;
                }
            }

            .companyMenuTitleSub {
                @if $type =="" {}

                @if $type ==sp {
                    font-size: 13px;
                }

                @if $type ==pc {
                    font-size: 14px;
                }
            }
        }

        .companyMenuWrap {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                @include maxPC;
                display: flex;
                justify-content: space-between;
            }

            >li {
                @if $type =="" {
                    background: #f2f2f2;

                    &:nth-child(1) {
                        .companyMenuHeader {
                            background: #005ba4;
                        }
                    }

                    &:nth-child(2) {
                        .companyMenuHeader {
                            background: #292874;
                        }
                    }

                    &:nth-child(3) {
                        .companyMenuHeader {
                            background: #75af2e;
                        }
                    }

                    &:nth-child(4) {
                        .companyMenuHeader {
                            background: #c90056;
                        }
                    }
                }

                @if $type ==sp {
                    display: flex;
                    width: 100%;

                    +li {
                        margin-top: 20px;
                    }
                }

                @if $type ==pc {
                    width: 31.6667%;
                }

                .companyMenuHeader {
                    @if $type =="" {
                        margin: 0;
                        color: #fff;
                    }

                    @if $type ==sp {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 31.4371%;
                        padding: 10px 10px;
                    }

                    @if $type ==pc {
                        padding: 29px 10px 15px;
                    }

                    i {
                        @if $type =="" {
                            display: inline-block;
                        }

                        @if $type ==sp {
                            width: 25px;
                            height: 20px;
                            margin-bottom: 6px;
                        }

                        @if $type ==pc {
                            width: 50px;
                            height: 40px;
                        }

                    }

                    h4 {
                        @if $type =="" {}

                        @if $type ==sp {
                            font-size: 14px;
                            line-height: 1.28;
                        }

                        @if $type ==pc {
                            margin-top: 11px;
                            font-size: 16px;
                        }
                    }
                }

                .companyMenuBody {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: 68.5629%;
                        padding: 19px 18px 24px;
                    }

                    @if $type ==pc {
                        padding: 30px 22px 52px;
                    }

                    .companyMenuList {
                        >li {
                            @if $type =="" {
                                position: relative;
                            }

                            @if $type ==sp {
                                padding-left: 15px;
                                font-size: 13px;

                                +li {
                                    margin-top: 12px;
                                }
                            }

                            @if $type ==pc {
                                padding-left: 15px;
                                font-size: 14px;

                                +li {
                                    margin-top: 12px;
                                }
                            }

                            &:before {
                                @if $type =="" {
                                    content: "";
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 0;
                                    background: #005aa1;
                                }

                                @if $type ==sp {
                                    width: 5px;
                                    height: 5px;
                                    top: .6em;
                                }

                                @if $type ==pc {
                                    width: 5px;
                                    height: 5px;
                                    top: .75em;
                                }
                            }

                            >a {
                                @if $type =="" {}

                                @if $type ==sp {}

                                @if $type ==pc {}
                            }
                        }
                    }
                }
            }
        }
    }
}


//3分でわかる共同カイテックとは
.page_company_learn {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}


    .companyLearnLead {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {}

        @if $type ==pc {
            display: flex;
            align-items: center;
            width: 100%;
            height: 536px;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }

            @if $type ==sp {
                width: 36.8%;
                max-width: 128px;
                height: 281px;
                background: url(../images/company/learn/main_sp.jpg) no-repeat right bottom;
                background-size: cover;
                z-index: -1;
            }

            @if $type ==pc {
                width: 22.8261%;
                height: 536px;
                background: url(../images/company/learn/main_left.jpg) no-repeat right bottom;
                background-size: cover;
            }
        }

        &:after {
            @if $type =="" {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
            }

            @if $type ==sp {}

            @if $type ==pc {
                width: 51.6304%;
                height: 536px;
                background: url(../images/company/learn/main_right.jpg) no-repeat left bottom;
                background-size: cover;
                transition: .2s right;
            }

            @if $type ==tb {}
        }

        .content {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                padding-bottom: 45px;
                margin: 0 auto;
                position: relative;
                max-width: 500px;
                width: 37.1739%;
                transform: translateX(-13.5vw);
                transition: .2s transform;
            }

            @if $type ==tb {
                transform: translateX(-14vw);
            }

            .companyLearnLeadCatch {
                @if $type =="" {}

                @if $type ==sp {
                    display: flex;
                    align-items: flex-start;
                    height: 281px;
                    padding-top: 25px;
                    margin-left: 21.3%;
                    margin-right: 5.3333%;
                    max-width: 250px;
                }

                @if $type ==pc {}
            }

            .companyLearnLeadNote {
                @if $type =="" {}

                @if $type ==sp {
                    max-width: 740px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin: 35px auto;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.6429;
                }

                @if $type ==pc {
                    padding-right: 1.8em;
                    padding-left: 1.8em;
                    margin-top: 34px;
                    font-size: 16px;
                    line-height: 2;
                    font-weight: bold;
                    text-align: center;
                }

                @if $type ==tb {
                    padding-right: 0.5em;
                    padding-left: 0.5em;

                }
            }
        }
    }

    .section {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {}

        .companyLearnList {
            @if $type =="" {
                strong {
                    color: $c_base;
                    @include fontRo;
                }

                .companyLearnTitle {
                    color: $c_navy;
                    font-weight: bold;
                }

            }

            @if $type ==sp {
                margin-top: 40px;
            }

            @if $type ==pc {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 95px;
            }

            >li {
                @if $type =="" {
                    border: 1px solid #d3ced0;
                }

                @if $type ==sp {
                    padding: 31px 19px 33px;

                    +li {
                        margin-top: 20px;
                    }

                    .companyLearnThumb {
                        text-align: center;
                    }

                    .companyLearnText {
                        margin-top: 12px;
                        text-align: center;

                        .companyLearnTitle {
                            font-size: 21px;
                            line-height: 1.619;
                            font-feature-settings: "palt";
                        }

                        .companyLearnNote {
                            margin-top: 8px;
                            line-height: 1.8571;
                        }
                    }

                    &.companyLearnYear {
                        .companyLearnText {
                            .companyLearnTitle {
                                strong {
                                    font-size: 31px;
                                    line-height: 1;

                                    span {
                                        font-size: 54px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }

                }

                @if $type ==pc {
                    width: calc(50% - 8px);
                    padding: 49px 7px 60px;

                    &.companyLearnYear,
                    &.companyLearnTechnology,
                    &.companyLearnJob,
                    &.companyLearnPillar,
                    &.companyLearnAchievement,
                    &.companyLearnOffice {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .companyLearnNote {
                            text-align: left;
                        }
                    }

                    +li {
                        margin-top: 15px;
                    }
                }

                &.companyLearnYear {
                    @if $type ==pc {
                        padding-right: 0;

                        .companyLearnThumb {
                            width: 54.424%;
                            order: 1;
                        }

                        .companyLearnText {
                            width: 36.0833%;
                            margin: 0 auto;
                            padding-right: 0;
                            padding-left: 0;
                            order: 0;

                            .companyLearnTitle {
                                margin-bottom: 16px;
                                font-size: 36px;
                                font-size: clamp(10px, 2.6667vw, 36px);
                                line-height: 1.5em;
                                text-align: left;

                                strong {
                                    font-size: 3.91vw;
                                    font-size: clamp(10px, 3.91vw, 47px);

                                    span {
                                        font-size: 7vw;
                                        font-size: clamp(10px, 7vw, 84px);
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }

                &.companyLearnTechnology {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        padding: 68px 0 0;
                        align-items: flex-end;

                        .companyLearnThumb {
                            width: 54.1667%;
                            margin-right: 5%;
                        }

                        .companyLearnText {
                            width: 43.75%;
                            padding: 0 0 68px;
                            margin: 0 auto;

                            .companyLearnTitle {
                                text-align: left;
                                font-size: clamp(10px, 2.6667vw, 32px);
                                line-height: 3rem;
                                font-feature-settings: "palt";

                                strong {
                                    font-size: clamp(10px, 3vw, 36px);
                                }
                            }

                            .companyLearnNote {
                                margin-top: 12px;
                            }
                        }
                    }
                }

                &.companyLearnJob {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        padding: 41px 0;

                        .companyLearnThumb {
                            width: 47.75%;
                            margin: 0 auto;
                        }

                        .companyLearnText {
                            width: 34.9167%;
                            margin-right: auto;
                            text-align: left;
                            padding-right: 0;
                            padding-left: 0;

                            .companyLearnTitle {
                                font-size: clamp(10px, 2.6667vw, 32px);
                                line-height: 3rem;
                            }

                            .companyLearnNote {
                                margin-top: 14px;
                            }
                        }
                    }
                }

                &.companyLearnPillar {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        .companyLearnThumb {
                            order: 1;
                            width: 47.75%;
                            margin: 0 auto 0 0;
                        }

                        .companyLearnText {
                            order: 0;
                            width: 49.9333%;
                            padding-left: 7.9167%;
                            padding-right: 0;
                            text-align: left;

                            .companyLearnTitle {
                                font-size: clamp(10px, 2.6667vw, 32px);
                                line-height: 3rem;
                            }

                            .companyLearnNote {
                                margin-top: 14px;
                            }
                        }
                    }
                }

                &.companyLearnAchievement {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        padding: 7.75% 15px 8.16%;
                    }

                    .companyLearnThumb {
                        @if $type =="" {}

                        @if $type ==sp {}

                        @if $type ==pc {
                            width: 43.08%;
                            margin-left: 7.08%;
                            margin-right: 8.33%;
                        }
                    }

                    .companyLearnText {
                        @if $type =="" {}

                        @if $type ==sp {}

                        @if $type ==pc {
                            padding: 0;
                            margin-right: auto;
                        }

                        .companyLearnTitle {
                            @if $type =="" {
                                color: $c_base;
                            }

                            @if $type ==sp {}

                            @if $type ==pc {
                                font-size: 36px;
                                text-align: left;
                                line-height: 1.4;
                            }
                        }
                    }
                }

                &.companyLearnOffice {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        padding: 40px 15px 38px;
                    }

                    .companyLearnThumb {
                        display: block;
                        margin: 0 auto;
                    }
                }

                .companyLearnText {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {
                        padding: 0 15px;
                        margin-top: 2px;
                        text-align: center;
                        font-size: 16px;
                        line-height: 2.125;
                    }

                    .noteLine1 {
                        @if $type =="" {}

                        @if $type ==sp {}

                        @if $type ==pc {
                            margin-top: 18px;
                        }
                    }
                }
            }
        }

        .companyLearnEnd {
            @if $type =="" {}

            @if $type ==sp {
                padding-top: 30px;
                margin-left: -15px;
                margin-right: -15px;
            }

            @if $type ==pc {}

            p {
                @if $type ==sp {
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 2.125;
                }
            }
        }
    }
}

.pointList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        &.col {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            >li {
                width: 100%;

                &.col2 {
                    width: 48%;

                    .pointListThumb,
                    .pointListText {
                        width: 100%;
                    }

                }
            }

        }
    }

    >li {
        @if $type =="" {}

        @if $type ==sp {
            display: flex;
            flex-direction: column;
        }

        @if $type ==pc {
            display: flex;


        }


        &.addContent {
            @if $type =="" {

                .addBlock,
                .addBlockFull {
                    background: #f2f2f2;
                }
            }

            @if $type ==sp {

                .addBlock,
                .addBlockFull {
                    order: 2;
                    margin-top: 20px;
                    padding: 35px 22px 40px;
                }
            }

            @if $type ==pc {
                flex-wrap: wrap;

                *+.addBlock,
                *+.addBlockFull {
                    margin-top: 40px;
                    padding: 60px;
                }

                .addBlock {
                    display: flex;
                    width: 100%;
                }

                .addBlockFull {
                    width: 100%;
                }
            }
        }

        +li {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 40px;
            }

            @if $type ==pc {
                margin-top: 60px;
            }
        }

        .pointListThumb {
            @if $type =="" {}

            @if $type ==sp {
                text-align: center;
                order: 1;
                margin-top: 18px;
            }

            @if $type ==pc {
                width: 48%;
                margin-right: 4%;
            }
        }

        .pointListText {
            @if $type =="" {}

            @if $type ==sp {
                order: 0;
            }

            @if $type ==pc {
                width: 48%;
            }

            .pointListTitle {
                @if $type =="" {
                    font-weight: 400;
                    position: relative;
                }

                @if $type ==sp {
                    padding-top: 3px;
                    padding-left: 16px;
                    padding-bottom: 3px;
                    font-size: 18px;
                    line-height: 1.2;
                }

                @if $type ==pc {
                    padding-top: 5px;
                    padding-left: 16px;
                    font-size: 18px;
                    line-height: 1.2;
                }

                &:before {
                    @if $type =="" {
                        content: "";
                        display: block;
                        width: 1px;
                        height: 100%;
                        background: $c_base;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                span {
                    @if $type =="" {
                        display: block;
                        color: $c_base;
                        @include fontPo;
                        font-weight: 600;
                    }

                    @if $type ==sp {
                        margin-bottom: 3px;
                        font-size: 14px;
                    }

                    @if $type ==pc {
                        margin-bottom: 3px;
                        font-size: 14px;
                    }
                }
            }
        }

    }
}

// 施工事例
.oa .worksDetailInfo {
    @media all and (-ms-high-contrast: none) {
        //background: #ce891c !important;
        background: rgba(#ececf4, .9) !important;
    }

    .worksDetailInfoText {
        &:before {
            //background: #ce891c;
            background: rgba(#ececf4, .9);
        }
    }
}

.busduct .worksDetailInfo {
    @media all and (-ms-high-contrast: none) {
        background: rgba(#e2eaf1, .9) !important;
    }

    .worksDetailInfoText {
        &:before {
            //background: #1b54c9;
            background: rgba(#e2eaf1, .9) !important;
        }
    }
}

.greening .worksDetailInfo {
    @media all and (-ms-high-contrast: none) {
        //background: #97c91b !important;
        background: rgba(#ebf4ec, .9) !important;
    }

    .worksDetailInfoText {
        &:before {
            //background: #97c91b;
            background: rgba(#ebf4ec, .9);
        }
    }
}

.worksDetailInfo {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
    }

    .worksDetailInfoThumb {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {}

        @if $type ==pc {
            width: 50%;
        }

        img {
            @if $type =="" {}

            @if $type ==sp {
                width: 100%;
                height: auto;
            }

            @if $type ==pc {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            @media all and (-ms-high-contrast: none) {
                height: auto !important;
                object-fit: none;
            }
        }
    }

    .worksDetailInfoText {
        @if $type =="" {
            position: relative;
            overflow: hidden;
        }

        @if $type ==sp {
            padding: 24px 15px 24px;
        }

        @if $type ==pc {
            display: flex;
            align-items: center;
            width: 50%;
            min-height: 430px;
            padding: 78px 50px 78px;
        }

        &:before {
            @if $type =="" {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                //z-index: -1;
                z-index: 1;
            }

            @if $type ==sp {}

            @if $type ==pc {}
        }

        .worksDetailInfoTextBg {
            @if $type =="" {
                display: block;
                width: calc(100% + 7px);
                height: calc(100% + 7px);
                position: absolute;
                top: -7px;
                left: -7px;
                background-size: cover;
                background-repeat: no-repeat;
                filter: blur(7px);
                z-index: 0;
                //opacity: .23;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            @media all and (-ms-high-contrast: none) {
                background: none !important;
            }
        }

        .worksDetailInfoInner {
            @if $type =="" {
                position: relative;
                z-index: 2;
            }

            @if $type ==sp {}

            @if $type ==pc {
                width: 100%;
                padding-right: 56px;
            }

            .worksDetailInfoHeader {
                @if $type =="" {
                    position: relative;
                }

                @if $type ==sp {
                    padding-bottom: 15px;
                    margin-bottom: 25px;
                }

                @if $type ==pc {
                    padding-bottom: 20px;
                    margin-bottom: 34px;
                }

                &:before {
                    @if $type =="" {
                        content: "";
                        display: block;
                        background: $c_base;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }

                    @if $type ==sp {
                        width: 40px;
                        height: 2px;
                    }

                    @if $type ==pc {
                        width: 58px;
                        height: 2px;
                    }
                }

                .worksDetailHeaderTitle {
                    @if $type =="" {
                        font-weight: 400;
                        line-height: 1.4;
                    }

                    @if $type ==sp {
                        font-size: 21px;
                    }

                    @if $type ==pc {
                        font-size: 32px;
                    }
                }

                .worksDetailHeaderTitleSub {
                    @if $type =="" {
                        line-height: 1.4;
                    }

                    @if $type ==sp {
                        margin-top: 6px;
                        font-size: 14px;
                    }

                    @if $type ==pc {
                        margin-top: 6px;
                        font-size: 15px;
                    }
                }
            }

            .worksDetailInfoListWrap {
                @if $type =="" {
                    display: inline-block;
                }
            }

            .worksDetailInfoList {
                @if $type =="" {
                    display: flex;
                    flex-wrap: wrap;
                }

                @if $type ==sp {
                    //     padding: 5px 10px 10px;
                }

                @if $type ==pc {
                    margin-bottom: 36px;
                    //     padding: 3px 3px 10px 10px;
                }

                //    &.bd {
                //     @if $type =="" {
                //      background: #ebf0f4;
                //     }
                //    }

                //    &.oa {
                //     @if $type =="" {
                //      background: #ececf4;
                //     }
                //    }

                //    &.gr {
                //     @if $type =="" {
                //      background: #ebf4ec;
                //     }
                //    }

                >li {
                    @if $type =="" {
                        color: #fff;
                        background: $c_navy;
                        line-height: 1.4;
                    }

                    @if $type ==sp {
                        padding: 3px 10px;
                        margin: 5px 5px 0 0;
                        font-size: 13px;
                    }

                    @if $type ==pc {
                        padding: 5px 20px 6px;
                        margin: 7px 7px 0 0;
                        font-size: 13px;
                    }


                    &.tag {
                        &.bd {
                            background: #4c95d0;
                        }

                        &.oa {
                            background: #5958ac;
                        }

                        &.gr {
                            background: #96c55c;
                        }
                    }
                }

            }

            .worksDetailInfoData {
                @if $type =="" {
                    line-height: 1.4;
                }

                @if $type ==sp {
                    margin-top: 25px;
                }

                @if $type ==pc {}

                >div {
                    @if $type =="" {
                        display: flex;
                        align-items: flex-start;
                    }

                    @if $type ==sp {

                        +div {
                            margin-top: 8px;
                        }
                    }

                    @if $type ==pc {

                        +div {
                            margin-top: 8px;
                        }
                    }

                    >dt {
                        @if $type =="" {
                            color: #0b0b0b;
                            text-align: center;
                            background: #fff;
                        }

                        @if $type ==sp {
                            min-width: 60px;
                            padding: 2px 0 3px;
                            margin-right: 10px;
                            font-size: 13px;
                        }

                        @if $type ==pc {
                            min-width: 70px;
                            padding: 4px 0 5px;
                            margin-right: 20px;
                            font-size: 14px;
                        }
                    }

                    >dd {
                        @if $type =="" {}

                        @if $type ==sp {}

                        @if $type ==pc {
                            padding: 1px 0;
                        }
                    }
                }
            }
        }
    }
}

.inner {
    &.boxShadow {
        @if $type =="" {}

        @if $type ==sp {
            padding-bottom: 20px;
        }

        @if $type ==pc {
            padding: 40px;
            box-shadow: 0 0 16px rgba(0, 0, 0, .1);
        }
    }
}

*:not(.title)+.innerBody .coutList:first-child,
*+.coutList {
    @if $type =="" {
        margin-top: 1.5em;
    }
}

.countList {
    @if $type =="" {
        list-style-type: none;
        counter-reset: item;
    }

    @if $type ==sp {
        &.mutual_sp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 0;

            >li {
                width: calc(50% - 1em);

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }

    @if $type ==pc {
        &.mutual_pc {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 0;

            >li {
                width: calc(50% - 20px);

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }

    >li {
        padding-left: 20px;
        position: relative;

        &:before {
            counter-increment: item;
            content: counter(item)'.';
            position: absolute;
            left: 0;
        }

    }
}

.imgInP {
    @if $type ==sp {}

    @if $type ==pc {
        overflow: hidden;
    }

    .img {
        @if $type ==sp {
            display: block;
            margin: 20px auto;
            padding-bottom: 10px;
        }

        @if $type ==pc {
            max-width: 480px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .cap {
            @if $type =="" {
                display: block;
                text-align: left;
                //font-size: 14px;
            }
        }
    }

    .img.left {
        @if $type ==sp {
            text-align: center;
        }

        @if $type ==pc {
            float: left;
            margin-right: 20px;
        }
    }

    .img.right {
        @if $type ==sp {
            text-align: center;
        }

        @if $type ==pc {
            float: right;
            margin-left: 20px;
        }
    }

    &.sp_img_btm {
        @if $type ==sp {
            display: flex;
            flex-wrap: wrap;

            .img {
                display: block;
                order: 2;
                margin: 10px auto 0;
                padding-bottom: 0;
            }

            .text {
                flex-basis: 100%;
                order: 1;
            }
        }
    }
}


.contentLead {
    @if $type =="" {
        display: flex;
    }

    @if $type ==sp {
        flex-direction: column;
    }

    @if $type ==pc {
        justify-content: space-between;
        max-width: 1120px;
        padding-top: 15px;
        margin: 0 auto;
    }

    .summaryInfo {
        @if $type =="" {}

        @if $type ==sp {
            order: 1;
        }

        @if $type ==pc {
            width: 47%;
        }

        .summaryInfoThumb {
            @if $type =="" {
                position: relative;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            figure img {
                @if $type =="" {
                    width: 100%;
                }
            }

            ul {
                @if $type =="" {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: -12px;
                }

                @if $type ==sp {}

                @if $type ==pc {}

                >li {
                    @if $type =="" {}

                    @if $type ==sp {
                        width: 16.4vw;
                        max-width: 93px;
                        margin-right: -.5em;
                    }

                    @if $type ==pc {
                        max-width: 93px;
                        margin-right: -16px;
                    }

                    @media all and (-ms-high-contrast: none) and (min-width: 981px) {
                        width: 93px;
                    }
                }
            }
        }

        *+.summaryInfoDataList {
            @if $type =="" {}

            @if $type ==sp {
                padding: 12px 12px;
                margin-top: 12px;
            }

            @if $type ==pc {
                padding: 16px 19px 16px;
                margin-top: 18px;
            }
        }

        .summaryInfoDataList {
            @if $type =="" {
                display: flex;
                background: #ebf0f4;
                flex-wrap: wrap;
            }

            @if $type ==sp {}

            @if $type ==pc {}

            >li {
                @if $type =="" {
                    color: #fff;
                    font-weight: bold;
                    text-align: center;
                    background: #5958ac;
                }

                @if $type ==sp {
                    width: calc(50% - 3.5px);
                    padding: 7px;
                    border-radius: 5px;
                    line-height: 1.4;

                    &:nth-child(odd) {
                        margin-right: 7px;
                    }

                    &:nth-child(n + 3) {
                        margin-top: 7px;
                    }
                }

                @if $type ==pc {
                    width: calc(50% - 3.5px);
                    padding: 9px 8px 8px;
                    font-size: 16px;
                    border-radius: 5px;

                    &:nth-child(odd) {
                        margin-right: 7px;
                    }

                    &:nth-child(n + 3) {
                        margin-top: 7px;
                    }
                }
            }
        }

        //不要かも
        .summaryInfoData {
            @if $type =="" {
                background: #f2f2f2;
            }

            @if $type ==sp {
                padding: 15px 20px;
                margin-top: 20px;
            }

            @if $type ==pc {
                padding: 20px 7.63%;
                margin-top: 20px;
            }

            dl:not([class]) {
                @if $type =="" {}

                @if $type ==sp {}

                @if $type ==pc {
                    font-size: 15px;
                }

                >div {
                    @if $type =="" {}

                    @if $type ==sp {
                        +div {
                            margin-top: .5em;
                        }
                    }

                    @if $type ==pc {
                        display: flex;
                    }

                    dt {
                        @if $type =="" {
                            position: relative;
                        }

                        @if $type ==sp {}

                        @if $type ==pc {
                            width: 6em;
                            padding-right: .5em;
                        }

                        &:before {
                            @if $type =="" {}

                            @if $type ==sp {}

                            @if $type ==pc {
                                content: ":";
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0.4em;
                                margin: auto;
                            }
                        }
                    }

                    dd {
                        @if $type =="" {}

                        @if $type ==sp {
                            font-size: 12px;
                        }

                        @if $type ==pc {
                            width: calc(100% - 5em);
                        }
                    }
                }
            }
        }

    }

    //不要かも　ここまで 

    .summaryText {
        @if $type =="" {}

        @if $type ==sp {
            order: 0;
            margin-bottom: 30px;
        }

        @if $type ==pc {
            display: flex;
            justify-content: space-between;
            width: 48%;
        }

        .summaryTextMain {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {}

            .summaryTextCatch {
                @if $type =="" {}

                @if $type ==sp {
                    font-size: 16px;
                }

                @if $type ==pc {
                    font-size: 18px;
                }
            }

            .summaryTextTitle {
                @if $type =="" {
                    font-weight: 500;
                    line-height: 1.4;
                    position: relative;
                }

                @if $type ==sp {
                    padding-bottom: 8px;
                    margin-top: 10px;
                    font-size: 21px;
                }

                @if $type ==pc {
                    padding-bottom: 13px;
                    margin-top: 16px;
                    font-size: 32px;
                }

                &:before {
                    @if $type =="" {
                        //content: "";
                        background: $c_base;
                        height: 2px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    @if $type ==sp {
                        width: 60px;
                    }

                    @if $type ==pc {
                        width: 60px;
                    }
                }
            }

            .summaryTextTitleSub {
                @if $type =="" {
                    line-height: 1.4;
                }

                @if $type ==sp {
                    margin-top: 25px;
                    font-size: 16px;
                }

                @if $type ==pc {
                    margin-top: 50px;
                    font-size: 22px;
                }
            }

            .summaryTextNote {
                @if $type =="" {}

                @if $type ==sp {
                    font-size: 13px;
                }

                @if $type ==pc {
                    font-size: 15px;
                }
            }
        }

        .summaryTextCategory {
            @if $type ==pc {
                margin-top: 20px;
            }
        }

    }
}

.productTop {
    &.inner.boxShadow {
        @if $type =="" {}

        @if $type ==sp {
            padding: 51px 20px 0;
        }

        @if $type ==pc {
            padding: 40px !important;
        }

        .contentLead {
            @if $type =="" {
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                text-shadow: 0 0 8px #fff;
            }

            @if $type ==sp {
                background-position: center;
            }

            @if $type ==pc {
                min-height: 444px;
                padding-top: 0;
                background-position: center right;
            }

            &:before {
                @if $type =="" {
                    content: "";
                    height: 100%;
                    position: absolute;
                }

                @if $type ==sp {
                    width: 100%;
                    background: #fff;
                    opacity: .5;
                    top: 0;
                    left: 0;
                }

                @if $type ==pc {
                    width: 130%;
                    background: rgb(255, 255, 255);
                    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 97%);
                    top: 0;
                    left: -60%;
                    opacity: .9;
                }

            }
        }

        .summaryText {
            @if $type =="" {
                width: 100%;
                text-shadow: 0 0 4px #fff;
            }

            @if $type ==sp {
                align-items: flex-end;
                padding: 80px 20px 70px;
                margin-bottom: 0;
                position: relative;
            }

            @if $type ==pc {
                padding: 0 40px 0;
                flex-direction: row;
                align-items: center;
            }

            .summaryTextMain {
                @if $type ==sp {}

                .summaryTextTitle {
                    @if $type =="" {}

                    @if $type ==sp {}

                    @if $type ==pc {}

                    &:before {
                        @if $type =="" {
                            display: none;
                        }

                        @if $type ==sp {}

                        @if $type ==pc {}
                    }
                }

                .summaryTextCatch {
                    @if $type =="" {
                        text-shadow: 0 0 4px #fff;
                    }

                    @if $type ==sp {
                        margin-top: 30px;
                    }

                    @if $type ==pc {
                        margin-top: 33px;
                        line-height: 2.2;
                        position: relative;
                    }
                }
            }
        }


    }
}

.hanging {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 20px;
    }

    @if $type ==pc {
        margin-top: 20px;
        font-size: 13px;
    }

    td:first-child {
        @if $type =="" {
            padding-right: 5px;
            white-space: nowrap;
        }
    }

    td {
        @if $type =="" {
            vertical-align: top;
        }
    }
}

*+.infoData {
    @if $type =="" {}

    @if $type ==sp {
        margin-top: 16px;
    }

    @if $type ==pc {
        margin-top: 16px;
    }
}

.infoData {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        display: flex;
    }

    .infoDataThumb {
        @if $type =="" {}

        @if $type ==sp {
            figure {
                text-align: center;
            }

        }

        @if $type ==pc {
            width: 48%;
            margin-right: 4%;
        }
    }

    .infoDataText {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 20px;
        }

        @if $type ==pc {
            width: 48%;
            font-size: 14px;
        }


    }
}

*+.titleStrong {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
        margin-top: 14px;
    }
}

.titleStrong {
    @if $type =="" {
        font-weight: bold;
    }

    @if $type ==sp {
        font-size: 16px;
    }

    @if $type ==pc {
        font-size: 18px;
    }
}

.titleStrong+p {
    @if $type =="" {
        margin-top: 0;
    }

    @if $type ==sp {}

    @if $type ==pc {}
}

//お知らせ系
.postContent {
    @if $type =="" {}

    @if $type ==sp {
        line-height: 1.8571;
    }

    @if $type ==pc {
        max-width: 1000px;
        margin: 0 auto;
        font-size: 16px;
        line-height: 2.25;
    }

    .postDate {
        @if $type =="" {
            color: #acaaaa;
        }

        @if $type ==sp {}

        @if $type ==pc {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    h1 {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 9px;
            margin-bottom: 15px;
            font-size: 21px;
            line-height: 1.619;
        }

        @if $type ==pc {
            margin-top: 15px;
            margin-bottom: 34px;
            font-size: 28px;
            line-height: 1.4;
        }
    }

    h1+.thumbArea {
        @if $type =="" {}

        @if $type ==sp {
            padding-right: 0;
            padding-left: 0;
        }

        @if $type ==pc {
            max-width: inherit;
            width: 100%;
        }

        img {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
                max-width: inherit;
                width: 100%;
            }
        }
    }

    .postTag {
        @if $type =="" {
            display: flex;
            justify-content: flex-end;
            color: #fff;
        }

        @if $type ==sp {
            flex-wrap: wrap;
            align-items: center;
            text-align: center;
            font-size: 11px;
        }

        @if $type ==pc {
            flex: none;
            font-size: 13px;
            text-align: center;
        }

        span {
            @if $type =="" {
                display: flex;

                &.tagProduct,
                &.tagNews,
                &.tagPressrelease,
                &.tagContribution {
                    order: 0;
                }

                &.tagProduct {
                    background: #556fb5;
                }

                &.tagNews {
                    background: #c90056;
                }

                &.tagPressrelease {
                    background: #b59355;
                }

                &.tagContribution {
                    background: #46976e;
                }

                &.tagColumn {
                    background: $c_base;
                }

                &:not([class]) {
                    order: 1;
                    background: $c_navy;
                }
            }

            @if $type ==sp {
                padding: 0 1em;
                margin: 3px 1em 4px 0;
                line-height: 20px;
            }

            @if $type ==pc {
                padding: 0 1em;
                margin: 3px 1em 4px 0;
            }
        }
    }

    .postTag+.thumbArea {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
            max-width: inherit;
        }
    }

    .thumbArea {
        @if $type =="" {}

        @if $type ==sp {
            padding-right: 20px;
            padding-left: 20px;
            margin: 0 auto 14px;
        }

        @if $type ==pc {
            max-width: 600px;
            margin: 0 auto 40px;
        }

        p+p {
            @if $type =="" {}

            @if $type ==sp {
                margin-top: 2px;
                //font-size: 12px;
            }

            @if $type ==pc {
                margin-top: 5px;
                //font-size: 13px;
            }
        }
    }

    *:not(.title)+.innerBody .thumbArea:first-child,
    *+.thumbArea {
        @if $type =="" {}

        @if $type ==sp {
            margin-top: 30px;
        }

        @if $type ==pc {
            margin-top: 40px;
        }
    }

}

#footContact {
    @if $type =="" {
        border-top: 1px solid #e2e2e2;
    }
}

html>body .gsc-inline-block {
    @if $type =="" {
        min-width: 100px;
    }
}

/*240515 add*/
.tourokubtn-ky a {
    @if $type =="" {
        display: block;
        color: #fff;
        text-decoration: none;
        background: $c_navy;
        position: relative;
    }

    @if $type ==sp {
        padding: 4px 20px 4px 20px;
    }

    @if $type ==pc {
        padding: 4px 10px 4px 10px;
    }

    @if $type ==pc {
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: .2s width;
            z-index: 0;
            background: #13192a;
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }

    /*>span.icon {
        @if $type ==pc {
            position: relative;
        }

        &:before {
            @if $type =="" {
                content: "";
                display: block;
                background: url(../images/common/icon_dash_white.svg) no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            @if $type ==sp {
                width: 9px;
                height: 9px;
                right: 10px;
            }

            @if $type ==pc {
                width: 9px;
                height: 9px;
                right: -10px;
            }
        }
    }*/

    >span.txt {
        position: relative;
        z-index: 10;
    }

}



        

// import //

// use Slick 
//@import "_slick";
//@import "_slick-theme";